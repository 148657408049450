import React, { useState } from 'react';
import { Row, Col, message, Card, Empty } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { getReport, exportReportPdf } from '../../../../../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

export default (props) => {
  const { meta, id } = props;
  const [data, setData] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const [subText, setSubText] = useState(null);
  const i18n = useTranslate();
  const { t } = i18n;

  const btnList = [];

  const onSearch = async (search) => {
    props.setLoading(true);
    if (search) {
      let searching = {
        report_type: search?.group?.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : null,
        time_from: search?.time_from ? dayjs(search?.time_from).format("HH:mm:ss") : null,
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
        time_to: search?.time_to ? dayjs(search?.time_to).format("HH:mm:ss") : null,
        shift_no: search?.shift_no?.value,
        session_hour: search?.session_hour?.value,
        category_level: search?.category_level?.value,
        group_by: search?.group_by?.value,
        layout: search?.layout?.value,
        sales_by: search?.sales_by?.value,
        username: search?.username,
        hide_cancelled: search?.hide_cancelled ? "1" : "0",
        hide_voided: search?.hide_voided ? "1" : "0",
        is_consolidate: search?.is_consolidate ? "1" : "0",
        record_count: search?.record_count,
        id: id
      };
      setSearchVal(searching);
      await getReport(searching).then(res => {
        props.setLoading(false);
        if(res.data.code === 200) {
          let resData = res.data.data;
          setData(resData);
          setSubText(`${resData?.meta_data?.length} record(s)`);
        }else {
          message.error(res.data.message);
        }
      }).catch(e => {
        props.setLoading(false);
        message.error(e.message);
      });
    } else {
      setSearchVal(null);
    }
  };

  const downloadPdf = async () => {
    if (!data?.id) {
      return message.error(t("General.invalid_req"));
    }
    if (data?.meta_data?.length <= 0) {
      return message.warning(t("General.no_data_print"));
    }

    props.setLoading(true);

    const payload = `id=${id}&activity_id=${data?.id}`;

    await exportReportPdf(payload).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={t("General.report")} btnList={btnList} subText={subText} />
      </Col>
      <Col span={24} className="clickRow">
        <Search meta={meta} onSearch={onSearch} downloadPdf={downloadPdf} reportType={''} />
        { " " }
        {/* data.each <span> {d}</span> */}
        
      </Col>
      <Col span={24}>
        {
          data?.meta_data?.length > 0 ?
            <Row gutter={[10, 10]} justify={'center'}>
              {
                data?.meta_data?.map((item, index) => {
                  return (
                    <Col xs={24} md={22} xl={11} xxl={8} className='mt-2' key={`meta data ${index}`}>
                      <Card bordered className='text-center'>
                        {
                          item?.map((v, i) => {
                            return (
                              <pre key={`meta data item ${index}`}>{v.value}</pre>
                            )
                          })
                        }
                      </Card>
                    </Col>
                  )
                })
              }
            </Row>
            :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </Col>
    </Row>
  )
}