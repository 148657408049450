import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, message, Button, Form } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { updateOutletEmsUser } from '../../../../ducks/services'
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';
const _ = require('lodash');

export default (props) => {

  const { id, meta, data, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'user',
      label: t("Outlet.outlet_manager"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'select',
      options: meta?.ems_users?.map((x) => ({ label: x.name, value: x.id })),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'roles',
      label: t("Outlet.roles"),
      req: true,
      type: 'select',
      options: meta?.roles?.map((x) => ({ label: x.name, value: x.id })),
      placeholder: t("General.please_select"),
      twocol: false,
      multiple: true,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if(data) {
      let temp = [];
      data?.roles?.map(role => {
        temp.push({
          label: role.name,
          value: role.id
        })
      })
      setValue('roles', temp);
      setValue('user', data?.user_id ? { label: data?.user_name, value: data?.user_id} : '');
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      user_id: val?.user?.value,
      roles: JSON.stringify(val?.roles?.map(x => ({id: x.value}))),
      id: id //outlet_id
    }

    await updateOutletEmsUser(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.update_ems_user"));
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    });
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[10, 10]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}