import React, { useEffect } from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, DateField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit, setValue } = useForm()
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    if (searchVal) {
      if (searchVal?.status) {
        setValue("status", props.field1.find(x => x.value === searchVal?.status));
      }
      if (searchVal?.outlet) {
        setValue("outlet", { label: props.field2.find(y => y.value === searchVal?.outlet)?.label, value: searchVal?.outlet });
      }
      if (searchVal?.netsuite_synced) {
        setValue("netsuite_synced", props.field3?.find(x => x.value === searchVal?.netsuite_synced));
      }
      if (searchVal?.is_netsuite_adjustment) {
        setValue("is_netsuite_adjustment", props.field4?.find(x => x.value === searchVal?.is_netsuite_adjustment));
      }
      if (searchVal?.date) {
        setValue("date", dayjs(searchVal?.date));
      }
    }
  }, [searchVal, props]);

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <InputField
            fieldname='reference'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.reference ? searchVal?.reference : ''}
            control={control}
            iProps={{ placeholder: `${t("General.search_gr_po_no")}..` }}
          />
        </Col>
        <Col flex="auto">
          <DateField
            fieldname='date'
            label=''
            initValue={searchVal?.date ? dayjs(searchVal?.date) : ''}
            control={control}
            class='mb-0 w-10'
            iProps={{ placeholder: 'GR Invoice Date', size: 'large' }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='status'
            label=''
            class='mb-0 w-10 default-select'
            initValue={props.field1?.[0]}
            control={control}
            iProps={{ placeholder: t("General.status") }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='outlet'
            label=''
            class='mb-0 w-10 default-select'
            initValue={props.field2?.[0]}
            control={control}
            iProps={{ placeholder: t("General.outlet") }}
            selectOption={props.field2}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='netsuite_synced'
            label=''
            class='mb-0 w-100 default-select'
            initValue={props.field3?.[0]}
            control={control}
            selectOption={props.field3}
            iProps={{ placeholder: t("Inventory.netsuite_synced") }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='is_netsuite_adjustment'
            label=''
            class='mb-0 w-100 default-select'
            initValue={props.field4?.[0]}
            control={control}
            selectOption={props.field4}
            iProps={{ placeholder: 'Is Netsuite Adjustment' }}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>{t("General.search")}</Button>
        </Col>
      </Row>
    </Form>
  )
}