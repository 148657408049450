import React, { Fragment } from 'react';
import { useTranslate } from 'Translate';

export default (props) => {
  const { columns, data } = props;
  const i18n = useTranslate();
    const { t } = i18n;

  return (
    <div className="inventory-table-container">
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.st_details_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) => 
              <Fragment key={`List Item ${listItemIndex}`}>
                {listItem?.list?.map((childListItem, childListItemIndex) => 
                  <Fragment key={`Child List Item ${childListItemIndex}`}>
                    {childListItem?.list?.map((grandChildListItem, grandChildListItemIndex) => 
                      <tr key={`List Item ${listItemIndex}: Child List Item ${childListItemIndex} Grand List Item ${grandChildListItemIndex}`}>
                        <td>{listItem.date}</td>
                        <td>{childListItem.location}</td>
                        <td>{childListItem.reference}</td>
                        <td>{grandChildListItem.item}</td>
                        <td>{grandChildListItem.status}</td>
                        <td>{grandChildListItem.uom}</td>
                        <td align='right'>{grandChildListItem.qty}</td>
                        <td align='right'>{grandChildListItem.system_qty}</td>
                        <td align='right'>{grandChildListItem.discrepancy_qty}</td>
                        <td align='right'>{grandChildListItem.unit_cost}</td>
                        <td align='right'>{grandChildListItem.inv_cost}</td>
                      </tr>
                    )}
                  </Fragment>
                )}
                <tr className='bg-gray text-white'>
                  <td colSpan={5}></td>
                  <td colSpan={listItem?.sub_totals?.length === 0 ? 20 : 1}>{t("Crm.subtotal")}</td>
                  {listItem?.sub_totals?.map((item, index) => 
                    <td key={`Sub Total ${item} ${index}`} align='right'>{item}</td>
                  )}
                </tr>
              </Fragment>
            )}

            
            <tr className='bg-gray text-white'>
              <td colSpan={5}></td>
              <td>{t("SalesReporting.grand_total")}</td>
              {data?.grand_total?.map((item, index) => 
                <td key={`Grand Total ${item} ${index}`} align='right'>{item}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}