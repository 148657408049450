import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {

  const { errors, control, meta, allowedEdit } = props
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("General.code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'name',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'printer_type',
      label: t("Pos.printer_type"),
      req: true,
      type: 'select',
      options: meta?.printer_types?.map((x) => ({ label: x, value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    }
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}