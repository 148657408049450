import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';
import { useTranslate } from 'Translate';

export default (props) => {
  const { setActiveTab, oneTab, searchParams } = props;
  const defaultActive = searchParams?.get('t');
  const i18n = useTranslate();
  const { t } = i18n;

  const tabs = [
    {
      name: t("General.details"),
      Comp: 'Outlet',
      allowedView: true
    },
    {
      name: t("Outlet.receipt"),
      Comp: 'PriceType',
      allowedView: true
    },
    {
      name: t("General.ems_user"),
      Comp: 'RegisteredUser',
      allowedView: true
    },
    {
      name: t("General.pos_user"),
      Comp: 'PosUser',
      allowedView: true
    },
    {
      name: t("Outlet.blocked_order_type"),
      Comp: 'BlockedOrderType',
      allowedView: true
    },
    {
      name: t("Outlet.blocked_item"),
      Comp: 'BlockedItem',
      allowedView: true
    },
    {
      name: t("Outlet.fa_integration"),
      Comp: 'FaIntegration',
      allowedView: allowed([AllRoles.OUTLET.FAINTEGRATION], 'read')
    },
    {
      name: t("Outlet.mall_integration"),
      Comp: 'MallIntegration',
      allowedView: allowed([AllRoles.OUTLET.MALLINTEGRATION], 'read')
    },
  ];

  return (
    <Tabs items={
        (oneTab ? [tabs[0]] : tabs).filter(x => x.allowedView).map((item, index) => {
          const Cardi = TabCards[item.Comp];
          return {
            key: (index + 1).toString(),
            label: item.name,
            forceRender: true,
            children: <Cardi {...props} />
          }
        })
    } defaultActiveKey={defaultActive || "1"} onChange={(activeKey) => setActiveTab(activeKey)}/>
  );
};
