import React from 'react';
import Icon from '@ant-design/icons';

const ValueSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="22" viewBox="0 0 52 22">
    <g id="Group_25315" data-name="Group 25315" transform="translate(403.573 -331.556)">
      <g id="Group_25250" data-name="Group 25250">
        <path
          id="Path_28651"
          data-name="Path 28651"
          d="M-395.8,353.251a.736.736,0,0,1-.523-.214.726.726,0,0,1-.223-.548v-14.5l-4.081,3.2a.636.636,0,0,1-.55.152.835.835,0,0,1-.493-.335l-1.757-2.346a.846.846,0,0,1-.134-.6.674.674,0,0,1,.342-.5l6.853-5.426a1.2,1.2,0,0,1,.342-.166,1.406,1.406,0,0,1,.372-.047h3.724a.739.739,0,0,1,.522.213.737.737,0,0,1,.223.551v19.805a.726.726,0,0,1-.223.548.736.736,0,0,1-.522.214Z"
          fill="#7c7c7c"
        />
      </g>
      <g id="Group_25251" data-name="Group 25251">
        <path
          id="Path_28652"
          data-name="Path 28652"
          d="M-387.28,353.251a.769.769,0,0,1-.552-.214.722.722,0,0,1-.223-.548v-2.165a2.79,2.79,0,0,1,.119-.654,1.652,1.652,0,0,1,.625-.838l3.1-3.107q2.384-1.86,3.918-3.155a14.541,14.541,0,0,0,2.279-2.3,3.237,3.237,0,0,0,.744-1.889,2.769,2.769,0,0,0-.521-1.721,2.135,2.135,0,0,0-1.8-.685,2.591,2.591,0,0,0-1.445.38,2.47,2.47,0,0,0-.879.992,4.806,4.806,0,0,0-.417,1.247.864.864,0,0,1-.4.55,1.26,1.26,0,0,1-.61.152h-3.724a.592.592,0,0,1-.447-.184.623.623,0,0,1-.178-.456,6.912,6.912,0,0,1,.609-2.743,7.128,7.128,0,0,1,1.639-2.269,7.5,7.5,0,0,1,2.533-1.539,9.485,9.485,0,0,1,3.321-.548,10.182,10.182,0,0,1,4.3.823,6.114,6.114,0,0,1,2.7,2.3,6.411,6.411,0,0,1,.922,3.459,6.207,6.207,0,0,1-.64,2.789,9.367,9.367,0,0,1-1.817,2.468,30.3,30.3,0,0,1-2.786,2.422l-2.74,2.835H-372a.76.76,0,0,1,.551.213.722.722,0,0,1,.223.548v3.077a.722.722,0,0,1-.223.548.77.77,0,0,1-.551.214Z"
          fill="#7c7c7c"
        />
      </g>
      <path
        id="Path_28653"
        data-name="Path 28653"
        d="M-360.332,353.556a12.282,12.282,0,0,1-3.961-.563,8.141,8.141,0,0,1-2.682-1.463,6.2,6.2,0,0,1-1.549-1.92,4.783,4.783,0,0,1-.537-1.966.594.594,0,0,1,.194-.456A.653.653,0,0,1-368.4,347h3.873a1.015,1.015,0,0,1,.566.137,1.315,1.315,0,0,1,.386.441,2.284,2.284,0,0,0,.7.945,2.631,2.631,0,0,0,1.118.5,6.934,6.934,0,0,0,1.429.136,3.576,3.576,0,0,0,2.369-.714,2.435,2.435,0,0,0,.848-1.966,2.131,2.131,0,0,0-.8-1.875,3.978,3.978,0,0,0-2.293-.563h-3.307a.722.722,0,0,1-.537-.213.749.749,0,0,1-.208-.548v-1.706a1.3,1.3,0,0,1,.149-.64,1.1,1.1,0,0,1,.327-.4l4.886-4.236h-8.281a.739.739,0,0,1-.522-.213.723.723,0,0,1-.224-.548v-2.864a.734.734,0,0,1,.224-.551.739.739,0,0,1,.522-.213h13.7a.771.771,0,0,1,.551.213.734.734,0,0,1,.224.551v2.589a1.181,1.181,0,0,1-.149.6,1.541,1.541,0,0,1-.329.411l-4.527,4.3.208.062a8.671,8.671,0,0,1,3.039.93,5.394,5.394,0,0,1,2.116,1.994,6.23,6.23,0,0,1,.774,3.261,5.74,5.74,0,0,1-1.148,3.58,7.374,7.374,0,0,1-3.113,2.331A11.568,11.568,0,0,1-360.332,353.556Z"
        fill="#7c7c7c"
      />
    </g>
  </svg>
);

const ValueIcon = (props) => <Icon component={ValueSVG} {...props} />;
export default ValueIcon;
