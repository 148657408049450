import React, { useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, data, addIngredient, editIngredient, convertIngredient, reloadApi, mode, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("Product.ing_code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit || data?.editable === "0"
    },
    {
      name: 'name',
      label: t("Outlet.name"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit || data?.editable === "0"
    },
    {
      name: 'unit_cost',
      label: t("Product.def_inv_unit_cost"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
      allowNegDecNum: true
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data.code);
      setValue('name', data.name);
      setValue('unit_cost', data.unit_cost);
    }
  }, [data]);

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    await addIngredient({ ...val }).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.ing_create_success"));
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      id: id
    }

    await editIngredient(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.ing_update_success"));
        setTimeout(() => reloadApi(), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const convertToSemi = async () => {
    if(data?.product_type !== "ingredient") {
      return message.error(t("Product.ing_cannot_convert"));
    }

    if(confirm(t("Product.confirm_convert_ing"))){
      props.setLoading(true);
      const payload = {
        id: id
      }

      await convertIngredient(payload).then(res => {
        props.setLoading(false);
        if (res.data.code === 200) {
          message.success(t("Product.ing_convert_success"));
          setTimeout(() => reloadApi(), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        props.setLoading(false);
        message.error(e.message ?? t("General.something_went_wrong"));
      })
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            {
              mode !== "add" && data?.product_type === "ingredient" &&
              <Button size="large" className='black-btn mr-1' onClick={() => convertToSemi()}>{t("Product.convert_semi_product")}</Button>
            }
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}