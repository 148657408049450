import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderTypeOption } from "../../ducks/actions";
import { addOrderType } from "../../ducks/services";
import { useTranslate } from 'Translate';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [fileId, setFileId] = useState();
  const [fileList, setFileList] = useState([]);
  const meta = useSelector(state => state.product.orderTypeOption);
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    dispatch(getOrderTypeOption(props.setLoading));
  }, []);

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      code: val.code,
      desc: val.desc,
      tax_condition_id: val?.tax_condition?.value,
      order_type_group_id: val?.order_type_group?.value,
      is_fa: val?.is_fa ? "1" : "0",
      blob_id: fileId
    }
    await addOrderType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.order_type_create_success"));
        setTimeout(() => navigate("/product/order-type"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>{t("Outlet.new_order_type")}</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                <Button size="large" htmlType='submit' className='green-btn'>{t("General.save")}</Button>
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/product/order-type')}>{'<'} {t("General.back")}</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <InputForm
            control={control}
            errors={errors}
            fileList={fileList}
            setFileList={setFileList}
            setFileId={setFileId}
            setLoading={props.setLoading}
            meta={meta}
            allowedEdit={true}
          />
        </Col>
      </Row>
    </Form>
  )
}