import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getTcItemList } from '../../../../../ducks/actions'
import { editTcItem, addTcItem, removeTcItem } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues, clearErrors } = useForm();
  const poItems = useSelector((state) => state.systems.tcItems);
  const meta = useSelector((state) => state.systems.tcItemsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const i18n = useTranslate();
  const { t } = i18n;

  const colName = [
    {
      title: t("System.tax_con_line"),
      dataIndex: 'position',
      key: 'position',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("System.tax_line_desc"),
      dataIndex: 'desc',
      key: 'desc',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("General.status"),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {t(`General.${text?.toLowerCase()}`)}
          </span>
          {
            allowedEdit &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? `(${t("General.deactivate")})` : `(${t("General.activate")})`}
            </Button>
          }
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="tax_condition_item"
          btnAction1={() => {
            setVisible(true);
            setValue('id', record.id);
            setValue('position', record.position);
            setValue('desc', record.desc);
            setValue('effective_date', record?.effective_date ? dayjs(record?.effective_date) : '');
            setValue('obsolete_date', record?.obsolete_date ? dayjs(record?.obsolete_date) : '');
            setValue('tax_method', record.tax_method ? { label: record?.tax_method, value: record.tax_method } : '');
            setValue('is_inclusive', record.is_inclusive == "1");
            setValue('taxable', record.taxable == "1");
            setValue('tax_amount', record.tax_amount);
            setValue('tax', record.tax_id ? { label: `${record?.tax_code} - ${record?.tax_desc}`, value: record.tax_id } : '');
          }}
          onRemove={allowedDelete && onRemove}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      type: 'input',
      label: t("General.position"),
      name: 'position',
      twocol: false,
      number: true,
      colWidth: '0 1 50%',
      placeholder: t("General.please_state"),
      req: true,
      reqmessage: 'Position Required',
      static: !allowedEdit
    },
    {
      type: 'input',
      label: t("System.tax_line_desc"),
      name: 'desc',
      twocol: false,
      colWidth: '0 1 50%',
      placeholder: t("General.please_state"),
      req: true,
      reqmessage: 'Descrption Required',
      static: !allowedEdit
    },
    {
      type: 'date',
      label: t("Product.eff_date"),
      name: 'effective_date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      placeholder: t("General.please_state"),
      req: true,
      reqmessage: 'Effective Date Required',
      static: !allowedEdit
    },
    {
      type: 'date',
      label: t("System.obsolete_date"),
      name: 'obsolete_date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      placeholder: t("General.please_state"),
      req: false,
      reqmessage: 'Obsolete Date Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      name: 'tax_method',
      label: t("System.tax_method"),
      req: true,
      placeholder: t("General.please_select"),
      class: 'default-select',
      options: _.map(meta?.tax_methods, (e) => ({ label: e, value: e })),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
      // onChange: (e) => {
      //   setIsPerc(e.value === "")
      // }
    },
    {
      type: 'select',
      label: t("General.tax"),
      name: 'tax',
      twocol: false,
      req: true,
      class: 'default-select',
      options: meta?.taxes?.map((x) => ({ label: `${x.code} - ${x.desc}`, value: x.id })),
      placeholder: t("General.please_select"),
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      type: 'input',
      label: t("System.tax_value"),
      name: 'tax_amount',
      number: true,
      // suffix: '%',
      twocol: false,
      colWidth: '0 1 50%',
      placeholder: t("General.please_state"),
      req: true,
      reqmessage: 'Tax Amount Required',
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_inclusive',
      label: t("System.is_inclusive"),
      req: false,
      twocol: false,
      colWidth: '1 0 100%',
      alignEnd: true
    },
    {
      type: 'switch',
      name: 'taxable',
      label: t("System.taxable"),
      req: false,
      twocol: false,
      colWidth: '1 0 100%',
      alignEnd: true
    },
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getTcItemList(1, limit, '', '', null, props.setLoading, id));
  }, [activeTab]);

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('position', '');
    setValue('desc', '');
    setValue('effective_date', '');
    setValue('obsolete_date', '');
    setValue('tax_method', '');
    setValue('is_inclusive', false);
    setValue('taxable', false);
    setValue('tax_amount', '');
    setValue('tax', '');
    clearErrors();
  }

  const btnList = [
    {
      text: `+ ${t("System.new_item")}`,
      classes: 'attendance-success',
      action: () => addNew(),
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getTcItemList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getTcItemList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        desc: search?.desc
      };
      setSearchVal(searching);
      dispatch(getTcItemList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getTcItemList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      ...val,
      tax_method: val?.tax_method?.value,
      tax_id: val?.tax?.value,
      effective_date: val?.effective_date ? dayjs(val?.effective_date).format("YYYY-MM-DD") : '',
      obsolete_date: val?.obsolete_date ? dayjs(val?.obsolete_date).format("YYYY-MM-DD") : '',
      is_inclusive: val?.is_inclusive ? 1 : 0,
      taxable: val?.taxable ? 1 : 0,
      tax_condition_item_id: val?.id,
      tax_condition_id: id
    }
    await (val?.id ? editTcItem : addTcItem)(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(`TC Item ${val?.id ? t("System.tc_item_update_success") : t("System.tc_item_add_success")}`);
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getTcItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onAction = async (recordId, status) => {
    props.setLoading(true);

    const payload = {
      tax_condition_id: id,
      tax_condition_item_id: recordId,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removeTcItem(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("System.tc_item_status_update_success"));
        setTimeout(() => dispatch(getTcItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onRemove = async (recordId) => {
    setLoad(true);

    const payload = {
      tax_condition_id: id,
      tax_condition_item_id: recordId,
      status_event: 'remove'
    }
    await removeTcItem(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(t("System.tc_item_remove_success"));
        setVisible(false);
        setPage(1);
        setTimeout(() => dispatch(getTcItemList(1, limit, '', '', searchVal, props.setLoading, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? t("General.edit") : t("General.add")} {t("System.tc_item")}
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
            }}>{t("General.cancel")}</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('position', '');
      setValue('desc', '');
      setValue('effective_date', '');
      setValue('obsolete_date', '');
      setValue('tax_method', '');
      setValue('is_inclusive', false);
      setValue('taxable', false);
      setValue('tax_amount', '');
      setValue('tax', '');
      clearErrors();
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setValue('id', record.id);
          setValue('position', record.position);
          setValue('desc', record.desc);
          setValue('effective_date', record?.effective_date ? dayjs(record?.effective_date) : '');
          setValue('obsolete_date', record?.obsolete_date ? dayjs(record?.obsolete_date) : '');
          setValue('tax_method', record.tax_method ? { label: record?.tax_method, value: record.tax_method } : '');
          setValue('is_inclusive', record.is_inclusive == "1");
          setValue('taxable', record.taxable == "1");
          setValue('tax_amount', record.tax_amount);
          setValue('tax', record.tax_id ? { label: `${record?.tax_code} - ${record?.tax_desc}`, value: record.tax_id } : '');
          setVisible(true);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={allowedEdit ? btnList : null} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            ListData={poItems?.list?.map((x, idx) => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            pagination={{
              total: poItems?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}