import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {

  const { errors, control, allowedEdit } = props
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("System.payment_term_code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}