import React, { useEffect, Fragment, useState } from 'react';
import { Form, Button, Row, Col, message, Spin, Space, Typography } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { updateMallIntegration, generateMallIntegration, generateUploadFile, resendUploadFile } from '../../../../ducks/services';
import ListCard from 'Molecules/ListCard';
import { DateField } from '../../../../../../atoms/FormElement';
import HeadingChip from 'Molecules/HeadingChip';
import dayjs from 'dayjs';
import { getUploadFiles } from '../../../../ducks/actions';
import { useDispatch, useSelector } from 'react-redux';
import AllRoles from '../../../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../../../routing/config/utils';
import { useTranslate } from 'Translate';

const _ = require('lodash');
const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const { meta, data, id, allowedEdit, activeTab } = props;
  const uploadFileList = useSelector((state) => state.outlet.uploadFileList);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const { control, formState: { errors }, handleSubmit, setValue, getValues, trigger } = useForm();
  const allowedGenerate = queryParameters.get('gen') === "1";
  const dispatch = useDispatch();
  const allowedEditMall = allowedEdit && allowed([AllRoles.OUTLET.MALLINTEGRATION], 'write');
  // const isOutletTab = activeTab === "1";
  // const isSuperAdmin = localStorage.getItem("is_superadmin") === "1";
  // const [visible, setVisible] = useState(false);
  // const [loading, setLoading] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  const handleResendUploadFile = async (uploadId) => {
    const payload = {
      upload_file_id: uploadId
    }
    props.setLoading(true);

    await resendUploadFile(payload).then(res => {
      if (res.data.code === 200) {
        message.success(t("General.resend_success"));
        dispatch(getUploadFiles(page, limit, 'descend', 'created_at', {outlet: id, date: 'null'}, props.setLoading));
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      message.error(e.message);
    }).finally(() => {
      props.setLoading(false);
    })
  }

  const colName = [
    {
      title: t("General.date"),
      dataIndex: 'date',
      key: 'date',
      sorter: true
    },
    {
      title: t("General.filename"),
      dataIndex: 'filename',
      key: 'filename',
      sorter: true,
      render: (text, record) => <a href={record.url} target='_blank'>{text}</a>
    },
    {
      title: t("Outlet.status_ftp"),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text) => <span className={text === "Success" ? "c-success" : "c-danger"}>{text}</span>
    },
    {
      title: t("General.action"),
      dataIndex: 'resend',
      key: 'resend',
      sorter: false,
      render: (text, record) => <Button htmlType="button" type='primary' className="green-btn" onClick={() => handleResendUploadFile(record.id)}>{t("General.resend")}</Button>
    }
  ];

  useEffect(() => {
    if (activeTab === "8") dispatch(getUploadFiles(1, limit, 'descend', 'created_at', {outlet: id, date: "null"}, props.setLoading));
  }, [activeTab]);

  const formFields = [
    {
      name: 'ftp_server_ip',
      label: t("Outlet.ftp_ip"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditMall
    },
    {
      name: 'ftp_server_login',
      label: t("Outlet.ftp_server_login"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditMall
    },
    {
      name: 'ftp_server_password',
      label: t("Outlet.ftp_server_password"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditMall
    },
    {
      name: 'ftp_server_port',
      label: t("Outlet.ftp_server_port"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditMall
    },
    {
      name: 'ftp_server_file_dir',
      label: t("Outlet.ftp_server_folder"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditMall
    },
    {
      name: 'ftp_server_file_format',
      label: t("Outlet.ftp_server_file_format"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      class: 'white-select',
      options: _.map(meta?.malls, (e) => ({ label: e, value: e })),
      static: !allowedEditMall
    },
    {
      name: 'machine_id',
      label: t("Outlet.machine_id_tenant_code"),
      req: false,
      placeholder: t("General.pls_enter"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEditMall,
      count: 9
    },
    {
      name: 'mall_code',
      label: t("Outlet.mall_code"),
      hidden: meta?.country !== "Singapore",
      req: false,
      placeholder: t("General.pls_enter"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEditMall
    },
    {
      name: 'till_no',
      label: t("Outlet.till_no"),
      hidden: meta?.country !== "Singapore",
      req: false,
      placeholder: t("General.pls_enter"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEditMall
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('ftp_server_file_dir', data.ftp_server_file_dir);
      setValue('ftp_server_file_format', data.ftp_server_file_format ? { label: data.ftp_server_file_format, value: data.ftp_server_file_format} : '');
      setValue('ftp_server_ip', data.ftp_server_ip);
      setValue('ftp_server_login', data.ftp_server_login);
      setValue('ftp_server_password', data.ftp_server_password);
      setValue('ftp_server_port', data.ftp_server_port);
      setValue('machine_id', data.machine_id);
      setValue('mall_code', data.mall_code);
      setValue('till_no', data.till_no);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      ftp_server_file_format: val?.ftp_server_file_format?.value,
      id: id
    }

    await updateMallIntegration(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Outlet.mall_integrated_success"));
        // setTimeout(() => navigate("/outlets"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const onGenerate = async () => {
    props.setLoading(true);
    const payload = {
      id: id,
      manual_generate: "1"
    }

    await generateMallIntegration(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Outlet.mall_sales_generated"));
        // setTimeout(() => navigate("/outlets"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  }

  const handleGenerateUploadFile = async () => {
    const isFormValid = await trigger();
    const reportDateValue = getValues().report_date

    if(!isFormValid) {
      message.error(t("General.pls_input_report_date"))
      return
    }

    if(!reportDateValue) {
      message.error(t("General.pls_input_report_date"))
      return
    }
    
    const payload = {
      date: dayjs(reportDateValue).format("YYYY-MM-DD"),
      id
    }
    props.setLoading(true);

    await generateUploadFile(payload).then(res => {
      if (res.data.code === 200) {
        message.success(t("General.generated_success"));
        dispatch(getUploadFiles(1, limit, 'descend', 'created_at', {outlet: id, date: "null"}, props.setLoading));
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    }).finally(() => {
      props.setLoading(false);
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getUploadFiles(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, {outlet: id, date: "null"}, props.setLoading));
    } else {
      dispatch(getUploadFiles(pagination.current, pagination.pageSize, 'descend', 'created_at', {outlet: id, date: "null"}, props.setLoading));
    }
  }

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)} scrollToFirstError>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
          
          <Col
            flex="0 1 50%"
          >
            <div className="datepicker-addon-button-container">
              <DateField
                fieldname='report_date'
                label={t("General.report_date")}
                control={control}
                iProps={{ placeholder: t("General.report_date"), size: 'large' }}
              />
              <Button htmlType="button" type="primary" className="green-btn datepicker-addon-button" onClick={handleGenerateUploadFile}>{t("General.generate")}</Button>
            </div>
          </Col>
        </Row>
        {
          allowedEditMall &&
          <Row gutter={[10, 10]} className="mt-1">
            <Col span={24} className='text-right'>
              {
                allowedGenerate &&
                <Button size="large" htmlType='button' className='green-btn attendance-success mr-2' onClick={() => onGenerate()}>Generate Mall Sales</Button>
              }
              <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
            </Col>
          </Row>
        }
      </Form>

      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={t("General.mall_int_logs")} />
        </Col>
        <Col span={24}>
          <ListCard
            ListData={uploadFileList?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            onChange={onTableChange}
            pagination={{
              total: uploadFileList?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
    </>
  )
}