import AllRoles from './AllRoles';

const titles = {
  Pos: 'Pos',
};

export const Pos = [
  {
    component: 'Terminal',
    path: '/pos/terminal',
    title: titles.Pos,
    subkeys: ['/pos/terminal', '/pos/terminal/add'],
    key: 'terminal',
    menu: 'POS',
    submenu: 'POS Terminal',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.POS.TERMINAL],
  },
  {
    component: 'AddTerminal',
    path: '/pos/terminal/add',
    title: titles.Pos,
    key: 'terminal',
    menu: 'POS',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.TERMINAL],
  },
  {
    component: 'EditTerminal',
    path: '/pos/terminal/:id',
    title: titles.Pos,
    key: 'terminal',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.TERMINAL],
  },
  {
    component: 'VoucherType',
    path: '/pos/voucher-type',
    title: titles.Pos,
    subkeys: ['/pos/voucher-type', '/pos/voucher-type/add'],
    key: 'voucher-type',
    menu: 'POS',
    submenu: 'Voucher Type',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.POS.VOUCHER_TYPE],
  },
  {
    component: 'AddVoucherType',
    path: '/pos/voucher-type/add',
    title: titles.Pos,
    key: 'voucher-type',
    menu: 'POS',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.VOUCHER_TYPE],
  },
  {
    component: 'EditVoucherType',
    path: '/pos/voucher-type/:id',
    title: titles.Pos,
    key: 'voucher-type',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.VOUCHER_TYPE],
  },
  {
    component: 'VoucherGenerator',
    path: '/pos/voucher-generator',
    title: titles.Pos,
    key: 'voucher-generator',
    menu: 'POS',
    submenu: 'Vouc. Generator',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.POS.VOUCHERS],
  },
  {
    component: 'Vouchers',
    path: '/pos/vouchers',
    title: titles.Pos,
    key: 'vouchers',
    menu: 'POS',
    submenu: 'Vouchers',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.POS.VOUCHERS],
  },
  {
    component: 'EditVoucher',
    path: '/pos/vouchers/:id',
    title: titles.Pos,
    key: 'vouchers',
    menu: 'POS',
    icon: 'OverviewIcon',
    // parent: false,
    permission: [AllRoles.POS.VOUCHERS],
  },
  {
    component: 'Discount',
    path: '/pos/discount',
    title: titles.Pos,
    subkeys: ['/pos/discount', '/pos/discount/add'],
    key: 'discount',
    menu: 'POS',
    submenu: 'Discount',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.POS.DISCOUNT],
  },
  {
    component: 'AddDiscount',
    path: '/pos/discount/add',
    title: titles.Pos,
    key: 'discount',
    menu: 'POS',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.DISCOUNT],
  },
  {
    component: 'EditDiscount',
    path: '/pos/discount/:id',
    title: titles.Pos,
    key: 'discount',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.DISCOUNT],
  },
  {
    component: 'KitchenQueue',
    path: '/pos/kitchen-queue',
    title: titles.Pos,
    subkeys: ['/pos/kitchen-queue', '/pos/kitchen-queue/add'],
    key: 'kitchen-queue',
    menu: 'POS',
    submenu: 'Kitchen Queue',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.POS.KITCHEN_QUEUE],
  },
  {
    component: 'AddKitchenQueue',
    path: '/pos/kitchen-queue/add',
    title: titles.Pos,
    key: 'kitchen-queue',
    menu: 'POS',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.KITCHEN_QUEUE],
  },
  {
    component: 'EditKitchenQueue',
    path: '/pos/kitchen-queue/:id',
    title: titles.Pos,
    key: 'kitchen-queue',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.KITCHEN_QUEUE],
  },
  {
    component: 'Printer',
    path: '/pos/printer',
    title: titles.Pos,
    subkeys: ['/pos/printer', '/pos/printer/add'],
    key: 'printer',
    menu: 'POS',
    submenu: 'Printers',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.POS.PRINTERS],
  },
  {
    component: 'AddPrinter',
    path: '/pos/printer/add',
    title: titles.Pos,
    key: 'printer',
    menu: 'POS',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.PRINTERS],
  },
  {
    component: 'EditPrinter',
    path: '/pos/printer/:id',
    title: titles.Pos,
    key: 'printer',
    menu: 'POS',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.POS.PRINTERS],
  },
  {
    component: 'AuditLog',
    path: '/pos/audit-log',
    title: titles.Pos,
    key: 'audit-log',
    menu: 'POS',
    submenu: 'Audit Log',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.POS.AUDIT_LOG],
  }
]