import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import SalesOrder from 'Modules/Reporting/SalesOrder';
import DailySummary from 'Modules/Reporting/DailySummary';
import SalesPayment from 'Modules/Reporting/SalesPayment';
import SalesDetails from 'Modules/Reporting/SalesDetails';
import SalesSummary from 'Modules/Reporting/SalesSummary';
import SalesHourly from 'Modules/Reporting/SalesHourly';
import VoucherListing from 'Modules/Reporting/VoucherListing';
import BillSummary from 'Modules/Reporting/BillSummary';
import BillSummaryListing from 'Modules/Reporting/BillSummaryListing';
import DiscountListing from 'Modules/Reporting/DiscountListing';
import CashManagementListing from 'Modules/Reporting/CashManagementListing';
import ProductSales from 'Modules/Reporting/ProductSales';
import SpeedOfService from 'Modules/Reporting/SpeedOfService';
import CancelledSales from 'Modules/Reporting/CancelledSales';
import TaxChargesListing from 'Modules/Reporting/TaxChargesListing';
import TaxChargesDetails from 'Modules/Reporting/TaxChargesDetails';
import PosAuditLog from 'Modules/Reporting/PosAuditLog';

const Components = {
  SalesOrder,
  DailySummary,
  SalesPayment,
  SalesDetails,
  SalesSummary,
  SalesHourly,
  VoucherListing,
  BillSummary,
  BillSummaryListing,
  DiscountListing,
  CashManagementListing,
  ProductSales,
  SpeedOfService,
  CancelledSales,
  TaxChargesListing,
  TaxChargesDetails,
  PosAuditLog
};

export default (props) => {
  const ReportComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <ReportComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};