import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const addVendor = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/create`, payload);
};

export const editVendor = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/update`, payload);
};

export const removeVendor = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/update-status`, payload);
};

export const bulkRemoveVendors = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/bulk-update-status`, payload);
};

export const bulkHideVendors = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/bulk-hide`, payload);
};

export const addPurchaseOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/create`, payload);
};

export const editPurchaseOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/update`, payload);
};

export const removePurchaseOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/update-status`, payload);
};

export const exportPoPdf = (id) => {
  return axios.get(`${externalBaseUrl}/purchase-orders/export-pdf?purchase_order_id=${id}`, { responseType: "blob" });
};

export const bulkRemovePurchaseOrders = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/bulk-update-status`, payload);
};

export const addVendorProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/vendor-product-create`, payload);
};

export const editVendorProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/vendor-product-update`, payload);
};

export const removeVendorProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/vendor-product-update-status`, payload);
};

export const bulkRemoveVendorProducts = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/vendor-product-bulk-update-status`, payload);
};

export const addPoItem = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/purchase-order-item-create`, payload);
};

export const editPoItem = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/purchase-order-item-update`, payload);
};

export const removePoItem = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/purchase-order-item-destroy`, payload);
};

export const addGoodReceive = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-ins/create`, payload);
};

export const editGoodReceive = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-ins/update`, payload);
};

export const removeGoodReceive = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-ins/update-status`, payload);
};

export const bulkRemoveGoodReceives = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-ins/bulk-update-status`, payload);
};

export const addGrItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-ins/stock-in-item-create`, payload);
};

export const editGrItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-ins/stock-in-item-update`, payload);
};

export const destroyGrItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-ins/stock-in-item-destroy`, payload);
};

export const addTransferOut = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-outs/create`, payload);
};

export const editTransferOut = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-outs/update`, payload);
};

export const removeTransferOut = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-outs/update-status`, payload);
};

export const bulkRemoveTransferOuts = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-outs/bulk-update-status`, payload);
};

export const addToItem = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-outs/transfer-out-item-create`, payload);
};

export const editToItem = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-outs/transfer-out-item-update`, payload);
};

export const removeToItem = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-outs/transfer-out-item-destroy`, payload);
};

export const addTransferIn = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-ins/create`, payload);
};

export const editTransferIn = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-ins/update`, payload);
};

export const removeTransferIn = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-ins/update-status`, payload);
};

export const bulkRemoveTransferIns = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-ins/bulk-update-status`, payload);
};

export const addTrItem = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-ins/transfer-in-item-create`, payload);
};

export const editTrItem = (payload) => {
  return axios.post(`${externalBaseUrl}/transfer-ins/transfer-in-item-update`, payload);
};

export const addStockWastage = (payload) => {
  return axios.post(`${externalBaseUrl}/written-offs/create`, payload);
};

export const editStockWastage = (payload) => {
  return axios.post(`${externalBaseUrl}/written-offs/update`, payload);
};

export const removeStockWastage = (payload) => {
  return axios.post(`${externalBaseUrl}/written-offs/update-status`, payload);
};

export const bulkRemoveStockWastages = (payload) => {
  return axios.post(`${externalBaseUrl}/written-offs/bulk-update-status`, payload);
};

export const addSwItem = (payload) => {
  return axios.post(`${externalBaseUrl}/written-offs/written-off-item-create`, payload);
};

export const editSwItem = (payload) => {
  return axios.post(`${externalBaseUrl}/written-offs/written-off-item-update`, payload);
};

export const removeSwItem = (payload) => {
  return axios.post(`${externalBaseUrl}/written-offs/written-off-item-destroy`, payload);
};

export const exportSwPdf = (id) => {
  return axios.get(`${externalBaseUrl}/written-offs/export-pdf?written_off_id=${id}`, { responseType: "blob" });
}

export const addStockAdjustment = (payload) => {
  return axios.post(`${externalBaseUrl}/adjustments/create`, payload);
};

export const editStockAdjustment = (payload) => {
  return axios.post(`${externalBaseUrl}/adjustments/update`, payload);
};

export const removeStockAdjustment = (payload) => {
  return axios.post(`${externalBaseUrl}/adjustments/update-status`, payload);
};

export const bulkRemoveStockAdjustments = (payload) => {
  return axios.post(`${externalBaseUrl}/adjustments/bulk-update-status`, payload);
};

export const addSaItem = (payload) => {
  return axios.post(`${externalBaseUrl}/adjustments/adjustment-item-create`, payload);
};

export const editSaItem = (payload) => {
  return axios.post(`${externalBaseUrl}/adjustments/adjustment-item-update`, payload);
};

export const removeSaItem = (payload) => {
  return axios.post(`${externalBaseUrl}/adjustments/adjustment-item-destroy`, payload);
};

export const addStockTemplate = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-templates/create`, payload);
};

export const editStockTemplate = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-templates/update`, payload);
};

export const removeStockTemplate = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-templates/update-status`, payload);
};

export const bulkRemoveStockTemplates = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-templates/bulk-update-status`, payload);
};

export const addStItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-templates/stock-template-item-create`, payload);
};

export const editStItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-templates/stock-template-item-update`, payload);
};

export const removeStItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-templates/stock-template-item-destroy`, payload);
};

export const addStockTake = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-takes/create`, payload);
};

export const editStockTake = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-takes/update`, payload);
};

export const removeStockTake = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-takes/update-status`, payload);
};

export const bulkRemoveStockTakes = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-takes/bulk-update-status`, payload);
};

export const addStakeItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-takes/stock-take-item-create`, payload);
};

export const editStakeItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-takes/stock-take-item-update`, payload);
};

export const removeStakeItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-takes/stock-take-item-destroy`, payload);
};

export const exportStockTakePdf = (id, isNew) => {
  if (isNew) {
    return axios.get(`${externalBaseUrl}/stock-takes/export-template-pdf?stock_template_id=${id}`, { responseType: "blob" });
  } else {
    return axios.get(`${externalBaseUrl}/stock-takes/export-pdf?stock_take_id=${id}`, { responseType: "blob" });
  }
};

export const generateInventoryNo = (payload, endpoint) => {
  return axios.post(`${externalBaseUrl}/${endpoint}/generate-reference`, payload);
};

export const duplicatePo = (payload, endpoint) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/duplicate`, payload);
};

export const sendEmail = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/email`, payload);
};

export const createGR = (payload) => {
  return axios.post(`${externalBaseUrl}/purchase-orders/create-stock-in`, payload);
};

export const importStItemCsv = async (file, id, syncUnitCost) => {
  if (localStorage.getItem("uploading")) {
    return
  } else {
    localStorage.setItem("uploading", true);
  }
  let paylod = new FormData();
  paylod.append("file", file);
  paylod.append("stock_take_id", id);
  paylod.append("is_sync_unit_cost", syncUnitCost ? "1" : "0");

  let upurl = `${externalBaseUrl}/stock-takes/stock-take-items-import`;
  try {
    const resp = await axios({ method: 'post', url: upurl, data: paylod, headers: { "Content-Type": "multipart/form-data" } });
    localStorage.removeItem("uploading");
    return resp;
  } catch (e) {
    localStorage.removeItem("uploading");
    return false;
  }
};

export const exportStockMovement = (search) => {
  var q = {
    location_id_eq: search?.location,
    product_id_eq: search?.product,
    report_date_gteq: search?.start_date,
    report_date_lteq: search?.end_date,
    reference_cont: search?.reference,
  }
  return axios.get(`${externalBaseUrl}/inventory-entries/export-report?q=${JSON.stringify(q)}`, { responseType: "blob" });
};

export const addStockConversion = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-conversions/create`, payload);
};

export const editStockConversion = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-conversions/update`, payload);
};

export const removeStockConversion = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-conversions/update-status`, payload);
};

export const bulkRemoveStockConversions = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-conversions/bulk-update-status`, payload);
};

export const addScItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-conversions/stock-conversion-item-create`, payload);
};

export const editScItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-conversions/stock-conversion-item-update`, payload);
};

export const removeScItem = (payload) => {
  return axios.post(`${externalBaseUrl}/stock-conversions/stock-conversion-item-destroy`, payload);
};

export const syncAllFromNetsuite = (payload) => {
  return axios.post(`${externalBaseUrl}/vendors/sync-all-vendors`, payload);
};

export const syncToNetsuite = (endpoint, payload) => {
  return axios.post(`${externalBaseUrl}/${endpoint}/resync-netsuite`, payload);
};