import AllRoles from './AllRoles';

const titles = {
  CRM: 'CRM',
};

export const CRM = [
  {
    component: 'Customer',
    path: '/crm/customers',
    title: titles.CRM,
    subkeys: ['/crm/customers', '/crm/customers/add'],
    key: 'crm-customer',
    menu: 'CRM',
    submenu: 'Customer',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.CRM.CUSTOMER],
  },
  {
    component: 'AddCustomer',
    path: '/crm/customers/add',
    title: titles.CRM,
    key: 'crm-customer',
    menu: 'CRM',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.CRM.CUSTOMER],
  },
  {
    component: 'EditCustomer',
    path: '/crm/customers/:id',
    title: titles.CRM,
    key: 'crm-customer',
    menu: 'CRM',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.CRM.CUSTOMER],
  }
]