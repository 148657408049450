import React from "react";
import Icon from '@ant-design/icons';

const UploadCloudSvg = () => (
  <svg id="_360_F_164165971_ELxPPwdwHYEhg4vZ3F4Ej7OmZVzqq4Ov" data-name="360_F_164165971_ELxPPwdwHYEhg4vZ3F4Ej7OmZVzqq4Ov" xmlns="http://www.w3.org/2000/svg" width="68.696" height="49.28" viewBox="0 0 68.696 49.28">
    <path id="Path_36433" data-name="Path 36433" d="M142.593,72.942a21.59,21.59,0,0,1,17.422,15.119,14.514,14.514,0,0,1,4.811,1.029,14.1,14.1,0,0,1,7.856,8.016,14.031,14.031,0,0,1-13.1,19.018q-4.822,0-9.647,0a2.075,2.075,0,0,1-1.433-.365,1.77,1.77,0,0,1-.2-2.618c.726-.8,1.828-.507,2.788-.558,2.3-.039,4.593.119,6.889,0a12.514,12.514,0,0,0,5.69-.838,10.466,10.466,0,0,0,4.1-16.164,10.419,10.419,0,0,0-7.5-3.936c-.684-.039-1.389.053-2.067-.044a1.9,1.9,0,0,1-1.35-1.626,18.146,18.146,0,0,0-35.281.289,1.776,1.776,0,0,1-1.947,1.382,10.319,10.319,0,0,0-6.887,2.012,10.476,10.476,0,0,0,3.447,18.554c2.039.6,3.876.259,5.95.427,2.292-.037,4.583-.08,6.875-.048a1.767,1.767,0,1,1-.191,3.529c-3.366,0-6.754,0-10.12,0a14.023,14.023,0,0,1-8.866-24.69,14.185,14.185,0,0,1,8.664-3.378A21.618,21.618,0,0,1,130.528,74.5,21.928,21.928,0,0,1,142.593,72.942Z" transform="translate(-104.907 -72.686)" fill="#7c7c7c" />
    <path id="Path_36434" data-name="Path 36434" d="M215.17,157.8a2.4,2.4,0,0,1,1.637.7q4.643,3.51,9.3,7.008a1.862,1.862,0,0,1,.85,1.284,1.776,1.776,0,0,1-1.679,2.016,2.022,2.022,0,0,1-1.451-.563c-2.244-1.715-4.512-3.4-6.767-5.1.011,7.417,0,14.841.007,22.256a2.031,2.031,0,0,1-.514,1.6,1.78,1.78,0,0,1-2.531.014,2.022,2.022,0,0,1-.517-1.612c.011-7.415,0-14.832,0-22.247-2.361,1.743-4.691,3.534-7.045,5.288a1.772,1.772,0,0,1-2.485-2.489,10.11,10.11,0,0,1,1.41-1.141q4.047-3.062,8.106-6.113A3.39,3.39,0,0,1,215.17,157.8Z" transform="translate(-180.938 -138.253)" fill="#7c7c7c" />
  </svg>
);

const UploadCloudIcon = props => <Icon component={UploadCloudSvg} {...props} />;

export default UploadCloudIcon;