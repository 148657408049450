import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getSalesOrderList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/sales-orders/list`, {
      q: JSON.stringify({
        reference_cont: search?.reference,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.SALES_ORDER_LIST,
      data: data
    });
  };
};
