import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

export default (props) => {

    const { control, handleSubmit } = useForm()
    const i18n = useTranslate();
    const { t } = i18n;

    const onSubmit = (val) => {
        props.onSearch(val);
    }

    return (
        <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
            <Row className='w-100' gutter={20}>
                <Col span={20}>
                    <InputField
                        fieldname='name'
                        label=''
                        class='mb-0 w-100'
                        initValue={''}
                        control={control}
                        iProps={{ placeholder: `${t("General.search_product")}..` }}
                    />
                </Col>
                <Col span={4}>
                    <Button className='w-100' size='large' type='primary' htmlType='submit'>{t("General.search")}</Button>
                </Col>
            </Row>
        </Form>
    )
}