import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Spin, message, Form, Tooltip } from 'antd';
import AddForm from '../components/AddForm';
import { getGoodReceiveDetail } from "../../ducks/actions";
import { editGoodReceive, removeGoodReceive } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined, CheckOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';
import { useForm } from 'react-hook-form';
import FormGroup from 'Molecules/FormGroup';
import { Popup } from 'Atoms/Popup';
import { useTranslate } from 'Translate';

const { Title } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation().pathname;
  const data = useSelector((state) => state.inventory.goodReceive);
  const meta = useSelector((state) => state.inventory.goodReceiveOption);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const allowedEdit = allowed([AllRoles.INVENTORY.GOOD_RECEIVE], 'write');
  const allowedDelete = allowed([AllRoles.INVENTORY.GOOD_RECEIVE], 'delete');
  const [canPost, setCanPost] = useState(false);
  const [noZeroReceiveQty, setNoZeroReceiveQty] = useState(false)
  const i18n = useTranslate();
      const { t } = i18n;

  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const [visible, setVisible] = useState(false);
  const [editable, setEditable] = useState(true);

  const formFields = [
    {
      name: 'remarks',
      label: t("System.remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      reqmessage: t("General.required"),
      req: true,
    }
  ];

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    dispatch(getGoodReceiveDetail(id, setLoad));
  };

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  };

  const onAction = async (status, remark = null) => {
    if (confirm(`Are you sure to ${status} now?`)) {
      props.setLoading(true);

      const payload = {
        stock_in_id: id,
        status_event: status
      }

      if(remark) payload.cancellation_remarks = remark

      await removeGoodReceive(payload).then(res => {
        props.setLoading(false);
        if (res.data.code === 200) {
          message.success(t("Success.update_good_receive_status"));
          setTimeout(() => dispatch(getGoodReceiveDetail(id, props.setLoading)), 250);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        props.setLoading(false);
        message.error(e.message ?? t("General.something_went_wrong"));
      })
    }
  }

  const openCancelPopUp = () => {
    setVisible(true);
    setValue('remarks', data?.remarks);
    clearErrors();
  }

  const onFinish = async (val) => {
    onAction('cancel', val?.remarks)
    setVisible(false);
  }

  const cancelPopUp = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {t("Inventory.cancel_gr")}
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('remarks', '');
            }}>{t("General.cancel")}</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('remarks', '');
    },
  };

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={load}>
        <Row gutter={[20, 30]}>
          <Col span={24} style={{ position: "sticky", top: "-30px", backgroundColor: "#f5f5f5", zIndex: 3, padding: "10px" }}>
            <Row justify={'space-between'} align={'middle'}>
              <Col flex='auto'>
                <Title level={3} className='mb-0 mt-0'>
                  {data?.reference}
                  {" (Status: "}
                  <span className={data?.status === "posted" ? "c-success" : data?.status === "pending" ? "c-pending" : "c-danger"}>
                    {data?.status?.[0]?.toUpperCase()}{data?.status?.slice(1)}
                  </span>
                  {")"}
                  {data?.netsuite_synced === "1" && <Tooltip title={t("Inventory.synced_to_netsuite")}><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip>}
                </Title>
              </Col>
              <Col flex='auto' className='text-right'>
                <Space size={10}>
                  {/* <Button size="large" htmlType='submit' className='green-btn'>{t("General.save")}</Button> */}
                  {
                    data?.status === "pending" &&
                    allowedEdit &&
                    data?.item_count > 0 &&
                    canPost &&
                    noZeroReceiveQty && 
                    <Button size="large" htmlType='button' className='green-btn text-white' onClick={() => onAction('post')}>
                      <CheckOutlined /> {t("Inventory.post_gr")}
                    </Button>
                  }
                  {
                    data?.status !== "cancelled" && data?.status !== "posted" &&
                    allowedEdit &&
                    data?.netsuite_synced === "0" &&
                    <Button size="large" htmlType='button' danger onClick={() => openCancelPopUp()}>
                      {t("Inventory.cancel_gr_short")}
                    </Button>
                  }
                  {editable && activeTab === "1" && <Button size="large" htmlType='submit' className='green-btn text-white' form="gr-details-form">{t("General.save")}</Button>}
                  <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/inventory/good-receive')}>{'<'} {t("General.back")}</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <AddForm
              loading={load}
              setLoading={setLoad}
              activeTab={activeTab}
              setActiveTab={setActive}
              data={data}
              meta={meta}
              id={id}
              updateGoodReceive={editGoodReceive}
              reloadApi={callApi}
              searchParams={searchParams}
              mode={'edit'}
              navigate={navigate}
              allowedEdit={allowedEdit}
              allowedDelete={allowedDelete}
              setCanPost={setCanPost}
              setNoZeroReceiveQty={setNoZeroReceiveQty}
              editable={editable}
              setEditable={setEditable}
            />
          </Col>
        </Row>
      </Spin>
      <Popup {...cancelPopUp} />
    </>
  )
}