import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Form, Button, message, Table } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getNetsuiteSyncLogs } from '../ducks/actions'
import { syncNetsuite, retrySyncNetsuite } from '../ducks/services'
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { useForm } from 'react-hook-form';
import { SelectField } from 'Atoms/FormElement';
import { Popup } from 'Atoms/Popup';
import { SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslate } from 'Translate';

dayjs.extend(customParseFormat);

export default (props) => {
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.systems.netsuiteSyncLogs);
  const meta = useSelector((state) => state.systems.netsuiteSyncLogsMeta);
  const [listData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const { control, handleSubmit, formState: { errors } }= useForm();
  const [visible, setVisible] = useState(false);
  const [deets, setDeets] = useState();
  const i18n = useTranslate();
  const { t } = i18n;

  const colName = [
    {
      title: t("General.ref"),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t("General.date"),
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: t("General.time_started"),
      dataIndex: 'from',
      key: 'from'
    },
    {
      title: t("General.time_ended"),
      dataIndex: 'to',
      key: 'to'
    },
    {
      title: t("General.sync_types"),
      dataIndex: 'sync_types',
      key: 'sync_types'
    },
  ];

  const expandedRowRender = (record) => {
    const colName = [
      {
        title: t("General.number_no"),
        dataIndex: 'key',
        key: 'key',
        width: 100,
        render: (text) => <span>{parseInt(text) + 1}.</span>
      },
      {
        title: t("General.sync_type"),
        dataIndex: 'progress',
        key: 'progress',
        width: 300,
        render: (text, rec) => <span className={rec?.status === "Completed" ? "c-success" : "c-pending"}>{text}</span>
      },
      {
        title: t("General.synced"),
        dataIndex: 'count',
        key: 'count',
        width: 100
      },
      {
        title: t("System.reason"),
        dataIndex: 'reasons',
        key: 'reasons',
        className: "white-space-break",
        render: (text) => {
          let temp = Array.isArray(text) ? text?.map(x => {
            let msg = x?.message;
            if(x?.location_code) {
              msg += ` (${x.location_code})`;
            }
            if(x?.payment_type) {
              msg += `(${x?.payment_type})`
            }
            return msg;
          }) : "-";
          if(temp === "-") {
            return temp;
          }else {
            return temp.join("\n-")
          }
        }
      },
      {
        title: 'Meta Data',
        dataIndex: 'meta',
        key: 'meta',
        render: (text, record) => text ? <Button type="link" onClick={() => onViewDeets(text, record.progress)}>{text.count && text.count > 100 ? "Download" : "View" } {t("General.details")}</Button> : "-"
      },
      {
        title: t("General.status"),
        dataIndex: 'status',
        key: 'status',
        width: 300,
        render: (text, rec) => {
          return (
            <div className='d-flex align-items-center'>
              <span className={text === "Completed" ? "c-success" : "c-pending"}>{text}</span>
              {
                rec?.retry &&
                <Button type="link" className='btn c-info ml-1' onClick={() => onRetry(rec.id, rec.syncType)}>{"(Retry)"}</Button>
              }
            </div>
          )
        }
      }
    ];

    let temp = [];
    let isManual = record?.meta_data?.is_manual ?? Object.keys(record.progress).length == 1
    Object.keys(record?.progress)?.map((key, ind) => {
      let syncType = meta?.sync_types?.find(x => x.value === key);
      let reasons = record?.reasons?.filter(x => x.name === key);
      let count = record?.meta_data?.[key]?.count || "-";
      let status = reasons?.length > 0 ? "Failed" : (record.progress[key] == "OK" ? "Completed" : record.progress[key] == "running" ? "In Progress" : "Failed")
      let syncMeta = record?.meta_data?.[key];
      temp.push({
        key: ind.toString(),
        progress: `${syncType?.label} (${isManual ? "Manual" : "System"})`, // && !syncType.hidden, hidden = no manual action allowed hence automatically assigned to system
        status: status,
        reasons: reasons,
        meta: syncMeta,
        count: count,
        syncType: syncType.value,
        id: record?.id,
        retry: status !== "Completed" && meta?.sync_types?.filter(x => x.can_retry).find(x => x.value === key) && dayjs().diff(dayjs(record.from, "hh:mm:ss A"), 'hour') >= 1
      })
    })

    return <Table columns={colName} dataSource={temp} pagination={false} />;
  }

  useEffect(() => {
    dispatch(getNetsuiteSyncLogs(page, limit, props.setLoading));
    dispatch(getListQueryItems('netsuiteSyncLogsQuery', 1, limit, '', '', ''));
  }, []);

  const onReload = () => {
    dispatch(getNetsuiteSyncLogs(page, limit, props.setLoading));
  }

  useEffect(() => {
    if(logs && logs?.total_count) {
      let temp = [];
      logs?.list?.map(x => {
        let types = [];
        Object.keys(x.progress).map(key => {
          types.push(meta?.sync_types?.find(x => x.value === key)?.label);
        })
        temp.push({
          ...x,
          sync_types: types.join(", ")
        })
      })
      setListData(temp);
    }
  }, [logs]);

  const onTableChange = (pagination) => {
    if (logs?.total_count === 0) {
      return;
    }
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    dispatch(getNetsuiteSyncLogs(pagination.current, pagination.pageSize, props.setLoading));
    dispatch(getListQueryItems('netsuiteSyncLogsQuery', pagination.current, pagination.pageSize, '', '', ''))
  }

  const onSubmit = async (val) => {
    props.setLoading(true);

    const payload = {
      sync_type: val?.sync_type?.value
    }

    await syncNetsuite(payload).then(res => {
      if(res.data.code === 200) {
        setPage(1);
        setTimeout(() => {
          dispatch(getNetsuiteSyncLogs(1, limit, props.setLoading));
          dispatch(getListQueryItems('netsuiteSyncLogsQuery', 1, '', limit, '', '', ''));
          props.setLoading(false);
          message.success(`${val?.sync_type?.label} Successfully!`);
        }, 2000);
      }else {
        props.setLoading(false);
        message.error(res.data.message);  
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onRetry = async (recId, syncType) => {
    props.setLoading(true);

    const payload = {
      sync_log_id: recId,
      sync_type: syncType
    }

    await retrySyncNetsuite(payload).then(res => {
      if(res.data.code === 200) {
        setTimeout(() => {
          dispatch(getNetsuiteSyncLogs(1, limit, props.setLoading));
          dispatch(getListQueryItems('netsuiteSyncLogsQuery', 1, '', limit, '', '', ''));
          props.setLoading(false);
          message.success(`Retry successful, please wait while we are processing the request!`);
        }, 2000);
      }else {
        props.setLoading(false);
        message.error(res.data.message);  
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const popup = {
    closable: true,
    visibility: visible,
    content: <pre style={{ whiteSpace: "break-spaces"}}><code>{JSON.stringify(deets, null, 2)}</code></pre>,
    width: 650,
    onCancel: () => {
      setVisible(false);
    },
  };

  const btnList = [
    {
      text: <SyncOutlined />,
      title: "Reload",
      action: () => onReload(),
    },
  ];

  const onViewDeets = (text, syncType) => {
    if(text.count && text.count > 100) {
      const blob = new Blob([JSON.stringify(text, null, 2)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${syncType}-details.txt`;
      a.click();
      URL.revokeObjectURL(url);
    }else{
      setVisible(true); setDeets(text);
    }
  }

  return (
    <>
      <Row gutter={[10, 15]}>
        <Col span={24}>
          <HeadingChip title={t("General.netsuite_sync_req")} />
        </Col>

        <Col span={24}>
          <Card bordered={false}>
            <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
              <Row className='w-100' gutter={[10, 10]}>
                <Col flex="auto">
                  <SelectField
                    isRequired={true}
                    fieldname='sync_type'
                    label=''
                    class='mb-0 w-100 default-select'
                    initValue={''}
                    control={control}
                    iProps={{ placeholder: 'Sync Type' }}
                    selectOption={meta?.sync_types?.filter(x => !x.hidden)?.map(x => ({label: x.label, value: x.value}))}
                    rules={{
                      required: 'Sync type is required',
                    }}
                    validate={errors.sync_type && 'error'}
                    validMessage={errors.sync_type && errors.sync_type.message}
                  />
                </Col>

                <Col flex="70px">
                  <Button className='w-100' size='large' type='primary' htmlType='submit'>{t("General.sync_now")}</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>

        <Col span={24}>
          <HeadingChip title={t("General.netsuite_sync_logs")} subText={<span>{t("General.results")}: {logs?.total_count}</span>} btnList={btnList} />
        </Col>

        <Col span={24} className="clickRow">
          <ListCard
            expandable={{
              expandedRowRender,
              expandRowByClick: true,
              defaultExpandedRowKeys: ["0"] // Array(50).fill().map((_, i) => i.toString())
            }}
            ListData={listData?.map((x, ind) => ({ ...x, key: ind.toString() }))}
            onChange={onTableChange}
            ListCol={colName}
            pagination={{
              total: logs?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}