import AllRoles from './AllRoles';

const titles = {
  Systems: 'Systems',
};

export const Systems = [
  {
    component: 'User',
    path: '/ems/users',
    title: titles.Systems,
    subkeys: ['/ems/users', '/ems/users/add'],
    key: 'users',
    menu: 'System',
    submenu: 'EMS Users',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.USERS],
  },
  {
    component: 'AddUser',
    path: '/ems/users/add',
    title: titles.Systems,
    key: 'users',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.USERS],
  },
  {
    component: 'EditUser',
    path: '/ems/users/:id',
    title: titles.Systems,
    key: 'users',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.USERS],
  },
  {
    component: 'Role',
    path: '/roles',
    title: titles.Systems,
    key: 'roles',
    menu: 'System',
    submenu: 'EMS Roles',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.ROLE],
  },
  {
    component: 'PosUser',
    path: '/pos/users',
    title: titles.Systems,
    subkeys: ['/pos/users', '/pos/users/add'],
    key: 'posusers',
    menu: 'System',
    submenu: 'POS Users',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.POSUSERS],
  },
  {
    component: 'AddPosUser',
    path: '/pos/users/add',
    title: titles.Systems,
    key: 'posusers',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.POSUSERS],
  },
  {
    component: 'EditPosUser',
    path: '/pos/users/:id',
    title: titles.Systems,
    key: 'posusers',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.POSUSERS],
  },
  {
    component: 'PosRole',
    path: '/pos/roles',
    title: titles.Systems,
    key: 'posroles',
    menu: 'System',
    submenu: 'POS Roles',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.POSROLE],
  },
  {
    component: 'Currency',
    path: '/currency',
    title: titles.Systems,
    subkeys: ['/currency', '/currency/add'],
    key: 'currency',
    menu: 'System',
    submenu: 'Currency',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.CURRENCY],
  },
  {
    component: 'AddCurrency',
    path: '/currency/add',
    title: titles.Systems,
    key: 'currency',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.CURRENCY],
  },
  {
    component: 'EditCurrency',
    path: '/currency/:id',
    title: titles.Systems,
    key: 'currency',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.CURRENCY],
  },
  {
    component: 'PaymentTerm',
    path: '/payment-term',
    title: titles.Systems,
    subkeys: ['/payment-term', '/payment-term/add'],
    key: 'payment-term',
    menu: 'System',
    submenu: 'Payment Term',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.PAYMENTTERM],
  },
  {
    component: 'AddPaymentTerm',
    path: '/payment-term/add',
    title: titles.Systems,
    key: 'payment-term',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.PAYMENTTERM],
  },
  {
    component: 'EditPaymentTerm',
    path: '/payment-term/:id',
    title: titles.Systems,
    key: 'payment-term',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.PAYMENTTERM],
  },
  {
    component: 'Region',
    path: '/region',
    title: titles.Systems,
    subkeys: ['/region', '/region/add'],
    key: 'region',
    menu: 'System',
    submenu: 'Region',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.REGION],
  },
  {
    component: 'AddRegion',
    path: '/region/add',
    title: titles.Systems,
    key: 'region',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.REGION],
  },
  {
    component: 'EditRegion',
    path: '/region/:id',
    title: titles.Systems,
    key: 'region',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.REGION],
  },
  {
    component: 'OrderTypeGroup',
    path: '/order-type-group',
    title: titles.Systems,
    subkeys: ['/order-type-group', '/order-type-group/add'],
    key: 'order-type-group',
    menu: 'System',
    submenu: 'Ord. Type Group',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.ORDER_TYPE_GROUP],
  },
  {
    component: 'AddTypeGroup',
    path: '/order-type-group/add',
    title: titles.Systems,
    key: 'order-type-group',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.ORDER_TYPE_GROUP],
  },
  {
    component: 'EditTypeGroup',
    path: '/order-type-group/:id',
    title: titles.Systems,
    key: 'order-type-group',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.ORDER_TYPE_GROUP],
  },
  {
    component: 'Tax',
    path: '/tax',
    title: titles.Systems,
    key: 'tax',
    menu: 'System',
    submenu: 'Tax',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.TAX],
  },
  {
    component: 'TaxCondition',
    path: '/tax-condition',
    title: titles.Systems,
    subkeys: ['/tax-condition', '/tax-condition/add'],
    key: 'tax-condition',
    menu: 'System',
    submenu: 'Tax Condition',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.TAX_CONDITION],
  },
  {
    component: 'AddTaxCondition',
    path: '/tax-condition/add',
    title: titles.Systems,
    key: 'tax-condition',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.TAX_CONDITION],
  },
  {
    component: 'EditTaxCondition',
    path: '/tax-condition/:id',
    title: titles.Systems,
    key: 'tax-condition',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.TAX_CONDITION],
  },
  {
    component: 'Reason',
    path: '/reason',
    title: titles.Systems,
    subkeys: ['/reason', '/reason/add'],
    key: 'reason',
    menu: 'System',
    submenu: 'Reason',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.REASON],
  },
  {
    component: 'AddReason',
    path: '/reason/add',
    title: titles.Systems,
    key: 'reason',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.REASON],
  },
  {
    component: 'EditReason',
    path: '/reason/:id',
    title: titles.Systems,
    key: 'reason',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.REASON],
  },
  {
    component: 'Remark',
    path: '/remark',
    title: titles.Systems,
    subkeys: ['/remark', '/remark/add'],
    key: 'remark',
    menu: 'System',
    submenu: 'Remarks',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.REMARKS],
  },
  {
    component: 'AddRemark',
    path: '/remark/add',
    title: titles.Systems,
    key: 'remark',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.REMARKS],
  },
  {
    component: 'EditRemark',
    path: '/remark/:id',
    title: titles.Systems,
    key: 'remark',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.REMARKS],
  },
  {
    component: 'RemarkType',
    path: '/remark-type',
    title: titles.Systems,
    subkeys: ['/remark-type', '/remark-type/add'],
    key: 'remark-type',
    menu: 'System',
    submenu: 'Remark Type',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.REMARK_TYPE],
  },
  {
    component: 'AddRemarkType',
    path: '/remark-type/add',
    title: titles.Systems,
    key: 'remark-type',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.REMARK_TYPE],
  },
  {
    component: 'EditRemarkType',
    path: '/remark-type/:id',
    title: titles.Systems,
    key: 'remark-type',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.REMARK_TYPE],
  },
  {
    component: 'PaymentMethodType',
    path: '/payment-method-type',
    title: titles.Systems,
    subkeys: ['/payment-method-type', '/payment-method-type/add'],
    key: 'payment-method-type',
    menu: 'System',
    submenu: 'Tender Media Type',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.PAYMENT_METHOD_TYPE],
  },
  {
    component: 'AddPaymentMethodType',
    path: '/payment-method-type/add',
    title: titles.Systems,
    key: 'payment-method-type',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.PAYMENT_METHOD_TYPE],
  },
  {
    component: 'EditPaymentMethodType',
    path: '/payment-method-type/:id',
    title: titles.Systems,
    key: 'payment-method-type',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.PAYMENT_METHOD_TYPE],
  },
  {
    component: 'Setup',
    path: '/setup',
    title: titles.Systems,
    key: 'setup',
    menu: 'System',
    submenu: 'Setup',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.SETUP],
  },
  {
    component: 'Merchant',
    path: '/merchant',
    title: titles.Systems,
    subkeys: ['/merchant', '/merchant/add'],
    key: 'merchant',
    menu: 'System',
    submenu: 'Merchant',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.MERCHANT],
  },
  {
    component: 'AddMerchant',
    path: '/merchant/add',
    title: titles.Systems,
    key: 'merchant',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.MERCHANT],
  },
  {
    component: 'EditMerchant',
    path: '/merchant/:id',
    title: titles.Systems,
    key: 'merchant',
    menu: 'System',
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.SYSTEM.MERCHANT],
  },
  {
    component: 'NetsuiteSync',
    path: '/netsuite-sync',
    title: titles.Systems,
    key: 'netsuite-sync',
    menu: 'System',
    submenu: 'Netsuite Sync',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.SYSTEM.NETSUITE_SYNC],
  },
]