import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const addCustomer = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/create`, payload);
};

export const editCustomer = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/update`, payload);
};

export const editCustomerPassword = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/update-login-password`, payload);
};

export const removeCustomer = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/update-status`, payload);
};

export const bulkRemoveCustomers = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/bulk-update-status`, payload);
};

export const addAddress = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/address-add`, payload);
};

export const editAddress = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/address-update`, payload);
};

export const removeAddress = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/address-update-status`, payload);
};

export const bulkRemoveAddresses = (payload) => {
  return axios.post(`${externalBaseUrl}/customers/address-bulk-update-status`, payload);
};
