import React from 'react';
import Icon from '@ant-design/icons';

const ContactSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="currentColor">
    <path d="M3,5.1c0.1,0.2,0.3,0.4,0.4,0.7c0.1,0.4,0.1,0.7,0.1,1.1c0,0.2-0.1,0.5-0.2,0.7C3,7.8,2.9,7.9,2.7,8
	C2.5,8.1,2.4,8.1,2.2,8.2C2.1,8.2,2,8.2,1.9,8.3C1.9,8.4,1.8,8.5,1.7,8.7c-0.1,0.3-0.1,0.6,0,0.9c0.1,0.4,0.1,0.6,0.1,1
	c0.1,0.6,0.3,1.2,0.5,1.9c0.1,0.2,0.1,0.4,0.3,0.6c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.1,0.3,0c0.2-0.1,0.4-0.1,0.6-0.1
	c0.2-0.1,0.4,0,0.6,0.1c0.2,0.1,0.4,0.3,0.5,0.5c0.2,0.3,0.3,0.6,0.4,1c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.2-0.2,0.4-0.4,0.5
	c-0.1,0.1-0.5,0.2-0.8,0.3c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4-0.1-0.6-0.2C2.6,16,2.2,15.6,1.8,15c-0.3-0.4-0.5-0.9-0.7-1.4
	c-0.1-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.2-0.1-0.4-0.2-0.6s-0.1-0.4-0.1-0.6c0-0.2-0.1-0.4-0.1-0.6
	c0-0.1-0.1-0.3-0.1-0.5C0.1,9.8,0,9.7,0,9.4C0,9.3,0,9.2,0,9c0-0.1,0-0.4,0-0.5C0,8.4,0,8.2,0,8c0-0.1,0-0.3,0-0.4
	c0-0.1,0.1-0.4,0.1-0.5c0.1-0.5,0.2-0.9,0.4-1.4l0,0c0.1-0.2,0.3-0.4,0.4-0.5C1.1,5.1,1.2,5,1.4,5c0.4-0.1,0.7-0.1,1-0.1
	C2.6,4.9,2.8,5,3,5.1z M16.5,3.7c0.5,0.9,1,1.7,1.5,2.6c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.3-0.4,0.3H9.9c-0.1,0-0.2,0-0.4-0.1
	C9.4,6.7,9.4,6.5,9.5,6.3c0.1-0.1,0.2-0.1,0.3-0.2c1.7-0.9,3.3-1.9,5-2.9C15.3,3,16.1,3.2,16.5,3.7z M8.2,7.5c3.5,0,7.1,0,10.5,0
	C19.4,7.5,20,8,20,8.7l0,0v6.9c0,0.5-0.3,0.9-0.7,1.1c-0.1,0.1-0.4,0.1-0.6,0.1H8.2c-0.2,0-0.4-0.1-0.6-0.1
	c-0.4-0.2-0.7-0.6-0.7-1.2c0-2.3,0-4.5,0-6.9C6.9,8.1,7.4,7.5,8.2,7.5L8.2,7.5L8.2,7.5z M10.5,10c-0.7,0.1-1.2,0.6-1.2,1.4
	c0.1,0.7,0.6,1.2,1.4,1.2c0.7-0.1,1.2-0.6,1.2-1.4c-0.1-0.4-0.3-0.8-0.6-1C11,10,10.8,10,10.5,10z M13.6,11.8h4.8
	c0.1,0,0.3-0.1,0.4-0.3c0-0.1-0.1-0.3-0.3-0.4c0,0,0,0-0.1,0h-5c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.4,0.1,0.4
	C13.4,11.9,13.5,11.8,13.6,11.8L13.6,11.8z M10,12.8c-0.4,0.1-0.8,0.2-1.2,0.4c-0.3,0.1-0.5,0.4-0.6,0.6c-0.1,0.1-0.1,0.4-0.1,0.5
	c0,0.2,0.1,0.4,0.4,0.5C8.6,15,8.7,15,8.8,15c1.2,0,2.5,0,3.7,0c0.3,0,0.6-0.2,0.6-0.5c0-0.2,0-0.5-0.1-0.7
	c-0.1-0.2-0.4-0.4-0.6-0.6C11.7,12.8,10.8,12.7,10,12.8L10,12.8z M14.9,13.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.3,0,0.4l0,0
	c0.1,0.1,0.1,0.1,0.3,0.1c1.2,0,2.5,0,3.7,0c0.1,0,0.3-0.1,0.4-0.3s-0.1-0.3-0.3-0.4c0,0,0,0-0.1,0L14.9,13.1z M5.8,8.4
	c0.1-0.1,0.4,0,0.4,0.2v0.1c0,0.7,0,1.5,0,2.2c0,0.1-0.1,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.3-0.4-0.6-0.5-0.9
	c-0.1-0.1-0.1-0.4-0.1-0.6V9.6c0-0.3,0.1-0.5,0.3-0.7C5.4,8.7,5.6,8.6,5.8,8.4z"/>
  </svg>
);

const ContactIcon = (props) => <Icon component={ContactSVG} {...props} />;
export default ContactIcon;
