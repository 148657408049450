import { Outlet } from './Outlet';
import { Product } from './Product';
import { Systems } from './Systems';
import { Pos } from './Pos';
import { Inventory } from './Inventory';
import { CRM } from './CRM';
import { Reporting } from './Reporting';
import { InventoryReporting } from './InventoryReporting';
import AllRoles from './AllRoles';
// import { Member } from './Member';

const titles = {
  DASHBOARD: 'Dashboard'
};

export default [
  {
    component: 'NotFound',
    path: '/404',
    title: titles.DASHBOARD,
    menu: '404',
    parent: false,
  },
  {
    component: 'Denied',
    path: '/denied',
    title: titles.DASHBOARD,
    menu: 'Denied',
    parent: false,
  },
  {
    component: 'Application',
    path: '/dashboard',
    title: titles.DASHBOARD,
    key: 'dashboard',
    menu: 'Dashboard',
    icon: 'DashboardIcon2',
    parent: true,
    permission: [AllRoles.DASHBOARD.DASHBOARD],
  },
  {
    component: 'Profile',
    path: '/profile',
    title: titles.DASHBOARD,
    menu: 'profile',
    parent: false,
  },

  ...Outlet,
  ...Product,
  ...Systems,
  ...Pos,
  ...Inventory,
  ...CRM,
  // ...Member,
  ...Reporting,
  ...InventoryReporting
];