import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const addProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/products/create`, payload);
};

export const editProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/products/update`, payload);
};

export const removeProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/products/update-status`, payload);
};

export const bulkRemoveProducts = (payload) => {
  return axios.post(`${externalBaseUrl}/products/bulk-update-status`, payload);
};

export const addBom = (payload) => {
  return axios.post(`${externalBaseUrl}/products/bom-create`, payload);
};

export const editBom = (payload) => {
  return axios.post(`${externalBaseUrl}/products/bom-update`, payload);
};

export const removeBom = (payload) => {
  return axios.post(`${externalBaseUrl}/products/bom-update-status`, payload);
};

export const bulkRemoveBoms = (payload) => {
  return axios.post(`${externalBaseUrl}/products/bom-bulk-update-status`, payload);
};

export const addProductGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-group-create`, payload);
};

export const editProductGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-group-update`, payload);
};

export const removeProductGroup = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-group-update-status`, payload);
};

export const duplicateProductGroupParts = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-group-parts-duplicate`, payload);
};

export const addProductPart = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-create`, payload);
};

export const editProductPart = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-update`, payload);
};

export const removeProductPart = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-update-status`, payload);
};

export const bulkRemoveProductParts = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-bulk-update-status`, payload);
};

export const updateProductPartsOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-update-order`, payload);
};

export const duplicateProductPart = (payload) => {
  return axios.post(`${externalBaseUrl}/products/part-bulk-duplicate`, payload);
};

export const uploadKdsImage = (payload) => {
  return axios.post(`${externalBaseUrl}/products/upload-kds-image`, payload);
};

export const removeKdsImage = (payload) => {
  return axios.post(`${externalBaseUrl}/products/remove-kds-image`, payload);
};

export const addProductMenu = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/create`, payload);
};

export const editProductMenu = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/update`, payload);
};

export const removeProductMenu = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/update-status`, payload);
};

export const bulkRemoveProductMenus = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/bulk-update-status`, payload);
};

export const addMenuType = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-type-create`, payload);
};

export const editMenuType = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-type-update`, payload);
};

export const removeMenuType = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-type-update-status`, payload);
};

export const bulkRemoveMenuTypes = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-type-bulk-update-status`, payload);
};

export const updateMenuTypesOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-type-update-order`, payload);
};

export const duplicateMenuCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-type-duplicate`, payload);
};

export const addOrderType = (payload) => {
  return axios.post(`${externalBaseUrl}/order-types/create`, payload);
};

export const editOrderType = (payload) => {
  return axios.post(`${externalBaseUrl}/order-types/update`, payload);
};

export const removeOrderType = (payload) => {
  return axios.post(`${externalBaseUrl}/order-types/update-status`, payload);
};

export const bulkRemoveOrderTypes = (payload) => {
  return axios.post(`${externalBaseUrl}/order-types/bulk-update-status`, payload);
};

export const updateOrderTypeOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/order-types/update-order`, payload);
};

export const addIngredient = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/create`, payload);
};

export const editIngredient = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/update`, payload);
};

export const convertIngredient = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/convert-to-semi`, payload);
};

export const removeIngredient = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/update-status`, payload);
};

export const bulkRemoveIngredients = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/bulk-update-status`, payload);
};

export const addAlacarte = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/alacarte-create`, payload);
};

export const editAlacarte = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/alacarte-update`, payload);
};

export const removeAlacarte = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/alacarte-update-status`, payload);
};

export const bulkRemoveAlacartes = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/alacarte-bulk-update-status`, payload);
};

export const uploadProductImage = async (file) => {
  // const fileObj = await readeFile(file)
  let paylod = new FormData();
  paylod.append("file", file);

  let upurl = `${externalBaseUrl}/products/upload-featured-graphic`;
  try {
    const resp = await byebugAxios({ method: 'post', url: upurl, data: paylod, headers: { "Content-Type": "multipart/form-data" } });
    return resp.data;
  } catch (e) {
    return false;
  }
};

export const addCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/create`, payload);
};

export const editCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/update`, payload);
};

export const removeCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/update-status`, payload);
};

export const bulkRemoveCategories = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/bulk-update-status`, payload);
};

export const addSubCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/sub-category-create`, payload);
};

export const editSubCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/sub-category-update`, payload);
};

export const removeSubCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/sub-category-update-status`, payload);
};

export const bulkRemoveSubCategories = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/sub-category-bulk-update-status`, payload);
};

export const addFurtherSubCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/further-subcategory-create`, payload);
};

export const editFurtherSubCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/further-subcategory-update`, payload);
};

export const removeFurtherSubCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/further-subcategory-update-status`, payload);
};

export const bulkRemoveFurtherSubCategories = (payload) => {
  return axios.post(`${externalBaseUrl}/categories/further-subcategory-bulk-update-status`, payload);
};

export const addMenuCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-category-create`, payload);
};

export const editMenuCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-category-update`, payload);
};

export const updateMenuCategoryOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-category-update-order`, payload);
};

export const removeMenuCategory = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-category-update-status`, payload);
};

export const addMenuItem = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-items-create`, payload);
};

export const removeMenuItem = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-items-remove`, payload);
};

export const updateMenuItemOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/menu-items-update-order`, payload);
};

export const addUom = (payload) => {
  return axios.post(`${externalBaseUrl}/uoms/create`, payload);
};

export const editUom = (payload) => {
  return axios.post(`${externalBaseUrl}/uoms/update`, payload);
};

export const removeUom = (payload) => {
  return axios.post(`${externalBaseUrl}/uoms/update-status`, payload);
};

export const bulkRemoveUoms = (payload) => {
  return axios.post(`${externalBaseUrl}/uoms/bulk-update-status`, payload);
};

export const addAvailability = (payload) => {
  return axios.post(`${externalBaseUrl}/work-schedules/create`, payload);
};

export const editAvailability = (payload) => {
  return axios.post(`${externalBaseUrl}/work-schedules/update`, payload);
};

export const removeAvailability = (payload) => {
  return axios.post(`${externalBaseUrl}/work-schedules/update-status`, payload);
};

export const bulkRemoveAvailabilities = (payload) => {
  return axios.post(`${externalBaseUrl}/work-schedules/bulk-update-status`, payload);
};

export const addMenuButton = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/product-menu-button-add`, payload);
};

export const editMenuButton = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/product-menu-button-update`, payload);
};

export const removeMenuButton = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/product-menu-button-update-status`, payload);
};

export const bulkRemoveMenuButtons = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/product-menu-button-bulk-update-status`, payload);
};

export const updateMenuButtonsOrder = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/product-menu-button-update-order`, payload);
};

export const addProductUom = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/product-uom-create`, payload);
};

export const editProductUom = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/product-uom-update`, payload);
};

export const removeProductUom = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/product-uom-update-status`, payload);
};

export const bulkRemoveProductUoms = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/product-uom-bulk-update-status`, payload);
};

export const syncMenu = (payload) => {
  return axios.post(`${externalBaseUrl}/product-menus/sync-menu`, payload);
};

export const exportProduct = (search = {}, selectedRowKeys) => {
  let q = {
    code_or_name_cont: encodeURIComponent(search?.code || ""),
    status_eq: search?.status
  }

  if(selectedRowKeys.length && selectedRowKeys.length > 0) {
    q["id_in"] = selectedRowKeys
  }

  return axios.get(`${externalBaseUrl}/products/export-report?q=${JSON.stringify(q)}`, { responseType: 'blob' });
};

export const editVendorProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/vendor-product-update`, payload);
};

export const removeVendorProduct = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/vendor-product-update-status`, payload);
};

export const bulkRemoveVendorProducts = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/vendor-product-bulk-update-status`, payload);
};

export const importProduct = async (file) => {
  if (localStorage.getItem("uploading")) {
    return
  } else {
    localStorage.setItem("uploading", true);
  }
  let paylod = new FormData();
  paylod.append("file", file);

  let upurl = `${externalBaseUrl}/products/import-products`;
  try {
    const resp = await axios({ method: 'post', url: upurl, data: paylod, headers: { "Content-Type": "multipart/form-data" } });
    localStorage.removeItem("uploading");
    return resp;
  } catch (e) {
    localStorage.removeItem("uploading");
    return { res: false, msg: e };
  }
};

export const syncAllUomFromNetsuite = (payload) => {
  return axios.post(`${externalBaseUrl}/ingredients/sync-all-uoms`, payload);
};

export const getLogs = () => {
  return axios.post(`${externalBaseUrl}/products/import-product-logs`);
};