import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Form, Button, message, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputPassword } from '../../atoms/FormElement';
import byebugAxios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../configs/constants';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined spin />;

const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { control, formState: { errors }, handleSubmit, watch } = useForm();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const token = queryParameters.get('token');

  let checkPassword = watch('password');

  useEffect(() => {
    if (!token) {
      message.error("Please proceed with forgot password.");
      navigate("/login");
      return;
    }
  }, []);

  // const i18n = useTranslate();
  // const { t } = i18n;

  const onFinish = (values) => {
    setLoading(true);
    const payload = {
      reset_password_token: token,
      new_password: values.password
    };
    byebugAxios
      .post(`${externalBaseUrl}/users/reset-password`, payload)
      .then((response) => {
        setLoading(false);
        if(response.data.code === 200){
          message.success(response?.data?.message);
          navigate('/login');
        }else {
          message.error(response?.data?.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(e?.message ?? t("General.something_went_wrong"));
      });
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Row gutter={[30, 24]}>
        <Col span={24}>
          <Title level={3} className="text-center mb-0">
            Reset Password
          </Title>
        </Col>
        <Col span={24}>
          <Form name="normal_forget" layout='vertical' onFinish={handleSubmit(onFinish)}>
            <InputPassword
              fieldname="password"
              label="New Password"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: "Enter new password"
              }}
              rules={{
                required: 'New password is required',
                validate: (value) => value.length >= 6 || "Password needs to be at least 6 characters."
              }}
              validate={errors.password && 'error'}
              validMessage={errors.password && errors.password.message}
            />
            <InputPassword
              isRequired={true}
              fieldname="confirm"
              label="Re-type Password"
              control={control}
              initValue=""
              iProps={{
                size: 'large',
                placeholder: 'Confirm password'
              }}
              rules={{
                required: 'Please confirm your password',
                validate: (value) => value === checkPassword || "The Password does not match"
              }}
              validate={errors.confirm ? 'error' : ''}
              validMessage={errors.confirm && errors.confirm.message}
            />
            <Button type="primary" htmlType="submit" className="w-100" size="large">
              Reset Now
            </Button>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
