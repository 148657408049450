import React, { Fragment } from 'react';
import { useTranslate } from 'Translate';

export default (props) => {
  const { columns, data } = props;
  const i18n = useTranslate();
    const { t } = i18n;

  return (
    <div className="inventory-table-container">
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.gr_summary_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) => 
              <tr key={`Child List Item ${listItemIndex}`}>
                <td>{listItem.location_code}</td>
                <td>{listItem.date}</td>
                <td>{listItem.reference}</td>
                <td>{listItem.supplier}</td>
                <td>{listItem.status}</td>
                <td align='right'>{listItem.qty}</td>
                <td align='right'>{listItem.gross_amount}</td>
                <td align='right'>{listItem.discount_amount}</td>
                <td align='right'>{listItem.net_amount}</td>
                <td align='right'>{parseFloat(listItem.total_inv_cost).toFixed(8)}</td>
              </tr>
            )}

            
            <tr className='bg-gray text-white'>
              <td colSpan={4}></td>
              <td>{t("SalesReporting.grand_total")}</td>
              {data?.grand_total?.map((item, index) => 
                <td key={`Grand Total ${item} ${index}`} align='right'>{item}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}