import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';
import { useTranslate } from 'Translate';

export default (props) => {
  const { setActiveTab, oneTab, searchParams } = props;
  const defaultActive = searchParams?.get('t');
  const i18n = useTranslate();
  const { t } = i18n;

  const tabs = [
    {
      name: t("General.details"),
      Comp: 'Details',
    },
    {
      name: t("Product.order_type_groups"),
      Comp: 'OrderType',
    },
    {
      name: t("Product.product_structure"),
      Comp: 'ProductGroup',
    },
    {
      name: "KDS",
      Comp: 'Kds',
    }
  ];

  return (
    <Tabs items={
        (oneTab ? [tabs[0]] : tabs).map((item, index) => {
          const Cardi = TabCards[item.Comp];
          return {
            key: (index + 1).toString(),
            label: item.name,
            forceRender: true,
            children: <Cardi {...props} />
          }
        })
    } defaultActiveKey={defaultActive || "1"} onChange={(activeKey) => setActiveTab(activeKey)}/>
  );
};
