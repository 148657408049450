import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getUserProfile = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/users/get-profile`);
    load && load(false);
    dispatch({
      type: action_types.USER_PROFILE,
      data: data
    });
  };
};