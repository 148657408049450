import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Spin, message, Form, Tooltip } from 'antd';
import AddForm from '../components/AddForm';
import { getTransferIn, reloadTransferIn } from "../../ducks/actions";
import { editTransferIn, removeTransferIn } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { CheckOutlined, CloseCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';
import { useForm } from 'react-hook-form';
import FormGroup from 'Molecules/FormGroup';
import { Popup } from 'Atoms/Popup';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation().pathname;
  const data = useSelector((state) => state.inventory.transferIn);
  const meta = useSelector((state) => state.inventory.transferInOption);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const allowedEdit = allowed([AllRoles.INVENTORY.TRANSFER_RECEIVE], 'write');
  const allowedDelete = allowed([AllRoles.INVENTORY.TRANSFER_RECEIVE], 'delete');
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const [visible, setVisible] = useState(false);
  const isPendingAndAllowed = data?.status === "pending" && allowedEdit;
  const isNotOriginManager = (!meta?.is_outlet_manager || (meta?.is_outlet_manager && data?.origin_code !== meta?.manager_outlet_code));
  const isAllowedAction = isPendingAndAllowed && isNotOriginManager;
  const i18n = useTranslate();
    const { t } = i18n;

  const formFields = [
    {
      name: 'remarks',
      label: t("System.remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      reqmessage: t("General.required"),
      req: true,
    }
  ];

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    dispatch(getTransferIn(id, setLoad));
  };

  const reloadApi = (data) => {
    dispatch(reloadTransferIn(data, meta));
  }

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  const onAction = async (statusEvent, remark = null) => {
    props.setLoading(true);

    const payload = {
      transfer_in_id: data?.id,
      status_event: statusEvent
    }

    if(remark) payload.cancellation_remarks = remark

    await removeTransferIn(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(`Transfer Receive status has been ${statusEvent}ed successfully!`);
        reloadApi(res.data.data)
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  // const exportPdf = async () => {
  //   props.setLoading(true);

  //   await exportPoPdf(id).then((res) => {
  //     props.setLoading(false);
  //     if (res.data) {
  //       const aElement = document.createElement('a');
  //       let filename = res.headers["x-filename"];
  //       aElement.setAttribute('download', filename ?? "sample.pdf");
  //       const href = URL.createObjectURL(res.data);
  //       aElement.href = href;
  //       aElement.setAttribute('target', '_blank');
  //       aElement.click();
  //       URL.revokeObjectURL(href);
  //     } else {
  //       message.error(res.data.message);
  //     }
  //   }).catch(e => {
  //     props.setLoading(false);
  //     message.error(e.message ?? t("General.something_went_wrong"));
  //   })
  // };

  const openCancelPopUp = () => {
    setVisible(true);
    setValue('remarks', data?.remarks);
    clearErrors();
  }

  const onFinish = async (val) => {
    onAction('cancel', val?.remarks)
    setVisible(false);
  }

  const cancelPopUp = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {t("Inventory.cancel_tr")}
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('remarks', '');
            }}>{t("General.cancel")}</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('remarks', '');
    },
  };

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={load}>
        <Row gutter={[20, 30]}>
          <Col span={24} style={{ position: "sticky", top: "-30px", backgroundColor: "#f5f5f5", zIndex: 3, padding: "10px" }}>
            <Row justify={'space-between'} align={'middle'}>
              <Col flex='auto'>
                <Title level={3} className='mb-0 mt-0'>
                  {data?.reference}
                  {" (Status: "}
                  <span className={data?.status === "posted" ? "c-success" : data?.status === "pending" ? "c-pending" : "c-danger"}>
                    {data?.status?.[0]?.toUpperCase()}{data?.status?.slice(1)}
                  </span>
                  {")"}
                  {data?.netsuite_synced === "1" && <Tooltip title={t("Inventory.synced_to_netsuite")}><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip>}
                </Title>
              </Col>
              <Col flex='auto' className='text-right'>
                <Space size={10}>
                  {/* <Button size="large" htmlType='button' className='green-btn text-white' onClick={() => exportPdf()}>
                    <ExportOutlined /> {t("Inventory.export_po")}
                  </Button> */}
                  {
                    isAllowedAction &&
                    <Button size="medium" htmlType='button' className='red-btn' onClick={() => onAction('reject')}>
                      <CloseCircleOutlined/> {t("Inventory.reject_tr")}
                    </Button>
                  }
                  {
                    isAllowedAction &&
                    data?.item_count > 0 &&
                    <Button size="medium" htmlType='button' className='green-btn' onClick={() => onAction('post')}>
                      <CheckOutlined /> {t("Inventory.post_tr")}
                    </Button>
                  }
                  {/* {
                    data?.status !== "cancelled" &&
                    allowedEdit &&
                    <Button size="medium" htmlType='button' danger onClick={() => openCancelPopUp()}>
                      Cancel TR
                    </Button>
                  } */}
                  <Button size="medium" htmlType='button' className='red-btn' onClick={() => navigate('/inventory/transfer-in')}>{'<'} {t("General.back")}</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <AddForm
              loading={props.loading}
              setLoading={setLoad}
              activeTab={activeTab}
              setActiveTab={setActive}
              data={data}
              meta={meta}
              id={id}
              editTransferIn={editTransferIn}
              reloadApi={reloadApi}
              reloadCount={callApi}
              searchParams={searchParams}
              mode={'edit'}
              navigate={navigate}
              allowedEdit={allowedEdit}
              allowedDelete={allowedDelete}
              isNotOriginManager={isNotOriginManager}
            />
          </Col>
        </Row>
      </Spin>
      <Popup {...cancelPopUp} />
    </>
  )
}