import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit } = useForm()
  const i18n = useTranslate();
  const { t } = i18n;

  const searchVoucherTypeLabel = (searchVal?.voucher_type === "" || searchVal?.voucher_type) ? true : false
  const searchStatusLabel = (searchVal?.status === "" || searchVal?.status) ? true : false

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <InputField
            fieldname='code'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.code ? searchVal?.code : ''}
            control={control}
            iProps={{ placeholder: `${t("General.search_code_name")}..` }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='voucher_type'
            label=''
            class='mb-0 w-100 default-select'
            initValue={searchVoucherTypeLabel ? {label: searchVal?.voucher_type === "" ? t("General.all_voucher_types") : searchVal?.voucher_type, value: searchVal?.voucher_type} : ''}
            control={control}
            selectOption={props.field1}
            iProps={{ placeholder: t("Pos.voucher_type") }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='status'
            label=''
            class='mb-0 w-10 default-select'
            initValue={searchStatusLabel ? {label: searchVal?.status === "" ? t("General.all_status") : searchVal?.status, value: searchVal?.status} : ''}
            control={control}
            iProps={{ placeholder: t("General.status") }}
            selectOption={props.field2}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>{t("General.search")}</Button>
        </Col>
      </Row>
    </Form>
  )
}