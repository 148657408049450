import React from 'react';
import { Card, Row, Col, Typography, Divider, List, Descriptions } from 'antd';

export default (props) => {
  const { userData, title } = props;
  const { Title, Text } = Typography;

  return (
    <Card bordered={false} className="uni-card b-black">
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Row gutter={20} justify="start">
            <Col span={24}>
              <Title className="mb-0 text-white" level={3}>
                {userData?.length}
              </Title>
            </Col>
            <Col span={24} className='text-white'>
              <Divider orientation="left" className='text-white'>{title}</Divider>
              {/* <Text className="c-gray">{title}</Text> */}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Card bordered={false} className="scrolling-card ultra-550 b-dark-gray text-white">
            <Row gutter={[20, 20]} justify="center" align="middle">
              <Col span={24}>
                <List
                  bordered
                  dataSource={userData?.map(x => ({...x, key: x.id}))}
                  renderItem={(item) => (
                    <List.Item>
                      {item.user_name}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
