import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import Customer from 'Modules/CRM/Customer';
import AddCustomer from 'Modules/CRM/Customer/AddCustomer';
import EditCustomer from 'Modules/CRM/Customer/EditCustomer';

const Components = {
  Customer,
  AddCustomer,
  EditCustomer
};

export default (props) => {
  const CrmComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <CrmComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};