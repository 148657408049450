import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {

  const { errors, control, meta, allowedEdit } = props
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("General.code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'kitchen_queue_type',
      label: t("Pos.queue_type"),
      req: true,
      type: 'select',
      options: meta?.queue_types?.map((x) => ({ label: x, value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'kitchen_display',
      label: t("Pos.kitchen_display"),
      req: true,
      type: 'select',
      options: meta?.kitchen_displays?.map((x) => ({ label: x, value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'slip_settings',
      label: t("Pos.slip_settings"),
      req: true,
      type: 'select',
      options: meta?.slip_settings?.map((x) => ({ label: x, value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'slip_print_mode',
      label: t("Pos.slip_print_mode"),
      req: true,
      type: 'select',
      options: meta?.slip_print_modes?.map((x) => ({ label: x, value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_readonly',
      label: t("Pos.readonly_mode"),
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: true,
      static: !allowedEdit
    },
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}