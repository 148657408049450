import React, { useEffect, Fragment, useState } from 'react';
import { Row, Col, Form, Button, message, Upload, Space } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { dummyRequest, uploadFileV2, getFileName } from 'Features/utility';
import placeholderImg from "Assets/img/product-placeholder.png"
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, addPaymentMethodType, updatePaymentMethodType, meta, data, mode, reloadApi, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [fileId, setFileId] = useState();
  const [fileList, setFileList] = useState([]);
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("General.code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit || ["CARD", "EWALLET"].includes(data?.code)
    },
    {
      name: 'desc',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_non_sales',
      label: t("System.is_non_sales"),
      req: false,
      twocol: false,
      colWidth: '1 0 25%',
      alignEnd: true,
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_kiosk',
      label: t("System.is_kiosk"),
      req: false,
      twocol: false,
      colWidth: '1 0 25%',
      alignEnd: true,
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('id', data.id);
      setValue('code', data.code);
      setValue('desc', data.desc);
      setValue('is_non_sales', data.is_non_sales === "1");
      setValue('is_kiosk', data.is_kiosk === "1");
      // setValue('hide_image', data.hide_image === "1");
      if (data?.image_url) {
        setFileList([{ uid: '-1', name: getFileName(data?.image_url), status: 'done', url: data?.image_url }]);
      } else {
        setFileList([]);
      }
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      // hide_image: val?.hide_image ? 1 : 0,
      blob_id: fileId,
      payment_method_type_id: val?.id,
      is_non_sales: val?.is_non_sales ? 1 : 0,
      is_kiosk: val?.is_kiosk ? 1 : 0,
    }

    await (mode === "add" ? addPaymentMethodType : updatePaymentMethodType)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t(`Success.tender_media_type_${mode === "add" ? "created" : "updated"}`));
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const handleUpload = async (e) => {
    props.setLoading(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      props.setLoading(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`${t("General.error_during_upload")} #{error}`);
      props.setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24} className="text-center">
          <Upload
            disabled={!allowedEdit}
            listType="picture-card"
            className="avatar-uploader larger"
            showUploadList={false}
            accept="image/*"
            maxCount={1}
            fileList={fileList}
            customRequest={dummyRequest}
            onChange={(e) => handleUpload(e)}
          >
            <Space size={4}>
              {fileList?.length > 0 ? (
                <img
                  src={fileList[0].url}
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) :
                <div>
                  <img src={placeholderImg} alt="" className='w-100' />
                </div>
              }
            </Space>
          </Upload>
        </Col>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          !["CASH"].includes(data?.code) &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}