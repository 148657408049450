// Transfer Out Details
import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

const _ = require('lodash');
const todayDate = new Date();

export default (props) => {
  const { id, data, meta, addTransferOut, editTransferOut, reloadApi, mode, allowedEdit, allowedDelete, generateInventoryNo } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [editable, setEditable] = useState(true);
  const [locationId, setLocationId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const i18n = useTranslate();
  const { t } = i18n;

  const disabledDates = (current) => {
    return startDate && current && current < startDate.startOf('day');
  }

  const formFields = [
    {
      name: 'reference',
      label: t("Inventory.to_no"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable || meta?.is_outlet_manager,
      addOnButton: t("General.generate"),
      onBtnClick: () => { 
        onGenerateInventoryNo()
      },
    },
    {
      name: 'date',
      label: t("Inventory.to_date"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable,
      disabledDate: disabledDates
    },
    {
      type: 'select',
      label: t("Inventory.org_loc_code"),
      name: 'origin',
      twocol: false,
      options: meta?.locations?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Origin Location Required',
      colWidth: '0 1 50%',
      static: !editable || meta?.is_outlet_manager,
      onChange: (e) => {
        setLocationId(e.value);
        setValue('order_type_group', '')
      }
    },
    {
      type: 'select',
      label: t("Inventory.des_loc_code"),
      name: 'destination',
      twocol: false,
      options: meta?.destinations?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Destination Location Required',
      colWidth: '0 1 50%',
      static: !editable
    },
    {
      name: 'order_type_group',
      label: t("Inventory.recipe"),
      req: false,
      placeholder: t("General.please_select"),
      type: 'select',
      class: 'default-select',
      options: meta?.locations?.find((x) => x.id === locationId)?.order_type_groups?.map(y => ({ label: `${y.code} ${y.desc}`, value: y.id })),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
    },
    {
      name: 'remarks',
      label: t("System.remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable
    },
    {
      name: 'cancellation_remarks',
      label: t("Inventory.cancellation_remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable,
      hidden: data && data?.cancellation_remarks ? false : true
    },
  ];

  useEffect(() => {
    if(!data || !Object.keys(data).length) {
      setValue('date', dayjs(todayDate))
    }
    
    if (data && 'id' in data && mode !== 'add') {
      setValue('reference', data.reference);
      setValue('date', data?.date ? dayjs(data?.date) : '');
      setValue('origin', data?.origin_id ? { label: `${data?.origin_code} ${data?.origin_desc}`, value: data?.origin_id } : '');
      setValue('destination', data?.destination_id ? { label: `${data?.destination_code} ${data?.destination_desc}`, value: data?.destination_id } : '');
      setValue('remarks', data?.remarks);
      setValue('order_type_group', data?.order_type_group_id ? { label: `${data?.order_type_group_code} ${data?.order_type_group_desc}`, value: data?.order_type_group_id } : '');
      setLocationId(data?.origin_id);
      setValue('cancellation_remarks', data?.cancellation_remarks);
      setEditable(data.status === "pending" && allowedEdit);
    }

    if (meta && mode === 'add') {
      if (meta?.is_outlet_manager) {
        let outlet = meta?.locations?.find(x => x.id === meta?.current_location_id);
        setValue('origin', outlet?.id ? { label: `${outlet?.code} ${outlet?.desc}`, value: outlet?.id } : '');
        setValue('reference', meta?.reference);
        setLocationId(outlet.id);
      }
    }

    setStartDate(dayjs(meta?.start_date));
  }, [data, meta]);

  const onGenerateInventoryNo = async () => {
    if(!locationId) {
      message.error(t("General.please_select_origin_location_code"));
      return
    }
    props.setLoading(true);

    const payload = {
      transfer_out_id: id,
      location_id: locationId
    }

    await generateInventoryNo(payload, 'transfer-outs').then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        setValue('reference', res.data.data)
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }


  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      origin_id: val?.origin?.value,
      destination_id: val?.destination?.value,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : '',
      order_type_group_id: val?.order_type_group?.value,
      transfer_out_id: id,
    }

    await (mode === "add" ? addTransferOut : editTransferOut)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t(`Success.transfer_out_${mode === "add" ? "created" : "updated"}`));
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          editable &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}