import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {

  const { errors, control, options, allowedEdit, onRevoke, allowedSave } = props
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'store_name',
      label: t("Outlet.name"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
    },
    {
      name: 'email',
      label: t("System.email"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      nospace: true,
      email: true,
      static: !allowedSave
    },
    {
      name: 'merchant_type',
      label: t("Delete.merchant"),
      req: true,
      type: 'select',
      options: options?.merchant_types?.map((x) => ({ label: x?.toUpperCase(), value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedSave
    },
    {
      name: 'slug',
      label: t("System.sub_id"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      hidden: allowedEdit,
      static: true,
    },
    {
      name: 'api_key',
      label: t("System.api_key"),
      type: 'password',
      // static: true,
      twocol: false,
      colWidth: "0 1 100%",
      onBtnClick: () => { 
        onRevoke()
      },
      addOnButton: t("General.revoke"),
      readOnly: true,
      class: "readonly",
      hidden: allowedEdit,
      static: !allowedSave
    }
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}