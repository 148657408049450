import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

const _ = require('lodash');
const todayDate = new Date();

export default (props) => {
  const { id, data, meta, addPurchaseOrder, editPurchaseOrder, reloadApi, mode, allowedEdit, generateInventoryNo, editable, setEditable } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  // const [editable, setEditable] = useState(true);
  const [locationId, setLocationId] = useState(null);
  const [isOutletManager, setIsOutletManager] = useState(null);
  const i18n = useTranslate();
    const { t } = i18n;

  const formFields = [
    {
      name: 'reference',
      label: t("Inventory.po_no"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable || isOutletManager,
      addOnButton: t("General.generate"),
      onBtnClick: () => { 
        onGenerateInventoryNo()
      },
    },
    {
      name: 'date',
      label: t("Inventory.po_date"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      type: 'select',
      label: t("Outlet.outlet"),
      name: 'location',
      twocol: false,
      options: meta?.locations?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id, loc: x })),
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Outlet Required',
      colWidth: '0 1 50%',
      static: !editable || isOutletManager,
      onChange: (e) => {
        let outlet = e.loc;
        setValue('delivery_email', outlet.email);
        setValue('delivery_address1', outlet.address1);
        setValue('delivery_address2', outlet.address2);
        setValue('delivery_city', outlet.city);
        setValue('delivery_postcode', outlet.postcode);
        setValue('delivery_phoneno', outlet.phoneno);
        setValue('delivery_state', outlet.state);
        setValue('delivery_country', outlet.country ? { label: outlet.country, value: outlet.country } : '');
        setValue('delivery_fax', outlet.fax);
        setValue('delivery_attn', `${outlet.code} ${outlet.desc}`);
        setLocationId(e.value)
      }
    },
    {
      name: 'organization_name',
      label: t("Inventory.comp_name"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'delivery_email',
      label: t("Inventory.delivery_email"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_attn',
      label: t("Inventory.delivery_attention"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_address1',
      label: t("Inventory.del_add_1"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_address2',
      label: t("Inventory.del_add_2"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_city',
      label: t("Inventory.del_city"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_postcode',
      label: t("Inventory.del_postal_code"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_state',
      label: t("Inventory.del_state"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      type: 'select',
      label: t("Inventory.del_country"),
      name: 'delivery_country',
      twocol: false,
      options: meta?.countries?.map((x) => ({ label: x, value: x })),
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Delivery Country Required',
      colWidth: '0 1 50%',
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_phoneno',
      label: t("Inventory.delivery_phone_no"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_fax',
      label: t("Inventory.delivery_fax_no"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      req: false,
      reqmessage: t("General.required"),
      static: !editable || isOutletManager
    },
    {
      name: 'delivery_date',
      label: t("Inventory.delivery_date"),
      placeholder: t("General.please_state"),
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'vendor_name',
      label: t("Product.supplier_code"),
      placeholder: t("General.please_state"),
      type: 'select',
      options: meta?.vendors?.map((x) => ({ label: `${x.code} ${x.name}`, value: x.id })),
      twocol: false,
      colWidth: '0 1 50%',
      req: true,
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'refno1',
      label: t("Inventory.ref_no_1"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: !editable
    },
    {
      name: 'refno2',
      label: t("Inventory.ref_no_2"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: !editable
    },
    {
      name: 'remarks',
      label: t("System.remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable
    },
    {
      name: 'cancellation_remarks',
      label: t("Inventory.cancellation_remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable,
      hidden: data && data?.cancellation_remarks ? false : true
    },
  ];

  useEffect(() => {
    if(!data || !Object.keys(data).length) {
      setValue('date', dayjs(todayDate))
    }

    if (data && 'id' in data && mode !== 'add') {
      setValue('reference', data.reference);
      setValue('date', data?.date ? dayjs(data?.date) : '');
      setValue('location', data?.location_id ? { label: `${data?.location_code} ${data?.location_desc}`, value: data?.location_id } : '');
      setValue('organization_name', data?.organization_name);
      setValue('delivery_email', data?.delivery_email);
      setValue('delivery_attn', data?.delivery_attn);
      setValue('delivery_address1', data?.delivery_address1);
      setValue('delivery_address2', data?.delivery_address2);
      setValue('delivery_city', data?.delivery_city);
      setValue('delivery_postcode', data?.delivery_postcode);
      setValue('delivery_state', data?.delivery_state);
      setValue('delivery_country', data?.delivery_country ? { label: data?.delivery_country, value: data?.delivery_country } : '');
      setValue('delivery_phoneno', data?.delivery_phoneno);
      setValue('delivery_fax', data?.delivery_fax);
      setValue('delivery_date', data?.delivery_date ? dayjs(data?.delivery_date) : '');
      setValue('vendor_name', data?.vendor_id ? { label: `${data?.vendor_code} ${data?.vendor_name}`, value: data?.vendor_id, } : '');
      setValue('refno1', data?.refno1);
      setValue('refno2', data?.refno2);
      setValue('remarks', data?.remarks);
      setValue('cancellation_remarks', data?.cancellation_remarks);
      setLocationId(data?.location_id);
      setEditable(data.status === "pending" && allowedEdit);
    }

    if (meta && mode === 'add') {
      setValue('organization_name', meta?.organization_name);

      if (meta?.is_outlet_manager) {
        let outlet = meta?.locations?.find(x => x.id === meta?.current_location_id);
        setValue('location', outlet?.id ? { label: `${outlet?.code} ${outlet?.desc}`, value: outlet?.id } : '');
        setValue('delivery_email', outlet?.email);
        setValue('delivery_address1', outlet?.address1);
        setValue('delivery_address2', outlet?.address2);
        setValue('delivery_city', outlet?.city);
        setValue('delivery_postcode', outlet?.postcode);
        setValue('delivery_phoneno', outlet?.phoneno);
        setValue('delivery_state', outlet?.state);
        setValue('delivery_country', outlet?.country ? { label: outlet?.country, value: outlet?.country } : '');
        setValue('delivery_fax', outlet?.fax);
        setValue('delivery_attn', `${outlet?.code} ${outlet?.desc}`);
        setValue('reference', meta?.reference);
        setLocationId(outlet?.id)
        setIsOutletManager(true);
      }
    }
  }, [data, meta]);

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      location_id: val?.location?.value,
      vendor_id: val?.vendor_name?.value,
      delivery_country: val?.delivery_country?.value,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : '',
      delivery_date: val?.delivery_date ? dayjs(val?.delivery_date).format("YYYY-MM-DD") : ''
    }

    await addPurchaseOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.create_purchase_order"));
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      location_id: val?.location?.value,
      vendor_id: val?.vendor_name?.value,
      delivery_country: val?.delivery_country?.value,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : '',
      delivery_date: val?.delivery_date ? dayjs(val?.delivery_date).format("YYYY-MM-DD") : '',
      purchase_order_id: id,
    }

    await editPurchaseOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.update_purchase_order"));
        setTimeout(() => reloadApi(res.data.data), 250);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const onGenerateInventoryNo = async () => {
    if(!locationId) {
      message.error(t("General.please_select_outlet"));
      return
    }
    props.setLoading(true);

    const payload = {
      purchase_order_id: id,
      location_id: locationId
    }

    await generateInventoryNo(payload, 'purchase-orders').then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        setValue('reference', res.data.data)
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)} id="po-details-form">
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
      </Row>
    </Form>
  )
}