import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Button, message, Space, Spin, Form, Typography } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../ducks/actions'
import { addCategory, editCategory, removeCategory, bulkRemoveCategories } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import Search from './components/Search'
import FormGroup from 'Molecules/FormGroup';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { useTranslate } from 'Translate';

const { Title } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const category = useSelector((state) => state.product.category);
  const meta = useSelector((state) => state.product.categoryMeta);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState(null);
  const allowedEdit = allowed([AllRoles.PRODUCT.CATEGORY], 'write');
  const allowedDelete = allowed([AllRoles.PRODUCT.CATEGORY], 'delete');
  const globalListQuery = useSelector((state) => state.global.listQueryItems);
  const i18n = useTranslate();
  const { t } = i18n;

  const colName = [
    {
      title: t("General.code"),
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.desc"),
      dataIndex: 'name',
      key: 'name',
      className: 'enable-click',
      sorter: true
    },
    // {
    //   title: 'Sub Categories',
    //   dataIndex: 'sub_category_count',
    //   key: 'sub_category_count',
    //   sorter: true
    // },
    // {
    //   title: 'Further Sub Categories',
    //   dataIndex: 'further_subcategory_count',
    //   key: 'further_subcategory_count',
    //   sorter: true
    // },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) =>
        <ActionButton
          title="category"
          btnAction1={() => navigate(`/product/category/${record.id}`)}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'code',
      label: t("Product.category_code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'name',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'select',
      label: t("Product.remark_types"),
      name: 'remark_types',
      twocol: false,
      options: meta?.remark_types?.map((x) => ({ label: x.code, value: x.id })),
      req: false,
      multiple: true,
      placeholder: t("General.please_select"),
      reqmessage: t("Product.remark_types_req"),
      colWidth: '0 1 100%',
      class: 'default-select',
      static: !allowedEdit
    },
  ];

  const addNew = () => {
    setVisible(true);
    setValue('id', null);
    setValue('code', '');
    setValue('name', '');
    setValue('remark_types', '');
  };

  const btnList = [
    {
      title: t("Product.confirm_remove_select_cat"),
      text: t("General.bulk_remove"),
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: `+ ${t("Product.new_category")}`,
      classes: 'attendance-succes',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.categoryListQuery) {
      dispatch(getCategories(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('categoryListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.categoryListQuery
      dispatch(getCategories(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      category_id: id,
      status_event: 'remove'
    }

    await removeCategory(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.cat_remove_success"));
        setTimeout(() => dispatch(getCategories(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('categoryListQuery', page, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      categories: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveCategories(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success(t("Product.cats_remove_success"));
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getCategories(1, limit, '', '', null, props.setLoading)), 500);
        dispatch(getListQueryItems('categoryListQuery', 1, limit, '', '', null, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      name: val?.name,
      code: val?.code,
      remark_types: val?.remark_types ? JSON.stringify(val?.remark_types?.map(x => ({ id: x.value }))) : "[]",
      remark_type_id: val?.remark_type?.value
    }

    await (val?.id ? editCategory : addCategory)(payload).then(res => {
      props.setLoading(false);
      if(res.data.code === 200) {
        message.success(`${val?.id ? t("Product.edit_cat_success") : t("Product.add_cat_success")}`);
        setVisible(false);
        setTimeout(() => navigate(`/product/category/${res.data.data}`), 300);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getCategories(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('categoryListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getCategories(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('categoryListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  };

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        code: search.code
      };
      setSearchVal(searching);
      dispatch(getCategories(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('categoryListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getCategories(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('categoryListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const popup = {
    closable: false,
    visibility: visible,
    content:
    <Spin indicator={antIcon} size="large" spinning={props.loading}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {t("Product.add_cat")}
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('code', '');
              setValue('name', '');
              setValue('remark_types', '');
            }}>{t("General.cancel")}</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('name', '');
      setValue('code', '');
      setValue('remark_types', '');
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
        navigate(`/product/category/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={t("Product.cats")} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.categoryListQuery?.searchValue}
            onRow={onClickRow}
            // listClass="nopad"
            ListData={category?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            onChange={onTableChange}
            pagination={{
              total: category?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}