import React, { useEffect, useState } from 'react';
import { Space, Button, Row, Col, Upload, Form, message } from 'antd';
import { dummyRequest, uploadFileV2, getFileName } from 'Features/utility';
import placeholderImg from "Assets/img/product-placeholder.png"
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, data, uploadKdsImage, removeKdsImage, reloadApi, allowedEdit } = props;
  const [fileId, setFileId] = useState();
  const [fileList, setFileList] = useState([]);
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    if (data && 'id' in data) {
      if (data?.kds_image_url) {
        setFileList([{ uid: '-1', name: getFileName(data?.kds_image_url), status: 'done', url: data?.kds_image_url }]);
      } else {
        setFileList([]);
      }
    }
  }, [data]);

  const onFinish = async () => {
    props.setLoading(true);
    const payload = {
      blob_id: fileId,
      product_id: id
    }

    await uploadKdsImage(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.kds_img_upload_success"));
        setFileId();
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const handleUpload = async (e) => {
    props.setLoading(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      props.setLoading(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`${t("General.error_during_upload")} ${error}`);
      props.setLoading(false);
    }
  };

  const removeImage = async () => {
    props.setLoading(true);
    const payload = {
      blob_id: fileId,
      product_id: id
    }
    await removeKdsImage(payload).then(d => {
      props.setLoading(false);
      if(d.data.code === 200) {
        message.success(t("Product.kds_img_remove_success"));
        reloadApi();
      }else{
        message.error(d.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(d.message);
    })
  }

  return (
    <Row gutter={[20, 20]}>
      <Col span={24} className="text-center">
        <Upload
          disabled={!allowedEdit}
          listType="picture-card"
          className="avatar-uploader larger"
          showUploadList={false}
          accept="image/*"
          maxCount={1}
          fileList={fileList}
          customRequest={dummyRequest}
          onChange={(e) => handleUpload(e)}
        >
          <Space size={4}>
            {fileList?.length > 0 ? (
              <img
                src={fileList[0].url}
                alt="avatar"
                style={{
                  width: '100%',
                }}
              />
            ) :
              <div>
                <img src={placeholderImg} alt="" className='w-100' />
              </div>
            }
          </Space>
        </Upload>
        {
          fileList?.length > 0 && allowedEdit && <Button type="link" onClick={() => removeImage()}>{t("General.remove")}</Button>
        }
      </Col>
      {
        allowedEdit &&
        <Col span={24} className='text-right'>
          <Button size="large" htmlType='submit' className='green-btn attendance-success' onClick={() => onFinish()}>{t("General.save")}</Button>
        </Col>
      }
    </Row>
  )
}