import React from 'react';
import { Tabs } from 'antd';
import * as TabCards from './tabList';
import { useTranslate } from 'Translate';

export default (props) => {
  const i18n = useTranslate();
  const { t } = i18n;

  const tabs = [
    {
      name: t("Product.order_screen"),
      Comp: 'OrderScreen',
    },
    {
      name: t("Product.bill_func_screen"),
      Comp: 'BillScreen',
    },
    {
      name: t("Product.checkout_screen"),
      Comp: 'CheckoutScreen',
    },
    {
      name: t("Product.promo_screen"),
      Comp: 'PromotionScreen',
    }
  ];

  return (
    <Tabs items={
      tabs.map((item, index) => {
        const Cardi = TabCards[item.Comp];
        return {
          key: (index + 1).toString(),
          label: item.name,
          forceRender: true,
          children: <Cardi {...props} />
        }
      })
    } defaultActiveKey={"1"} />
  );
};
