// Transfer In Details
import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, data, meta, addTransferIn, editTransferIn, reloadApi, mode, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [editable, setEditable] = useState(false);
  const i18n = useTranslate();
    const { t } = i18n;

  const formFields = [
    {
      name: 'reference',
      label: t("Inventory.tr_no"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'date',
      label: t("General.date"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    // {
    //   name: 'receive_date',
    //   label: t("Inventory.tr_date"),
    //   req: true,
    //   placeholder: t("General.please_state"),
    //   type: 'date',
    //   format: 'YYYY-MM-DD',
    //   twocol: false,
    //   colWidth: '0 1 50%',
    //   reqmessage: t("General.required"),
    //   static: !editable
    // },
    {
      type: 'select',
      label: t("Inventory.org_loc_code"),
      name: 'origin',
      twocol: false,
      // options: meta?.locations?.map((x) => ({ label: x.code, value: x.id })),
      options: [],
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Origin Location Required',
      colWidth: '0 1 50%',
      static: !editable
    },
    {
      type: 'select',
      label: t("Inventory.des_loc_code"),
      name: 'destination',
      twocol: false,
      // options: meta?.locations?.map((x) => ({ label: x.code, value: x.id })),
      options: [],
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Destination Location Required',
      colWidth: '0 1 50%',
      static: !editable
    },
    {
      name: 'order_type_group',
      label: t("Inventory.recipe"),
      req: false,
      placeholder: t("General.please_select"),
      type: 'select',
      class: 'default-select',
      // options: meta?.locations?.find((x) => x.id === locationId)?.order_type_groups?.map(y => ({ label: `${y.code} ${y.desc}`, value: y.id })),
      options: [],
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'remarks',
      label: t("System.remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable
    },
    {
      name: 'cancellation_remarks',
      label: t("Inventory.cancellation_remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable,
      hidden: data && data?.cancellation_remarks ? false : true
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('reference', data.reference);
      setValue('date', data?.date ? dayjs(data?.date) : '');
      setValue('receive_date', data?.receive_date ? dayjs(data?.receive_date) : '');
      setValue('origin', data?.origin_id ? { label: `${data?.origin_code} ${data?.origin_desc}`, value: data?.origin_id } : '');
      setValue('destination', data?.destination_id ? { label: `${data?.destination_code} ${data?.destination_desc}`, value: data?.destination_id } : '');
      setValue('remarks', data?.remarks);
      setValue('order_type_group', data?.order_type_group_id ? { label: `${data?.order_type_group_code} ${data?.order_type_group_desc}`, value: data?.order_type_group_id } : '');
      setValue('cancellation_remarks', data?.cancellation_remarks);
      // setEditable(data.status === "pending" && allowedEdit);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      origin_id: val?.origin?.value,
      destination_id: val?.destination?.value,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : '',
      order_type_group_id: val?.order_type_group?.value,
      transfer_in_id: id,
    }

    await (mode === "add" ? addTransferIn : editTransferIn)(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t(`Success.transfer_receive_${mode === "add" ? "created" : "updated"}`));
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          editable &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}