import React from 'react';
import ScreenListing from '../components/ScreenListing';

export default (props) => {
  const { data } = props;

  return (
    <>
      <ScreenListing
        {...props}
        datalist={data?.order_screen_list}
        buttonType={"order_screen"}
      />
    </>
  )
}