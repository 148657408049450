import React, {useState, useEffect} from 'react';
import {Space, Button, Row, Col, Typography, Spin } from 'antd';
import { WarningIcon } from '../../atoms/CustomIcons';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslate } from 'Translate';

const antIcon = <LoadingOutlined spin />;
const { Title, Text } = Typography;

export default (props) => {

    const { onClose, onDelete, title, btntext } = props;
    const i18n = useTranslate();
    const { t } = i18n;

    return (
        <Spin indicator={antIcon} size="large" spinning={props.loading}>
            <Space direction='vertical' size={50}>
                <Space direction='vertical' size={20} className='text-center'>
                    <WarningIcon style={{fontSize: 50}} className='c-error' />
                    <Title level={3} className='mb-0'>{t("General.delete")} {t(`Delete.${title?.toLowerCase()}`)}</Title>
                    <Text>{t(`Delete.confirm_delete_${title?.toLowerCase()}`)}</Text>
                </Space>
                <Row gutter={10}>
                    <Col span={12}><Button size='large' type="primary" className='gray-btn w-100' onClick={onClose}>{t("General.cancel")}</Button></Col>
                    <Col span={12}><Button size='large' type="primary" danger className='w-100' onClick={onDelete}>{btntext ? t(btntext) : t("General.delete")}</Button></Col>
                </Row>
            </Space>
        </Spin>
    )
}