import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { getVoucherTypeOption } from "../../ducks/actions"
import { addVoucherType } from "../../ducks/services"
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'Translate';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const meta = useSelector((state) => state.pos.vtOption);
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    dispatch(getVoucherTypeOption(props.setLoading));
  }, []);

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      apply_method: val.apply_method?.value,
      value_type: val?.value_type?.value
    }
    await addVoucherType(payload).then(res => {
      props.setLoading(false);
      if(res.data.code === 200) {
        message.success(t("Success.create_voucher_type"));
        setTimeout(() => navigate("/pos/voucher-type"), 500);
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>{t("New.voucher_type")}</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                <Button size="large" htmlType='submit' className='green-btn'>{t("General.save")}</Button>
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/pos/voucher-type')}>{'<'} {t("General.back")}</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          meta={meta}
          editable={true}
        />
      </Row>
    </Form>
  )
}