import React, { Fragment } from 'react';
import { useTranslate } from 'Translate';

export default (props) => {
  const { columns, data } = props;
  const i18n = useTranslate();
      const { t } = i18n;

  return (
    <div className="inventory-table-container">
      <div className="inventory-table">
        <table>
          <thead>
            <tr>
              {columns?.gr_details_listing?.list?.map(item =>
                <th className={`${item?.longCol ? 'long-col' : ''} text-${item.align}`} key={`Title ${item.title}`}>
                  {item.title}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.list?.map((listItem, listItemIndex) => 
              <Fragment key={`List Item ${listItemIndex}`}>
                {/* <tr>
                  <td>{listItem.date}</td>
                </tr> */}
                {listItem?.list?.map((childListItem, childListItemIndex) => 
                  <tr key={`List Item ${childListItemIndex}: Child List Item ${childListItemIndex}`}>
                    <td>{childListItem.date}</td>
                    <td>{childListItem.location}</td>
                    <td>{childListItem.stock_in_reference}</td>
                    <td>{childListItem.po_reference}</td>
                    <td>{childListItem.supplier}</td>
                    <td>{childListItem.status}</td>
                    <td>{childListItem.item}</td>
                    <td>{childListItem.uom}</td>
                    <td align='right'>{childListItem.qty}</td>
                    <td align='right'>{childListItem.discrepancy_qty}</td>
                    <td align='right'>{childListItem.gross_amount}</td>
                    <td align='right'>{childListItem.discount_amount}</td>
                    <td align='right'>{childListItem.total_amount}</td>
                    <td align='right'>{childListItem.total_inv_cost}</td>
                    <td align='right'>{childListItem.unit_cost}</td>
                  </tr>
                )}
                <tr className='bg-gray text-white'>
                  <td colSpan={7}></td>
                  <td>{t("Crm.subtotal")}</td>
                  {listItem?.sub_totals?.map((item, index) => 
                    <td key={`Sub Total ${item} ${index}`} align='right'>{item}</td>
                  )}
                </tr>
              </Fragment>
            )}

            
            <tr className='bg-gray text-white'>
              <td colSpan={7}></td>
              <td>{t("SalesReporting.grand_total")}</td>
              {data?.grand_total?.map((item, index) => 
                <td key={`Grand Total ${item} ${index}`} align='right'>{item}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}