import React, { useEffect, Fragment, useState } from 'react';
import { Form, Button, Row, Col, message, Spin, Space, Typography } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getFaSyncLogs } from '../../../../ducks/actions';
import { updateFaIntegration, resyncSingleFa } from '../../../../ducks/services';
import { LoadingOutlined } from '@ant-design/icons';
import { Popup } from 'Atoms/Popup';
import { InputPassword } from 'Atoms/FormElement';
import AllRoles from '../../../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../../../routing/config/utils';
import { useTranslate } from 'Translate';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.outlet.faSyncLogs);
  const { data, id, allowedEdit, meta, reloadApi, activeTab } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const { control: control2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, setValue: setValue2 } = useForm();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const singapore = meta?.country === "Singapore"; // check by organization.country
  const allowedEditFa = allowedEdit && allowed([AllRoles.OUTLET.FAINTEGRATION], 'write');
  const i18n = useTranslate();
  const { t } = i18n;

  const colName = [
    {
      title: t("General.date"),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true
    },
    {
      title: t("General.fa_type"),
      dataIndex: 'reference',
      key: 'reference',
      sorter: true
    },
    {
      title: t("General.message"),
      dataIndex: 'message',
      key: 'message',
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: t("General.details"),
      dataIndex: 'source_data',
      key: 'source_data',
      width: "65%",
      render: (text) => <pre style={{ whiteSpace: "break-spaces" }}><code>{JSON.stringify(text, null, 2)}</code></pre>
    }
  ]

  const formFields = [
    {
      name: 'grab_reference',
      label: t("General.grab_id"),
      req: false,
      placeholder: 'E.g: 1-D2TDFB3JDP6BRD',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditFa,
      addOnButton: t("General.resync"),
      isDisabledAddOnBtn: !data.grab_reference,
      onBtnClick: () => onResyncFa("grabfood")
    },
    {
      name: 'grab_last_sync_at',
      label: t("General.grab_menu_last_synced"),
      req: false,
      static: true,
      placeholder: t("General.timestamp"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
    },
    {
      name: 'foodpanda_reference',
      label: t("General.foodpanda_id"),
      req: false,
      placeholder: 'E.g: z91d',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditFa,
      addOnButton: t("General.resync"),
      isDisabledAddOnBtn: !data.foodpanda_reference,
      onBtnClick: () => onResyncFa("foodpanda")
    },
    {
      name: 'foodpanda_last_sync_at',
      label: t("General.foodpanda_last_synced"),
      req: false,
      static: true,
      placeholder: t("General.timestamp"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
    },
    {
      name: 'shopeefood_reference',
      label: t("General.shopee_food_id"),
      req: false,
      placeholder: 'E.g: 1234567',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditFa,
      addOnButton: t("General.resync"),
      isDisabledAddOnBtn: !data.shopeefood_reference,
      onBtnClick: () => onResyncFa("shopeefood")
    },
    {
      name: 'shopeefood_last_sync_at',
      label: t("General.shopee_food_last_synced"),
      req: false,
      static: true,
      placeholder: t("General.timestamp"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
    },
    {
      name: 'o2o_reference',
      label: t("General.o2o_id"),
      req: false,
      placeholder: 'E.g: 100001',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEditFa,
      addOnButton: t("General.resync"),
      isDisabledAddOnBtn: !data.o2o_reference,
      onBtnClick: () => onResyncFa("o2o")
    },
    {
      name: 'o2o_last_sync_at',
      label: t("General.o2o_last_synced"),
      req: false,
      static: true,
      placeholder: t("General.timestamp"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
    },
    {
      name: 'deliveroo_reference',
      label: t("General.deliveroo_id"),
      req: false,
      placeholder: 'E.g: comp-abc-sg1',
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      hidden: !singapore,
      static: !allowedEditFa,
      addOnButton: t("General.resync"),
      isDisabledAddOnBtn: !data.deliveroo_reference,
      onBtnClick: () => onResyncFa("deliveroo")
    },
    {
      name: 'deliveroo_last_sync_at',
      label: t("General.deliveroo_last_synced"),
      req: false,
      static: true,
      placeholder: t("General.timestamp"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      hidden: !singapore,
      reqmessage: t("General.required"),
    }
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('grab_reference', data.grab_reference);
      setValue('grab_last_sync_at', data.grab_last_sync_at);
      setValue('foodpanda_reference', data.foodpanda_reference);
      setValue('foodpanda_last_sync_at', data.foodpanda_last_sync_at);
      setValue('shopeefood_reference', data.shopeefood_reference);
      setValue('shopeefood_last_sync_at', data.shopeefood_last_sync_at);
      setValue('o2o_reference', data.o2o_reference);
      setValue('o2o_last_sync_at', data.o2o_last_sync_at);
      setValue('deliveroo_reference', data.deliveroo_reference);
      setValue('deliveroo_last_sync_at', data.deliveroo_last_sync_at);
    }
  }, [data]);

  useEffect(() => {
    if (activeTab === "7") reloadLogApi();
  }, [activeTab]);

  const reloadLogApi = (pageSize = 1, limitSize = 25, orderSeq = '', orderCol = '') => {
    dispatch(getFaSyncLogs(pageSize, limitSize, orderSeq, orderCol, props.setLoading, id))
  };

  const onFinish = async (val) => {
    setLoading(true);
    const payload = {
      ...val,
      id: id
    }

    await updateFaIntegration(payload).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Outlet.fa_integration_updated_success"));
        setVisible(false);
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message);
    })
  };

  const onConfirm = (val2) => {
    let val = {
      ...getValues(),
      ...val2
    };
    onFinish(val);
  };

  const onResyncFa = async (faType) => {
    props.setLoading(true);
    const payload = {
      fa_type: faType,
      id: id
    }

    await resyncSingleFa(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(res.data.message);
        reloadLogApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" onFinish={handleSubmit2(onConfirm)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {t("Outlet.update_fa_integration_details")}
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <InputPassword
                isRequired={true}
                fieldname="password"
                label={t("Outlet.pls_enter_password_proceed")}
                control={control2}
                initValue=""
                iProps={{ size: 'large' }}
                rules={{required: t("General.password_cannot_blank")}}
                validate={errors2?.password && 'error'}
                validMessage={errors2?.password && errors2?.password.message}
              />
            </Col>
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('password', null);
            }}>{t("General.cancel")}</Button></Col>
            {
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('password', '');
    },
  };

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      reloadLogApi(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey)
    } else {
      reloadLogApi(pagination.current, pagination.pageSize)
    }
  }

  return (
    <>
      <Form layout="vertical" scrollToFirstError>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
        </Row>
        {
          allowedEditFa &&
          <Row gutter={[10, 10]} className="mt-1">
            <Col span={24} className='text-right'>
              <Button size="large" htmlType='button' className='green-btn attendance-success' onClick={() => {
                setVisible(true);
                setValue('password', null);
              }}>{t("General.save")}</Button>
            </Col>
          </Row>
        }
      </Form>

      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={t("General.fa_sync_logs")} />
        </Col>
        <Col span={24}>
          <ListCard
            ListData={logs?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            onChange={onTableChange}
            pagination={{
              total: logs?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}