import React, { Fragment, useEffect, useState } from 'react';
import { Select, Form, Row, Col, Space, Typography, Spin, Button, message, Upload, Tabs } from 'antd';
import * as TabCards from './tabList';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useTranslate } from 'Translate';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const i18n = useTranslate();
  const { t } = i18n;
  const tabs = [
    {
      name: t("Product.kiosk"),
      Comp: 'Kiosk',
    },
    {
      name: t("System.qms"),
      Comp: 'Qms',
    },
  ];

  const { id, activeTab, navigate } = props;
  const dispatch = useDispatch();
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const option = useSelector((state) => state.product.menuCategoryOption);
  const [subTab, setSubTab] = useState("1")

  

  return (
    <>
    <Tabs items={
      tabs.map((item, index) => {
        const Cardi = TabCards[item.Comp];
        return {
          key: (index + 1).toString(),
          label: item.name,
          forceRender: true,
          children: <Cardi {...props} activeTab={activeTab} id={id} subTab={subTab} />,
        }
      })
    } defaultActiveKey={"1"} onChange={(activeKey) => setSubTab(activeKey)} />
    </>
  );
}