import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ROOT } from "../configs/routeNames";
import { Provider } from "react-redux";
import store from "../redux/store";
import "../assets/styles/antd.scss";
import "../assets/styles/styles.scss";
import { TranslateProvider } from 'Translate';
import RoutingList from './config/RoutingList';
import Dashboard from '../app/pages/Dashboard';
import Outlet from '../app/pages/Outlet';
import Product from '../app/pages/Product';
import Systems from '../app/pages/Systems';
import Pos from '../app/pages/Pos';
import Inventory from '../app/pages/Inventory';
import CRM from '../app/pages/CRM';
import Reporting from '../app/pages/Reporting';
import InventoryReporting from '../app/pages/InventoryReporting';
import Member from '../app/pages/Member';
import { checkPermission, isLoggedIn } from './config/utils';

import Login from '../app/pages/Login';
import NoPage from '../app/modules/Login/NoPage';

// import Pages from "./Pages";
import { TabTitle } from "../utils/GeneralFunction";

// Context
import { ResponsiveProvider } from "../app/contexts/ResponsiveContext";

import { getFirstHasAccessPage } from "./config/utils";

const compList = {
  Dashboard,
  Outlet,
  Product,
  Systems,
  Pos,
  Inventory,
  CRM,
  Reporting,
  Member,
  InventoryReporting
};

const Root = () => {
  // const company = localStorage.getItem('company') ?? 'EMS Dashboard';
  TabTitle('EMS Dashboard');

  const PrivateRoute = ({ title, comp, permission }) => {
    const MyComponent = compList[title];
    if (!permission || checkPermission(permission)){
      return isLoggedIn() ? <MyComponent Comp={comp} /> : <Navigate to="/denied" />;
    }else{
      return isLoggedIn() ? <Navigate to={getFirstHasAccessPage()} replace /> : <Navigate to="/login" replace />;
    }
  };

  const routeComponents = RoutingList.map(({ title, component, path, permission }, key) => (
    <Route
      path={path}
      element={<PrivateRoute title={title} comp={component} permission={permission} />}
      key={key}
    />
  ));
  
  return (
    <ResponsiveProvider>
      <TranslateProvider>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route
                path={ROOT}
                element={
                  isLoggedIn() ? (
                    // <Navigate to="/dashboard" replace />
                    <Navigate to="/outlets" />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route path="/login" element={<Login Comp={'Login'} />} />
              <Route path="/forgot-password" element={<Login Comp={'ForgotPassword'} />} />
              <Route path="/reset-password" element={<Login Comp={'OPTPCode'} />} />
              <Route path="/country" element={<Login Comp={'Country'} />} />
              {routeComponents}
              <Route path="*" element={<NoPage />} />
            </Routes>
          </Router>
        </Provider>
      </TranslateProvider>
    </ResponsiveProvider>
  )
};
export default Root;
