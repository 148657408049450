import React, { useEffect, useState } from 'react';
import { Row, Col, Tooltip, message, Space } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTransferInList } from '../ducks/actions'
import { syncToNetsuite } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import dayjs from 'dayjs';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { CheckCircleTwoTone, CloudSyncOutlined } from '@ant-design/icons';
import { useTranslate } from 'Translate';

export default (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.inventory.transferIns);
  const meta = useSelector((state) => state.inventory.transferInsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const allowedEdit = allowed([AllRoles.INVENTORY.TRANSFER_RECEIVE], 'write');
  const [outlets, setOutlets] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const globalListQuery = useSelector((state) => state.global.listQueryItems);
  const searchNetsuiteSynced = queryParameters.get('ns') ? queryParameters.get('ns') == "1" : null;
  const searchOutlet = queryParameters.get('ou');
  const searchDate = queryParameters.get('sd');
  const i18n = useTranslate();
    const { t } = i18n;

  const colName = [
    {
      title: t("Inventory.tr_no"),
      dataIndex: 'reference',
      key: 'reference',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.date"),
      dataIndex: 'date',
      key: 'date',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Inventory.org_loc_code"),
      dataIndex: 'origin_code',
      key: 'origin_code',
      className: 'enable-click',
      sorter: true,
      render: (text, rec) => <span>{text} {rec?.origin_desc}</span>
    },
    {
      title: t("Inventory.des_loc_code"),
      dataIndex: 'destination_code',
      key: 'destination_code',
      className: 'enable-click',
      sorter: true,
      render: (text, rec) => <span>{text} {rec?.destination_desc}</span>
    },
    {
      title: t("System.remarks"),
      dataIndex: 'remarks',
      key: 'remarks',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Inventory.posting_date_time"),
      dataIndex: 'posted_at',
      key: 'posted_at',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text || "-"}</span>
    },
    {
      title: t("General.status"),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center' style={{gap: "10px"}}>
          <span className={text === "Posted" ? "c-success" : text === "Pending" ? "c-pending" : "c-danger"}>
            {t(`General.${text?.toLowerCase()}`)}
          </span>
          {
            (
              text === "Posted" &&
              record?.can_manual_sync === "1"
            ) &&
            <Tooltip title="Sync to Netsuite">
              <a className='pointer ml-1' onClick={() => onSyncNetsuite(record.id)}>
                <CloudSyncOutlined /> {t("General.sync")}
              </a>
            </Tooltip>
          }
          {record?.netsuite_synced === "1" && <Tooltip title={t("Inventory.synced_to_netsuite")}><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip>}
        </div>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="purchase_order"
          btnAction1={() => navigate(`/inventory/transfer-in/${record.id}`)}
          recordId={record.id}
          // onRemove={record?.status === "Pending" && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ]

  const addNew = () => navigate('/inventory/transfer-in/add');

  const btnList = [
    // {
    //   title: 'Are you sure to remove the selected Transfer Receive(s)?',
    //   text: t("General.bulk_remove"),
    //   classes: 'red-btn text-white attendance-success',
    //   permit: true,
    //   action: () => onBulkRemove(),
    // },
    // {
    //   text: ' Transfer Receive',
    //   action: () => addNew(),
    // },
  ];

  useEffect(() => {
    if(!globalListQuery.transferInListQuery) {
      let search = searchVal || {};
      if (searchNetsuiteSynced !== null) {
        search["netsuite_synced"] = searchNetsuiteSynced ? "1" : "0"
      }
      if (searchOutlet) {
        search["outlet"] = searchOutlet;
      }
      if (searchDate) {
        search["date"] = searchDate;
      }

      setSearchVal(search)
      dispatch(getTransferInList(1, limit, '', '', search, props.setLoading));
      dispatch(getListQueryItems('transferInListQuery', 1, limit, '', '', search, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.transferInListQuery
      dispatch(getTransferInList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  useEffect(() => {
    if(meta) {
      if('locations' in meta) {
        let temp = [];
        meta?.locations?.map((item, index) => {
          if(index === 0) {
            temp.push({
              label: t("General.all_outlets"),
              value: ""
            })
          }
          temp.push({
            label: `${item.code} ${item.desc}`,
            value: item.id
          })
        });
        setOutlets(temp);
      }

      if ("statuses" in meta) {
        let temp2 = [];
        meta.statuses?.map((x, ind) => {
          if (ind === 0) {
            temp2.push({
              label: t("General.all_status"),
              value: ""
            });
          }
          temp2.push({
            label: x,
            value: x
          })
        });
        setStatuses(temp2);
      }
    }
  }, [meta])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.status !== 'Pending'
    }),
  };

  const onSyncNetsuite = async (recordId) => {
    props.setLoading(true);

    const payload = {
      transfer_in_id: recordId
    }

    await syncToNetsuite("transfer-ins", payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(res.data.message);
        setTimeout(() => dispatch(getTransferInList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('transferInListQuery', page, limit, '', '', searchVal, props.setLoading))
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getTransferInList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('transferInListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getTransferInList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('transferInListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    navigate('/inventory/transfer-in');
    if (search) {
      let searching = {};
      searching = {
        reference: search?.reference,
        status: search.status.value,
        outlet: search?.outlet?.value,
        date: search.date ? dayjs(search.date).format("YYYY-MM-DD") : "",
        receive_date: search.receive_date ? dayjs(search.receive_date).format("YYYY-MM-DD") : "",
        netsuite_synced: search?.netsuite_synced?.value
      };
      setSearchVal(searching);
      dispatch(getTransferInList(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('transferInListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getTransferInList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('transferInListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/inventory/transfer-in/${record.id}`)
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={t("General.trans_receive")} btnList={allowedEdit && btnList} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.transferInListQuery?.searchValue}
            onRow={onClickRow}
            // listClass="nopad"
            ListData={orders?.list?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            // rowSelection={rowSelection}
            onChange={onTableChange}
            pagination={{
              total: orders?.total_count,
              current: page,
              pageSize: limit
            }}
            field1={statuses}
            field2={outlets}
          />
        </Col>
      </Row>
    </>
  )
}