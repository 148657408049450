import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getUserRoleList = (page, limit, order, orderby, search = null, load) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/roles/list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      name_cont: search?.name,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.USER_ROLES,
      data: data,
      meta: meta
    });
  };
};

export const getUserRoleOption = (load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/roles/option`);
  load && load(false);
  dispatch({
    type: action_types.ROLE_OPTION,
    data: data,
  });
};

export const getUserList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/users/list`, {
    q: JSON.stringify({
      name_cont: search?.name,
      email_cont: search?.email,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.USER_LIST,
    data: data,
  });
};

export const getUserOption = (load) => async (dispatch) => {
  load && load(true);  
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/users/option`);
  load && load(false);
  dispatch({
    type: action_types.USER_OPTION,
    data: data,
  });
};

export const getUserDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/users/get`, {
    id: id
  });
  load && load(false);
  dispatch({
    type: action_types.USER_DETAIL,
    data: data,
    meta: meta
  });
};

export const getPosUserRoleList = (page, limit, order, orderby, search = null, load) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/pos-roles/list`, {
      q: JSON.stringify({
        s: `${orderby} ${ordering}`
      }),
      name_cont: search?.name,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.POS_USER_ROLES,
      data: data,
      meta: meta
    });
  };
};

export const getPosUserRoleOption = (load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/pos-roles/option`);
  load && load(false);
  dispatch({
    type: action_types.POS_ROLE_OPTION,
    data: data,
  });
};

export const getPosUserList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/pos-users/list`, {
    q: JSON.stringify({
      name_cont: search?.name,
      email_cont: search?.email,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.POS_USER_LIST,
    data: data,
  });
};

export const getPosUserOption = (load) => async (dispatch) => {
  load && load(true);  
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/pos-users/option`);
  load && load(false);
  dispatch({
    type: action_types.POS_USER_OPTION,
    data: data,
  });
};

export const getPosUserDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/pos-users/get`, {
    id: id
  });
  load && load(false);
  dispatch({
    type: action_types.POS_USER_DETAIL,
    data: data,
    meta: meta
  });
};

export const getCurrencies = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/currencies/list`, {
    q: JSON.stringify({
      code_or_symbol_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.CURRENCY_LIST,
    data: data,
  });
};

export const getCurrencyDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/currencies/get`, {
    currency_id: id
  });
  load && load(false);
  dispatch({
    type: action_types.CURRENCY_DETAIL,
    data: data,
    meta: meta
  });
};

export const getPaymentTerms = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/payment-terms/list`, {
    q: JSON.stringify({
      code_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.PAYMENT_TERM_LIST,
    data: data,
  });
};

export const getPaymentTermDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/payment-terms/get`, {
    payment_term_id: id
  });
  load && load(false);
  dispatch({
    type: action_types.PAYMENT_TERM_DETAIL,
    data: data,
    meta: meta
  });
};

export const getRegionList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/regions/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.name,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.REGION_LIST,
    data: data,
  });
};

export const getRegionDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/regions/get`, {
    region_id: id
  });
  load && load(false);
  dispatch({
    type: action_types.REGION_DETAIL,
    data: data,
    meta: meta
  });
};

export const getTaxConditionList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/tax-conditions/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.name,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.TAX_CONDITION_LIST,
    data: data,
  });
};

export const getTaxConditionDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/tax-conditions/get`, {
    tax_condition_id: id
  });
  load && load(false);
  dispatch({
    type: action_types.TAX_CONDITION_DETAIL,
    data: data,
    meta: meta
  });
};

export const getTcItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/tax-conditions/tax-condition-item-list`, {
      tax_condition_id: id,
      q: JSON.stringify({
        desc_cont: search?.desc,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.TAX_CONDITION_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getReasonList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/reasons/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.name,
      reason_type_eq: search?.reason_type,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.REASON_LIST,
    data: data,
    meta: meta
  });
};

export const getReasonDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/reasons/get`, {
    reason_id: id
  });
  load && load(false);
  dispatch({
    type: action_types.REASON_DETAIL,
    data: data,
    meta: meta
  });
};

export const getReasonOption = (load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/reasons/option`);
  load && load(false);
  dispatch({
    type: action_types.REASON_OPTION,
    data: data
  });
};

export const getRemarks = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/remarks/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.name,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.REMARK_LIST,
    data: data,
    meta: meta
  });
};

export const getRemarkDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/remarks/get`, {
    remark_id: id
  });
  load && load(false);
  dispatch({
    type: action_types.REMARK_DETAIL,
    data: data,
    meta: meta
  });
};

export const getRemarkTypeList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/remark-types/list`, {
      q: JSON.stringify({
        code_or_desc_cont: search?.name,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.REMARK_TYPE_LIST,
      data: data
    });
  };
};

export const getRemarkTypeDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/remark-types/get`, {
      remark_type_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.REMARK_TYPE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getRtItemList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/remark-types/remark-type-item-list`, {
      remark_type_id: id,
      q: JSON.stringify({
        remark_code_cont: search?.remark_code,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.REMARK_TYPE_ITEM_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getTaxList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data },
  } = await axios.post(`${externalBaseUrl}/taxes/list`, {
    q: JSON.stringify({
      // code_or_desc_cont: search?.name,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.TAXES_LIST,
    data: data,
  });
};

export const getPaymentMethodTypeList = (page, limit, order, orderby, search = null, load) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/payment-method-types/list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.PAYMENT_METHOD_TYPE_LIST,
    data: data,
    meta: meta
  });
};

export const getPaymentMethodTypeDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/payment-method-types/get`, {
      payment_method_type_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.PAYMENT_METHOD_TYPE_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getPaymentMethodTypeOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/payment-method-types/option`);
    load && load(false);
    dispatch({
      type: action_types.PAYMENT_METHOD_TYPE_OPTION,
      data: data
    });
  };
};

export const getPaymentMethodList = (page, limit, order, orderby, search = null, load, id) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/payment-method-types/payment-method-list`, {
    payment_method_type_id: id,
    q: JSON.stringify({
      code_or_desc_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.PAYMENT_METHOD_LIST,
    data: data,
    meta: meta
  });
};

export const getPaymentMethodDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/payment-methods/get`, {
      payment_method_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.PAYMENT_METHOD_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getPaymentMethodOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/payment-methods/option`);
    load && load(false);
    dispatch({
      type: action_types.PAYMENT_METHOD_OPTION,
      data: data
    });
  };
};

export const getOrganizationDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/organizations/get`, {
      id: id
    });
    load && load(false);
    dispatch({
      type: action_types.ENTITY_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getAdList = (status, page, limit, order, orderby, search = null, load, id) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/organizations/ad-list`, {
    q: JSON.stringify({
      code_or_desc_cont: search?.code,
      s: `${orderby} ${ordering}`
    }),
    id: id,
    ad_type: status,
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.AD_LIST,
    data: data,
    meta: meta
  });
};

export const getMerchantList = (page, limit, order, orderby, search = null, load, id) => async (dispatch) => {
  load && load(true);
  let ordering = '';
  if (order == 'ascend') {
    ordering = 'ASC';
  } else if (order == 'descend') {
    ordering = 'DESC';
  }
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/merchants/list`, {
    q: JSON.stringify({
      store_name_cont: search?.store_name,
      email_cont: search?.email,
      s: `${orderby} ${ordering}`
    }),
    page: page,
    limit: limit
  });
  load && load(false);
  dispatch({
    type: action_types.MERCHANT_LIST,
    data: data,
    meta: meta
  });
};

export const getMerchantOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/merchants/option`);
    load && load(false);
    dispatch({
      type: action_types.MERCHANT_OPTION,
      data: data
    });
  };
};

export const getMerchantDetail = (id, load) => async (dispatch) => {
  load && load(true);
  const {
    data: { data, meta },
  } = await axios.post(`${externalBaseUrl}/merchants/get`, {
    merchant_id: id
  });
  load && load(false);
  dispatch({
    type: action_types.MERCHANT_DETAIL,
    data: data,
    meta: meta
  });
};

export const getOrderTypeGroupList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/order-type-groups/list`, {
      q: JSON.stringify({
        code_or_desc_cont: search?.name,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.ORDER_TYPE_GROUP_LIST,
      data: data
    });
  };
};

export const getEntityUserList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/organizations/user-list`, {
      q: JSON.stringify({
        user_name_or_username_or_user_email_or_organization_name_cont: search?.name,
        s: `${orderby} ${ordering}`
      }),
      id: id,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.ENTITY_USER_LIST,
      data: data,
      meta: meta
    });
  };
};

export const getOrderTypeGroupDetail = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/order-type-groups/get`, {
      order_type_group_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.ORDER_TYPE_GROUP_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getNetsuiteSyncLogs = (page, limit, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/organizations/netsuite-sync-log-list`, {
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.NETSUITE_SYNC_LOGS,
      data: data,
      meta: meta
    });
  };
};