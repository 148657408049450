import axios from 'Services/axiosInterceptor';
import { externalBaseUrl } from '../../../../configs/constants';

export const exportStockBalance = (search = {}) => {
  var obj = {
    location_ids: search?.outlets ? JSON.parse(search?.outlets) : null,
    from: search?.start_date,
    to: search?.end_date,
  };
  obj = removeUndefinedObject(obj);
  var queryString = Object.keys(obj).map(key => key + "=" + encodeURIComponent(obj[key])).join("&");

  return axios.get(`${externalBaseUrl}/reportings/export-stock-balance?${queryString}`, { responseType: 'blob'});
};

export const exportInventoryReport = (search = {}, type) => {
  var obj = { ...search, report_type: type };
  obj = removeUndefinedObject(obj);
  var queryString = Object.keys(obj).map(key => key + "=" + encodeURIComponent(obj[key])).join("&");

  return axios.get(`${externalBaseUrl}/reportings/export-inventory-reports?${queryString}`, { responseType: 'blob'});
};

export const resyncSingleLocationScript = (payload) => {
  return axios.post(`${externalBaseUrl}/reportings/resync-daily-netsuite`, payload);
}

function removeUndefinedObject(obj) {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}