import AllRoles from './AllRoles';

const titles = {
  Product: 'Product',
};

export const Product = [
  {
    component: 'Product',
    path: '/product',
    title: titles.Product,
    key: 'product',
    subkeys: ['/product', '/product/add'],
    menu: 'Product',
    submenu: "Product",
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'ProgrammeIcon',
    permission: [AllRoles.PRODUCT.PRODUCT],
  },
  {
    component: 'AddProduct',
    path: '/product/add',
    title: titles.Product,
    key: 'product',
    menu: 'Product',
    submenu: "Menu",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.PRODUCT],
  },
  {
    component: 'ProductMenu',
    path: '/product/menu',
    title: titles.Product,
    key: 'menu',
    subkeys: ['/product/menu', '/product/menu/add'],
    menu: 'Product',
    submenu: "Menu",
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'ProgrammeIcon',
    permission: [AllRoles.PRODUCT.MENU],
  },
  {
    component: 'AddProductMenu',
    path: '/product/menu/add',
    title: titles.Product,
    key: 'menu',
    menu: 'Product',
    submenu: "Menu",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.MENU],
  },
  {
    component: 'EditProductMenu',
    path: '/product/menu/:id',
    title: titles.Product,
    key: 'menu',
    menu: 'Product',
    submenu: "Menu",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.MENU],
  },
  {
    component: 'MenuItem',
    path: '/product/menu/:id/category/:cid/items',
    title: titles.Product,
    key: 'menu',
    menu: 'Product',
    submenu: "Menu",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.MENU],
  },
  {
    component: 'OrderType',
    path: '/product/order-type',
    title: titles.Product,
    key: 'order-type',
    subkeys: ['/product/order-type', '/product/order-type/add'],
    menu: 'Product',
    submenu: "Order Type",
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'ProgrammeIcon',
    permission: [AllRoles.PRODUCT.ORDERTYPE],
  },
  {
    component: 'AddOrderType',
    path: '/product/order-type/add',
    title: titles.Product,
    key: 'order-type',
    menu: 'Product',
    submenu: "Order Type",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.ORDERTYPE],
  },
  {
    component: 'EditOrderType',
    path: '/product/order-type/:id',
    title: titles.Product,
    key: 'order-type',
    menu: 'Product',
    submenu: "Order Type",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.ORDERTYPE],
  },
  {
    component: 'Ingredient',
    path: '/product/ingredient',
    title: titles.Product,
    key: 'ingredient',
    subkeys: ['/product/ingredient', '/product/ingredient/add'],
    menu: 'Product',
    submenu: "Ingredient",
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'ProgrammeIcon',
    permission: [AllRoles.PRODUCT.INGREDIENT],
  },
  {
    component: 'AddIngredient',
    path: '/product/ingredient/add',
    title: titles.Product,
    key: 'ingredient',
    menu: 'Product',
    submenu: "Ingredient",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.INGREDIENT],
  },
  {
    component: 'EditIngredient',
    path: '/product/ingredient/:id',
    title: titles.Product,
    key: 'ingredient',
    menu: 'Product',
    submenu: "Ingredient",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.INGREDIENT],
  },
  {
    component: 'EditProduct',
    path: '/product/:id',
    title: titles.Product,
    key: 'product',
    menu: 'Product',
    submenu: "Menu",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.PRODUCT],
  },
  {
    component: 'Category',
    path: '/product/category',
    title: titles.Product,
    key: 'category',
    subkeys: ['/product/category'],
    menu: 'Product',
    submenu: "Category",
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'ProgrammeIcon',
    permission: [AllRoles.PRODUCT.CATEGORY],
  },
  {
    component: 'EditCategory',
    path: '/product/category/:id',
    title: titles.Product,
    key: 'category',
    menu: 'Product',
    submenu: "Category",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.CATEGORY],
  },
  {
    component: 'UnitMeasurement',
    path: '/product/uom',
    title: titles.Product,
    key: 'uom',
    subkeys: ['/product/uom', '/product/uom/add'],
    menu: 'Product',
    submenu: "UOM",
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'ProgrammeIcon',
    permission: [AllRoles.PRODUCT.UOM],
  },
  {
    component: 'AddUom',
    path: '/product/uom/add',
    title: titles.Product,
    key: 'uom',
    menu: 'Product',
    submenu: "UOM",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.UOM],
  },
  {
    component: 'EditUom',
    path: '/product/uom/:id',
    title: titles.Product,
    key: 'uom',
    menu: 'Product',
    submenu: "UOM",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.UOM],
  },
  {
    component: 'Availability',
    path: '/product/availability',
    title: titles.Product,
    key: 'availability',
    subkeys: ['/product/availability', '/product/availability/add'],
    menu: 'Product',
    submenu: "Availability",
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'ProgrammeIcon',
    permission: [AllRoles.PRODUCT.AVAILABILITY],
  },
  {
    component: 'AddAvailability',
    path: '/product/availability/add',
    title: titles.Product,
    key: 'availability',
    menu: 'Product',
    submenu: "Availability",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.AVAILABILITY],
  },
  {
    component: 'EditAvailability',
    path: '/product/availability/:id',
    title: titles.Product,
    key: 'availability',
    menu: 'Product',
    submenu: "Availability",
    icon: 'OverviewIcon',
    parent: false,
    permission: [AllRoles.PRODUCT.AVAILABILITY],
  },
]