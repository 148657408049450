import React, { Fragment } from 'react';
import { Button, Image } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  useTranslate,
  useTranslateDispatch,
  useTranslateState
} from 'Translate';
import MalaysiaFlag from '../../../assets/img/malay.svg';
import USAFlag from '../../../assets/img/engl.svg';
import ChinaFlag from "../../../assets/img/zh.png"


function LanguageSwitcher() {
  const { language } = useTranslateState(); // we get the current language
  const i18n = useTranslate(); // we get the utils functions
  const { t, getLanguages } = i18n;
  const dispatch = useTranslateDispatch();

  const items = getLanguages().map((key, index) => {
    return key !== language ? (
      <Fragment key={index}>
        <PageHeader
          ghost={false}
          className='extra-margin0'
          extra={[
            <Button
              key={"btn-1"}
              type="text"
              className="h-auto p-0"
              onClick={
                (e) => {
                  dispatch({ type: 'CHANGE_LANGUAGE', language: 'en' });
                  localStorage.setItem('lang', 'en');
                }
              }
            >
              <Image width={32} src={USAFlag} preview={false} />
            </Button>,
            <Button
              key={"btn-2"}
              type="text"
              className="h-auto p-0"
              onClick={
                (e) => {
                  dispatch({ type: 'CHANGE_LANGUAGE', language: 'zh' });
                  localStorage.setItem('lang', 'zh');
                }
              }
            >
              <Image width={32} src={ChinaFlag} preview={false} />
            </Button>
          ]}
        />
        {/* <Switch key={index} defaultChecked checked={check}  /> */}
      </Fragment>
    ) : (
      ''
    );
  });

  return (
    <>
      <div className="site-page-header-ghost-wrapper">{items}</div>
    </>
  );
}

export default LanguageSwitcher;