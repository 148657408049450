import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Empty, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getDailySummary } from '../ducks/actions'
import { exportDailySummaryReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.dailySummary);
  const meta = useSelector((state) => state.reporting.dailySummaryOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [scrollingWidth, setScrollingWidth] = useState(1000);
  const i18n = useTranslate();
      const { t } = i18n;

  const btnList = [
    {
      text: t("General.export_report"),
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getDailySummary(searchVal, props.setLoading));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      let temp = [];
      if(search?.outlets?.length > 0) {
        search?.outlets?.map(x => {
          temp.push(x.value)
        });
      }
      let temp2 = Object.entries(search).filter(([key, value]) => value === true);
      let obj = {};
      temp2.forEach(d => {
        obj[d[0]] = d[1];
      })
      searching = {
        ...obj,
        outlets: temp,
        is_non_sales: search?.non_sales?.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
        checkedCount: ''
      };
      // let ddd = search?.checkedCount;
      // setScrollingWidth((1000 + (ddd * 200)));
      setSearchVal(searching);
      dispatch(getDailySummary(searching, props.setLoading));
    } else {
      setSearchVal(null);
      setScrollingWidth(1000);
      dispatch(getDailySummary(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning(t("General.no_data_export"));
    }
    props.setLoading(true);

    await exportDailySummaryReport(searchVal).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={t("General.daily_sum_report")} btnList={allowed([AllRoles.REPORTING.DAILY_SUMMARY], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} />

            <div className="table-responsive" style={{ overflowX: 'auto', marginTop: "30px" }}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th rowSpan={2} style={{minWidth: "50px"}}>{t("Outlet.outlet")}</th>
                    <th colSpan={11} className='text-center'>{t("SalesReporting.sales")}</th>
                    {
                      searchVal?.tender &&
                      <th colSpan={meta?.childrens?.find(x => x.header === "payment_method")?.children?.length} className='text-center'>{t("SalesReporting.tender")}</th>
                    }
                    {
                      searchVal?.order_type &&
                      <th colSpan={meta?.childrens?.find(x => x.header === "order_type_desc")?.children?.length} className='text-center'>{t("SalesReporting.order_type")}</th>
                    }
                  </tr>
                  <tr>
                    <th style={{minWidth: "50px"}}>{t("General.business_date")}</th>
                    <th style={{ minWidth: "120px"}} className='text-right'>{t("SalesReporting.gross_sales")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("Delete.discount")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("Inventory.net_amt")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("SalesReporting.inclusive_tax")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("Crm.net_sales")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("SalesReporting.exc_tax")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("SalesReporting.voucher_amt")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("Dashboard.charges")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("SalesReporting.bill_rounding")}</th>
                    <th style={{minWidth: "100px"}} className='text-right'>{t("SalesReporting.total_sales")}</th>
                    {
                      searchVal?.tender &&
                      meta?.childrens?.find(x => x.header === "payment_method")?.children?.map(x => (
                        <th className='text-right' style={{ minWidth: "100px" }}>{x.label}</th>
                      ))
                    }
                    {
                      searchVal?.order_type &&
                      meta?.childrens?.find(x => x.header === "order_type_desc")?.children?.map(x => {
                        let totalCount = 0;
                        orders?.list?.forEach(y => {
                          y?.list?.forEach(z => {
                            if (parseFloat(z[x.value]) > 0) {
                              totalCount += 1;
                            }
                          })
                        });
                        return (
                          <th className='text-right' style={{ minWidth: "100px" }}>{x.label} ({totalCount})</th>
                      )})
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="11">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.length > 0 &&
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          {
                            x.list?.map((y, yIndex) => {
                              return (
                                <tr key={yIndex}>
                                  <td style={{minWidth: "50px"}}>{y.location_code}</td>
                                  <td style={{ minWidth: "120px" }}>{y.report_date}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.sub_total)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.discount_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.net_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.inclusive_tax_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.net_sales)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.exclusive_tax_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.voucher_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.delivery_charge)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.adjustment_amount)}</td>
                                  <td align='right' style={{minWidth: "100px"}}>{comma(y.amount)}</td>
                                  {
                                    searchVal?.tender &&
                                    meta?.childrens?.find(b => b.header === "payment_method")?.children?.map(c => (
                                      <td align="right" style={{ minWidth: "100px" }}>{y[c.value]}</td>
                                    ))
                                  }
                                  {
                                    searchVal?.order_type &&
                                    meta?.childrens?.find(b => b.header === "order_type_desc")?.children?.map(c => (
                                      <td align="right" style={{ minWidth: "100px" }}>{y[c.value]}</td>
                                    ))
                                  }
                                </tr>
                              )
                            })
                          }
                          {
                            x.list?.length > 1 &&
                            <tr className='bg-gray text-white fwbold'>
                              <td colSpan={2}>{t("Crm.subtotal")}</td>
                              {x?.sub_totals?.map((z, zIndex) => (
                                <td align='right' key={zIndex}>{z}</td>
                              ))}
                            </tr>
                          }
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                        ? <td colSpan="12">{t("SalesReporting.grand_total")}</td>
                        : <td align='' colSpan="2">{t("SalesReporting.grand_total")}</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}