import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Spin } from 'antd';
import AddForm from '../components/AddForm';
import { getProductMenu } from "../../ducks/actions";
import { editProductMenu } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';
import { useTranslate } from 'Translate';

const { Title } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation().pathname;
  const data = useSelector((state) => state.product.productMenu);
  const meta = useSelector((state) => state.product.productMenuMeta);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    dispatch(getProductMenu(id, setLoad));
  };

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>
                {data?.code} - {data?.desc}
              </Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                {/* <Button size="large" htmlType='submit' className='green-btn'>{t("General.save")}</Button> */}
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/product/menu')}>{'<'} {t("General.back")}</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AddForm
            loading={props.loading}
            setLoading={setLoad}
            activeTab={activeTab}
            setActiveTab={setActive}
            data={data}
            meta={meta}
            id={id}
            updateProductMenu={editProductMenu}
            reloadApi={callApi}
            searchParams={searchParams}
            mode={'edit'}
            navigate={navigate}
            allowedEdit={allowed([AllRoles.PRODUCT.MENU], 'write')}
            allowedDelete={allowed([AllRoles.PRODUCT.MENU], 'delete')}
          />
        </Col>
      </Row>
    </Spin>
  )
}