import React, { Fragment } from 'react';
import FormGroup from 'Molecules/FormGroup';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {

  const { errors, control, meta, editable } = props
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("General.code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'desc',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'apply_method',
      label: t("Pos.apply_method"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'select',
      options: meta?.apply_methods?.map(x => ({label: x, value: x})),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'apply_reference',
      label: t("Pos.apply_ref"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'value',
      label: t("Pos.def_val"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      number: true,
      min: 1,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'value_type',
      label: t("Pos.val_type"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'select',
      options: meta?.value_types?.map(x => ({label: x, value: x})),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      name: 'counter_reference',
      label: t("Pos.doc_counter_run_no"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable,
      tips: t("Pos.enter_0_if_voucher_import")
    },
    {
      name: 'counter_digit_number',
      label: t("Pos.define_run_no"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      number: true,
      min: 0,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable,
      tips: t("Pos.enter_0_if_voucher_import")
    },
  ];

  return (
    <>
      {formFields.map((item, idx) => (
        <Fragment key={idx}>
          <FormGroup item={item} control={control} errors={errors} />
        </Fragment>
      ))}
    </>
  )
}