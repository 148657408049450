import React, { useEffect, Fragment, useState } from 'react';
import { Form, Button, Row, Col, message, Spin, Space, Typography } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { clearOutletData } from '../../../../ducks/services';
import { InputPassword } from 'Atoms/FormElement';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

const _ = require('lodash');
const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { meta, activeTab, data, navigate, addOutlet, editOutlet, mode, id, reloadApi, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const isOutletTab = activeTab === "1";
  const isSuperAdmin = localStorage.getItem("is_superadmin") === "1";
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("Outlet.outlet_code"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: mode === "edit"
    },
    {
      name: 'desc',
      label: t("Outlet.outlet_name"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'outlet_group',
      label: t("Outlet.outlet_group_code"),
      req: isOutletTab,
      placeholder: t("General.please_select"),
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      class: 'white-select',
      options: _.map(meta?.location_groups, (e) => ({ label: e.code, value: e.id })),
      static: !allowedEdit
    },
    {
      name: 'product_menu',
      label: t("Outlet.outlet_concept"),
      req: isOutletTab,
      placeholder: t("General.please_select"),
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      options: _.map(meta?.product_menus, (e) => ({ label: e.code, value: e.id })),
      static: !allowedEdit
    },
    {
      name: 'email',
      label: t("Outlet.email"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      email: true,
      breakrow: true,
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'mobileno',
      label: t("Outlet.mobile_number"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      number: true,
      nostep: true,
      nomin: true,
      static: !allowedEdit
    },
    {
      name: 'phoneno',
      label: t("Outlet.phone_number"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      number: true,
      nomin: true,
      nostep: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'fax',
      label: t("Outlet.fax_number"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      number: true,
      nostep: true,
      nomin: true,
      static: !allowedEdit
    },
    {
      name: 'address1',
      label: t("Outlet.address_1"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
      maxLength: 48,
    },
    {
      name: 'address2',
      label: t("Outlet.address_2"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
      maxLength: 48,
    },
    {
      name: 'city',
      label: t("Outlet.city"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'postcode',
      label: t("Outlet.postal_code"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      number: true,
      nomin: true,
      static: !allowedEdit
    },
    {
      name: 'state',
      label: t("Outlet.state"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'input',
      string: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'timezone',
      label: t("Outlet.timezone"),
      req: isOutletTab,
      placeholder: t("General.please_state"),
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      options: _.map(meta?.timezones, (e) => ({ label: e, value: e })),
      static: !allowedEdit,
      menuPlacement: 'top'
    },
    {
      name: 'region',
      label: t("Outlet.region"),
      req: false,
      placeholder: t("General.please_select"),
      type: 'select',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      options: _.map(meta?.regions, (e) => ({ label: e.code, value: e.id })),
      static: !allowedEdit,
      isClearable: true
    },
    // {
    //   name: 'tax_condition',
    //   label: t("Outlet.tax_condition"),
    //   req: false,
    //   placeholder: t("General.please_select"),
    //   type: 'select',
    //   twocol: false,
    //   colWidth: '0 1 50%',
    //   reqmessage: t("General.required"),
    //   options: _.map(meta?.tax_conditions, (e) => ({ label: e.code, value: e.id })),
    // },
    {
      name: 'organization_reference',
      label: t("Outlet.company_code"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'start_date',
      label: t("Outlet.operation_start_date"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'date',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'start_time',
      label: t("Outlet.operation_start_time"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'time',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_plastic_allowed',
      label: t("Outlet.is_plastic_bag_allowed_take_away"),
      req: false,
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit,
      alignEnd: true
    },
    {
      type: 'switch',
      name: 'is_initial_stock_take_required',
      label: t("Outlet.is_initial_stock_take_required_for_existing_outlet"),
      req: false,
      twocol: true,
      colWidth: '0 1 50%',
      static: !allowedEdit,
      alignEnd: true,
      hidden: mode !== "add"
    }
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('code', data.code);
      setValue('desc', data.desc);
      setValue('outlet_group', data.location_group_id ? { label: data.location_group_code, value: data.location_group_id } : '');
      setValue('product_menu', data.product_menu_id ? { label: data.product_menu_code, value: data.product_menu_id } : '');
      setValue('email', data.email);
      setValue('mobileno', data.mobileno);
      setValue('phoneno', data.phoneno);
      setValue('fax', data.fax);
      setValue('address1', data.address1);
      setValue('address2', data.address2);
      setValue('city', data.city);
      setValue('postcode', data.postcode);
      setValue('state', data.state);
      setValue('timezone', data.timezone ? { label: data.timezone, value: data.timezone } : '');
      setValue('region', data.region_id ? { label: data.region_code, value: data.region_id } : '');
      setValue('tax_condition', data.tax_condition_id ? { label: data.tax_condition_code, value: data.tax_condition_id } : '');
      setValue('organization_reference', data.organization_reference);
      setValue('start_date', dayjs(data.start_date));
      setValue('start_time', dayjs(data.start_time, "HH:mm"));
      setValue('is_plastic_allowed', data.is_plastic_allowed === "1");
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    var temp = {
      ...val,
      location_group_id: val?.outlet_group?.value,
      product_menu_id: val?.product_menu?.value,
      is_plastic_allowed: val?.is_plastic_allowed ? "1" : "0",
      start_at: dayjs(val?.start_date).format("YYYY-MM-DD") + " " + dayjs(val?.start_time).format("HH:mm"),
      // tax_condition_id: val?.tax_condition?.value,
      region_id: val?.region?.value,
      timezone: val?.timezone?.value,
      id: id
    }

    if (mode === "add") {
      temp["is_initial_stock_take_required"] = val?.is_initial_stock_take_required ? "1" : "0"
    }

    const payload = _.omit(temp, ['outlet_group', 'product_menu', 'region', 'tax_condition']);

    mode === "add"
      ?
      await addOutlet(payload).then(res => {
        props.setLoading(false);
        if (res.data.code === 200) {
          message.success(t("Outlet.outlet_added_success"));
          setTimeout(() => reloadApi(res.data.data), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        props.setLoading(false);
        message.error(e.message);
      })
      :
      await editOutlet(payload).then(res => {
        props.setLoading(false);
        if (res.data.code === 200) {
          message.success(t("Outlet.outlet_edited_success"));
          // setTimeout(() => navigate("/outlets"), 500);
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        props.setLoading(false);
        message.error(e.message);
      })
  };

  const onClearData = async (val) => {
    setLoading(true);
    await clearOutletData({
      id: id,
      password: val?.password
    }).then(res => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Outlet.outlet_data_cleared_success"));
        setValue('password', null);
        setVisible(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={loading}>
      <Form layout="vertical" onFinish={handleSubmit(onClearData)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {t("Outlet.clear_outlet_data")}
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <InputPassword
                fieldname="password"
                label={t("Outlet.pls_enter_password_to_proceed")}
                control={control}
                initValue=""
                iProps={{ size: 'large' }}
              />
            </Col>
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('password', null);
            }}>{t("General.cancel")}</Button></Col>
            {
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('password', '');
    },
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)} scrollToFirstError>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
        </Row>
        {
          allowedEdit &&
          <Row gutter={[10, 10]} className="mt-1">
            {
              isSuperAdmin &&
              mode == "edit" &&
              <Col span={12}>
                <Button size="large" htmlType='button' className='red-btn attendance-success' onClick={() => setVisible(true)}>{t("Outlet.clear_outlet_data")}</Button>
              </Col>
            }
            <Col span={24} className='text-right'>
              <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
            </Col>
          </Row>
        }
      </Form>
      <Popup {...popup} />
    </>
  )
}