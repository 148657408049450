import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Typography, Form, message, Spin, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getProductAvailability } from '../../ducks/actions';
import { editAvailability, removeAvailability } from '../../ducks/services';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import AddEditAvailability from '../Components/AddEditAvailability';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';
import { useTranslate } from 'Translate';

const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { Title } = Typography;
  const [load, setLoad] = useState(false);
  const { control, formState: { errors }, setValue, reset, handleSubmit, getValues, clearErrors } = useForm();
  const productAvailability = useSelector((state) => state.product.availability);
  const allowedEdit = allowed([AllRoles.PRODUCT.AVAILABILITY], 'write');
  const allowedDelete = allowed([AllRoles.PRODUCT.AVAILABILITY], 'delete');
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    dispatch(getProductAvailability(id, setLoad));
  }, [])

  const onFinish = async (val) => {
    setLoad(true);
    let payload = {
      name: val?.template_name,
      shifts: val?.work_hours && val?.work_hours.length > 0 ? JSON.stringify(val?.work_hours.map((value) => ({
        dow: value.day,
        shift_type: value.work_hour_type.value,
        start_time: value?.start_time ? dayjs(value?.start_time).format("HH:mm") : "",
        end_time: value?.end_time ? dayjs(value?.end_time).format("HH:mm") : ""
      }))) : "[]",
    };

    editAvailability({ ...payload, work_schedule_id: id })
      .then((response) => {
        setLoad(false);
        if (response.data.code == 200) {
          message.success(t("Product.product_avblty_update_success"));
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoad(false);
        message.error(error?.message ?? 'Something went wront');
      });
  };

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={load}>
        <Form scrollToFirstError layout="vertical" onFinish={handleSubmit(onFinish)}>
          <Row gutter={[24, 30]}>
            <Col span={24}>
              <Row justify={'space-between'} align={'middle'}>
                <Col flex='auto'>
                  <Title level={3} className='mb-0 mt-0'>{t("Product.edit_product_avblty")}</Title>
                </Col>
                <Col flex='auto' className='text-right'>
                  <Space size={10}>
                    {
                      allowedEdit &&
                      <Button size="large" htmlType='submit' className='green-btn'>{t("General.save")}</Button>
                    }
                    <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/product/availability')}>{'<'} {t("General.back")}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <AddEditAvailability
                setLoading={setLoad}
                errors={errors}
                control={control}
                setValue={setValue}
                reset={reset}
                getValues={getValues}
                data={productAvailability}
                id={id}
                mode={'edit'}
                clearErrors={clearErrors}
                allowedEdit={allowedEdit}
                allowedDelete={allowedDelete}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};
