import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import User from 'Modules/Systems/User';
import AddUser from 'Modules/Systems/User/AddUser';
import EditUser from 'Modules/Systems/User/EditUser';
import Role from 'Modules/Systems/Role';
import PosUser from 'Modules/Systems/PosUser';
import AddPosUser from 'Modules/Systems/PosUser/AddPosUser';
import EditPosUser from 'Modules/Systems/PosUser/EditPosUser';
import PosRole from 'Modules/Systems/PosRole';
import Currency from 'Modules/Systems/Currency';
import AddCurrency from 'Modules/Systems/Currency/AddCurrency';
import EditCurrency from 'Modules/Systems/Currency/EditCurrency';
import PaymentTerm from 'Modules/Systems/PaymentTerm';
import AddPaymentTerm from 'Modules/Systems/PaymentTerm/AddPaymentTerm';
import EditPaymentTerm from 'Modules/Systems/PaymentTerm/EditPaymentTerm';
import Region from 'Modules/Systems/Region';
import AddRegion from 'Modules/Systems/Region/AddRegion';
import EditRegion from 'Modules/Systems/Region/EditRegion';
import TaxCondition from 'Modules/Systems/TaxCondition';
import AddTaxCondition from 'Modules/Systems/TaxCondition/AddTaxCondition';
import EditTaxCondition from 'Modules/Systems/TaxCondition/EditTaxCondition';
import Reason from 'Modules/Systems/Reason';
import AddReason from 'Modules/Systems/Reason/AddReason';
import EditReason from 'Modules/Systems/Reason/EditReason';
import Remark from 'Modules/Systems/Remark';
import AddRemark from 'Modules/Systems/Remark/AddRemark';
import EditRemark from 'Modules/Systems/Remark/EditRemark';
import RemarkType from 'Modules/Systems/RemarkType';
import AddRemarkType from 'Modules/Systems/RemarkType/AddRemarkType';
import EditRemarkType from 'Modules/Systems/RemarkType/EditRemarkType';
import Tax from 'Modules/Systems/Tax';
import PaymentMethod from 'Modules/Systems/PaymentMethod';
import AddPaymentMethod from 'Modules/Systems/PaymentMethod/AddPaymentMethod';
import EditPaymentMethod from 'Modules/Systems/PaymentMethod/EditPaymentMethod';
import PaymentMethodType from 'Modules/Systems/PaymentMethodType';
import AddPaymentMethodType from 'Modules/Systems/PaymentMethodType/AddPaymentMethodType';
import EditPaymentMethodType from 'Modules/Systems/PaymentMethodType/EditPaymentMethodType';
import Setup from 'Modules/Systems/Setup';
import Merchant from 'Modules/Systems/Merchant';
import AddMerchant from 'Modules/Systems/Merchant/AddMerchant';
import EditMerchant from 'Modules/Systems/Merchant/EditMerchant';
import OrderTypeGroup from 'Modules/Systems/OrderTypeGroup';
import AddTypeGroup from 'Modules/Systems/OrderTypeGroup/AddTypeGroup';
import EditTypeGroup from 'Modules/Systems/OrderTypeGroup/EditTypeGroup';
import NetsuiteSync from 'Modules/Systems/NetsuiteSync';

const Components = {
  User,
  AddUser,
  EditUser,
  Role,
  PosUser,
  AddPosUser,
  EditPosUser,
  PosRole,
  Currency,
  AddCurrency,
  EditCurrency,
  PaymentTerm,
  AddPaymentTerm,
  EditPaymentTerm,
  Region,
  AddRegion,
  EditRegion,
  TaxCondition,
  AddTaxCondition,
  EditTaxCondition,
  Reason,
  AddReason,
  EditReason,
  Remark,
  AddRemark,
  EditRemark,
  RemarkType,
  AddRemarkType,
  EditRemarkType,
  Tax,
  PaymentMethod,
  AddPaymentMethod,
  EditPaymentMethod,
  PaymentMethodType,
  AddPaymentMethodType,
  EditPaymentMethodType,
  Setup,
  Merchant,
  AddMerchant,
  EditMerchant,
  OrderTypeGroup,
  AddTypeGroup,
  EditTypeGroup,
  NetsuiteSync
};

export default (props) => {
  const SystemComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <SystemComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};