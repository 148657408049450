import * as action_types from './constants';

const initialState = {
  products: {},
  product: {},
  boms: {},
  productMenus: {},
  productMenu: {},
  productMenuMeta: {},
  menuType: {},
  orderTypes: {},
  orderType: {},
  orderTypeOption: {},
  ingredients: {},
  ingredient: {},
  ingredientMeta: {},
  option: {},
  productGroupOption: {},
  category: {},
  categoryDetail: {},
  categoryMeta: {},
  subCategory: {},
  furtherSubCategory: {},
  furtherSubCategoryMeta: {},
  menuCategoryOption: {},
  menuCategoryDetail: {},
  menuCategoryMeta: {},
  menuItem: {},
  availableProduct: {},
  uoms: {},
  uom: {},
  availabilities: {},
  availability: {},
  alacartes: {},
  selectedMenuType: '',
  kioskValue: '1',
  menuLogs: {},
  menuLogsMeta: {},
};

export default (state = initialState, action) => {
  const { type, data, meta } = action;
  switch (type) {
    case action_types.PRODUCT_LIST:
      return { ...state, products: data, option: meta };
    case action_types.PRODUCT_DETAIL:
      return { ...state, product: data, option: meta };
    case action_types.PRODUCT_OPTION:
      return { ...state, option: data };
    case action_types.BOM_LIST:
      return { ...state, boms: data };
    case action_types.PRODUCT_GROUP_OPTION:
      return { ...state, productGroupOption: data };
    case action_types.PRODUCT_MENU_LIST:
      return { ...state, productMenus: data };
    case action_types.PRODUCT_MENU_DETAIL:
      return { ...state, productMenu: data, productMenuMeta: meta };
    case action_types.ORDER_TYPE_LIST:
      return { ...state, orderTypes: data };
    case action_types.ORDER_TYPE_DETAIL:
      return { ...state, orderType: data, orderTypeOption: meta };
    case action_types.ORDER_TYPE_OPTION:
      return { ...state, orderTypeOption: data };
    case action_types.INGREDIENT_LIST:
      return { ...state, ingredients: data };
    case action_types.INGREDIENT_DETAIL:
      return { ...state, ingredient: data, ingredientMeta: meta };
    case action_types.ALACARTE_LIST:
      return { ...state, alacartes: data };
    case action_types.CATEGORY_LIST:
      return { ...state, category: data, categoryMeta: meta };
    case action_types.CATEGORY_DETAIL:
      return { ...state, categoryDetail: data, categoryMeta: meta };
    case action_types.SUB_CATEGORY_LIST:
      return { ...state, subCategory: data };
    case action_types.FURTHER_SUBCATEGORY_LIST:
      return { ...state, furtherSubCategory: data, furtherSubCategoryMeta: meta };
    case action_types.PRODUCT_MENU_TYPE_LIST:
      return { ...state, menuType: data };
    case action_types.MENU_CATEGORY_OPTION:
      return { ...state, menuCategoryOption: data };
    case action_types.MENU_CATEGORY_DETAIL:
      return { ...state, menuCategoryDetail: data, menuCategoryMeta: meta };
    case action_types.MENU_ITEM_LIST:
      return { ...state, menuItem: data };
    case action_types.AVAILABLE_PRODUCT_LIST:
      return { ...state, availableProduct: data };
    case action_types.UOM_LIST:
      return { ...state, uoms: data };
    case action_types.UOM_DETAIL:
      return { ...state, uom: data };
    case action_types.PRODUCT_AVAILABILITY_LIST:
      return { ...state, availabilities: data };
    case action_types.PRODUCT_AVAILABILITY_DETAIL:
      return { ...state, availability: data };
    case action_types.PRODUCT_UOM_LIST:
      return { ...state, productUomsData: data, productUomsMeta: meta };
    case action_types.SELECTED_MENU_TYPE:
      return { ...state, selectedMenuType: data };
    case action_types.KIOSK_VALUE:
      return { ...state, kioskValue: data };
    case action_types.MENU_SYNC_LOGS:
      return { ...state, menuLogs: data, menuLogsMeta: meta };
      
    default:
      return state;
  }
};
