import React, { useEffect, useState } from 'react';
import { Row, Col, message, Button } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNetSuiteReport } from '../ducks/actions'
import { resyncSingleLocationScript } from '../ducks/services'
import Search from './components/Search'
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

export default (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const netsuiteReport = useSelector((state) => state.inventoryReporting.netsuiteReport);
  const meta = useSelector((state) => state.inventoryReporting.netsuiteReportMeta);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const defaultSearchDate = dayjs().subtract(1, 'day').format('YYYY/MM/DD')
  const queryParameters = new URLSearchParams(location.search);
  const isHiddenAccess = queryParameters.get('ha') === "1";
  const i18n = useTranslate();
    const { t } = i18n;

  const colName = [
    {
      title: t("Outlet.outlet"),
      dataIndex: 'location',
      key: 'location',
      render: (text) => <span className='text-bold'>{text}</span>
    },
    {
      title: t("General.date"),
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: t("InvReporting.gr_vendor_bill"),
      dataIndex: 'good_receive_bill',
      key: 'good_receive_bill',
      render: (text, record) => <>
        <div className='mb-1'>
          {t("General.success")}: <span className='c-success fontSize18Medium'>{text.success}</span>
        </div>
        <div>
          {t("General.total")}: <span className='text-bold'>{text.total}</span>
        </div>
        {
          text.success != text.total &&
          meta?.can_manual_sync &&
          <Button type="link" className='p-0' onClick={() => onResync("good_receive_bill", record)}>{t("General.resync")}</Button>
        }
      </>
    },
    {
      title: t("InvReporting.gr_adj"),
      dataIndex: 'good_receive_adjustment',
      key: 'good_receive_adjustment',
      render: (text, record) => <>
        <div className='mb-1'>
          {t("General.success")} <span className='c-success fontSize18Medium'>{text.success}</span>
        </div>
        <div>
          {t("General.total")}: <span className='text-bold'>{text.total}</span>
        </div>
        {
          text.success != text.total &&
          meta?.can_manual_sync &&
          <Button type="link" className='p-0' onClick={() => onResync("good_receive_adjustment", record)}>{t("General.resync")}</Button>
        }
      </>
    },
    {
      title: t("General.trans_receive"),
      dataIndex: 'transfer_receive',
      key: 'transfer_receive',
      render: (text, record) => <>
        <div className='mb-1'>
          {t("General.success")} <span className='c-success fontSize18Medium'>{text.success}</span>
        </div>
        <div>
          {t("General.total")}: <span className='text-bold'>{text.total}</span>
        </div>
        {
          text.success != text.total &&
          meta?.can_manual_sync &&
          <Button type="link" className='p-0' onClick={() => onResync("transfer_receive", record)}>{t("General.resync")}</Button>
        }
      </>
    },
    {
      title: t("General.stock_wastes"),
      dataIndex: 'stock_wastage',
      key: 'stock_wastage',
      render: (text, record) => <>
        <div className='mb-1'>
          {t("General.success")} <span className='c-success fontSize18Medium'>{text.success}</span>
        </div>
        <div>
          {t("General.total")}: <span className='text-bold'>{text.total}</span>
        </div>
        {
          text.success != text.total &&
          meta?.can_manual_sync &&
          <Button type="link" className='p-0' onClick={() => onResync("stock_wastage", record)}>{t("General.resync")}</Button>
        }
      </>
    },
    {
      title: t("General.stock_takes"),
      dataIndex: 'stock_takes',
      key: 'stock_takes',
      render: (text, record) => <>
        <div className='mb-1'>
          {t("General.success")} <span className='c-success fontSize18Medium'>{text.success}</span>
        </div>
        <div>
          {t("General.total")}: <span className='text-bold'>{text.total}</span>
        </div>
        {
          text.success != text.total &&
          meta?.can_manual_sync &&
          <Button type="link" className='p-0' onClick={() => onResync("stock_takes", record)}>{t("General.resync")}</Button>
        }
      </>
    },
    {
      title: t("InvReporting.adj_stock_conv"),
      dataIndex: 'adjustment',
      key: 'adjustment',
      render: (text, record) => <>
        <div className='mb-1'>
          {t("General.success")} <span className='c-success fontSize18Medium'>{text.success}</span>
        </div>
        <div>
          {t("General.total")}: <span className='text-bold'>{text.total}</span>
        </div>
        {
          text.success != text.total &&
          meta?.can_manual_sync &&
          <Button type="link" className='p-0' onClick={() => onResync("adjustment", record)}>{t("General.resync")}</Button>
        }
      </>
    },
    {
      title: t("InvReporting.cogs"),
      dataIndex: 'cogs',
      key: 'cogs',
      render: (text, record) => <>
        <div className='mb-1'>
          {t("General.success")} <span className='c-success fontSize18Medium'>{text.success}</span>
        </div>
        <div>
          {t("General.total")}: <span className='text-bold'>{text.total}</span>
        </div>
        {
          text.success != text.total &&
          meta?.can_manual_sync &&
          <Button type="link" className='p-0' onClick={() => onResync("cogs", record)}>{t("General.resync")}</Button>
        }
      </>
    },
    {
      title: t("SalesReporting.tender"),
      dataIndex: 'tender',
      key: 'tender',
      render: (text, record) => <>
        <div className='mb-1'>
          {t("General.success")} <span className='c-success fontSize18Medium'>{text.success}</span>
        </div>
        <div>
          {t("General.total")}: <span className='text-bold'>{text.total}</span>
        </div>
        {
          text.success != text.total &&
          meta?.can_manual_sync &&
          <Button type="link" className='p-0' onClick={() => onResync("tender", record)}>{t("General.resync")}</Button>
        }
      </>
    }
  ];

  useEffect(() => {
    dispatch(getNetSuiteReport({start_date: defaultSearchDate}, props.setLoading));
  }, []);

  useEffect(() => {
    if(meta) {
      if('locations' in meta) {
        let temp = [];
        meta.locations.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta])

  const onSearch = (search) => {
    // setPage(1);
    if (search) {
      let searching = {};
      let temp = [];
      if (search?.outlets?.length > 0) {
        search?.outlets?.map(x => {
          temp.push(x.value)
        });
      }
      searching = {
        start_date: search.start_date ? dayjs(search.start_date).format("YYYY-MM-DD") : "",
        // end_date: search.end_date ? dayjs(search.end_date).format("YYYY-MM-DD") : "",
        outlets: temp.length > 0 ? JSON.stringify(temp) : null
      };
      setSearchVal(searching);
      dispatch(getNetSuiteReport(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getNetSuiteReport(null, props.setLoading));
    }
  };

  const onResync = async (scriptType, record) => {
    if (isHiddenAccess) {
      props.setLoading(true);
      const payload = {
        location_id: record.location_id,
        date: record.date,
        script_type: scriptType
      }

      await resyncSingleLocationScript(payload).then(res => {
        props.setLoading(false);
        if (res.data.code === 200) {
          message.success("Resync in progress. Please check the Netsuite Sync Log page for more details.");
        } else {
          message.error(res.data.message);
        }
      }).catch(e => {
        props.setLoading(false);
        message.error(e.message);
      })
    } else {
      let qParams = `?sd=${record.date}&ou=${record.location_id}`;
      let url = "";
      if (scriptType === "cogs") {
        qParams += '&cn=0';
        url = "/reporting/sales-order";
      } else if (scriptType === "tender") {
        qParams += '&tn=0';
        url = "/reporting/sales-order";
      } else if (scriptType === "good_receive_bill") {
        qParams += '&ns=0&ia=0';
        url = "/inventory/good-receive";
      } else if (scriptType === "good_receive_adjustment") {
        qParams += '&ns=0&ia=1';
        url = "/inventory/good-receive";
      } else if (scriptType === "transfer_receive") {
        qParams += '&ns=0';
        url = "/inventory/transfer-in";
      } else {
        qParams += '&ns=0';
        // to be continue here for the rest of the filters
        // 1. index page, 2. search component 3. actions
        // refer to goodreceive indexjsx, components, actions
        if (scriptType === "stock_takes") {
          url = "/inventory/stock-take";
        } else if (scriptType === "stock_wastage") {
          url = "/inventory/stock-wastage";
        } else {
          url = "/inventory/stock-conversion";
        }
      }

      window.open(url + qParams, "_blank");
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={t("General.netsuite_report")} />
      </Col>
      <Col span={24} className="inv-report-sb">
        <ListCard
          Search={Search}
          onSearch={onSearch}
          ListData={netsuiteReport?.list?.map(x => ({...x, key: x.id}))}
          ListCol={colName}
          field1={outlets}
          pagination={false}
          showHeader={netsuiteReport?.list?.length ? true : false}
          isSticky={true}
        />
      </Col>
    </Row>
  )
}