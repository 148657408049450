import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import Product from 'Modules/Product';
import AddProduct from 'Modules/Product/AddProduct';
import EditProduct from 'Modules/Product/EditProduct';
import ProductMenu from 'Modules/Product/ProductMenu';
import AddProductMenu from 'Modules/Product/ProductMenu/AddProductMenu';
import EditProductMenu from 'Modules/Product/ProductMenu/EditProductMenu';
import MenuItem from 'Modules/Product/ProductMenu/MenuItem';
import OrderType from 'Modules/Product/OrderType';
import AddOrderType from 'Modules/Product/OrderType/AddOrderType';
import EditOrderType from 'Modules/Product/OrderType/EditOrderType';
import Ingredient from 'Modules/Product/Ingredient';
import AddIngredient from 'Modules/Product/Ingredient/AddIngredient';
import EditIngredient from 'Modules/Product/Ingredient/EditIngredient';
import Category from 'Modules/Product/Category';
import EditCategory from 'Modules/Product/Category/EditCategory';
import UnitMeasurement from 'Modules/Product/UnitMeasurement';
import AddUom from 'Modules/Product/UnitMeasurement/AddUom';
import EditUom from 'Modules/Product/UnitMeasurement/EditUom';
import Availability from 'Modules/Product/Availability';
import AddAvailability from 'Modules/Product/Availability/AddAvailability';
import EditAvailability from 'Modules/Product/Availability/EditAvailability';

const Components = {
  Product,
  AddProduct,
  EditProduct,
  ProductMenu,
  AddProductMenu,
  EditProductMenu,
  MenuItem,
  OrderType,
  AddOrderType,
  EditOrderType,
  Ingredient,
  AddIngredient,
  EditIngredient,
  Category,
  EditCategory,
  UnitMeasurement,
  AddUom,
  EditUom,
  Availability,
  AddAvailability,
  EditAvailability
};

export default (props) => {
  const ProductComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <ProductComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};