import AllRoles from './AllRoles';

const titles = {
  Outlet: 'Outlet',
};

export const Outlet = [
    {
      component: 'Outlet',
      path: '/outlets',
      title: titles.Outlet,
      key: 'outlets',
      menu: 'Outlet',
      submenu: 'Outlet',
      icon: 'OverviewIcon',
      permission: [AllRoles.OUTLET.OUTLET],
      parent: true,
      parenticon: 'FacultyIcon',
    },
    {
      component: 'AddOutlet',
      path: '/outlets/add',
      title: titles.Outlet,
      key: 'outlets',
      menu: 'Outlet',
      icon: 'OverviewIcon',
      permission: [AllRoles.OUTLET.OUTLET],
      parent: false,
    },
    {
      component: 'EditOutlet',
      path: '/outlets/:id',
      title: titles.Outlet,
      key: 'outlets',
      menu: 'Outlet',
      icon: 'OverviewIcon',
      permission: [AllRoles.OUTLET.OUTLET],
      parent: false,
    },
    {
      component: 'OutletGroup',
      path: '/outlet-group',
      title: titles.Outlet,
      key: 'outlet-group',
      menu: 'Outlet',
      submenu: "Outlet Group",
      icon: 'OverviewIcon',
      permission: [AllRoles.OUTLET.OUTLETGROUP],
      parent: true,
      parenticon: 'FacultyIcon',
    },
    {
      component: 'AddOutletGroup',
      path: '/outlet-group/add',
      title: titles.Outlet,
      key: 'outlet-group',
      menu: 'Outlet',
      submenu: "Outlet Group",
      icon: 'OverviewIcon',
      permission: [AllRoles.OUTLET.OUTLETGROUP],
      parent: false,
    },
    {
      component: 'EditOutletGroup',
      path: '/outlet-group/:id',
      title: titles.Outlet,
      key: 'outlet-group',
      menu: 'Outlet',
      submenu: "Outlet Group",
      icon: 'OverviewIcon',
      permission: [AllRoles.OUTLET.OUTLETGROUP],
      parent: false,
    },
    {
      component: 'UploadFile',
      path: '/mall-sales',
      title: titles.Outlet,
      key: 'mall-sales',
      menu: 'Outlet',
      submenu: "Mall Sales",
      icon: 'OverviewIcon',
      // permission: [AllRoles.OUTLET.OUTLETGROUP],
      parent: true,
      parenticon: 'FacultyIcon',
    },
]