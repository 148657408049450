import React, { useEffect, Fragment, useState } from 'react';
import { Space, Button, Row, Col, Upload, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { dummyRequest, uploadFileV2, getFileName } from 'Features/utility';
import placeholderImg from "Assets/img/product-placeholder.png"
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, meta, data, addProduct, updateProduct, reloadApi, navigate, mode, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const [fileId, setFileId] = useState();
  const [fileList, setFileList] = useState([]);
  const [subCategories, setSubCates] = useState([]);
  const [furtherSubCates, setFurtherSubCates] = useState([]);
  const [isCombo, setIsCombo] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("Outlet.product_code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 35%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'remark',
      label: t("General.reference"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 15%',
      static: !allowedEdit
    },
    {
      name: 'name',
      label: t("Product.pos_name_display_pos"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'short_name',
      label: t("Product.receipt_name_display"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'kitchen_name',
      label: t("Product.kitchen_name_display"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'input',
      name: 'price',
      label: t("Product.selling_price"),
      req: true,
      twocol: true,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'select',
      label: t("Product.kitchen_queue"),
      name: 'product_kitchen_queue',
      twocol: false,
      req: false,
      multiple: true,
      options: meta?.kitchen_queues?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      placeholder: t("General.please_select"),
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: t("Product.major_group"),
      name: 'category',
      twocol: false,
      req: true,
      options: meta?.categories?.map((x) => ({ label: x.code, value: x.id })),
      placeholder: t("General.please_select"),
      reqmessage: t("Product.major_group_req"),
      colWidth: '1 0 33.33%',
      static: !allowedEdit,
      onChange: (e) => {
        console.log(e);
        let temp = meta?.sub_categories?.filter(x => x.category_id === e.value);
        setValue('sub_category', null);
        setValue('further_subcategory', null);
        setSubCates(temp.map(x => ({label: x.code, value: x.id})));
        setFurtherSubCates([]);
      }
    },
    {
      type: 'select',
      label: t("Product.family_group"),
      name: 'sub_category',
      twocol: false,
      req: false,
      options: subCategories,
      placeholder: t("General.please_select"),
      reqmessage: t("Product.family_group_req"),
      colWidth: '1 0 33.33%',
      static: !allowedEdit,
      onChange: (e) => {
        let temp = meta?.further_subcategories?.filter(x => x.sub_category_id === e.value);
        setValue('further_subcategory', null);
        setFurtherSubCates(temp.map(x => ({ label: x.code, value: x.id })));
      }
    },
    {
      type: 'select',
      label: t("Product.retail_group"),
      name: 'further_subcategory',
      twocol: false,
      req: false,
      options: furtherSubCates,
      placeholder: t("General.please_select"),
      reqmessage: t("Product.retail_group_req"),
      colWidth: '0 1 33.33%',
      static: !allowedEdit
    },
    {
      name: 'summary',
      label: t("General.summary"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'textarea',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'description',
      label: t("General.desc"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'textarea',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_semi_product',
      label: t("Product.is_semi_product"),
      req: false,
      twocol: false,
      colWidth: '1 0 100%',
      alignEnd: true,
      // hidden: isCombo,
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_combo',
      label: t("Product.is_combo"),
      req: false,
      twocol: false,
      colWidth: '1 0 100%',
      alignEnd: true,
      static: !allowedEdit,
      onChange: (e) => {
        setIsCombo(e);
      } 
    },
    {
      type: 'switch',
      name: 'is_condiment_required',
      label: t("Product.is_condiment_req"),
      req: false,
      twocol: false,
      colWidth: '1 0 100%',
      alignEnd: true,
      hidden: isCombo,
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'is_plastic_bag',
      label: t("Product.is_plastic_bag"),
      req: false,
      twocol: false,
      colWidth: '1 0 100%',
      alignEnd: true,
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data.code);
      setValue('name', data.name);
      setValue('remark', data.remark);
      setValue('short_name', data.short_name);
      setValue('kitchen_name', data.kitchen_name);
      setValue('summary', data.summary);
      setValue('description', data.description);
      setValue('category', data.category_id ? { label: data.category_code, value: data.category_id } : '');
      setValue('sub_category', data.sub_category_id ? { label: data.sub_category_code, value: data.sub_category_id } : '');
      setValue('further_subcategory', data.further_subcategory_id ? { label: data.further_subcategory_code, value: data.further_subcategory_id } : '');
      setValue('price', data.price);
      setValue('is_condiment_required', data.is_condiment_required === "1" ? true : false);
      setValue('is_plastic_bag', data.is_plastic_bag === "1");
      setIsCombo(data.is_combo === "1");
      let temp = [];
      data?.kitchen_queues?.map(queue => {
        temp.push({
          label: `${queue.code} ${queue.desc}`,
          value: queue.id
        })
      })
      setValue('product_kitchen_queue', temp);
      setValue('is_combo', data.is_combo === "1" ? true : false);
      setValue('hide_image', data.hide_image === "1" ? true : false);
      setValue('is_semi_product', data.is_semi_product === "1" ? true : false);
      if (data?.featured_graphic_url) {
        setFileList([{ uid: '-1', name: getFileName(data?.featured_graphic_url), status: 'done', url: data?.featured_graphic_url }]);
      }else{
        setFileList([]);
      }

      if (meta && 'sub_categories' in meta && data.category_id) {
        let temp = [];
        meta?.sub_categories?.filter(x => x.category_id === data.category_id)?.map(x => {
          temp.push({
            label: x.code,
            value: x.id
          })
        });
        setSubCates(temp);
      }
      if (meta && 'further_subcategories' in meta && data.sub_category_id) {
        let temp = [];
        meta?.further_subcategories?.filter(x => x.sub_category_id === data.sub_category_id)?.map(x => {
          temp.push({
            label: x.code,
            value: x.id
          })
        });
        setFurtherSubCates(temp);
      }
    }
  }, [data]);

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      category_id: val?.category?.value,
      sub_category_id: val?.sub_category?.value,
      further_subcategory_id: val?.further_subcategory?.value,
      product_kitchen_queues: val?.product_kitchen_queue ? JSON.stringify(val?.product_kitchen_queue?.map(x => ({ id: x.value }))) : "[]",
      is_semi_product: val?.is_semi_product ? 1 : 0,
      is_combo: val?.is_combo ? 1 : 0,
      is_condiment_required: val?.is_condiment_required ? 1 : 0,
      is_plastic_bag: val?.is_plastic_bag ? 1 : 0,
      hide_image: val?.hide_image ? 1 : 0,
      blob_id: fileId,
      product_id: id
    }

    await updateProduct(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.product_edit_success"));
        setFileId();
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      category_id: val?.category?.value,
      sub_category_id: val?.sub_category?.value,
      further_subcategory_id: val?.further_subcategory?.value,
      product_kitchen_queues: val?.product_kitchen_queue ? JSON.stringify(val?.product_kitchen_queue?.map(x => ({ id: x.value }))) : "[]",
      is_semi_product: val?.is_semi_product ? 1 : 0,
      is_combo: val?.is_combo? 1: 0,
      is_condiment_required: val?.is_condiment_required ? 1 : 0,
      is_plastic_bag: val?.is_plastic_bag ? 1 : 0,
      blob_id: fileId
    }

    await addProduct(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.product_added_success"));
        setFileId();
        setTimeout(() => navigate(`/product/${res.data.data}`), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const handleUpload = async (e) => {
    props.setLoading(true);
    try{
      let file = await uploadFileV2(e.file.originFileObj);
      props.setLoading(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: getFileName(file?.url), status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`${t("General.error_during_upload")} #{error}`);
      props.setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 20]}>
        <Col span={24} className="text-center">
          <Upload
            disabled={!allowedEdit}
            listType="picture-card"
            className="avatar-uploader larger"
            showUploadList={false}
            accept="image/*"
            maxCount={1}
            fileList={fileList}
            customRequest={dummyRequest}
            onChange={(e) => handleUpload(e)}
          >
            <Space size={4}>
              {fileList?.length > 0 ? (
                <img
                  src={fileList[0].url}
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) :
                <div>
                  {/* <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div> */}
                  <img src={placeholderImg} alt="" className='w-100' />
                </div>
              }
            </Space>
          </Upload>
        </Col>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}