import React from 'react';
import { Button, Form, Row, Col } from 'antd';
import { InputField, SelectField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

export default (props) => {
  const { searchVal } = props;
  const { control, handleSubmit } = useForm()
  const i18n = useTranslate();
  const { t } = i18n;

  const searchStatusLabel = (searchVal?.reason_type === "" || searchVal?.reason_type) ? true : false

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100 inline-form'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex="auto">
          <InputField
            fieldname='name'
            label=''
            class='mb-0 w-100'
            initValue={searchVal?.name ? searchVal?.name : ''}
            control={control}
            iProps={{ placeholder: t("General.search_by_code_or_desc") }}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname="reason_type"
            class="mb-0 w-100 default-select"
            label=""
            control={control}
            iProps={{ placeholder: t("System.reason_type") }}
            selectOption={props.field1}
            initValue={searchStatusLabel ? {label: searchVal?.reason_type === "" ? t("General.all_types") : searchVal?.reason_type, value: searchVal?.reason_type} : ''}
          />
        </Col>
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>{t("General.search")}</Button>
        </Col>
      </Row>
    </Form>
  )
}