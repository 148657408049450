import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import SalesOrder from 'Modules/Member/SalesOrder';

const Components = {
  SalesOrder
};

export default (props) => {
  const MemberComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <MemberComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};