import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { addCurrency } from "../../ducks/services"
import { useTranslate } from 'Translate';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const i18n = useTranslate();
  const { t } = i18n;

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      code: val.code,
      symbol: val.symbol
    }
    await addCurrency(payload).then(res => {
      props.setLoading(false);
      if(res.data.code === 200) {
        message.success(t("System.currency_create_success"));
        setTimeout(() => navigate("/currency"), 500);
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>{t("System.new_currency")}</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                <Button size="large" htmlType='submit' className='green-btn'>{t("General.save")}</Button>
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/currency')}>{'<'} {t("General.back")}</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          allowedEdit={true}
        />
      </Row>
    </Form>
  )
}