import React, { useEffect, Fragment } from 'react';
import { Row, Col, Form, Button, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, addRemarkType, updateRemarkType, meta, data, mode, reloadApi, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("General.code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'desc',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('code', data.code);
      setValue('desc', data.desc);
    }
  }, [data]);

  const onFinish = async (val) => {
    if (mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      remark_type_id: id
    }

    await updateRemarkType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.updated_remark_type"));
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val
    }
    await addRemarkType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.create_remark_type"));
        setTimeout(() => reloadApi(res.data.data), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}