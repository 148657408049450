import React, { useEffect, Fragment } from 'react';
import { Row, Col, Form, Button, message, Card } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, updateCondition, data, meta, reloadApi, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      type: 'switch',
      name: 'skip_addon_item',
      label: t("Pos.skip_addon_item"),
      req: false,
      twocol: false,
      colWidth: '1 0 25%',
      alignEnd: true,
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'skip_combo',
      label: t("Pos.skip_combo"),
      req: false,
      twocol: false,
      colWidth: '1 0 25%',
      alignEnd: true,
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'skip_promotion_item',
      label: t("Pos.skip_promo_item"),
      req: false,
      twocol: false,
      colWidth: '0 1 50%',
      alignEnd: true,
      static: !allowedEdit
    },
    {
      type: 'switch',
      name: 'skip_discounted_item',
      label: t("Pos.skip_discount_item"),
      req: false,
      twocol: false,
      colWidth: '0 1 25%',
      alignEnd: true,
      static: !allowedEdit
    },
    {
      type: 'select',
      name: 'skip_discount_list',
      label: t("Pos.skip_discount_list"),
      req: false,
      reqmessage: t("General.required"),
      class: 'default-select',
      twocol: false,
      multiple: true,
      options: meta?.products?.map(x => ({ label: x.name, value: x.id })),
      colWidth: '0 1 75%',
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('skip_discounted_item', data.skip_discounted_item === "1");
      setValue('skip_addon_item', data.skip_addon_item === "1");
      setValue('skip_combo', data.skip_combo === "1");
      setValue('skip_promotion_item', data.skip_promotion_item === "1");
      let temp = [];
      if(data?.blocked_products && data?.blocked_products?.length > 0) {
        data?.blocked_products.forEach(d => {
          temp.push({
            label: d.name,
            value: d.id
          });
        });
      }
      setValue('skip_discount_list', temp);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      skip_discounted_item: val?.skip_discounted_item ? 1 : 0,
      skip_addon_item: val?.skip_addon_item ? 1 : 0,
      skip_combo: val?.skip_combo ? 1 : 0,
      skip_promotion_item: val?.skip_promotion_item ? 1 : 0,
      products: JSON.stringify(val?.skip_discount_list?.map((x) => x.value)),
      discount_id: id
    }

    await updateCondition(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.updated_discount_usage_condition"));
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Card bordered={false} className=''>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
          {
            allowedEdit &&
            <Col span={24} className='text-right'>
              <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
            </Col>
          }
        </Row>
      </Card>
    </Form>
  )
}