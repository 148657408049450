import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, data, addVendor, editVendor, reloadApi, mode, meta, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [isNetsuiteAdj, setIsNetsuiteAdj] = useState(false);
  const i18n = useTranslate();
    const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("Inventory.vendor_code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'name',
      label: t("Outlet.name"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'company_registration_no',
      label: t("Inventory.comp_reg_no"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'tax_registration_no',
      label: t("Inventory.tax_reg_no"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'select',
      label: t("Product.currency_code"),
      name: 'currency',
      twocol: false,
      options: meta?.currencies?.map((x) => ({ label: x.code, value: x.id })),
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Currency Code Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: t("Inventory.payment_term_code"),
      name: 'payment_term',
      twocol: false,
      options: meta?.payment_terms?.map((x) => ({ label: x.code, value: x.id })),
      req: false,
      placeholder: t("General.please_select"),
      reqmessage: t("Inventory.payment_term_code_req"),
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      name: 'cancellation_grace_period',
      label: t("Inventory.cancel_grace_period_days"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'is_netsuite_adjustment',
      label: t("Inventory.netsuite_adj"),
      placeholder: t("General.please_select"),
      type: 'switch',
      twocol: false,
      colWidth: '0 1 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top', // move label to top
      static: !allowedEdit,
      onChange: (e) => {
        setIsNetsuiteAdj(e)
      }
    },
    {
      name: 'warehouse_reference',
      label: t("System.external_id"),
      req: isNetsuiteAdj,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
      hidden: !isNetsuiteAdj
    },
    {
      name: 'address1',
      label: t("Outlet.address_1"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'address2',
      label: t("Outlet.address_2"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'city',
      label: t("Outlet.city"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'postcode',
      label: t("Outlet.postal_code"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'state',
      label: t("Outlet.state"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'select',
      label: t("Outlet.country"),
      name: 'country',
      twocol: false,
      options: meta?.countries?.map((x) => ({ label: x, value: x })),
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Country Required',
      colWidth: '0 1 50%',
      static: !allowedEdit
    },
    {
      name: 'order_attn',
      label: t("Inventory.order_attention_name"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'email',
      label: t("System.email"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      multipleEmail: true,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
      beautifyTips: {__html: 'use <span class="font-italic">(comma (,) separated)</span> for input multiple email address'}
    },
    {
      name: 'finance_email',
      label: t("General.finance_email"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      email: true,
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit,
    },
    {
      name: 'other_email',
      label: t("Inventory.other_email"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      email: true,
      twocol: false,
      colWidth: '0 1 50%',
      static: !allowedEdit,
    },
    {
      name: 'phoneno',
      label: t("Inventory.phone_no"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'fax',
      label: t("Inventory.fax_no"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'remarks',
      label: t("System.remarks"),
      placeholder: t("General.please_state"),
      type: 'textarea',
      twocol: false,
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
    {
      name: 'is_hide',
      label: t("Inventory.is_hide"),
      placeholder: t("General.please_select"),
      type: 'switch',
      twocol: false,
      colWidth: '0 1 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top', // move label to top
      static: !allowedEdit
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data.code);
      setValue('name', data.name);
      setValue('company_registration_no', data.company_registration_no);
      setValue('tax_registration_no', data.tax_registration_no);
      setValue('email', data.email);
      setValue('finance_email', data.finance_email);
      setValue('other_email', data.other_email);
      setValue('currency', data.currency_id ? { label: data.currency_code, value: data.currency_id } : '');
      setValue('payment_term', data.payment_term_id ? { label: data.payment_term_code, value: data.payment_term_id } : '');
      setValue('country', data.country ? { label: data.country, value: data.country } : '');
      setValue('address1', data.address1);
      setValue('address2', data.address2);
      setValue('city', data.city);
      setValue('postcode', data.postcode);
      setValue('state', data.state);
      setValue('order_attn', data.order_attn);
      setValue('phoneno', data.phoneno);
      setValue('fax', data.fax);
      setValue('remarks', data.remarks);
      setValue('is_netsuite_adjustment', data.is_netsuite_adjustment === "1");
      setValue('is_hide', data.is_hide === "1");
      setIsNetsuiteAdj(data.is_netsuite_adjustment === "1")
      setValue('warehouse_reference', data.warehouse_reference);
      setValue('cancellation_grace_period', data.cancellation_grace_period);
    }
  }, [data]);

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      currency_id: val?.currency?.value,
      country: val?.country?.value,
      payment_term_id: val?.payment_term?.value,
      is_netsuite_adjustment: val?.is_netsuite_adjustment ? 1 : 0,
      is_hide: val?.is_hide ? 1 : 0,
    }

    await addVendor(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.create_vendor"));
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      currency_id: val?.currency?.value,
      payment_term_id: val?.payment_term?.value,
      country: val?.country?.value,
      is_netsuite_adjustment: val?.is_netsuite_adjustment ? 1 : 0,
      is_hide: val?.is_hide ? 1 : 0,
      vendor_id: id
    }

    await editVendor(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.update_vendor"));
        setTimeout(() => reloadApi(), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)} scrollToFirstError>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}