import React, { useEffect, useState } from 'react';
import { Row, Col, message, Button } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPosUserList } from '../ducks/actions'
import { removePosUser, bulkRemovePosUsers } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { useTranslate } from 'Translate';

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.systems.posusers);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const globalListQuery = useSelector((state) => state.global.listQueryItems);
  const allowedDelete = allowed([AllRoles.SYSTEM.POSUSERS], 'delete')
  const i18n = useTranslate();
  const { t } = i18n;

  const colName = [
    {
      title: t("System.username"),
      dataIndex: 'username',
      key: 'username',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Outlet.name"),
      dataIndex: 'name',
      key: 'name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("System.role"),
      dataIndex: 'roles_str',
      key: 'roles_str',
      className: 'enable-click',
      sorter: true
    },
    // {
    //   title: t("System.email"),
    //   dataIndex: 'email',
    //   key: 'email',
    //   className: 'enable-click',
    //   sorter: true,
    //   render: (text) => <span>{text || "-"}</span>
    // },
    {
      title: t("System.demo_user"),
      dataIndex: 'is_demo',
      key: 'is_demo',
      className: 'enable-click',
      sorter: true,
      align: 'center',
      render: (text) => <span>{text === "1" ? <CheckCircleFilled className='c-success' style={{ fontSize: '20px' }} /> : <CloseCircleFilled className='c-danger' style={{ fontSize: '20px' }} />}</span>
    },
    {
      title: t("General.status"),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {t(`General.${text?.toLowerCase()}`)}
          </span>
          {
            allowed([AllRoles.SYSTEM.POSUSERS], 'write') &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? `(${t("General.deactivate")})` : `(${t("General.activate")})`}
            </Button>
          }
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="user"
          btnAction1={() => navigate(`/pos/users/${record.id}`)}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const addNew = () => navigate('/pos/users/add');

  const btnList = [
    {
      title: t("System.confirm_remove_select_user"),
      text: t("General.bulk_remove"),
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: `+ ${t("System.new_pos_user")}`,
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if(!globalListQuery.posUsersListQuery) {
      dispatch(getPosUserList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('posUsersListQuery', 1, limit, '', '', null, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.posUsersListQuery
      dispatch(getPosUserList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  const onSearch = (val) => {
    setPage(1);
    if (val) {
      let searching = {
        name: val?.name,
        email: val?.email
      }
      setSearchVal(searching)
      dispatch(getPosUserList(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('posUsersListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal();
      dispatch(getPosUserList(1, limit, '', '' , null, props.setLoading));
      dispatch(getListQueryItems('posUsersListQuery', 1, limit, '', '' , null, props.setLoading))
    }
  };

  const onAction = async (id, status) => {
    props.setLoading(true);

    const payload = {
      id: id,
      status_event: status === 'Active' ? 'deactivate' : 'activate'
    }

    await removePosUser(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("System.user_status_update_success"));
        setTimeout(() => dispatch(getPosUserList(1, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('posUsersListQuery', 1, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getPosUserList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('posUsersListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getPosUserList(pagination.current, pagination.pageSize, '', '', null, props.setLoading));
      dispatch(getListQueryItems('posUsersListQuery', pagination.current, pagination.pageSize, '', '', null, props.setLoading))
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      id: id,
      status_event: 'remove'
    }

    await removePosUser(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Outlet.pos_user_remove_success"));
        setTimeout(() => dispatch(getPosUserList(1, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('posUsersListQuery', 1, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      users: JSON.stringify(selectedRowKeys)
    }

    await bulkRemovePosUsers(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success(t("Outlet.pos_users_remove_success"));
        setTimeout(() => dispatch(getPosUserList(1, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('posUsersListQuery', 1, limit, '', '', searchVal, props.setLoading))
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
        navigate(`/pos/users/${record.id}`);
      },
    };
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={t("Menu.pos_users")} btnList={allowed([AllRoles.SYSTEM.POSUSERS], 'write') && btnList} btnHidden={selectedRowKeys.length <= 0 || !allowed([AllRoles.SYSTEM.POSUSERS], 'delete') } />
      </Col>
      <Col span={24} className="clickRow">
        <ListCard
          onRow={onClickRow}
          Search={Search}
          onSearch={onSearch}
          searchVal={globalListQuery.posUsersListQuery?.searchValue}
          ListData={users?.list?.map(x => ({ ...x, key: x.id }))}
          onChange={onTableChange}
          ListCol={colName}
          rowSelection={rowSelection}
          pagination={{
            total: users?.total_count,
            current: page,
            pageSize: limit
          }}
        />
      </Col>
    </Row>
  )
}