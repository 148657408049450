import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Table, message, Empty } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryReportsMeta, getInventoryReports } from '../ducks/actions'
import { exportInventoryReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';

// Tables
import {
  GoodReceiveDetails,
  GoodReceiveSummary,
  InventoryBalanceDaily,
  MaterialConsumption,
  ProductRecipe,
  PurchaseOrderSummary,
  PurchaseOrderDetails,
  StockAdjustmentDetails,
  StockMovement,
  StockTakeDetails,
  StockWastageDetails,
  StockWastageSummary,
  TransferDescrepancyDetails
} from './components/Tables';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reporting.inventoryReport);
  const meta = useSelector((state) => state.reporting.inventoryReportMeta);
  const [searchVal, setSearchVal] = useState(null);
  const i18n = useTranslate();
  const { t } = i18n;

  // Filter UI
  const [reportType, setReportType] = useState(null);
  const [isMultiOutlet, setIsMultiOutlet] = useState(false);
  const [dateFromOnly, setDateFromOnly] = useState(false);
  const [stockMovementFilter, setStockMovementFilter] = useState(false);
  const [trDescList, setTrDescList] = useState(false);
  const [productRecipeFilter, setProductRecipeFilter] = useState(false);
  const [materialCon, setMaterialCon] = useState(false);

  // Meta Dropdown data
  const [outlets, setOutlets] = useState([]);
  const [statuses, setStatuses] = useState(false);
  const [products, setProducts] = useState([]);
  const [ingredients, setIngredients] = useState([]);

  const inventoryReport = reports?.list || []

  useEffect(() => {
    dispatch(getInventoryReportsMeta());
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = meta?.locations.map(x => ({ label: `${x.code} ${x.desc}`, value: x.id }))

        setOutlets(temp);
      }

      if ("statuses" in meta) {
        let temp = meta?.statuses.map(x => ({ label: x, value: x }))
        temp = [{ label: t("General.all_status"), value: "" }, ...temp]

        setStatuses(temp);
      }

      if ("ingredients" in meta) {
        let temp = meta?.ingredients.map(x => ({ label: `${x.code} ${x.name}`, value: x.id }))

        setIngredients(temp);
      }

      if ("products" in meta) {
        let temp = meta?.products.map(x => ({ label: `${x.code} ${x.name}`, value: x.id }))

        setProducts(temp);
      }
    }
  }, [meta]);

  const reportTypeDropdown = [
    {
      label: t("InvReporting.material_con"),
      value: 'material_consumption_listing',
      noStatus: true,
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.gr_details"),
      value: 'gr_details_listing',
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.gr_summary"),
      value: 'gr_summary_listing',
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.inv_bal_daily"),
      value: 'closing_stock_listing',
      noStatus: true,
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.sa_details"),
      value: 'adj_details_listing',
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.stock_movement"),
      value: 'stock_movement_listing',
      noStatus: true,
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.st_details"),
      value: 'st_details_listing',
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.sw_summary_list"),
      value: 'sw_summary_listing',
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.trans_desc_details_list"),
      value: 'tr_descrepancy_details_listing',
    },
    {
      label: t("InvReporting.product_recipe"),
      value: 'product_recipe_listing',
      noStatus: true,
    },
    {
      label: t("InvReporting.sw_details_list"),
      value: 'sw_details_listing',
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.po_summary_list"),
      value: 'po_summary_listing',
      isMultiOutlet: true
    },
    {
      label: t("InvReporting.po_details_list"),
      value: 'po_details_listing',
      isMultiOutlet: true,
      noStatus: true
    },
  ]
  
  const columns = {
    material_consumption_listing: {
      list: [
        {
          title: t("General.date"),
          dataIndex: 'date',
          align: 'left'
        },
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location',
          align: 'left'
        },
        {
          title: t("InvReporting.parent_item"),
          dataIndex: 'item',
          align: 'left'
        },
        {
          title: t("SalesReporting.item_qty"),
          dataIndex: 'qty',
          align: 'right'
        },
        {
          title: t("SalesReporting.gross_sales"),
          dataIndex: 'gross_sales',
          align: 'right'
        },
        {
          title: t("Inventory.gross_amt"),
          dataIndex: 'gross_amount',
          align: 'right'
        },
        {
          title: t("Delete.discount"),
          dataIndex: 'discount_amount',
          align: 'right'
        },
        {
          title: t("General.tax"),
          dataIndex: 'tax_amount',
          align: 'right'
        },
        {
          title: t("Crm.net_sales"),
          dataIndex: 'net_sales',
          align: 'right'
        },
        {
          title: t("Inventory.net_amt"),
          dataIndex: 'net_amount',
          align: 'right'
        },
        {
          title: t("Inventory.inv_dot_cost"),
          dataIndex: 'cost',
          align: 'right'
        },
      ],
      nestedList: [
        {
          title: t("General.item"),
          dataIndex: 'item',
          align: "left"
        },
        {
          title: t("General.uom"),
          dataIndex: 'uom',
          align: "left"
        },
        {
          title: t("InvReporting.tran_qty"),
          dataIndex: 'qty',
          align: "right"
        },
        {
          title: t("InvReporting.total_inv_cost"),
          dataIndex: 'inv_cost',
          align: "right"
        },
      ],
      // isDetail: false,
      isNested: true,
      totalColSpan: 0,
      nestedColSpan: 4,
    },
    gr_details_listing: {
      list: [
        {
          title: t("General.date"),
          dataIndex: 'date',
          longCol: false,
          align: "left"
        },
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location',
          longCol: false,
          align: "left"
        },
        {
          title: t("InvReporting.inv_no"),
          dataIndex: 'stock_in_reference',
          longCol: false,
          align: "left"
        },
        {
          title: t("InvReporting.po_no"),
          dataIndex: 'po_reference',
          longCol: false,
          align: "left"
        },
        {
          title: t("Inventory.supplier"),
          dataIndex: 'supplier',
          longCol: true,
          align: "left"
        },
        {
          title: t("General.status"),
          dataIndex: 'status',
          longCol: false,
          align: "left"
        },
        {
          title: t("General.item"),
          dataIndex: 'item',
          longCol: true,
          align: "left"
        },
        {
          title: t("General.uom"),
          dataIndex: 'uom',
          longCol: false,
          align: "left"
        },
        {
          title: t("InvReporting.doc_qty"),
          dataIndex: 'qty',
          longCol: false,
          align: "right"
        },
        {
          title: t("Inventory.discrepancy_qty"),
          dataIndex: 'discrepancy_qty',
          longCol: false,
          align: "right"
        },
        {
          title: t("Inventory.gross_amt"),
          dataIndex: 'gross_amount',
          longCol: false,
          align: "right"
        },
        {
          title: t("InvReporting.disc_amt"),
          dataIndex: 'discount_amount',
          longCol: false,
          align: "right"
        },
        {
          title: t("InvReporting.total_amt"),
          dataIndex: 'total_amount',
          longCol: false,
          align: "right"
        },
        {
          title: t("SalesReporting.total_inv_cost"),
          dataIndex: 'total_inv_cost',
          longCol: false,
          align: "right"
        },
        {
          title: t("Inventory.unit_cost"),
          dataIndex: 'unit_cost',
          longCol: false,
          align: "right"
        },
      ],
      totalColSpan: 5,
      isDetail: true
    },
    gr_summary_listing: {
      list: [
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location_code',
          align: "left"
        },
        {
          title: t("Inventory.grn_doc_date"),
          dataIndex: 'date',
          align: "left"
        },
        {
          title: t("Inventory.grn_doc_no"),
          dataIndex: 'reference',
          align: "left"
        },
        {
          title: t("Inventory.supplier"),
          dataIndex: 'supplier',
          align: "left"
        },
        {
          title: t("General.status"),
          dataIndex: 'status',
          align: "left"
        },
        {
          title: t("InvReporting.doc_qty"),
          dataIndex: 'qty',
          align: "right"
        },
        {
          title: t("Inventory.gross_amt"),
          dataIndex: 'gross_amount',
          align: "right"
        },
        {
          title: t("Inventory.discount_amt"),
          dataIndex: 'discount_amount',
          align: "right"
        },
        {
          title: t("Inventory.net_amt"),
          dataIndex: 'net_amount',
          align: "right"
        },
        {
          title: t("SalesReporting.total_inv_cost"),
          dataIndex: 'total_inv_cost',
          align: "right"
        },
      ],
      totalColSpan: 4,
      isDetail: false
    },
    closing_stock_listing: {
      // “Item”, “UOM”, “Bal. Qty”, “Bal. Inv. Cost”, “Unit Cost”, “Closing Margin (Inv. Cost)“, “Closing Margin % (Inv. Cost)”
      list: [
        {
          title: t("General.date"),
          dataIndex: 'date',
          align: 'left'
        },
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location',
          align: 'left'
        },
        {
          title: t("General.item"),
          dataIndex: 'item',
          align: 'left'
        },
        {
          title: t("General.uom"),
          dataIndex: 'uom',
          align: 'left'
        },
        {
          title: t("InvReporting.bal_qty"),
          dataIndex: 'closing_quantity',
          align: 'right'
        },
        {
          title: t("InvReporting.bal_inv_cost"),
          dataIndex: 'closing_cost',
          align: 'right'
        },
        {
          title: t("Inventory.unit_cost"),
          dataIndex: 'closing_unit_cost',
          align: 'right'
        },
        {
          title: t("InvReporting.close_margin_inv_cost"),
          dataIndex: 'closing_adjustment_cost',
          align: 'right'
        },
        {
          title: t("InvReporting.close_margin_perc_inv_cost"),
          dataIndex: 'closing_adjustment_cost_perc',
          align: 'right'
        },
      ]
    },
    adj_details_listing: {
      list: [
        {
          title: t("Inventory.adj_date"),
          dataIndex: 'date',
          align: 'left'
        },
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location',
          align: 'left'
        },
        {
          title: t("Inventory.adj_doc_no"),
          dataIndex: 'reference',
          align: 'left'
        },
        {
          title: t("General.item"),
          dataIndex: 'item',
          align: 'left'
        },
        {
          title: t("General.uom"),
          dataIndex: 'uom',
          align: 'left'
        },
        {
          title: t("InvReporting.adj_qty"),
          dataIndex: 'qty',
          align: 'right'
        },
        {
          title: t("Inventory.adj_unit_cost"),
          dataIndex: 'unit_cost',
          align: 'right'
        },
        {
          title: t("InvReporting.adj_inv_cost"),
          dataIndex: 'inv_cost',
          align: 'right'
        },
      ],
      isDetail: true,
      totalColSpan: 1,
      isNestedList: true
    },
    stock_movement_listing: {
      list: [
        {
          title: t("General.item"),
          dataIndex: 'product_code',
          align: 'left'
        },
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location',
          align: 'left'
        },
        {
          title: t("General.uom"),
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: t("Inventory.doc_date"),
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: t("InvReporting.ledger_id"),
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: t("InvReporting.doc_no"),
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: t("InvReporting.transaction"),
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: t("General.status"),
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: t("InvReporting.qty"),
          dataIndex: 'name',
          align: 'right'
        },
        {
          title: t("InvReporting.running_bal"),
          dataIndex: 'name',
          align: 'right'
        },
        {
          title: t("InvReporting.inv_cost"),
          dataIndex: 'name',
          align: 'right'
        },
        {
          title: t("InvReporting.running_inv_cost"),
          dataIndex: 'name',
          align: 'right'
        },
      ]
    },
    st_details_listing: {
      list: [
        {
          title: t("Inventory.doc_date"),
          dataIndex: 'docdate',
          align: 'left'
        },
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location',
          align: 'left'
        },
        {
          title: t("Inventory.st_no"),
          dataIndex: 'doc_no',
          align: 'left'
        },
        {
          title: t("General.item"),
          dataIndex: 'item',
          align: 'left'
        },
        {
          title: t("General.status"),
          dataIndex: 'status',
          align: 'left'
        },
        {
          title: t("General.uom"),
          dataIndex: 'uom',
          align: 'left'
        },
        {
          title: t("InvReporting.doc_qty"),
          dataIndex: 'qty',
          align: 'right'
        },
        {
          title: t("InvReporting.system_quantity"),
          dataIndex: 'system_qty',
          align: 'right'
        },
        {
          title: t("Inventory.discrepancy_qty"),
          dataIndex: 'discrepancy_qty',
          align: 'right'
        },
        {
          title: t("Inventory.unit_cost"),
          dataIndex: 'unit_cost',
          align: 'right'
        },
        {
          title: t("SalesReporting.total_inv_cost"),
          dataIndex: 'inv_cost',
          align: 'right'
        },
      ],
      totalColSpan: 2,
      isNestedList: true
    },
    sw_summary_listing: {
      list: [
        {
          title: t("InvReporting.doc_no"),
          dataIndex: 'reference',
          align: 'left'
        },
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location_code',
          align: 'left'
        },
        {
          title: t("General.status"),
          dataIndex: 'status',
          align: 'left'
        },
        {
          title: t("InvReporting.doc_qty"),
          dataIndex: 'qty',
          align: 'right'
        },
        {
          title: t("Inventory.unit_cost"),
          dataIndex: 'unit_cost',
          align: 'right'
        },
        {
          title: t("InvReporting.total_inv_cost"),
          dataIndex: 'inv_cost',
          align: 'right'
        },
      ],
      totalColSpan: 2,
      isDetail: true
    },
    tr_descrepancy_details_listing: {
      list: [
        {
          title: t("InvReporting.transfer_doc_no"),
          dataIndex: 'reference',
          align: 'left'
        },
        {
          title: t("InvReporting.origin"),
          dataIndex: 'origin',
          align: 'left'
        },
        {
          title: t("InvReporting.destination"),
          dataIndex: 'destination',
          align: 'left'
        },
        {
          title: t("General.item"),
          dataIndex: 'item',
          align: 'left'
        },
        {
          title: t("General.status"),
          dataIndex: 'status',
          align: 'left'
        },
        {
          title: t("InvReporting.doc_qty"),
          dataIndex: 'qty',
          align: 'right'
        },
        {
          title: t("InvReporting.confirmed_qty"),
          dataIndex: 'confirm_qty',
          align: 'right'
        },
        {
          title: t("InvReporting.total_inv_cost_out"),
          dataIndex: 'out_inv_cost',
          align: 'right'
        },
        {
          title: t("InvReporting.total_inv_cost_receive"),
          dataIndex: 'in_inv_cost',
          align: 'right'
        },
  
      ],
      totalColSpan: 4,
      isDetail: true
    },
    product_recipe_listing: {
      list: [
        {
          title: t("InvReporting.parent_item"),
          dataIndex: 'product_code',
          align: "left"
        },
        {
          title: t("General.uom"),
          dataIndex: 'uom',
          align: "left"
        },
        {
          title: t("InvReporting.item_count"),
          dataIndex: 'item_count',
          align: "right"
        },
      ],
      nestedList: [
        {
          title: t("General.item"),
          dataIndex: 'item',
          align: "left"
        },
        {
          title: t("General.uom"),
          dataIndex: 'uom',
          align: "left"
        },
        {
          title: t("Product.recipe_rate"),
          dataIndex: 'recipe_rate',
          align: "right"
        },
        {
          title: t("InvReporting.yield_perc"),
          dataIndex: 'yield_perc',
          align: "right"
        },
        {
          title: t("SalesReporting.item_qty"),
          dataIndex: 'item_qty',
          align: "right"
        },
      ],
      totalColSpan: 1,
      nestedColSpan: 4,
      isNested: true
    },
    sw_details_listing: {
      list: [
        {
          title: t("InvReporting.doc_no"),
          dataIndex: 'reference',
          align: 'left'
        },
        {
          title: t("Inventory.doc_date"),
          dataIndex: 'date',
          align: 'left'
        },
        {
          title: t("Outlet.outlet"),
          dataIndex: 'location_code',
          align: 'left'
        },
        {
          title: t("General.item"),
          dataIndex: 'item',
          align: 'left'
        },
        {
          title: t("General.uom"),
          dataIndex: 'uom',
          align: 'left'
        },
        {
          title: t("General.status"),
          dataIndex: 'status',
          align: 'left'
        },
        {
          title: t("InvReporting.doc_qty"),
          dataIndex: 'qty',
          align: 'right'
        },
        {
          title: t("Inventory.unit_cost"),
          dataIndex: 'unit_cost',
          align: 'right'
        },
        {
          title: t("InvReporting.total_inv_cost"),
          dataIndex: 'inv_cost',
          align: 'right'
        },
      ],
      totalColSpan: 5,
      isDetail: false,
    },
    po_summary_listing: {
      list: [
        {
          title: t("Inventory.po_no"),
          dataIndex: 'reference',
          align: 'left'
        },
        {
          title: t("General.status"),
          dataIndex: 'status',
          align: 'left'
        },
        {
          title: t("Inventory.supplier"),
          dataIndex: 'vendor_code',
          align: 'left'
        },
        {
          title: t("Inventory.order_qty"),
          dataIndex: 'qty',
          align: 'right'
        },
        {
          title: t("Inventory.gross_amt"),
          dataIndex: 'gross_amount',
          align: 'right'
        },
        {
          title: t("InvReporting.disc_amt"),
          dataIndex: 'discount_amount',
        },
        {
          title: t("Inventory.tax_amt"),
          dataIndex: 'tax_amount',
          align: 'right'
        },
        {
          title: t("Inventory.net_amt"),
          dataIndex: 'amount',
          align: 'right'
        },
        {
          title: t("InvReporting.total_inv_cost"),
          dataIndex: 'inv_cost',
          align: 'right'
        },
        {
          title: t("Inventory.received_qty"),
          dataIndex: 'received_qty',
          align: 'right'
        },
        {
          title: t("InvReporting.outstanding_qty"),
          dataIndex: 'outstanding_qty',
          align: 'right'
        },
      ],
      totalColSpan: 2,
      isDetail: true
    },
    po_details_listing: {
      list: [
        {
          title: t("General.date"),
          dataIndex: 'date',
          align: 'left'
        },
        {
          title: t("InvReporting.sku"),
          dataIndex: 'code',
          align: 'left'
        },
        {
          title: t("InvReporting.month"),
          dataIndex: 'month',
          align: 'left'
        },
        {
          title: t("InvReporting.year"),
          dataIndex: 'year',
          align: 'left'
        },
        {
          title: t("InvReporting.store"),
          dataIndex: 'store',
          align: 'left'
        },
        {
          title: t("InvReporting.product_cat"),
          dataIndex: 'product_category',
          align: 'left'
        },
        {
          title: t("InvReporting.po"),
          dataIndex: 'reference',
          align: 'left'
        },
        {
          title: t("Delete.vendor"),
          dataIndex: 'vendor',
          align: 'left'
        },
        {
          title: t("General.item"),
          dataIndex: 'name',
          align: 'left'
        },
        {
          title: t("InvReporting.base_unit"),
          dataIndex: 'base_uom_desc',
          align: 'left'
        },
        {
          title: t("InvReporting.unit"),
          dataIndex: 'uom_desc',
          align: 'left'
        },
        {
          title: t("InvReporting.cost_unit"),
          dataIndex: 'unit_cost',
          align: 'right'
        },
        {
          title: t("InvReporting.total_qty"),
          dataIndex: 'quantity',
          align: 'right'
        },
      ]
    },
  }

  const onSearch = (search) => {
    // if (search && 'outlets' in search) {
    let searching = {};

    searching = {
      report_type: search?.report_type.value,
      date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
      date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
      status: search?.status?.value
    };

    if (search?.report_type.value === "stock_movement_listing" || search?.report_type.value === "po_details_listing") {
      let temp = [];
      if (search?.products?.length > 0) {
        search?.products?.map(x => {
          temp.push(x.value)
        });
      }
      searching["products"] = temp;
    }

    if (isMultiOutlet) {
      if (search?.outlet.length && search?.outlet.length > 0) searching.location_ids = JSON.stringify(search?.outlet?.map(x => x.value))
    } else {
      searching.location_id = search?.outlet?.value
    }

    if (search?.report_type.value === "tr_descrepancy_details_listing") {
      searching = {
        report_type: search?.report_type.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : null,
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
      };
    }

    setSearchVal(searching);
    dispatch(getInventoryReports(searching, props.setLoading, isMultiOutlet, search?.report_type.value, message));
    setReportType(search?.report_type.value)
    // } else {
    //   setSearchVal(null);
    //   dispatch(getInventoryReports(null, props.setLoading));
    // }
  };

  const exportReport = async (search) => {
    if (reports?.total_count === 0) {
      return message.warning(t("General.no_data_export"));
    }
    let searching = {};

    searching = {
      report_type: search?.report_type.value,
      date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
      date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
      status: search?.status?.value
    };

    if (search?.report_type.value === "stock_movement_listing" || search?.report_type.value === "po_details_listing") {
      let temp = [];
      if (search?.products?.length > 0) {
        search?.products?.map(x => {
          temp.push(x.value)
        });
      }
      searching["products"] = temp;
    }

    if (isMultiOutlet) {
      if (search?.outlet.length && search?.outlet.length > 0) searching.location_ids = search?.outlet?.map(x => x.value)
    } else {
      searching.location_id = search?.outlet?.value
    }

    if (search?.report_type.value === "tr_descrepancy_details_listing") {
      searching = {
        report_type: search?.report_type.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : null,
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : null,
      };
    }

    setReportType(search?.report_type.value)
    props.setLoading(true);

    await exportInventoryReport(searching, reportType).then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={t("General.inv_report")} />
        </Col>

        <Col span={24}>
          <Card bordered={false}>
            <Search
              field1={reportTypeDropdown}
              field2={outlets}
              field3={statuses}
              field4={products}
              field5={ingredients}
              meta={meta}
              onSearch={onSearch}
              exportReport={exportReport}
              isMultiOutlet={isMultiOutlet}
              setIsMultiOutlet={setIsMultiOutlet}
              dateFromOnly={dateFromOnly}
              setDateFromOnly={setDateFromOnly}
              stockMovementFilter={stockMovementFilter}
              setStockMovementFilter={setStockMovementFilter}
              trDescList={trDescList}
              setTrDescList={setTrDescList}
              productRecipeFilter={productRecipeFilter}
              setProductRecipeFilter={setProductRecipeFilter}
              materialCon={materialCon}
              setMaterialCon={setMaterialCon}
              setStatuses={setStatuses}
            />

            {(reportType && inventoryReport && Object.keys(inventoryReport).length > 0) ?
              <>
                {reportType === 'material_consumption_listing' && <MaterialConsumption columns={columns} data={reports} />}
                {reportType === 'gr_details_listing' && <GoodReceiveDetails columns={columns} data={reports} />}
                {reportType === 'gr_summary_listing' && <GoodReceiveSummary columns={columns} data={reports} />}
                {reportType === 'adj_details_listing' && <StockAdjustmentDetails columns={columns} data={reports} />}
                {reportType === 'sw_details_listing' && <StockWastageDetails columns={columns} data={reports} />}
                {reportType === 'sw_summary_listing' && <StockWastageSummary columns={columns} data={reports} />}
                {reportType === 'st_details_listing' && <StockTakeDetails columns={columns} data={reports} />}
                {reportType === 'tr_descrepancy_details_listing' && <TransferDescrepancyDetails columns={columns} data={reports} />}
                {reportType === 'product_recipe_listing' && <ProductRecipe columns={columns} data={reports} />}
                {reportType === 'po_summary_listing' && <PurchaseOrderSummary columns={columns} data={reports} />}
                {reportType === 'po_details_listing' && <PurchaseOrderDetails columns={columns} data={reports} />}
                {reportType === 'stock_movement_listing' && <StockMovement columns={columns} data={reports} />}
                {reportType === 'closing_stock_listing' && <InventoryBalanceDaily columns={columns} data={reports} />}
              </>
              :
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
          </Card>
        </Col>
      </Row>
    </>
  )
}
