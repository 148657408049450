import { combineReducers } from 'redux';
import userReducer from '../../features/userSlice';
import global from 'Modules/Application/ducks/reducers';
import outlet from 'Modules/Outlet/ducks/reducers';
import product from 'Modules/Product/ducks/reducers';
import systems from 'Modules/Systems/ducks/reducers';
import pos from 'Modules/Pos/ducks/reducers';
import inventory from 'Modules/Inventory/ducks/reducers';
import crm from 'Modules/CRM/ducks/reducers';
import reporting from 'Modules/Reporting/ducks/reducers';
import members from 'Modules/Member/ducks/reducers';
import profile from 'Modules/Profile/ducks/reducers';
import inventoryReporting from 'Modules/InventoryReporting/ducks/reducers';
import { DESTROY_SESSION } from 'Modules/Application/ducks/constants';

const appReducer = combineReducers({
  user: userReducer,
  global,
  outlet,
  product,
  systems,
  pos,
  inventory,
  crm,
  reporting,
  members,
  profile,
  inventoryReporting
});

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
