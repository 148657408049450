import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Space, Button, Typography, Table, message, Tooltip, Spin } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesOrderList } from '../ducks/actions'
import { exportSoReport, getInventoryList, getSalesOrderItemList } from '../ducks/services'
import { Popup } from 'Atoms/Popup';
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { CheckCircleTwoTone, LoadingOutlined, CloudSyncOutlined } from '@ant-design/icons';
import { syncToNetsuite } from '../../Inventory/ducks/services';
import { nonSalesFilters, cogsFilters, tenderFilters } from '../../../../configs/constantData';
import { useTranslate } from 'Translate';

const antIcon = <LoadingOutlined spin />;

function comma(nStr) {
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

const { Title, Text } = Typography;

const filters = [
  {
    label: "Invoice Report",
    value: "invoice"
  },
  {
    label: "Sales Orders Report",
    value: "bill"
  },
]

export default (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.salesOrders);
  const meta = useSelector((state) => state.reporting.salesOrdersOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [visible, setVisible] = useState(false);
  const [rec, setRecord] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [tenderTypes, setTenderTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selected, setSelected] = useState(filters[0].value);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const globalListQuery = useSelector((state) => state.global.listQueryItems);
  // const [activeTab, setActiveTab] = useState("1");
  const [customActiveTab, setCustomActiveTab]= useState(null);
  const [invList, setInvList] = useState([])
  const [saleItemList, setSaleItemList] = useState([])
  const [loading, setLoading] = useState(false);
  const searchTenderNetsuite = queryParameters.get('tn') ? queryParameters.get('tn') == "1" : null;
  const searchCogsNetsuite = queryParameters.get('cn') ? queryParameters.get('cn') == "1" : null;
  const searchOutlet = queryParameters.get('ou');
  const searchDate = queryParameters.get('sd');
  const i18n = useTranslate();
    const { t } = i18n;

  // Debug Sales Order Item List UI item and price not align
  useEffect(() => {
    if(saleItemList) {
      const itemNameColArr = document.querySelectorAll('.itemNameCol')
      const itemPriceColArr = document.querySelectorAll('.itemPriceCol')

      if(itemNameColArr && itemNameColArr.length) {
        itemNameColArr.forEach((item, i) => {
          itemPriceColArr[i].style.height = `${item.offsetHeight}px`
        })
      }
    }
  }, [saleItemList]);

  const colName = [
    {
      title: t("Outlet.outlet"),
      dataIndex: 'location_code',
      key: 'location_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Delete.order_type"),
      dataIndex: 'order_type_desc',
      key: 'order_type_desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.business_date"),
      dataIndex: 'report_date',
      key: 'report_date',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Inventory.bill_no"),
      dataIndex: 'order_no',
      key: 'order_no',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Inventory.collection_no"),
      dataIndex: 'collection_no',
      key: 'collection_no',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Inventory.gross_amt"),
      dataIndex: 'sub_total_excluded_tax',
      key: 'sub_total_excluded_tax',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Delete.discount"),
      dataIndex: 'discount_amount',
      key: 'discount_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true
    },
    {
      title: t("Crm.net_sales"),
      dataIndex: 'net_sales',
      key: 'net_sales',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`

    },
    {
      title: t("Inventory.tax_amt"),
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`

    },
    {
      title: t("Dashboard.charges"),
      dataIndex: 'delivery_charge',
      key: 'delivery_charge',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Delete.voucher"),
      dataIndex: 'voucher_amount',
      key: 'voucher_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Crm.adj_amt"),
      dataIndex: 'adjustment_amount',
      key: 'adjustment_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true
    },
    {
      title: t("Crm.net_total"),
      dataIndex: 'amount',
      key: 'amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`

    },
    {
      title: t("General.status"),
      dataIndex: 'status',
      key: 'status',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Paid" ? "c-success" : "c-danger"}>
            {text === "Paid" ? t("General.completed") : t(`General.${text?.toLowerCase()}`)}
          </span>
          {record?.is_non_sales === "0" && record?.inventory_netsuite_synced === "1" && record?.tender_netsuite_synced === "0" && <Tooltip title="COGS Synced to Netsuite"><CheckCircleTwoTone twoToneColor="#c3423f" className="ml-1" /></Tooltip>}
          {record?.is_non_sales === "0" && record?.inventory_netsuite_synced === "0" && record?.tender_netsuite_synced === "1" && <Tooltip title="Tender Synced to Netsuite"><CheckCircleTwoTone twoToneColor="#1677ff" className="ml-1" /></Tooltip>}
          {(record?.is_non_sales === "0" ? (record?.inventory_netsuite_synced === "1" && record?.tender_netsuite_synced === "1") : record?.inventory_netsuite_synced === "1") && <Tooltip title={t("Inventory.synced_to_netsuite")}><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip>}

          {
                (
                  (text === "Paid" || text === "Refunded") &&
                  record?.can_manual_sync === "1"
                ) &&
                  <Tooltip title="Sync to Netsuite">
                    <a className='pointer ml-1' onClick={(e) => {
                      e.stopPropagation()
                      onSyncNetsuite(record.id)
                    }}>
                      <CloudSyncOutlined /> {t("General.sync")}
                    </a>
                  </Tooltip>
              }
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="sales_order"
          btnAction1={() => {
            setVisible(true);
            setRecord(record);
            setCustomActiveTab("1");
          }}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const colName2 = [
    {
      title: t("General.qty"),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      className: 'valign-top',
      width: 30
    },
    {
      title: t("General.item"),
      dataIndex: 'name',
      key: 'name',
      className: 'valign-top',
      render: (text, record) => <>
        {record.quantity > 1 ? <div className='itemNameCol'>{`${text} @${comma(displayTax(rec) ? record.price : record.price_excluded_tax)}`}</div> : <div className='itemNameCol'>{text}</div>}
        {record.parts.map((x, ind) => {
          return (
            <div className="itemNameCol" key={ind}>
              <Space size={15}>
                <span>{x.quantity || record.quantity}</span>
                <span>{x.name}</span>
              </Space>
            </div>
          )
        })}
      </>
    },
    {
      title: meta?.currency_symbol,
      dataIndex: 'amount_excluded_parts',
      key: 'amount_excluded_parts',
      align: 'right',
      className: 'valign-top',
      width: 105,
      render: (text, record) => {
        const price = comma(displayTax(rec) ? text : record?.amount_excluded_parts_excluded_tax);
        return (
          <>
            <div className={`${meta?.receipt_hide_zero_amount && parseFloat(price) <= 0 ? "invisible" : ""} itemPriceCol`}>{price}</div>
            {/* <br /> */}
            {record.parts.map((x, ind) => {
              return (
                <div className='text-right itemPriceCol' key={ind}>
                  {displayTax(rec)
                    ? parseFloat(x.amount)
                      ? <div>{comma(x.amount)}</div>
                      : <div className='invisible'>-</div>
                    : parseFloat(x.amount_excluded_tax)
                      ? <div>{comma(x.amount_excluded_tax)}</div>
                      : <div className='invisible'>-</div>
                  }
                </div>
              )
            })}
          </>
        )
      }
    },
  ];

  const inventoryColName = [
    {
      title: t("Outlet.product_code"),
      dataIndex: 'product_code',
      key: 'product_code',
      align: 'center',
      className: 'valign-top',
    },
    {
      title: t("Product.product_name"),
      dataIndex: 'product_name',
      key: 'product_code',
      align: 'center',
      className: 'valign-top',
    },
    {
      title: t("Product.uom_code"),
      dataIndex: 'uom_code',
      key: 'uom_code',
      align: 'center',
      className: 'valign-top',
    },
    {
      title: t("Inventory.source_name"),
      dataIndex: 'source_name',
      key: 'source_name',
      align: 'center',
      className: 'valign-top',
    },
    {
      title: t("Inventory.unit_cost"),
      dataIndex: 'unit_cost',
      key: 'unit_cost',
      align: 'right',
      className: 'valign-top',
    },
    {
      title: t("General.qty"),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      className: 'valign-top',
    },
    {
      title: t("General.cost"),
      dataIndex: 'cost',
      key: 'cost',
      align: 'right',
      className: 'valign-top',
    },
    {
      title: t("Inventory.netsuite_sync_?"),
      dataIndex: 'netsuite_synced',
      key: 'netsuite_synced',
      className: 'valign-top',
      render: (text) => text === "1" ? <Tooltip title={t("Inventory.synced_to_netsuite")}><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip> : "No"
    },
  ]

  const displayTax = (salesOrder) => {
    if (salesOrder.currency_code == "IDR" && salesOrder.is_fa === "1" && ["GRAB_FOOD", "GRAB_FOOD_PICKUP"].includes(salesOrder.order_type_code)) {
      return true;
    } else {
      return salesOrder.is_non_sales == "0" && salesOrder.tax_inclusive == "1" && (orders?.tax_display_inclusive || parseFloat(salesOrder.discount_amount) > 0);
    }
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: 
      <Space direction='vertical' size={20} className='w-100'>
        <Title level={4} className='mb-0'>
          {rec?.order_no}
        </Title>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Title level={5} className='mb-0'>
              {t("General.status")}: <span className={`${rec?.status === "Paid" ? "c-success" : "c-danger"}`}>{rec?.status === "Paid" ? "Completed" : rec?.status} {rec?.refunded_at_str && `(${rec?.refunded_at_str})`}</span>
            </Title>
          </Col>
          {(rec?.reasons && rec?.reasons.length) ?
            <Col span={24}>
              <div>{t("System.reasons")}:</div>
              <ul>
                { rec?.reasons?.map(item => <li key={`${item.id} ${item.desc}`}>{item.desc}</li>) }
              </ul>
            </Col>
            : ''
          }
        </Row>

        {/* Pop Up Tabs */}
        <div className="popup-tabs mb-5">
          <div className={`tab-item ${customActiveTab === "1" ? 'active' : ''}`} onClick={() => setCustomActiveTab("1")}>{t("General.bill")}</div>
          {
            allowed([AllRoles.REPORTING.SALES], 'read') &&
            <div className={`tab-item ${customActiveTab === "2" ? 'active' : ''}`} onClick={() => setCustomActiveTab("2")}>{t("General.inventory")}</div>
          }
        </div>

        {customActiveTab === "1" &&
          <Row gutter={[10, 10]} justify={'center'}>
            {rec?.organization?.receipt_hide_logo === "0" &&
              <Col span={24} className='text-center'>
                <img src={rec?.organization?.image_url} alt="Logo" width='100px' />
              </Col>
            }
            <Col span={24} className='text-center'>
              {rec?.organization?.name}
              <br />
              {rec?.organization?.tax_label}
            </Col>
            <Col span={24} className='text-center'>
              {rec?.location?.address1}
              <br />
              {rec?.location?.address2}
              <br />
              {rec?.location?.address3}
            </Col>
            <Col span={22} className='text-center'>
              <hr style={{borderStyle: "dashed"}} />
            </Col>
            <Col span={22} className=''>
              {t("SalesReporting.invoice_no")}: {rec?.order_no}
              <br />
              <div style={{display: "flex", justifyContent: "space-between"}}><span>{t("General.date")}: {rec?.date}</span> <span>{rec?.time}</span></div>
              {t("SalesReporting.cashier")}: {rec?.created_by_name}
              <br />
              {t("SalesReporting.prn_on")}: {rec?.paid_at_str}
            </Col>
            <Col span={22} className='text-center'>
              <hr style={{borderStyle: "dashed"}} />
            </Col>
            <Col span={24} className='text-center'>
              *** {rec?.order_type_desc} *** {rec?.collection_no && `- ${rec?.collection_no}`}
            </Col>
            <Col span={24}>
              <Spin indicator={antIcon} size="large" spinning={loading}>
                <Table
                  className='sales-table'
                  dataSource={saleItemList?.map((x, ind) => ({ ...x, key: `Child Item ${x.id} - ${ind}` }))}
                  columns={colName2}
                  pagination={false}
                  summary={() => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} className="valign-top text-center">
                            <Text>{rec?.items_quantity}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1} className="valign-top">
                            <Text>{t("Crm.subtotal")}</Text>
                            <br />
                            {
                              parseFloat(rec?.discount_amount) > 0 &&
                              <>
                                <Text>{t("Delete.discount")} {`(${rec?.discount_desc})`}</Text>
                                <br />
                                {
                                  rec?.discount_remark &&
                                  <>
                                    <Text>{rec?.discount_remark}</Text>
                                    <br />
                                  </>
                                }
                              </>
                            }
                            {
                              rec?.tax_condition_items?.filter(x => displayTax(rec) ? x.taxable === "0" : x.taxable != null).map((x, ind) => {
                                return (<React.Fragment key={ind}>
                                  <Text>{x.desc}</Text>
                                  <br />
                                </React.Fragment>)
                              })
                            }
                            {
                              rec?.vouchers.length > 0 && 
                              rec?.vouchers.map(item => <>
                                <Text>{item.voucher_type_desc}</Text>
                                <br />
                                <Text>({t("SalesReporting.ref_no")}: {item.voucher_code})</Text>
                                <br />
                              </>)
                            }
                            {
                              parseFloat(rec?.adjustment_amount) !== 0 &&
                              <>
                                <Text>{t("Crm.rounding_adj")}</Text>
                                <br />
                              </>
                            }
                            <Text>{t("Crm.net_total")}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} className="valign-top text-right sales-table-price-col">
                            <Text>
                              {
                                displayTax(rec) ? comma(rec?.sub_total) : comma(rec?.sub_total_excluded_tax)
                              }
                            </Text>
                            <br />
                            {
                              parseFloat(rec?.discount_amount) > 0 &&
                              <>
                                <Text>-{comma(rec?.discount_amount)}</Text>
                                <br />
                                {
                                  rec?.discount_remark &&
                                  <>
                                    <Text/>
                                    <br />
                                  </>
                                }
                              </>
                            }
                            {
                              rec?.tax_condition_items?.filter(x => displayTax(rec) ? x.taxable === "0" : x.taxable != null)?.map((x, ind) => {
                                return (<React.Fragment key={ind}>
                                  <Text>{comma(x.total_tax_amount)}</Text>
                                  <br />
                                </React.Fragment>)
                              })
                            }
                            {
                              rec?.vouchers.length > 0 && 
                              rec?.vouchers.map(item => <>
                                <Text>-{comma(item.amount)}</Text>
                                <br />
                                <br />
                              </>)
                            }
                            {
                              parseFloat(rec?.adjustment_amount) !== 0 &&
                              <>
                                <Text>{comma(rec?.adjustment_amount)}</Text>
                                <br />
                              </>
                            }
                            <Text>{comma(rec?.amount)}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2} className="valign-top">
                            <Space direction='vertical' size={5}>
                              {
                                rec?.payments?.map((x, ind) => (
                                  <Text key={ind}>{x.payment_method_desc}</Text>
                                ))
                              }
                              {
                                parseFloat(rec?.change_amount) > 0 &&
                                <Text>{t("Crm.change")} {rec?.currency_code}</Text>
                              }
                            </Space>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={0} colSpan={1} className="text-right">
                            <Space direction='vertical' size={5}>
                              {
                                rec?.payments?.map((x, ind) => (
                                  <Text key={ind}>{comma(x.amount)}</Text>
                                ))
                              }
                              {
                                parseFloat(rec?.change_amount) > 0 &&
                                <Text>{comma(rec?.change_amount)}</Text>
                              }
                            </Space>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2} className="valign-top">
                            <Row justify={'space-between'} align={'middle'}>
                              <Col>
                                <Text>{t("Crm.tax_summary")}</Text>
                                {
                                  rec?.tax_condition_items?.filter(x => x.taxable == "1").map((x, ind) => {
                                    return (
                                      <Fragment key={ind}>
                                        <br />
                                        <Text>{x.desc}</Text>
                                      </Fragment>
                                    )
                                  })
                                }
                              </Col>
                              <Col>
                                <Text>{t("Crm.taxable")}</Text>
                                {
                                  rec?.tax_condition_items?.filter(x => x.taxable == "1").map((x, ind) => {
                                    return (
                                      <Fragment key={ind}>
                                        <br />
                                        <Text>{comma(rec?.net_sales)}</Text>
                                      </Fragment>
                                    )
                                  })
                                }
                              </Col>
                            </Row>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} className="valign-top text-right">
                            <Text>{t("General.tax")}</Text>
                            {
                              rec?.tax_condition_items?.filter(x => x.taxable == "1").map((x, ind) => {
                                return (
                                  <Fragment key={ind}>
                                    <br/>
                                    <Text>{comma(x?.total_tax_amount)}</Text>
                                  </Fragment>
                                )
                              })
                            }
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} className="valign-top">
                            <Title level={5} className='text-center mb-0'>
                              {rec?.organization?.receipt_footer_desc?.map((x, ind) => (<div key={ind} className={x === "" ? "mt-1" : ""}>{x}</div>))}
                            </Title>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        {rec?.organization?.qr_url &&
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4} className="valign-top text-center">
                              <img src={rec?.organization?.qr_url} alt="Logo" width='100px' />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        }
                      </>
                    )
                  }}
                />
              </Spin>
            </Col>
          </Row>
        }

        {customActiveTab === "2" && 
        <Spin indicator={antIcon} size="large" spinning={loading}>
          <div className='inventory-entries-table'>
            <Table columns={inventoryColName} dataSource={invList?.map(x => ({ ...x, key: x.id }))} pagination={false} />
          </div>
          </Spin>
        }
      
        <Row gutter={10} justify={'center'}>
          <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
            setVisible(false);
            setRecord();
            setCustomActiveTab(null)
          }}>{t("General.close")}</Button></Col>
        </Row>
      </Space>
    ,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setRecord();
      setCustomActiveTab(null)
    },
  };

  const btnList = [
    {
      text: t("General.export_report"),
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    if(!globalListQuery.salesListQuery) {
      let search = searchVal || {};
      if (searchTenderNetsuite !== null) {
        search["tender_netsuite_synced"] = searchTenderNetsuite ? "1" : "0";
        search["status"] = "paid";
        search["is_non_sales"] = "0"
      }
      if (searchCogsNetsuite !== null) {
        search["inventory_netsuite_synced"] = searchCogsNetsuite ? "1" : "0";
        search["status"] = "paid";
      }
      if (searchOutlet) {
        search["outlet"] = [searchOutlet];
      }
      if (searchDate) {
        search["start_date"] = searchDate;
        search["end_date"] = searchDate;
      }
      
      setSearchVal(search);
      dispatch(getSalesOrderList(1, limit, '', '', search, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', 1, limit, '', '', search, props.setLoading));
    } else {
      const {page, limit, sortOrder, sortColKey, searchValue} = globalListQuery.salesListQuery
      dispatch(getSalesOrderList(page, limit, sortOrder, sortColKey, searchValue, props.setLoading));
      setPage(page);
      setLimit(limit);
      setSearchVal(searchValue);
    }
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }

      if ("order_types" in meta) {
        let temp2 = [];
        
        meta.order_types?.map((x, ind) => {
          if(ind === 0) {
            temp2.push({
              label: t("Crm.all_order_types"),
              value: ""
            });
          }

          temp2.push({
            label: x.desc,
            value: x.id
          })
        });
        setOrderTypes(temp2);
      }

      if ("statuses" in meta) {
        let temp3 = [];
        
        meta.statuses?.map((x, ind) => {
          if(ind === 0) {
            temp3.push({
              label: t("General.all_status"),
              value: ""
            });
          }

          temp3.push({
            label: x === 'paid' ? 'Completed' : x,
            value: x
          })
        });
        setStatuses(temp3);
      }

      if ("tender_types" in meta) {
        let temp3 = [];
        
        meta.tender_types?.map((x, ind) => {
          if(ind === 0) {
            temp3.push({
              label: "All Tender Types",
              value: ""
            });
          }

          temp3.push({
            label: `${x.name} (${x.payment_method_type_desc})`,
            value: x.id
          })
        });
        setTenderTypes(temp3);
      }
    }
  }, [meta]);

  useEffect(() => {
    if(customActiveTab === "1" && rec?.id) {
      setLoading(true);
      getSalesOrderItemList({sales_order_id: rec?.id}).then(res => {
        if (res.data.code === 200) {
          setSaleItemList(res.data.data?.list)
        } else {
          message.error(res.data.message);
        }
      }).catch(() => {
        message.error(res.data.message);
      }).finally(() => {
        setLoading(false)
      })
    }

    if(customActiveTab === "2") {
      setLoading(true);
      getInventoryList({sales_order_id: rec?.id}).then(res => {
        if (res.data.code === 200) {
          setInvList(res.data.data?.list)
        } else {
          message.error(res.data.message);
        }
      }).catch(() => {
        message.error(res.data.message);
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [customActiveTab])

  const onTableChange = (pagination, filters, sorter) => {
    if (orders?.total_count === 0) {
      return;
    }
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getSalesOrderList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading))
    } else {
      dispatch(getSalesOrderList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading))
    }
  }

  const onSearch = (search) => {
    setPage(1);
    navigate("/reporting/sales-order");

    if (search && 'outlet' in search) {
      let searching = {};
      let temp = [];
      if (search?.outlet?.length > 0) {
        search?.outlet?.map(x => {
          temp.push(x.value)
        });
      }
      searching = {
        order_no: search.reference,
        start_date: search.start_date ? dayjs(search.start_date).format("YYYY-MM-DD") : "",
        end_date: search.end_date ? dayjs(search.end_date).format("YYYY-MM-DD") : "",
        order_type: search.order_type.value,
        tender_type: search.tender_type.value,
        status: search.status.value
      };

      if(search?.is_non_sales?.value) {
        searching["is_non_sales"] = search?.is_non_sales?.value
      }
      if(search?.tender_netsuite_synced?.value) {
        searching["tender_netsuite_synced"] = search?.tender_netsuite_synced?.value
      }
      if(search?.inventory_netsuite_synced?.value) {
        searching["inventory_netsuite_synced"] = search?.inventory_netsuite_synced?.value
      }
      if (temp.length > 0) {
        searching["outlet"] = temp;
      }
      setSearchVal(searching);
      dispatch(getSalesOrderList(1, limit, '', '', searching, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', 1, limit, '', '', searching, props.setLoading))
    } else {
      setSearchVal(null);
      dispatch(getSalesOrderList(1, limit, '', '', null, props.setLoading));
      dispatch(getListQueryItems('salesListQuery', 1, limit, '', '', null, props.setLoading))
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setVisible(true);
          setRecord(record);
          setCustomActiveTab("1");
        }
      },
    };
  };

  const exportReport = async () => {
    if(orders?.total_count === 0) {
      return message.warning(t("General.no_data_export"));
    }
    props.setLoading(true);

    await exportSoReport(selected, searchVal, selectedRowKeys).then((res) => {
      props.setLoading(false);
      
      if (res.data) {
        res.data.text().then((text) => {
          try {
            let errCode = JSON.parse(text).code;
            if(errCode && errCode != 200) {
              let errMsg = JSON.parse(text).message
              message.error(errMsg);
            }
          } catch (e) {
            // catch = no error from backend, hence download
            const aElement = document.createElement('a');
            let filename = res.headers["x-filename"];
            aElement.setAttribute('download', filename ?? "sample.pdf");
            const href = URL.createObjectURL(res.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
          }
        })
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const onSyncNetsuite = async (soId) => {
    props.setLoading(true);

    const payload = {
      sales_order_id: soId
    }

    await syncToNetsuite("sales-orders", payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(res.data.message);
        setTimeout(() => dispatch(getSalesOrderList(page, limit, '', '', searchVal, props.setLoading)), 500);
        dispatch(getListQueryItems('stockWastageListQuery', page, limit, '', '', searchVal, props.setLoading))
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const dropSelection = {
    value: selected,
    onAction: (e) => setSelected(e),
    options: filters
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={t("General.sales_orders")} drop={dropSelection} btnList={allowed([AllRoles.REPORTING.SALES], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            searchVal={globalListQuery.salesListQuery?.searchValue}
            ListData={orders?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            rowSelection={rowSelection}
            ListCol={colName}
            field1={outlets}
            field2={orderTypes}
            field3={statuses}
            field4={nonSalesFilters}
            field5={tenderTypes}
            field6={tenderFilters}
            field7={cogsFilters}
            pagination={{
              total: orders?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}