import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import AddForm from '../components/AddForm';
import { addProductMenu } from "../../ducks/services"
import { useTranslate } from 'Translate';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const i18n = useTranslate();
  const { t } = i18n;

  return (
    <Row gutter={[20, 30]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>{t("Product.new_product_menu")}</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/product/menu')}>{'<'} {t("General.back")}</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          loading={props.loading}
          setLoading={props.setLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          oneTab={true}
          mode={'add'}
          addProductMenu={addProductMenu}
          navigate={navigate}
          reloadApi={(propId) => navigate(`/product/menu/${propId}`)}
          allowedEdit={true}
        />
      </Col>
    </Row>
  )
}