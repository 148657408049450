import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Typography, Button, Space, message } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOutletOption } from '../ducks/actions'
import { addOutlet } from '../ducks/services'
import AddForm from '../components/AddForm';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const meta = useSelector((state) => state.outlet.option);
  const [activeTab, setActiveTab] = useState("1");
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    dispatch(getOutletOption(props.setLoading))
  }, []);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>{t("Outlet.new_outlet")}</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/outlets')}>{'<'} {t("General.back")}</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          loading={props.loading}
          setLoading={props.setLoading}
          meta={meta}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          navigate={navigate}
          addOutlet={addOutlet}
          mode={'add'}
          oneTab={true}
          reloadApi={(oid) => navigate(`/outlets/${oid}`)}
          allowedEdit={true}
        />
      </Col>
    </Row>
  )
}