import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Space, Button, Typography, Table, Spin } from 'antd';
import ListCard from 'Molecules/ListCard';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesOrderList } from '../../../../../ducks/actions'
import { getSalesOrderItemList } from '../../../../../../Reporting/ducks/services'
import { Popup } from 'Atoms/Popup';
import ActionButton from 'Molecules/ActionButton';
import { LoadingOutlined } from '@ant-design/icons';
import Search from './Search';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

const antIcon = <LoadingOutlined spin />;

function comma(nStr) {
  nStr += '';
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}


const { Title, Text } = Typography;

export default (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.crm.salesOrders);
  const meta = useSelector((state) => state.crm.salesOrdersOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [searchVal, setSearchVal] = useState(null);
  const [visible, setVisible] = useState(false);
  const [rec, setRecord] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [orderTypes, setOrderTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [saleItemList, setSaleItemList] = useState([])
  const [loading, setLoading] = useState(false);
  const i18n = useTranslate();
    const { t } = i18n;

  const colName = [
    {
      title: t("Outlet.outlet"),
      dataIndex: 'location_code',
      key: 'location_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Delete.order_type"),
      dataIndex: 'order_type_desc',
      key: 'order_type_desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.business_date"),
      dataIndex: 'report_date',
      key: 'report_date',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Inventory.bill_no"),
      dataIndex: 'order_no',
      key: 'order_no',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Inventory.gross_amt"),
      dataIndex: 'sub_total_excluded_tax',
      key: 'sub_total_excluded_tax',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Dashboard.discount"),
      dataIndex: 'discount_amount',
      key: 'discount_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Crm.net_sales"),
      dataIndex: 'net_sales',
      key: 'net_sales',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Inventory.tax_amt"),
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Dashboard.charges"),
      dataIndex: 'delivery_charge',
      key: 'delivery_charge',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Delete.voucher"),
      dataIndex: 'voucher_amount',
      key: 'voucher_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Crm.adj_amt"),
      dataIndex: 'adjustment_amount',
      key: 'adjustment_amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("Crm.net_total"),
      dataIndex: 'amount',
      key: 'amount',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) => `${comma(text)}`
    },
    {
      title: t("General.status"),
      dataIndex: 'status',
      key: 'status',
      className: 'enable-click',
      align: 'right',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Paid" ? "c-success" : "c-danger"}>
            {text === "Paid" ? "Completed" : text}
          </span>
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="sales_order"
          btnAction1={() => {
            setVisible(true);
            setRecord(record);
          }}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const colName2 = [
    {
      title: t("General.qty_dot"),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      className: 'valign-top',
      width: 30
    },
    {
      title: t("General.item"),
      dataIndex: 'name',
      key: 'name',
      className: 'valign-top',
      render: (text, record) => <>
        {record.quantity > 1 ? `${text} @${displayTax(rec) ? record.price : record.price_excluded_tax}` : text}
        <br />
        {record.parts.map((x, ind) => {
          return (
            <Row justify={'space-between'} align={'middle'} key={ind}>
              <Space size={15}>
                <span>{x.quantity || record.quantity}</span>
                <span>{x.name}</span>
              </Space>
            </Row>
          )
        })}
      </>
    },
    {
      title: 'RM',
      dataIndex: 'amount_excluded_parts',
      key: 'amount_excluded_parts',
      align: 'right',
      className: 'valign-top',
      width: 105,
      render: (text, record) => <>
        {comma(displayTax(rec) ? text : record?.amount_excluded_parts_excluded_tax)}
        <br />
        {record.parts.map((x, ind) => {
          return (
            <div className='text-right' key={ind}>
              {record.name?.length > 39 && <br />}
              { displayTax(rec)
                ? parseFloat(x.amount)
                  ? <span>{comma(x.amount)}</span>
                  : <span className='invisible'>-</span>
                : parseFloat(x.amount_excluded_tax)
                  ? <span>{comma(x.amount_excluded_tax)}</span>
                  : <span className='invisible'>-</span>
              }
            </div>
          )
        })}
      </>
    },
  ];

  const displayTax = (salesOrder) => {
    if (salesOrder.currency_code == "IDR" && salesOrder.is_fa === "1" && ["GRAB_FOOD", "GRAB_FOOD_PICKUP"].includes(salesOrder.order_type_code)) {
      return true;
    } else {
      return salesOrder.is_non_sales == "0" && salesOrder.tax_inclusive == "1" && (orders?.tax_display_inclusive || parseFloat(salesOrder.discount_amount) > 0);
    }
  }

  const popup = {
    closable: false,
    visibility: visible,
    content:
      <Space direction='vertical' size={20} className='w-100'>
        <Title level={4} className='mb-0'>
          {t("Crm.sales_order_detail")} - {rec?.order_no}
        </Title>
        <Row gutter={[10, 10]}>
          {rec?.organization?.receipt_hide_logo === "0" &&
            <Col span={24} className='text-center'>
              <img src={rec?.organization?.image_url} alt="Logo" width='100px' />
            </Col>
          }
          <Col span={24} className='text-center'>
            <Title level={5} className='text-center mb-0'>
              {rec?.organization?.receipt_header_desc?.map((x, ind) => (<div key={ind} className={x === "" ? "mt-1" : ""}>{x}</div>))}
            </Title>
          </Col>
          <Col span={24} className='text-center'>
            *** {rec?.order_type_desc} *** {rec?.collection_no && `- ${rec?.collection_no}`}
          </Col>
          <Col span={24}>
            <Spin indicator={antIcon} size="large" spinning={loading}>
              <Table
                className='sales-table'
                dataSource={saleItemList?.map((x, ind) => ({ ...x, key: `Child Item ${x.id} - ${ind}` }))}
                columns={colName2}
                pagination={false}
                summary={() => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} className="valign-top text-center">
                          <Text>{rec?.items_quantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} className="valign-top">
                          <Text>{t("Crm.subtotal")}</Text>
                          <br />
                          {
                            parseFloat(rec?.discount_amount) > 0 &&
                            <>
                              <Text>{t("Delete.discount")} {`(${rec?.discount_desc})`}</Text>
                              <br />
                              {
                                rec?.discount_remark &&
                                <>
                                  <Text>{rec?.discount_remark}</Text>
                                  <br />
                                </>
                              }
                            </>
                          }
                          {
                            rec?.tax_condition_items?.filter(x => displayTax(rec) ? x.taxable === "0" : x.taxable != null).map((x, ind) => {
                              return (<React.Fragment key={ind}>
                                <Text>{x.desc}</Text>
                                <br />
                              </React.Fragment>)
                            })
                          }
                          {
                            parseFloat(rec?.voucher_amount) > 0 &&
                            <>
                              <Text>{t("Delete.voucher")} {`(${rec?.voucher_name})`}</Text>
                              <br />
                            </>
                          }
                          {
                            parseFloat(rec?.adjustment_amount) !== 0 &&
                            <>
                              <Text>{t("Crm.rounding_adj")}</Text>
                              <br />
                            </>
                          }
                          <Text>{t("Crm.net_total")}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="valign-top text-right">
                          <Text>
                            {
                              displayTax(rec) ? rec?.sub_total : rec?.sub_total_excluded_tax
                            }
                          </Text>
                          <br />
                          {
                            parseFloat(rec?.discount_amount) > 0 &&
                            <>
                              <Text>-{comma(rec?.discount_amount)}</Text>
                              <br />
                              {
                                rec?.discount_remark &&
                                <>
                                  <Text/>
                                  <br />
                                </>
                              }
                            </>
                          }
                          {
                            rec?.tax_condition_items?.filter(x => displayTax(rec) ? x.taxable === "0" : x.taxable != null)?.map((x, ind) => {
                              return (<React.Fragment key={ind}>
                                <Text>{comma(x.total_tax_amount)}</Text>
                                <br />
                              </React.Fragment>)
                            })
                          }
                          {
                            parseFloat(rec?.voucher_amount) > 0 &&
                            <>
                              <Text>-{comma(rec?.voucher_amount)}</Text>
                              <br />
                            </>
                          }
                          {
                            parseFloat(rec?.adjustment_amount) !== 0 &&
                            <>
                              <Text>{comma(rec?.adjustment_amount)}</Text>
                              <br />
                            </>
                          }
                          <Text>{rec?.amount}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2} className="valign-top">
                          <Space direction='vertical' size={5}>
                            {
                              rec?.payments?.map((x, ind) => (
                                <Text key={ind}>{x.payment_method_desc}</Text>
                              ))
                            }
                            {
                              parseFloat(rec?.change_amount) > 0 &&
                              <Text>{t("Crm.change")} {rec?.currency_code}</Text>
                            }
                          </Space>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0} colSpan={1} className="text-right">
                          <Space direction='vertical' size={5}>
                            {
                              rec?.payments?.map((x, index) => (
                                <Text key={index}>{comma(x.amount)}</Text>
                              ))
                            }
                            {
                              parseFloat(rec?.change_amount) > 0 &&
                              <Text>{comma(rec?.change_amount)}</Text>
                            }
                          </Space>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2} className="valign-top">
                          <Row justify={'space-between'} align={'middle'}>
                            <Col>
                              <Text>{t("Crm.tax_summary")}</Text>
                              {
                                rec?.tax_condition_items?.filter(x => x.taxable == "1").map((x, ind) => {
                                  return (
                                    <Fragment key={ind}>
                                      <br />
                                      <Text>{x.desc}</Text>
                                    </Fragment>
                                  )
                                })
                              }
                            </Col>
                            <Col>
                              <Text>{t("Crm.taxable")}</Text>
                              {
                                rec?.tax_condition_items?.filter(x => x.taxable == "1").map((x, ind) => {
                                  return (
                                    <Fragment key={ind}>
                                      <br />
                                      <Text>{comma(rec?.net_sales)}</Text>
                                    </Fragment>
                                  )
                                })
                              }
                            </Col>
                          </Row>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="valign-top text-right">
                          <Text>{t("General.tax")}</Text>
                          {
                            rec?.tax_condition_items?.filter(x => x.taxable == "1").map((x, ind) => {
                              return (
                                <Fragment key={ind}>
                                  <br />
                                  <Text>{comma(x?.total_tax_amount)}</Text>
                                </Fragment>
                              )
                            })
                          }
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4} className="valign-top">
                          <Title level={5} className='text-center mb-0'>
                            {rec?.organization?.receipt_footer_desc?.map((x, ind) => (<div key={ind} className={x === "" ? "mt-1" : ""}>{x}</div>))}
                          </Title>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      {rec?.organization?.qr_url &&
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={4} className="valign-top text-center">
                            <img src={rec?.organization?.qr_url} alt="Logo" width='100px' />
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      }
                    </>
                  )
                }}
              />
            </Spin>
          </Col>
        </Row>
        <Row gutter={10} justify={'center'}>
          <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
            setVisible(false);
            setRecord();
          }}>{t("General.close")}</Button></Col>
        </Row>
      </Space>
    ,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setRecord();
    },
  };

  useEffect(() => {
    dispatch(getSalesOrderList(1, limit, '', '', null, props.setLoading, id));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }

      if ("order_types" in meta) {
        let temp2 = [];
        
        meta.order_types?.map((x, ind) => {
          if(ind === 0) {
            temp2.push({
              label: t("Crm.all_order_types"),
              value: ""
            });
          }

          temp2.push({
            label: x.desc,
            value: x.id
          })
        });
        setOrderTypes(temp2);
      }

      if ("statuses" in meta) {
        let temp3 = [];
        
        meta.statuses?.map((x, ind) => {
          if(ind === 0) {
            temp3.push({
              label: t("General.all_status"),
              value: ""
            });
          }

          temp3.push({
            label: x === 'paid' ? 'Completed' : x,
            value: x
          })
        });
        setStatuses(temp3);
      }
    }
  }, [meta]);

  useEffect(() => {
    if (rec && rec?.id) {
      setLoading(true);
      getSalesOrderItemList({ sales_order_id: rec?.id }).then(res => {
        if (res.data.code === 200) {
          setSaleItemList(res.data.data?.list)
        } else {
          message.error(res.data.message);
        }
      }).catch(() => {
        message.error(res.data.message);
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [rec]);

  useEffect(() => {
    if (saleItemList) {
      const itemNameColArr = document.querySelectorAll('.itemNameCol')
      const itemPriceColArr = document.querySelectorAll('.itemPriceCol')

      if (itemNameColArr && itemNameColArr.length) {
        itemNameColArr.forEach((item, i) => {
          itemPriceColArr[i].style.height = `${item.offsetHeight}px`
        })
      }
    }
  }, [saleItemList]);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getSalesOrderList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading, id));
    } else {
      dispatch(getSalesOrderList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading, id));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search && 'outlet' in search) {
      let searching = {};
      let temp = [];
      if (search?.outlet?.length > 0) {
        search?.outlet?.map(x => {
          temp.push(x.value)
        });
      }
      searching = {
        order_no: search.reference,
        start_date: search.start_date ? dayjs(search.start_date).format("YYYY-MM-DD") : "",
        end_date: search.end_date ? dayjs(search.end_date).format("YYYY-MM-DD") : "",
        outlet: JSON.stringify(temp),
        order_type: search.order_type.value,
        status: search.status.value
      };
      setSearchVal(searching);
      dispatch(getSalesOrderList(1, limit, '', '', searching, props.setLoading, id));
    } else {
      setSearchVal(null);
      dispatch(getSalesOrderList(1, limit, '', '', null, props.setLoading, id));
    }
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setVisible(true);
          setRecord(record);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            Search={Search}
            onSearch={onSearch}
            ListData={orders?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            field1={outlets}
            field2={orderTypes}
            field3={statuses}
            pagination={{
              total: orders?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}