import React, { useEffect } from 'react';
import { Button, Form, Row, Col, Typography } from 'antd';
import { SelectField, DateField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import DateRangeDropdown from 'Modules/Application/components/DateRangeDropdown';
import { useTranslate } from 'Translate';

const { Title } = Typography;

export default (props) => {

  const { searchTitle } = props;
  const { control, handleSubmit, setValue, getValues } = useForm();
  const i18n = useTranslate();
        const { t } = i18n;

  useEffect(() => {
    if(props.field1 && !getValues('outlet')?.label) {
      setValue('outlet', { label: props.field1[0]?.label, value: props.field1[0]?.value});
    }
    if (!getValues().start_date) {
      setValue('start_date', dayjs());
      setValue('dateRange', { label: t("General.today"), value: 'today' });
    }
    if (!getValues().end_date) {
      setValue('end_date', dayjs());
    }
  }, [props])

  const onSubmit = (val) => {
    props.onSearch(val);
  }

  return (
    <Form onFinish={handleSubmit(onSubmit)} layout="inline" className='w-100'>
      <Row className='w-100' gutter={[10, 10]}>
        <Col flex='auto'>
          <SelectField
            fieldname='outlet'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: t("General.outlet") }}
            selectOption={props.field1}
          />
        </Col>
        <Col flex='auto'>
          <SelectField
            fieldname='products'
            label=''
            class='mb-0 w-100 default-select'
            initValue={''}
            control={control}
            iProps={{ placeholder: 'Item', isMulti: true }}
            selectOption={props.field2}
          />
        </Col>
        <Col flex="auto">
          <SelectField
            fieldname='non_sales'
            label=''
            class='mb-0 w-100 default-select'
            initValue={{ label: "Non Sales: All", value: "all" }}
            control={control}
            selectOption={[{
              label: "Non Sales: All",
              value: "all"
            }, {
              label: "Non Sales: No",
              value: "no"
            }, {
              label: "Non Sales: Yes",
              value: "yes"
            }]}
            iProps={{ placeholder: 'Is Non Sales' }}
          />
        </Col>
        <DateRangeDropdown control={control} onSetValue={setValue} placeholderValue={t("Inventory.business_date")} isRequired={false} />
        <Col flex="70px">
          <Button className='w-100' size='large' type='primary' htmlType='submit'>{t("General.search")}</Button>
        </Col>
      </Row>
      {searchTitle &&
        <Row className='mt-1'>
          <Col>
            <Title level={4} className='mb-0 c-gray'> {">"} {searchTitle}</Title>
          </Col>
        </Row>
      }
    </Form>
  )
}