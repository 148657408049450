import React from "react";
import Icon from '@ant-design/icons';

const ScholarshipSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
        <path d="M160.909,304.51a3.17,3.17,0,0,0-3.074-2.18H144.2a3.368,3.368,0,0,0-1.928.61,2.921,2.921,0,0,0-1.135,1.57,3.256,3.256,0,0,0,.642,2.9,9.021,9.021,0,0,0,3.235,2.56,5.5,5.5,0,0,0,4.577,4.74v2.08h-2.845a.98.98,0,0,0-1.044.9v3.73a.981.981,0,0,0,1.044.91H155.3a.981.981,0,0,0,1.044-.91v-3.73a.98.98,0,0,0-1.044-.9h-2.845v-2.06a5.531,5.531,0,0,0,4.589-4.77,8.967,8.967,0,0,0,3.224-2.55A3.3,3.3,0,0,0,160.909,304.51Zm-15.923,3.26c-1.606-1.08-2.03-2.12-1.835-2.81a1.128,1.128,0,0,1,1.044-.81h.791Zm12.068,0v-3.62h.781a1.142,1.142,0,0,1,1.055.81C159.085,305.65,158.66,306.69,157.054,307.77Z" transform="translate(-141.018 -302.33)"/>
    </svg>
  );

  const ScholarshipIcon = props => <Icon component={ScholarshipSvg} {...props} />;

  export default ScholarshipIcon;


