import axios from 'Services/axiosInterceptor';
import * as action_types from './constants';
import { externalBaseUrl } from '../../../../configs/constants';

export const getCustomerList = (page, limit, order, orderby, search = {}, load) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/customers/list`, {
      q: JSON.stringify({
        first_name_or_last_name_or_email_cont: search?.name,
        s: `${orderby} ${ordering}`
      }),
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.CRM_CUSTOMER_LIST,
      data: data
    });
  };
};

export const getCustomer = (id, load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/customers/get`, {
      user_id: id
    });
    load && load(false);
    dispatch({
      type: action_types.CRM_CUSTOMER_DETAIL,
      data: data,
      meta: meta
    });
  };
};

export const getCustomerOption = (load) => {
  load && load(true);
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.post(`${externalBaseUrl}/customers/option`);
    load && load(false);
    dispatch({
      type: action_types.CRM_CUSTOMER_OPTION,
      data: data
    });
  };
};

export const getSalesOrderList = (page, limit, order, orderby, search = {}, load, id) => {
  load && load(true);
  let ordering = '';
  if (order == "ascend") {
    ordering = 'ASC'
  } else if (order == "descend") {
    ordering = 'DESC'
  }
  return async (dispatch) => {
    const {
      data: { data, meta },
    } = await axios.post(`${externalBaseUrl}/sales-orders/list`, {
      q: JSON.stringify({
        order_no_cont: search?.order_no,
        order_type_id_eq: search?.order_type,
        report_date_gteq: search?.start_date,
        report_date_lteq: search?.end_date,
        user_id_eq: id,
        status_eq: search?.status,
        s: `${orderby} ${ordering}`
      }),
      outlets: search?.outlet,
      page: page,
      limit: limit
    });
    load && load(false);
    dispatch({
      type: action_types.CRM_SALES_ORDER_LIST,
      data: data,
      meta: meta
    });
  };
};
