import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import Vendor from 'Modules/Inventory/Vendor';
import AddVendor from 'Modules/Inventory/Vendor/AddVendor';
import EditVendor from 'Modules/Inventory/Vendor/EditVendor';
import PurchaseOrder from 'Modules/Inventory/PurchaseOrder';
import AddPurchaseOrder from 'Modules/Inventory/PurchaseOrder/AddPurchaseOrder';
import EditPurchaseOrder from 'Modules/Inventory/PurchaseOrder/EditPurchaseOrder';
import GoodReceive from 'Modules/Inventory/GoodReceive';
import AddGoodReceive from 'Modules/Inventory/GoodReceive/AddGoodReceive';
import EditGoodReceive from 'Modules/Inventory/GoodReceive/EditGoodReceive';
import TransferOut from 'Modules/Inventory/TransferOut';
import AddTransferOut from 'Modules/Inventory/TransferOut/AddTransferOut';
import EditTransferOut from 'Modules/Inventory/TransferOut/EditTransferOut';
import TransferIn from 'Modules/Inventory/TransferIn';
import AddTransferIn from 'Modules/Inventory/TransferIn/AddTransferIn';
import EditTransferIn from 'Modules/Inventory/TransferIn/EditTransferIn';
import StockWastage from 'Modules/Inventory/StockWastage';
import AddStockWastage from 'Modules/Inventory/StockWastage/AddStockWastage';
import EditStockWastage from 'Modules/Inventory/StockWastage/EditStockWastage';
import StockAdjustment from 'Modules/Inventory/StockAdjustment';
import AddStockAdjustment from 'Modules/Inventory/StockAdjustment/AddStockAdjustment';
import EditStockAdjustment from 'Modules/Inventory/StockAdjustment/EditStockAdjustment';
import StockTemplate from 'Modules/Inventory/StockTemplate';
import AddStockTemplate from 'Modules/Inventory/StockTemplate/AddStockTemplate';
import EditStockTemplate from 'Modules/Inventory/StockTemplate/EditStockTemplate';
import StockTake from 'Modules/Inventory/StockTake';
import AddStockTake from 'Modules/Inventory/StockTake/AddStockTake';
import EditStockTake from 'Modules/Inventory/StockTake/EditStockTake';
import StockMovement from 'Modules/Inventory/StockMovement';
import StockConversion from 'Modules/Inventory/StockConversion';
import AddStockConversion from 'Modules/Inventory/StockConversion/AddStockConversion';
import EditStockConversion from 'Modules/Inventory/StockConversion/EditStockConversion';

const Components = {
  Vendor,
  AddVendor,
  EditVendor,
  PurchaseOrder,
  AddPurchaseOrder,
  EditPurchaseOrder,
  GoodReceive,
  AddGoodReceive,
  EditGoodReceive,
  TransferOut,
  AddTransferOut,
  EditTransferOut,
  TransferIn,
  AddTransferIn,
  EditTransferIn,
  StockWastage,
  AddStockWastage,
  EditStockWastage,
  StockAdjustment,
  AddStockAdjustment,
  EditStockAdjustment,
  StockTemplate,
  AddStockTemplate,
  EditStockTemplate,
  StockTake,
  AddStockTake,
  EditStockTake,
  StockMovement,
  StockConversion,
  AddStockConversion,
  EditStockConversion
};

export default (props) => {
  const VendorComp = Components[props.Comp];
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading} setLoading={setLoading}>
      <VendorComp loading={loading} setLoading={setLoading} />
    </Dashboard>
  );
};