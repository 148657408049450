import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Empty, message, Space, Tooltip } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getBillSummary } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const i18n = useTranslate();
      const { t } = i18n;
      
  var cols = [
    {
      title: t("General.business_date"),
      dataIndex: 'report_date',
      key: 'report_date',
      width: "150px",
      sortable: true
    },
    {
      title: t("Outlet.outlet"),
      dataIndex: 'location_code',
      key: 'location_code',
      width: "150px",
      sortable: true
    },
    {
      title: t("Inventory.bill_no"),
      dataIndex: 'order_no',
      key: 'order_no',
      width: "150px",
      sortable: true
    },
    {
      title: t("SalesReporting.trans_count"),
      dataIndex: 'trans_count',
      key: 'trans_count',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: t("SalesReporting.guest_count"),
      dataIndex: 'total_pax',
      key: 'total_pax',
      align: 'right',
      width: "150px",
      comma: true,
      sortable: true
    },
    {
      title: t("Inventory.gross_amt"),
      dataIndex: 'gross_amount',
      key: 'gross_amount',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: t("Delete.discount"),
      dataIndex: 'discount_amount',
      key: 'discount_amount',
      align: 'right',
      comma: true
    },
    {
      title: t("Crm.net_sales"),
      dataIndex: 'net_sales',
      key: 'net_sales',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: t("General.tax"),
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      align: 'right',
      comma: true
    },
    {
      title: t("Inventory.inc_tax_amt"),
      dataIndex: 'inclusive_tax_amount',
      key: 'inclusive_tax_amount',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: t("Inventory.exc_tax_amt"),
      dataIndex: 'exclusive_tax_amount',
      key: 'exclusive_tax_amount',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: t("Delete.voucher"),
      dataIndex: 'voucher_amount',
      key: 'voucher_amount',
      align: 'right',
      comma: true
    },
    {
      title: t("Dashboard.charges"),
      dataIndex: 'delivery_charge',
      key: 'delivery_charge',
      align: 'right',
      comma: true
    },
    {
      title: t("SalesReporting.adj_amt"),
      dataIndex: 'adjustment_amount',
      key: 'adjustment_amount',
      align: 'right',
      width: "150px",
      comma: true
    },
    {
      title: t("Crm.net_total"),
      dataIndex: 'net_total',
      key: 'net_total',
      align: 'right',
      width: 120,
      comma: true
    }
  ];

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.billSummary);
  const meta = useSelector((state) => state.reporting.billSummaryOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [colName, setColName] = useState(cols);

  const btnList = [
    {
      text: t("General.export_report"),
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getBillSummary(searchVal, props.setLoading, "1"));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} - ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
    if (orders?.headers) {
      let temp = cols;
      orders?.fe_cols?.map(x => {
        temp.push({
          title: x.label,
          dataIndex: x.key,
          key: x.key,
          align: 'right',
          width: "120px",
          comma: true
        })
      });

      setColName(temp);
    }
  }, [meta, orders]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      searching = {
        is_non_sales: search?.is_non_sales?.value,
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
      };
      if (search?.outlets?.length > 0) {
        let temp2 = [];
        search?.outlets?.map(x => {
          temp2.push(x.value)
        });
        searching["location_ids"] = JSON.stringify(temp2);
      }
      setSearchVal(searching);
      dispatch(getBillSummary(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getBillSummary(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (orders?.total_count === 0) {
      return message.warning(t("General.no_data_export"));
    }
    props.setLoading(true);

    await exportSalesReport(searchVal, "bill_summary").then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const onTableChange = (col) => {
    if (!col.sortable) return;
    if (orders?.list?.length <= 0) return;

    let orderBy = col.dataIndex;
    let order = '';
    let searching = searchVal || {};
    if(searching["s"]) {
      orderBy = searching["s"].split(" ")[0];
      order = searching["s"].split(" ")[1];

      if(orderBy !== col.dataIndex) {
        order = '';
        orderBy = col.dataIndex;
      }
    }

    order = order == "asc" ? "desc" : order == "desc" ? "" : "asc";

    if (order) {
      searching["s"] = `${orderBy} ${order}`;
    } else {
      searching["s"] = null;
    }
    setSearchVal(searching);
    dispatch(getBillSummary(searchVal, props.setLoading));
  };

  const tableHeader = (x) => {
    let searchCol = "";
    let searchOrder = "";
    let className = x.align == "right" ? "text-right" : "";
    if (x.sortable) className += " pointer";
    if (searchVal && "s" in searchVal && searchVal["s"]) {
      searchCol = searchVal["s"].split(" ")[0]
      searchOrder = searchVal["s"].split(" ")[1]
    }
    let upHighlighted = x.dataIndex == searchCol && searchOrder == "asc" ? "blue" : "rgba(0, 0, 0, 0.29)";
    let downHighlighted = x.dataIndex == searchCol && searchOrder == "desc" ? "blue" : "rgba(0, 0, 0, 0.29)";

    return (
      <Tooltip title="Click to sort" overlayClassName={!x.sortable ? "d-none" : ""} key={x.key}>
        <th className={className} style={{ minWidth: x.width && x.width }} onClick={() => onTableChange(x)}>
          <Row justify={'space-between'} align={'middle'}>
            <span>{x.title}</span>
            {
              x.sortable &&
              <span
                style={{ display: "inline-flex", flexDirection: "column", alignItems: "center" }}
                className="ant-table-column-sorter-inner" aria-hidden="true"
              >
                <span role="img" aria-label="caret-up" className="anticon anticon-caret-up ant-table-column-sorter-up">
                  <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em" height="1em" fill={upHighlighted} aria-hidden="true">
                    <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                  </svg>
                </span>
                <span role="img" aria-label="caret-down" className="anticon anticon-caret-down ant-table-column-sorter-down">
                  <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em" height="1em" fill={downHighlighted} aria-hidden="true">
                    <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                  </svg>
                </span>
              </span>
            }
          </Row>
        </th>
      </Tooltip>
    )
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={t("General.bill_sum_report")} btnList={allowed([AllRoles.REPORTING.SALES_PAYMENT], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} />

            <div className="table-responsive" style={{ overflowX: 'auto', marginTop: "30px" }}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {
                      colName.map((x) => tableHeader(x))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="14">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.length > 0 &&
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          {
                            x.list?.map((y, yIndex) => {
                              return (
                                <tr key={yIndex}>
                                  {
                                    colName.map((x) => (
                                      <td key={x.key} align={x.align} style={{ minWidth: x.width && x.width }}>
                                        {y.comma ? comma(y[x.dataIndex]) : y[x.dataIndex]}
                                      </td>
                                    ))
                                  }
                                </tr>
                              )
                            })
                          }
                          <tr className='bg-gray text-white fwbold'>
                            <td align='' colSpan="3">{t("Crm.subtotal")}</td>
                            {
                              x?.sub_totals && Object.entries(x?.sub_totals)?.map(([key, data], zIndex) => {
                                return (
                                  <td key={zIndex} align='right'>{comma(data)}</td>
                                )
                              })
                            }
                          </tr>
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                        ? <td colSpan="99">{t("SalesReporting.grand_total")}</td>
                        : <td align='' colSpan="3">{t("SalesReporting.grand_total")}</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}