import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { InputCheckbox } from 'Atoms/FormElement';
import PermissionCheckbox from '../PermissionCheckbox';

const { Panel } = Collapse;

export default (props) => {

    const { item, control, setValue, getValues, onCheckAll, data, resetNeutral, childNeutral, hidePermissions } = props;
    const [neutral, setNeutral] = useState(false);

    useEffect(() => {
      if(data && data.length) {
        let n = false;
        item.screens.forEach(x => {
          if(n == false) {
            let parent = data.find(z => z.name === item.label);
            if(parent) {
              if (parent.all == 1) {
                n = false;
              }else {
                let permissions = parent?.permissions
                if (permissions) {
                  let d = permissions.find(z => x.role == z.permission_name);
                  if (d) {
                    if (d.read == 1 || d.delete == 1 || d.write == 1) {
                      n = true;
                    }
                  }
                }
              }
            }
          }
        });
        setNeutral(n);
      }else {
        setNeutral(false);
      }
    }, [data]);

    useEffect(() => {
      if(resetNeutral && resetNeutral > 0) {
        setNeutral(false);
      }
    }, [resetNeutral])

    const onCheckParent = (e, screen, item) => {
        if (e == true) {
          setValue(`${screen}-read`, [1]);
          setValue(`${screen}-write`, [1]);
          setValue(`${screen}-delete`, [1]);
          let val = true;
          let n = false
          item.screens.map(x => {
            if(getValues(`${x.role}`) == false) {
              val = false
            } else {
              n = true;
            }
          })
          setValue(`${item.module}`, val)
          if (val == false && n == true) {
            setNeutral(true);
          } else {
            setNeutral(false);
          }
        } else {
          setValue(`${screen}-read`, []);
          setValue(`${screen}-write`, []);
          setValue(`${screen}-delete`, []);
          setValue(`${item.module}`, false);
          let n = false
          let val = true;
          item.screens.map(x => {
            if(getValues(`${x.role}`) == false) {
              val = false
            } else {
              n = true;
            }
          })
          if (val == false && n == true) {
            setNeutral(true);
          } else {
            setNeutral(false);
          }
        }
      };

    return (
        <Col span={24}>
            <Collapse
            className="custom-collapse"
            bordered={false}
            collapsible={'header'}
            expandIcon={({ isActive }) =>
                !isActive ? <PlusOutlined /> : <MinusOutlined />
            }
            >
                <Panel
                    forceRender={true}
                    style={{ border: '0px' }}
                    header={item.label}
                    extra={
                    <InputCheckbox
                        fieldname={`${item.module}`}
                        label=""
                        class="mb-0 fullWidth-checbox"
                        control={control}
                        intr={neutral}
                        initValue={false}
                        onChange={(e) => {onCheckAll(e, item.screens); setNeutral(false)}}
                    />
                    }
                >
                    <Row gutter={[20,20]}>
                    {item.screens.map((x,j) => (
                    <Fragment key={j}>
                      <PermissionCheckbox 
                        item={item} 
                        x={x} 
                        onCheckParent={onCheckParent} 
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        setNeutralP={setNeutral}
                        data={data}
                        resetNeutral={resetNeutral}
                        childNeutral={childNeutral}
                        hidePermissions={hidePermissions}
                      />
                    </Fragment>
                    ))}
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    )
}