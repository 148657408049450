import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, message, Upload, Space, Typography, Form, Button, Radio } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListCard from 'Molecules/ListCard';
import DndListCard from 'Molecules/DndListCard';
import Search from './components/Search';
import { getMenuItems, getMenuCategory, getAvailableProducts, getKioskValue } from '../../ducks/actions';
import { addMenuItem, removeMenuItem, editMenuCategory, updateMenuItemOrder } from '../../ducks/services';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { dummyRequest, getFileName, uploadFileV2 } from 'Features/utility';
import { InputField } from 'Atoms/FormElement';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import AllRoles from '../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../routing/config/utils';

import { useLocation } from 'react-router-dom';
import { useTranslate } from 'Translate';

const { Title } = Typography;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, cid } = useParams();
  const [filterVal, setFilterVal] = useState('detail');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchVal, setSearchVal] = useState(null);
  const menuItem = useSelector((state) => state.product.menuItem);
  const availableProduct = useSelector((state) => state.product.availableProduct);
  const menuCategoryDetail = useSelector((state) => state.product.menuCategoryDetail);
  const menuCategoryMeta = useSelector((state) => state.product.menuCategoryMeta);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();
  const [hasDirty, setHasDirty] = useState(false);
  const [data, setData] = useState([]);
  const kioskValue = useSelector((state) => state.product.kioskValue);
  const allowedEdit = allowed([AllRoles.PRODUCT.MENU], 'write');

  const query = useQuery();
  const paramValue = query.get('isKiosk'); 
  const i18n = useTranslate();
  const { t } = i18n;

  const filters = [
    {
      label: t("General.details"),
      value: 'detail',
    },
    {
      label: t("General.all"),
      value: 'all',
    },
    {
      label: t("General.active"),
      value: 'active',
    },
  ];

  // menu items
  const ListCol = [
    {
      title: t("Outlet.product_code"),
      dataIndex: 'product_code',
      key: 'product_code',
      sorter: true,
    },
    {
      title: t("Product.product_name"),
      dataIndex: 'product_name',
      key: 'product_name',
      sorter: true,
    },
    {
      title: t("Product.sales_qty"),
      dataIndex: 'sales_qty',
      key: 'sales_qty',
      sorter: true,
      align: 'right',
    }
  ];

  // products
  const ListCol2 = [
    {
      title: t("Outlet.product_code"),
      dataIndex: 'code',
      key: 'code',
      sorter: true,
    },
    {
      title: t("Product.product_name"),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    }
  ];

  // details
  const formFields = [
    {
      name: 'desc',
      label: t("Outlet.name"),
      placeholder: t("General.pls_enter"),
      req: true,
      type: 'input',
      reqmessage: t("General.required"),
      twocol: false,
      colWidth: '1 0 100%',
      static: !allowedEdit,
    },
    {
      name: 'availability',
      label: t("Product.availability"),
      placeholder: t("General.please_select"),
      req: true,
      type: 'select',
      reqmessage: t("General.required"),
      twocol: false,
      options: menuCategoryMeta?.work_schedules?.map((x) => ({ label: x.name, value: x.id })),
      colWidth: '1 0 100%',
      class: 'default-select',
      static: !allowedEdit,
    },
    {
      name: 'valid_from',
      label: t("General.valid_from_date"),
      placeholder: t("General.please_select"),
      req: true,
      type: 'date',
      reqmessage: 'Date required',
      twocol: false,
      format: 'YYYY-MM-DD',
      colWidth: '1 0 50%',
      static: !allowedEdit,
    },
    {
      name: 'valid_from_time',
      label: t("General.time"),
      placeholder: t("General.please_select"),
      req: true,
      type: 'time',
      reqmessage: 'Time required',
      twocol: false,
      format: 'HH:mm',
      colWidth: '1 0 50%',
      static: !allowedEdit,
    },
    {
      name: 'valid_to',
      label: t("General.valid_to_date"),
      placeholder: t("General.please_select"),
      // req: true,
      type: 'date',
      reqmessage: 'Date required',
      twocol: false,
      format: 'YYYY-MM-DD',
      colWidth: '1 0 50%',
      static: !allowedEdit,
    },
    {
      name: 'valid_to_time',
      label: t("General.time"),
      placeholder: t("General.please_select"),
      // req: true,
      type: 'time',
      reqmessage: 'Time required',
      twocol: false,
      format: 'HH:mm',
      colWidth: '1 0 50%',
      static: !allowedEdit,
    },
    {
      type: 'switch',
      name: 'auto_sort_by_sales',
      label: t("Product.auto_sort_by_sales"),
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit,
    },
    {
      type: 'switch',
      name: 'apply_promo_price',
      label: t("Product.apply_promo_price"),
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit,
    },
    {
      type: 'switch',
      name: 'auto_populate_item_by_sales',
      label: t("Product.auto_populate_item_sales"),
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit,
    },
    {
      name: 'auto_populate_item_count',
      label: t("Product.auto_populate_item_count"),
      placeholder: t("General.pls_enter"),
      req: false,
      type: 'input',
      reqmessage: t("General.required"),
      twocol: false,
      colWidth: '1 0 50%',
      static: !allowedEdit,
    },
    {
      type: 'switch',
      name: 'is_upsell',
      label: t("Product.is_upsell"),
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: false, // move form to top
      labelPosition: 'top', // move label to top
      hidden: kioskValue === "1",
      static: !allowedEdit,
    },
    {
      type: 'switch',
      name: 'hide_kiosk',
      label: t("Product.hide_in_kiosk"),
      req: false,
      twocol: false,
      colWidth: '1 0 50%',
      alignEnd: true,
      alignEnd: false, // move form to top
      labelPosition: 'top',
      static: !allowedEdit,
      hidden: kioskValue === "2",
    },
  ];

  const rowClassName = (record, index) => {
    return availableProduct?.product_ids?.some(x => x === record?.id) ? 'highlight-row' : '';
  };

  const callApi = (status, ppage, plimit, order, orderby, search) => {
    if (status === "detail") {
      dispatch(getMenuCategory(cid, props.setLoading));
    } else if(status === "active") {
      dispatch(getMenuItems(ppage, 1000, order, orderby, search, id, cid, props.setLoading));
    } else {
      dispatch(getAvailableProducts(ppage, plimit, order, orderby, search, id, cid, props.setLoading));
    }
  }

  const onFilter = (e) => {
    setFilterVal(e.target.value);
    setPage(1);
    callApi(e.target.value, 1, limit, '', '', null);
  };

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      callApi(filterVal, pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal);
    } else {
      callApi(filterVal, pagination.current, pagination.pageSize, '', '', searchVal);
    }
  };

  const onSearch = (search) => {
    if (search) {
      let searchVal = {};
      searchVal = {
        product: search.name
      };
      setSearchVal(searchVal);
      callApi(filterVal, page, limit, '', '', searchVal);
    } else {
      setSearchVal(null);
      callApi(filterVal, page, limit, '', '', null);
    }
  };

  useEffect(() => {
    dispatch(getKioskValue(paramValue))
    callApi(filterVal, 1, limit, '', '', null);
  }, []);

  useEffect(() => {
    if(menuCategoryDetail) {
      setValue('id', menuCategoryDetail.id);
      setValue('desc', menuCategoryDetail.desc);
      setValue('valid_from', menuCategoryDetail.valid_from ? dayjs(menuCategoryDetail.valid_from, "YYYY-MM-DD") : '');
      setValue('valid_to', menuCategoryDetail.valid_to ? dayjs(menuCategoryDetail.valid_to, "YYYY-MM-DD") : '');
      setValue('valid_from_time', menuCategoryDetail.valid_from_time ? dayjs(menuCategoryDetail.valid_from_time, "HH:mm") : '');
      setValue('valid_to_time', menuCategoryDetail.valid_to_time ? dayjs(menuCategoryDetail.valid_to_time, "HH:mm") : '');
      setValue('availability', menuCategoryDetail.work_schedule_id ? { label: menuCategoryDetail.work_schedule_name, value: menuCategoryDetail.work_schedule_id } : '');
      setValue('apply_promo_price', menuCategoryDetail.apply_promo_price === "1");
      setValue('auto_sort_by_sales', menuCategoryDetail.auto_sort_by_sales === "1");
      setValue('auto_populate_item_by_sales', menuCategoryDetail.auto_populate_item_by_sales === "1");
      setValue('auto_sort_scope', menuCategoryDetail.auto_sort_scope ? { label: menuCategoryDetail.auto_sort_scope, value: menuCategoryDetail.auto_sort_scope } : '');
      setValue('auto_populate_item_count', menuCategoryDetail.auto_populate_item_count);
      setValue('is_upsell', menuCategoryDetail.is_upsell === "1");
      setValue('hide_kiosk', menuCategoryDetail.hide_kiosk === "1");
      if(menuCategoryDetail?.image_url) {
        setFileList([{ uid: '-1', name: getFileName(menuCategoryDetail?.image_url), status: 'done', url: menuCategoryDetail?.image_url }])
      } else {
        setFileList([])
      }
    }
  }, [menuCategoryDetail]);

  useEffect(() => {
    if (menuItem) {
      setData(menuItem?.list);
    }
  }, [menuItem]);

  const reloadApi = (data) => {
    // dispatch(reloadMenuItems(filterVal, data));
    callApi(filterVal, 1, limit, '', '', null);
  }

  const removeFromMenu = async () => {
    props.setLoading(true);

    const payload = {
      menu_category_id: cid,
      products: JSON.stringify(selectedRowKeys)
    }

    await removeMenuItem(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.item_remove_menu_success"));
        rowSelection.onChange([]);
        reloadApi();
      } else {
        message.error(res.data.message)
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"))
    })
  }

  const addToMenu = async () => {
    props.setLoading(true);

    const payload = {
      menu_category_id: cid,
      products: JSON.stringify(selectedRowKeys)
    }

    await addMenuItem(payload).then(res => {
      props.setLoading(false);
      if(res.data.code === 200) {
        message.success(t("Product.items_add_menu_success"));
        rowSelection.onChange([]);
        reloadApi();
      }else {
        message.error(res.data.message)
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"))
    })
  };

  const btnList = [
    {
      text: t("General.update_listing"),
      action: () => onUpdateList(data),
      classes: 'green-btn',
      btnHidden: filterVal !== "active" || !hasDirty || !allowedEdit
    },
    {
      title: t("Product.confirm_remove_select_product"),
      text: t("Product.remove_from_menu"),
      action: () => removeFromMenu(),
      classes: 'text-white',
      permit: true,
      btnHidden: selectedRowKeys?.length <= 0 || filterVal === 'all' || !allowedEdit,
      placement: 'topLeft'
    },
    {
      title: t("Product.confirm_add_select_product"),
      text: t("Product.add_to_menu"),
      action: () => addToMenu(),
      classes: 'green-btn',
      permit: true,
      btnHidden: selectedRowKeys?.length <= 0 || filterVal === 'active' || !allowedEdit,
      placement: 'topLeft'
    },
    {
      text: `< ${t("General.back")}`,
      action: () => navigate(`/product/menu/${id}?t=3`),
      classes: 'red-btn text-white'
    }
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onFinish = async (val) => {
    props.setLoading(true);

    let validToDate = '';
    let validToTime = '';

    if(val?.valid_to) validToDate = dayjs(val?.valid_to).format("YYYY-MM-DD");

    if(val?.valid_to_time) validToTime = dayjs(val?.valid_to_time).format("HH:mm");

    const payload = {
      menu_category_id: val?.id,
      valid_from: dayjs(val?.valid_from).format("YYYY-MM-DD") + " " + dayjs(val?.valid_from_time).format("HH:mm"),
      valid_to: `${validToDate} ${validToTime}`.trim(),
      work_schedule_id: val?.availability?.value,
      apply_promo_price: val?.apply_promo_price ? 1 : 0,
      desc: val?.desc,
      blob_id: fileId,
      auto_sort_by_sales: val?.auto_sort_by_sales ? 1 : 0,
      auto_sort_scope: val?.auto_sort_scope?.value,
      auto_populate_item_by_sales: val?.auto_populate_item_by_sales ? 1 : 0,
      auto_populate_item_count: val?.auto_populate_item_count,
      is_upsell: val?.is_upsell ? 1 : 0,
      hide_kiosk: val?.hide_kiosk ? 1 : 0,
    }

    await editMenuCategory(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.menu_category_update_success"));
        setTimeout(() => callApi("detail"), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const handleUpload = async (e) => {
    props.setLoading(true);
    try {
      let file = await uploadFileV2(e.file.originFileObj);
      props.setLoading(false);
      setFileId(file?.id);
      setFileList([{ uid: '-1', name: file?.filename, status: 'done', url: file?.url }]);
    } catch (error) {
      message.error(`${t("General.error_during_upload")} ${error}`);
      props.setLoading(false);
    }
  };

  const onUpdateList = async (datalist) => {
    props.setLoading(true);

    const payload = {
      menu_category_id: cid,
      menu_items: JSON.stringify(datalist?.map((d, i) => ({ id: d.id, position: i + 1 })))
    }
    await updateMenuItemOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.listing_order_update_success"));
        // setVisible(false);
        // callApi(filterVal, page, limit, '', '', null);
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Row gutter={[20, 30]}>
      <Col span={24}>
        <HeadingChip title={menuCategoryDetail.desc} btnList={btnList} />
      </Col>
      <Col span={24}>
        <Radio.Group
          size="large"
          className="radio-tabs"
          buttonStyle="solid"
          options={filters}
          onChange={onFilter}
          value={filterVal}
          optionType="button"
        />
      </Col>
      <Col span={24}>
        {
          filterVal === "detail" ?
            <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
              <Space direction='vertical' size={15} className='w-100'>
                <InputField
                  fieldname='id'
                  label=''
                  class='d-none'
                  initValue={''}
                  control={control}
                />
                <div className='text-center'>
                  <Upload
                    disabled={!allowedEdit}
                    listType="picture-card"
                    className="avatar-uploader larger"
                    showUploadList={false}
                    accept="image/*"
                    maxCount={1}
                    fileList={fileList}
                    customRequest={dummyRequest}
                    onChange={(e) => handleUpload(e)}
                  >
                    <Space size={4}>
                      {fileList?.length > 0 ? (
                        <img
                          src={fileList[0].url}
                          alt="avatar"
                          style={{
                            width: '100%',
                          }}
                        />
                      ) :
                        <div>
                          <PlusOutlined />
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            {t("General.upload")}
                          </div>
                        </div>
                      }
                    </Space>
                  </Upload>
                </div>
                <Row gutter={[10, 10]}>
                  {formFields?.map((x, idx) => (
                    <Fragment key={idx}>
                      <FormGroup item={x} control={control} errors={errors} />
                    </Fragment>
                  ))}
                </Row>
                {
                  allowedEdit &&
                  <Row gutter={10} justify={'end'}>
                    <Col span={2}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.save")}</Button></Col>
                  </Row>
                }
              </Space>
            </Form>
          :
            filterVal === "active"
            ?
              <DndListCard
                setHasDirty={setHasDirty}
                setDataList={setData}
                list={data}
                Search={Search}
                onSearch={onSearch}
                onChange={onTableChange}
                ListCol={ListCol}
                ListData={data?.map(x => ({ ...x, key: x.id }))}
                rowSelection={allowedEdit && rowSelection}
                pagination={false}
              />
            :
            <ListCard
              Search={Search}
              onSearch={onSearch}
              onChange={onTableChange}
              rowClassName={rowClassName}
              ListCol={ListCol2}
              ListData={availableProduct?.list?.map(x => ({ ...x, key: x.id }))}
              rowSelection={allowedEdit && rowSelection}
              pagination={{
                total: availableProduct?.total_count,
                current: page,
                pageSize: limit,
              }}
            />
        }
      </Col>
    </Row>
  );
};
