// STAKE ITEM
import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Tooltip, Upload } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getStakeItemList } from '../../../../../ducks/actions'
import { editStakeItem, addStakeItem, removeStakeItem, importStItemCsv } from '../../../../../ducks/services'
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined, CheckCircleTwoTone, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import Search from './Search'
import ActionButton from 'Molecules/ActionButton';
import { allowed } from '../../../../../../../../routing/config/utils';
import AllRoles from '../../../../../../../../routing/config/AllRoles';
import { saveAs } from 'file-saver';
import { dummyRequest } from 'Features/utility';
import { SwitchField } from 'Atoms/FormElement';
import { useTranslate } from 'Translate';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, activeTab, data, allowedEdit, allowedDelete, reloadCount } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues, clearErrors } = useForm();
  const { control: controlUpload, handleSubmit: handleSubmitUpload, setValue: setValueUpload } = useForm();
  const stakeItems = useSelector((state) => state.inventory.stakeItems);
  const meta = useSelector((state) => state.inventory.stakeItemsOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const editable = data?.status === "pending" && allowedEdit;
  const [dataSource, setDataSource] = useState([]);
  const isInitial = data?.is_initial === "1";
  const isNotPosted = data?.status !== "posted";
  const [isSemiProduct, setIsSemiProduct] = useState(false);
  // const [isIngredient, setIsIngredient] = useState(true);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [fileObj, setFileObj] = useState();
  const [fileList, setFileList] = useState([]);
  const [syncUnitCost, setSyncUnitCost] = useState(false)
  const i18n = useTranslate();
    const { t } = i18n;

  const colName = [
    {
      title: t("General.number_no"),
      dataIndex: 'index',
      key: 'index',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("Inventory.item_code"),
      dataIndex: 'code',
      key: 'code',
      sorter: true,
      className: 'enable-click',
    },
    {
      title: t("General.desc"),
      dataIndex: 'desc',
      key: 'desc',
      sorter: true,
      className: 'enable-click',
    },
    {
      title: t("Inventory.unit_cost"),
      dataIndex: 'unit_cost',
      key: 'unit_cost',
      sorter: true,
      className: !isInitial ? "" : 'enable-click',
      align: 'right',
      hidden: !allowed([AllRoles.INVENTORY.STOCK_TAKE], 'read') || !isInitial,
      render: (text) => <span>{text || 0}</span>,
      onCell: (record) => ({
        record,
        editable: !isInitial && isNotPosted,
        dataIndex: 'unit_cost',
        resetfield: 'true',
        title: t("Inventory.unit_cost"),
        req: [{ required: true, message: "Unit Cost is required" }],
        handleSave
      }),
    },
    {
      title: t("General.uom"),
      dataIndex: 'uom_code',
      key: 'uom_code',
      sorter: true,
      className: 'enable-click',
      render: (text, record) => record?.is_semi_product === "1" ? record?.is_ingredient === "1" ? text : "-" : text
    },
    {
      title: t("Inventory.document_qty"),
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: true,
      align: 'right',
      render: (text) => <span>{text || 0}</span>,
      onCell: (record) => ({
        record,
        editable: isNotPosted,
        dataIndex: 'quantity',
        resetfield: 'true',
        title: t("Inventory.document_qty"),
        req: [{ required: true, message: t("Inventory.document_qty_req") }],
        handleSave
      }),
    },
    {
      title: t("Inventory.system_qty"),
      dataIndex: 'system_quantity',
      key: 'system_quantity',
      sorter: true,
      className: 'enable-click',
      align: 'right',
      hidden: !allowed([AllRoles.INVENTORY.STOCK_TAKE], 'read') || isInitial,
      render: (text) => <span>{text || 0}</span>
    },
    {
      title: t("Inventory.discrepancy_qty_short"),
      dataIndex: 'discrepancy_quantity',
      key: 'discrepancy_quantity',
      sorter: true,
      className: 'enable-click',
      align: 'right',
      hidden: !allowed([AllRoles.INVENTORY.STOCK_TAKE], 'read') || isInitial,
      render: (text) => <span>{text || 0}</span>
    },
    {
      title: t("System.reason"),
      dataIndex: 'reason_code',
      key: 'reason_code',
      sorter: true,
      className: 'enable-click',
      hidden: !allowed([AllRoles.INVENTORY.STOCK_TAKE], 'read') || isInitial,
      render: (text, record) => <span>
        {text ? `${text} - ${record.reason_desc}` : '-'}
      </span>
    },
    {
      title: t("Inventory.is_ing"),
      dataIndex: 'is_ingredient',
      key: 'is_ingredient',
      sorter: true,
      className: 'enable-click',
      align: 'center',
      render: (text, record) => record?.is_semi_product === "1" ? text === "1" ? t("General.yes") : "-" : t("General.no")
    },
    {
      title: t("Inventory.netsuite_sync_?"),
      dataIndex: 'netsuite_synced',
      key: 'netsuite_synced',
      sorter: true,
      className: 'enable-click',
      hidden: !data.status == "posted",
      render: (text) => text === "1" ? <Tooltip title={t("Inventory.synced_to_netsuite")}><CheckCircleTwoTone twoToneColor="#52c41a" className="ml-1" /></Tooltip> : "No"
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="stock_take_item"
          btnAction1={editable ? () => {
            setVisible(true);
            setValue('id', record.id);
            setValue('item', record.product_id ? { label: record.desc, value: record.product_id } : '');
            setSelectedItem(record.product_id);
            setValue('quantity', record.quantity);
            setValue('reason', record.reason_id ? {label: `${record.reason_code} - ${record.reason_desc}`, value: record.reason_id} : '');
            setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : "");
            setIsSemiProduct(record.is_semi_product === "1");
            // setIsIngredient(record.is_semi_product === "1" ? record.is_ingredient === "1" ? true : false : true);
            setValue('is_ingredient', record.is_ingredient === "1");
          } : false}
          onRemove={(data?.status === "pending" && allowedDelete) && onRemove}
          recordId={record.id}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'item',
      label: t("General.item"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.products, (e) => ({ ...e, label: `${e.code} ${e.name}`, value: e.id })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      onChange: (e) => {
        setSelectedItem(e.value);
        let inventoryUom = e.product_uoms?.find(x => x.is_inventory_uom === "1");
        setValue('uom', inventoryUom ? { label: inventoryUom?.code, value: inventoryUom?.id } : '');
        if (e.is_semi_product === "1") {
          setIsSemiProduct(true)
          // setIsIngredient(true)
          // setValue("is_ingredient", true);
        } else {
          setIsSemiProduct(false)
          // setIsIngredient(true)
          // setValue("is_ingredient", false);
        }
      },
      disabled: getValues('id') ? true : false
    },
    {
      name: 'unit_cost',
      label: t("Inventory.unit_cost"),
      req: isInitial,
      placeholder: t("General.pls_enter"),
      type: 'input',
      number: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      hidden: !isInitial,
    },
    {
      name: 'quantity',
      label: t("General.qty"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'input',
      negativeNumber: data?.is_initial === "1",
      number: data?.is_initial === "0" ? true : undefined, // false return undefined to fix console warning
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      id: "focusQtyInput",
      selectAllOnFocus: true,
    },
    {
      name: 'reason',
      label: t("System.reason"),
      req: false,
      placeholder: t("General.please_select"),
      type: 'select',
      class: 'default-select',
      options: _.map(meta?.reasons, (e) => ({ label: `${e.code} - ${e.desc}`, value: e.id })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
    },
    {
      name: 'uom',
      label: t("General.uom"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'select',
      class: 'default-select',
      options: meta?.products?.find(item => item.id === selectedItem)?.product_uoms.map(item => ({ label: item.code, value: item.id })),
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      hidden: !allowed([AllRoles.INVENTORY.STOCK_TAKE], 'read'),
      // hidden: !allowed([AllRoles.INVENTORY.STOCK_TAKE], 'read') || !isIngredient,
    },
    // {
    //   name: 'is_ingredient',
    //   label: t("Product.is_ingredient"),
    //   req: false,
    //   type: 'switch',
    //   twocol: false,
    //   colWidth: '0 1 100%',
    //   reqmessage: t("General.required"),
    //   hidden: !isSemiProduct,
    //   onChange: (e) => {
    //     setIsIngredient(e)
    //   }
    // },
  ];

  useEffect(() => {
    if (activeTab === "2")
      dispatch(getStakeItemList(1, limit, '', '', null, setLoad, id));
  }, [activeTab]);

  useEffect(() => {
    if(stakeItems?.list) {
      setDataSource(stakeItems?.list);
    }
  }, [stakeItems]);

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('item', '');
    setValue('quantity', '');
    setValue('reason', '');
    setValue('uom', '');
    setValue('unit_cost', '');
    setIsSemiProduct(false);
    setValue('is_ingredient', false);
    // setIsIngredient(true);
    setSelectedItem();
    clearErrors();
  }

  const btnList = [
    {
      text: t("General.dl_sample"),
      classes: 'green-btn attendance-success',
      btnHidden: !isInitial,
      action: () => handleDownload(),
    },
    {
      text: t("General.import_items"),
      classes: 'attendance-success',
      // isUploadCsv: true,
      btnHidden: !isInitial,
      action: () => setUploadVisible(true)
    },
    {
      text: `+ ${t("New.item")}`,
      classes: 'attendance-success',
      action: () => addNew(),
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    // setPage(pagination.current);
    // setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getStakeItemList(1, limit, sorter.order, sorter.columnKey, searchVal, setLoad, id));
    } else {
      dispatch(getStakeItemList(1, limit, '', '', searchVal, setLoad, id));
    }
  }
  
  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        product_code: search?.product_code
      };
      setSearchVal(searching);
      dispatch(getStakeItemList(1, limit, '', '', searching, setLoad, id));
    } else {
      setSearchVal(null);
      dispatch(getStakeItemList(1, limit, '', '', null, setLoad, id));
    }
  };

  const onFinish = async (val, reload = true) => {
    setLoad(true);

    const payload = {
      opening_unit_cost: val?.unit_cost,
      quantity: val?.quantity,
      reason_id: val?.reason?.value,
      product_id: val?.item?.value,
      uom_id: val?.uom?.value,
      stock_take_item_id: val?.id,
      stock_take_id: id,
      is_ingredient: isSemiProduct ? "1" : "0",
    }
    await (val?.id ? editStakeItem : addStakeItem)(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(t(`Success.stock_take_item_${val?.id ? "updated" : "added"}`));
        if (reload) {
          setVisible(false);
          setPage(1);
          reloadCount();
          setTimeout(() => dispatch(getStakeItemList(1, limit, '', '', searchVal, setLoad, id)), 500);
        } else {
          const newData = [...dataSource];
          const index = newData.findIndex((item) => res.data.data.id === item.id);
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...res.data.data,
          });
          setDataSource(newData);
        }
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onRemove = async (recordId) => {
    setLoad(true);

    const payload = {
      stock_take_item_id: recordId,
      stock_take_id: id
    }
    await removeStakeItem(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(t("Success.stock_take_item_removed"));
        setVisible(false);
        setPage(1);
        reloadCount();
        setTimeout(() => dispatch(getStakeItemList(1, limit, '', '', searchVal, setLoad, id)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  // when import stock take please prompt a popup for user
  const handleUploadFile = (e) => {
    const file = e.file
    setFileObj(e.file.originFileObj)
    setFileList([{ uid: file?.originFileObj.uid, name: file?.name, status: 'done', url: "" }]);
  }

  const handleUpload = async (val) => {
    if(!fileList.length) {
      message.error(t("General.pls_upload_file"));
      return
    }

    props.setLoading(true);
    try {
      let res = await importStItemCsv(fileObj, id, val?.is_sync_unit_cost);
      props.setLoading(false);
      if(res === undefined) return;
      if(res === false) {
        message.error(t("General.import_err_try_again"));
      } else {
        if(res?.data?.code === 200) {
          reloadCount();
          setFileObj()
          setFileList([]);
          setValueUpload("is_sync_unit_cost", false);
          message.success(t("Success.imported"));
          dispatch(getStakeItemList(1, limit, '', '', searchVal, setLoad, id));
        }else {
          message.error(res?.data?.message);
        }
      }

    } catch (error) {
      message.error(`${t("General.error_during_upload")} ${error}`);
      props.setLoading(false);
    }
  };

  const handleDownload = () => {
    // window.open(meta?.sample_url, '_blank');
    // saveAs(`${meta?.domain_url}/stock-take-item-sample.png`);
    saveAs("https://byebug-crm-production.s3.ap-southeast-1.amazonaws.com/img/stock-take-item-sample.png");
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? t("General.edit") : t("General.add")} {t("Inventory.stake_item")}
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('item', '');
              setValue('quantity', '');
              setValue('reason', '');
              setValue('uom', '');
              setSelectedItem();
              setIsSemiProduct(false);
              setValue('is_ingredient', false);
              // setIsIngredient(true);
              clearErrors();
            }}>{t("General.cancel")}</Button></Col>
            {
              editable &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('item', '');
      setValue('quantity', '');
      setValue('reason', '');
      setValue('uom', '');
      setSelectedItem();
      setIsSemiProduct(false);
      setValue('is_ingredient', false);
      // setIsIngredient(true);
      clearErrors();
    },
  };

  // when import stock take please prompt a popup for user
  const uploadPopup = {
    closable: false,
    visibility: uploadVisible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmitUpload(handleUpload)}>
        <Space size={10} direction='vertical' className='w-100'>
          <Col span={24} className="text-center">
            <Upload 
              onChange={(e) => handleUploadFile(e)}
              maxCount={1} 
              listType="picture-card"
              customRequest={dummyRequest} 
              showUploadList={false} 
              fileList={fileList}
              className={!isInitial ? 'd-none' : ''}
            >
              <Space size={4}>
                {fileList?.length > 0 ? (
                  <div>
                    <UploadOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      {fileList[0]?.name}
                    </div>
                  </div>
                ) :
                  <div>
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      {t("General.upload")}
                    </div>
                  </div>
                }
              </Space>
            </Upload>
          </Col>
          <Col span={24}>
            <SwitchField
              fieldname={"is_sync_unit_cost"}
              label={"Update Ingredient Default Unit Cost? "}
              iProps={{ size: 'large' }}
              control={controlUpload}
              initValue={syncUnitCost}
              onChange={(e) => setSyncUnitCost(e)}
            />
          </Col>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setUploadVisible(false);
              setValueUpload("is_sync_unit_cost", false);
              setFileObj()
              setFileList([]);
            }}>{t("General.cancel")}</Button></Col>
            <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.submit")}</Button></Col>
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setUploadVisible(false);
      setValueUpload("is_sync_unit_cost", false);
      setFileObj()
      setFileList([]);
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click") && editable){
          setValue('id', record.id);
          setValue('item', record.product_id ? { label: record.desc, value: record.product_id } : '');
          setValue('quantity', record.quantity);
          setValue('reason', record.reason_id ? { label: `${record.reason_code} - ${record.reason_desc}`, value: record.reason_id } : '');
          setSelectedItem(record.product_id);
          setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : "");
          setIsSemiProduct(record.is_semi_product === "1");
          setValue('is_ingredient', record.is_ingredient === "1");
          // setIsIngredient(record.is_semi_product === "1" ? record.is_ingredient === "1" ? true : false : true);
          setValue('unit_cost', record.unit_cost);
          setVisible(true);
        } else {
          return e;
        }
      },
    };
  };

  const handleSave = (row, oldrow, colname) => {
    let isDirty = false;
    if (colname === "quantity") {
      if (parseFloat(row.quantity) > parseFloat(row.quantity_threshold)) {
        if (confirm(t("Confirm.unusual_quantity_proceed"))) {
        } else {
          return;
        }
      }
      isDirty = parseFloat(row.quantity).toFixed(5) !== parseFloat(oldrow.quantity).toFixed(5);
      row = {
        ...row,
        quantity: parseFloat(row.quantity).toFixed(5)
      }
    } else if (colname === "unit_cost") {
      isDirty = parseFloat(row.unit_cost).toFixed(8) !== parseFloat(oldrow.unit_cost).toFixed(8);
      row = {
        ...row,
        unit_cost: parseFloat(row.unit_cost).toFixed(8)
      }
    } else {
      isDirty = row[colname] !== oldrow[colname];
    }
    if (isDirty) {
      const payload = {
        unit_cost: row?.unit_cost,
        quantity: row?.quantity,
        reason: { value: row?.reason_id },
        item: { value: row?.product_id },
        uom: { value: row?.uom_id },
        id: row?.id,
      }
      onFinish(payload, false);
    }
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={''} btnList={editable ? btnList : []} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            cellEditable={true}
            onSearch={onSearch}
            Search={Search}
            onRow={onClickRow}
            ListData={dataSource?.map((x, idx) => ({ ...x, key: x.id, index: idx + 1}))}
            onChange={onTableChange}
            ListCol={colName.filter(x => !x.hidden)}
            pagination={false}
          />
        </Col>
      </Row>
      <Popup {...popup} />
      <Popup {...uploadPopup} />
    </Spin>
  )
}