import React, { useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import ActionButton from 'Molecules/ActionButton';
import FormGroup from 'Molecules/FormGroup';
import { bulkRemoveVendorProducts, removeVendorProduct, editVendorProduct } from '../../../../../ducks/services' // update unit cost
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import AllRoles from '../../../../../../../../routing/config/AllRoles';
import { allowed } from '../../../../../../../../routing/config/utils';
import { useTranslate } from 'Translate';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const { id, data, meta, allowedEdit, reloadApi } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'supplier',
      label: t("Product.supplier"),
      placeholder: 'Supplier',
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: true
    },
    {
      name: 'eff_date',
      label: t("Product.eff_date"),
      placeholder: t("General.please_select"),
      req: true,
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: 'Effective Date is Required',
      static: !allowedEdit
    },
    {
      name: 'currency',
      label: t("Product.currency_code"),
      placeholder: t("General.please_select"),
      type: 'select',
      req: true,
      options: meta?.currencies?.map((x) => ({ label: x.code, value: x.id })),
      twocol: false,
      colWidth: '0 1 100%',
      class: 'default-select',
      reqmessage: 'Currency is Required',
      static: !allowedEdit
    },
    {
      type: 'input',
      name: 'price',
      label: t("General.price"),
      placeholder: t("General.pls_enter"),
      number: true,
      req: true,
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'uom',
      label: t("Product.uom_code"),
      placeholder: t("General.please_select"),
      type: 'select',
      req: true,
      options: meta?.uoms?.map((x) => ({ label: x.code, value: x.id })),
      twocol: false,
      colWidth: '0 1 100%',
      class: 'default-select',
      reqmessage: 'UOM is Required',
      static: !allowedEdit
    },
    {
      type: 'select',
      label: t("General.regions"),
      name: 'regions',
      twocol: false,
      req: false,
      multiple: true,
      options: meta?.regions?.map((x) => ({ label: x.code, value: x.id })),
      placeholder: t("General.please_select"),
      class: "default-select",
      colWidth: '0 1 100%',
      static: !allowedEdit
    },
  ]

  const colName = [
    {
      title: t("Product.supplier_code"),
      dataIndex: 'vendor_code',
      key: 'vendor_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Product.supplier_name"),
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Product.eff_date"),
      dataIndex: 'eff_date',
      key: 'eff_date',
      className: 'enable-click',
      sorter: (a, b) => a.eff_date.localeCompare(b.eff_date)
    },
    {
      title: t("Product.currency_code"),
      dataIndex: 'currency_code',
      key: 'currency_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.unit_price"),
      dataIndex: 'price',
      key: 'price',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Product.uom_code"),
      dataIndex: 'uom_code',
      key: 'uom_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.regions"),
      dataIndex: 'regions',
      key: 'regions',
      className: 'enable-click',
      sorter: true,
      render: (text) => <span>{text?.map((x) => x.code).join(", ") || "-"}</span>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="item_supplier"
          btnAction1={() => {
            setValue('id', record.id);
            setValue('price', record.price);
            setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : '');
            setValue('currency', record.currency_id ? { label: record.currency_code, value: record.currency_id } : '');
            setValue('eff_date', record.eff_date ? dayjs(record.eff_date) : '');
            setValue('supplier', `${record.vendor_code} ${record.vendor_name}`);
            let temp = [];
            record?.regions?.map(region => {
              temp.push({
                label: region.code,
                value: region.id
              })
            })
            setValue('regions', temp);
            setVisible(true);
          }}
          recordId={record.id}
          onRemove={allowed([AllRoles.PRODUCT.INGREDIENT], 'delete') && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const btnList = [
    {
      title: t("Product.confirm_remove_select_item_supplier"),
      text: t("General.bulk_remove"),
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      id: id,
      vendor_product_id: val?.id,
      price: val?.price,
      eff_date: val?.eff_date ? dayjs(val?.eff_date).format("YYYY-MM-DD") : "",
      currency_id: val?.currency?.value,
      uom_id: val?.uom?.value,
      regions: val?.regions ? JSON.stringify(val?.regions?.map(x => ({ id: x.value }))) : "[]",
    }

    await editVendorProduct(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(t("Product.success_edit_select_item_supplier"));
        setVisible(false);
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onRemove = async (recId) => {
    setLoad(true);

    const payload = {
      id: id,
      vendor_product_id: recId,
      status_event: "remove"
    }

    await removeVendorProduct(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        setVisible(false);
        message.success(t("Product.success_remove_item_supplier"));
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onBulkRemove = async () => {
    setLoad(true);

    const payload = {
      id: id,
      vendor_products: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveVendorProducts(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success(t('Product.success_remove_item_suppliers'));
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const popup2 = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            {t("Product.edit_item_supplier")}
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => setVisible(false)}>{t("General.cancel")}</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")){
          setValue('id', record.id);
          setValue('price', record.price);
          setValue('uom', record.uom_id ? { label: record.uom_code, value: record.uom_id } : '');
          setValue('currency', record.currency_id ? { label: record.currency_code, value: record.currency_id } : '');
          setValue('eff_date', record.eff_date ? dayjs(record.eff_date) : '');
          setValue('supplier', `${record.vendor_code} ${record.vendor_name}`);
          let temp = [];
          record?.regions?.map(region => {
            temp.push({
              label: region.code,
              value: region.id
            })
          })
          setValue('regions', temp);
          setVisible(true);
        }
      },
    };
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            listClass="nopad"
            ListData={data?.vendor_products?.map(x => ({ ...x, key: x.id }))}
            onRow={onClickRow}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            pagination={true
              // {
              //   total: alacartes?.total_count,
              //   current: page,
              //   pageSize: limit
              // }
            }
          />
        </Col>
      </Row>
      <Popup {...popup2} />
    </>
  )
}