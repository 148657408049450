import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getKitchenDisplays } from '../ducks/actions'
import { removeKitchenDisplay, bulkRemoveKitchenDisplays } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.pos.kitchenDisplays);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);

  const colName = [
    {
      title: t("Pos.kitchen_display_code"),
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.desc"),
      dataIndex: 'desc',
      key: 'desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="kitchen_display"
          btnAction1={() => navigate(`/pos/kitchen-display/${record.id}`)}
          recordId={record.id}
          onRemove={onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const addNew = () => navigate('/pos/kitchen-display/add');

  const btnList = [
    {
      title: t("Confirm.remove_select_kitchen_displays"),
      text: t("General.bulk_remove"),
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: `+ ${t("New.kitchen_display")}`,
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    dispatch(getKitchenDisplays(1, limit, '', '', null, props.setLoading));
  }, []);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getKitchenDisplays(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
    } else {
      dispatch(getKitchenDisplays(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        code: search.code // code_cont_or_desc_cont
      };
      setSearchVal(searching);
      dispatch(getKitchenDisplays(1, limit, '', '', searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getKitchenDisplays(1, limit, '', '', null, props.setLoading));
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      kitchen_display_id: id,
      status_event: 'remove'
    }

    await removeKitchenDisplay(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.remove_kitchen_display"));
        setTimeout(() => dispatch(getKitchenDisplays(page, limit, '', '', searchVal, props.setLoading)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      kitchen_displays: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveKitchenDisplays(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success(t("Success.remove_kitchen_display"));
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getKitchenDisplays(1, limit, '', '', null, props.setLoading)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/pos/kitchen-display/${record.id}`)
      },
    };
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={t("General.kitchen_display")} btnList={btnList} btnHidden={selectedRowKeys.length <= 0} />
      </Col>
      <Col span={24} className="clickRow">
        <ListCard
          onRow={onClickRow}
          Search={Search}
          onSearch={onSearch}
          ListData={data?.list?.map(x => ({ ...x, key: x.id }))}
          onChange={onTableChange}
          ListCol={colName}
          rowSelection={rowSelection}
          pagination={{
            total: data?.total_count,
            current: page,
            pageSize: limit
          }}
        />
      </Col>
    </Row>
  )
}