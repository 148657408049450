import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message, Spin, Space, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FormGroup from 'Molecules/FormGroup';
import { InputField } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import { Popup } from 'Atoms/Popup';
import { useTranslate } from 'Translate';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

const _ = require('lodash');

export default (props) => {
  const { id, data, editPosUserPassword, reloadApi, mode } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [visible, setVisible] = useState(false);
  const [load, setLoad] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'login_password',
      type: 'password',
      // static: true,
      twocol: false,
      colWidth: "0 1 50%",
      onBtnClick: () => { 
        setVisible(true);
        setValue('new_password', '');
      },
      addOnButton: t("General.edit"),
      readOnly: true,
      class: "readonly"
    }
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('login_password', data.login_password);
    }
  }, [data]);

  const onFinish = async (val) => {
    setLoad(true);
    const payload = {
      login_password: val?.new_password,
      id: id
    }

    await editPosUserPassword(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(t("System.login_pw_update_success"));
        reloadApi();
        setVisible(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {t("System.edit_pw")}
          </Title>
          <Row gutter={[10, 10]}>
            <Col span={24} className='text-center'>
              <InputField
                isRequired={true}
                fieldname="new_password"
                label={t("System.login_pw")}
                control={control}
                initValue=""
                iProps={{
                  size: 'large',
                  type: 'number'
                }}
                rules={{
                  required: t("General.pls_enter_password"),
                  pattern: {
                    value: /^\d{6}$/,
                    message: t("System.pw_6_num"),
                  },
                  validate: (e) => e !== data?.login_password || t("System.pw_cannot_same")
                }}
                validate={errors.new_password && 'error'}
                validMessage={errors.new_password && errors.new_password.message}
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
            }}>{t("General.cancel")}</Button></Col>
            <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
    },
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Row gutter={[20, 30]}>
          {formFields.map((item, idx) => (
            <Fragment key={idx}>
              <FormGroup item={item} control={control} errors={errors} />
            </Fragment>
          ))}
        </Row>
      </Form>
      <Popup {...popup} />
    </>
  )
}