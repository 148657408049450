export const REPORTING_STOCK_BALANCE = 'REPORTING_STOCK_BALANCE';
export const REPORTING_SALES_ORDER_LIST = 'REPORTING_SALES_ORDER_LIST';
export const REPORTING_DAILY_SUMMARY = 'REPORTING_DAILY_SUMMARY';
export const REPORTING_SALES_PAYMENT = 'REPORTING_SALES_PAYMENT';
export const REPORTING_SALES_DETAILS = 'REPORTING_SALES_DETAILS';
export const REPORTING_SALES_SUMMARY = 'REPORTING_SALES_SUMMARY';
export const REPORT_SALES_SUMMARY_OPTION = 'REPORT_SALES_SUMMARY_OPTION';
export const REPORTING_SALES_HOURLY = 'REPORTING_SALES_HOURLY';
export const REPORTING_SALES_VOUCHER_LISTING = 'REPORTING_SALES_VOUCHER_LISTING';
export const REPORTING_BILL_SUMMARY = 'REPORTING_BILL_SUMMARY';
export const REPORTING_BILL_SUMMARY_LISTING = 'REPORTING_BILL_SUMMARY_LISTING';
export const REPORTING_SALES_DISCOUNT_LISTING = 'REPORTING_SALES_DISCOUNT_LISTING';
export const REPORTING_CASH_MANAGEMENT_LISTING = 'REPORTING_CASH_MANAGEMENT_LISTING';
export const REPORTING_PRODUCT_SALES_LISTING = 'REPORTING_PRODUCT_SALES_LISTING';
export const REPORTING_SPEED_OF_SERVICE_LISTING = 'REPORTING_SPEED_OF_SERVICE_LISTING';
export const REPORTING_CANCELLED_SALES_LISTING = 'REPORTING_CANCELLED_SALES_LISTING';
export const REPORTING_TAX_CHARGES_LISTING = 'REPORTING_TAX_CHARGES_LISTING';
export const REPORTING_TAX_CHARGES_DETAILS = 'REPORTING_TAX_CHARGES_DETAILS';
export const REPORTING_POS_AUDIT_LOG_LISTING = 'REPORTING_POS_AUDIT_LOG_LISTING';
export const REPORTING_INVENTORY = 'REPORTING_INVENTORY';
export const REPORTING_INVENTORY_META = 'REPORTING_INVENTORY_META';
