import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Form, Button, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, addTerminal, updateTerminal, meta, data, mode, reloadApi, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'username',
      label: t("Pos.pos_id"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'alias',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'location',
      label: t("Outlet.outlet_code"),
      req: true,
      type: 'select',
      options: meta?.locations?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'pos_type',
      label: t("Pos.pos_type"),
      req: true,
      type: 'select',
      options: meta?.pos_types?.map((x) => ({ label: x, value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
      onChange: (e) => {
        if(e.value === "server") {
          setValue('login_no', { label: '01', value: '01' });
          setIsDisabled(true)
        } else {
          setValue('login_no', data?.login_no ? { label: data.login_no, value: data.login_no } : '');
          setIsDisabled(false)
        }
      }
    },
    {
      name: 'login_no',
      label: t("Pos.pos_no"),
      req: true,
      type: 'select',
      options: meta?.login_nos?.map((x) => ({ label: x, value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
      disabled: isDisabled,
    },
    {
      name: 'cc_ip',
      label: t("Pos.cc_terminal_ip"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'cc_model',
      label: t("Pos.cc_model"),
      req: true,
      type: 'select',
      options: meta?.cc_models?.map((x) => ({ label: x, value: x })),
      placeholder: t("General.please_select"),
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
    },
  ];

  useEffect(() => {
    if (data && 'id' in data) {
      setValue('username', data.username);
      setValue('alias', data.alias);
      setValue('location', { label: `${data.location_code} ${data.location_desc ? data.location_desc : ''}`, value: data.location_id });
      setValue('pos_type', data.login_type ? { label: data.login_type, value: data.login_type } : '');
      setIsDisabled(data.login_type === "server")
      setValue('login_no', data.login_no ? { label: data.login_no, value: data.login_no } : '');
      setValue('cc_ip', data.cc_ip);
      setValue('cc_model', { label: `${data.cc_model}`, value: data.cc_model });
    }
  }, [data]);

  const onFinish = async (val) => {
    if (mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      location_id: val?.location?.value,
      login_type: val?.pos_type?.value,
      login_no: val?.login_no?.value,
      cc_model: val?.cc_model?.value,
      id: id
    }

    await updateTerminal(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.updated_pos_terminal"));
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      location_id: val?.location?.value,
      login_type: val?.pos_type?.value,
      login_no: val?.login_no?.value,
      cc_model: val?.cc_model?.value,
    }
    await addTerminal(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.create_pos_terminal"));
        setTimeout(() => reloadApi(res.data.data), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}