import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Space, Button, message } from 'antd';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationDetail } from '../ducks/actions'
import { editOrganization, deleteOrganization } from '../ducks/services'
import { LoadingOutlined } from '@ant-design/icons';
import AddForm from './components/AddForm';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { unsetEntity } from 'Modules/Country/ducks/services';
import { Popup } from 'Atoms/Popup';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.systems.entity);
  const meta = useSelector((state) => state.systems.entityOption);
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const [load, setLoad] = useState(false);
  const id = localStorage.getItem("current_organization_id");
  const allowedEdit = allowed([AllRoles.SYSTEM.SETUP], 'write');
  const allowedDelete = allowed([AllRoles.SYSTEM.SETUP], 'delete');
  const [deleteVisible, setDeleteVisible] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    dispatch(getOrganizationDetail(id, setLoad))
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  const reloadApi = (data) => {
    dispatch(getOrganizationDetail(id, setLoad));
  }

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  const switchEntity = async () => {
    setLoad(true);

    await unsetEntity().then(() => {
      setLoad(false);
      localStorage.removeItem("current_organization_id");
      navigate("/country");
    }).catch(e => {
      message.error(e.message ?? t("General.something_went_wrong"))
    });
  };

  const onDelete = () => {
    setDeleteVisible(true);
  };

  const onFinishDelete = async () => {
    props.setLoading(true);
    const payload = {
      id: id,
      status_event: 'remove'
    }
    await deleteOrganization(payload).then((response) => {
      props.setLoading(false);
      if (response.data.code === 200) {
        message.success(t("Success.entity_deleted"));
        setDeleteVisible(false);
        switchEntity();
      } else {
        message.error(response.data.message);
      }
    }).catch((e) => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    });
  };

  const deletePopUp = {
    closable: false,
    visibility: deleteVisible,
    content: <Spin indicator={antIcon} size="large" spinning={props.loading}>
      <Space direction='vertical' size={30} className='w-100'>
        <Title level={4} className='m-0'>
          {t("System.del_this_entity")}
        </Title>
        <Row gutter={[10, 10]}>
          {t("System.once_del_no_go_back")}
        </Row>
        <Row gutter={10} justify={'center'}>
          <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
            setDeleteVisible(false);
          }}>{t("General.cancel")}</Button></Col>
          {
            <Col span={12}><Button size='large' type="primary" className='green-btn w-100' onClick={onFinishDelete}>{t("General.confirm")}</Button></Col>
          }
        </Row>
      </Space>
    </Spin>,
    width: 650,
    onCancel: () => {
      setDeleteVisible(false);
    },
  };

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={load}>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'}>
              <Col flex='auto'>
                <Title level={3} className='mb-0 mt-0'>{t("System.setup")}</Title>
              </Col>
                {
                  data?.is_owner &&
                  <Col flex='auto' className='text-right'>
                    <Button size="large" className="red-btn text-white attendance-success mr-2" onClick={onDelete}>Delete Entity</Button>
                  </Col>
                }
            </Row>
          </Col>
          <Col span={24}>
            <AddForm
              loading={load}
              setLoading={setLoad}
              meta={meta}
              activeTab={activeTab}
              setActiveTab={setActive}
              data={data}
              searchParams={searchParams}
              editOrganization={editOrganization}
              id={id}
              reloadApi={reloadApi}
              allowedEdit={allowedEdit}
              allowedDelete={allowedDelete}
            />
          </Col>
        </Row>
      </Spin>
      <Popup {...deletePopUp} />
    </>
  )
}