import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography, Form, message, Spin } from 'antd';
import InputForm from '../components/InputForm';
import { useForm } from 'react-hook-form';
import { getKitchenQueueOption } from '../../ducks/actions';
import { addKitchenQueue } from "../../ducks/services";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'Translate';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {

  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const meta = useSelector((state) => state.pos.kitchenQueueOption);
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    dispatch(getKitchenQueueOption(props.setLoading));
  }, []);

  const onFinish = async (val) => {
    props.setLoading(true);

    const payload = {
      code: val.code,
      desc: val.desc,
      kitchen_queue_type: val?.kitchen_queue_type?.value,
      display_mode: val?.kitchen_display?.value,
      slip_settings: val?.slip_settings?.value,
      slip_print_mode: val?.slip_print_mode?.value,
      is_readonly: val?.is_readonly ? 1 : 0,
    }
    await addKitchenQueue(payload).then(res => {
      props.setLoading(false);
      if(res.data.code === 200) {
        message.success(t("Success.create_kitchen_queue"));
        setTimeout(() => navigate("/pos/kitchen-queue"), 500);
      }else{
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>{t("New.kitchen_queue")}</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                <Button size="large" htmlType='submit' className='green-btn'>{t("General.save")}</Button>
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/pos/kitchen-queue')}>{'<'} {t("General.back")}</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <InputForm
          control={control}
          errors={errors}
          meta={meta}
          allowedEdit={true}
        />
      </Row>
    </Form>
  )
}