import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Typography, Button, Space, Spin, Form, message } from 'antd';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOutlet } from '../ducks/actions'
import { editOutlet, editOutletReceipt, duplicateOutlet } from '../ducks/services'
import AddForm from '../components/AddForm';
import { LoadingOutlined } from '@ant-design/icons';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { Popup } from 'Atoms/Popup';
import { useForm } from 'react-hook-form';
import FormGroup from 'Molecules/FormGroup';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.outlet.outlet);
  const meta = useSelector((state) => state.outlet.option);
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const [load, setLoad] = useState(false);
  const [duplicateVisible, setDuplicateVisible] = useState(false);
  const { control, formState: { errors }, handleSubmit, setValue, clearErrors } = useForm();
  const i18n = useTranslate();
  const { t } = i18n;

  const duplicateFormFields = [
    {
      name: 'duplicate',
      label: t("Outlet.outlet_code"),
      placeholder: t("General.please_select"),
      req: true,
      type: 'input',
      reqmessage: t("General.required"),
      twocol: false,
      colWidth: '1 0 100%',
      class: 'default-select'
    }
  ];

  useEffect(() => {
    reloadApi();
  }, []);

  useEffect(() => {
    if (searchParams.get('t')) {
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams]);

  const reloadApi = () => {
    dispatch(getOutlet(id, setLoad));
  }

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  };

  const onDuplicate = async (val) => {
    setLoad(true);

    const payload = {
      id: id,
      code: val.duplicate
    }
    await duplicateOutlet(payload).then(res => {
      setLoad(false);
      if (res.data.code === 200) {
        message.success(t("Outlet.outlet_duplicated_success"));
        setDuplicateVisible(false);
        setTimeout(() => {
          navigate(`/outlets/${res.data.data}`);
          window.location.reload();
        }, 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const popup2 = {
    closable: false,
    visibility: duplicateVisible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onDuplicate)}>
        <Space direction='vertical' size={15} className='w-100'>
          <Title level={4} className='m-0'>
            {t("Outlet.duplicate_outlet")}
          </Title>
          <Row gutter={[10, 10]}>
            {duplicateFormFields?.map((x, idx) => (
              <Fragment key={idx}>
                <FormGroup item={x} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setDuplicateVisible(false);
              setValue('duplicate', '');
            }}>{t("General.cancel")}</Button></Col>
            {
              allowed([AllRoles.OUTLET.OUTLET], 'write') &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setDuplicateVisible(false);
      setValue('duplicate', '');
    },
  };

  return (
    <>
      <Spin indicator={antIcon} size="large" spinning={load}>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Row justify={'space-between'} align={'middle'}>
              <Col flex='auto'>
                <Title level={3} className='mb-0 mt-0'>{data?.code} - {data?.desc}</Title>
              </Col>
              <Col flex='auto' className='text-right'>
                <Space size={10}>
                  {
                    allowed([AllRoles.OUTLET.OUTLET], 'write') &&
                    <Button type='primary' htmlType='button' className='b-success attendance-success' onClick={() => {
                      setDuplicateVisible(true);
                      setValue('duplicate', '');
                    }}>
                      {t("Outlet.duplicate_outlet")}
                    </Button>
                  }
                  <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/outlets')}>{'<'} {t("General.back")}</Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <AddForm
              loading={props.loading}
              setLoading={setLoad}
              meta={meta}
              activeTab={activeTab}
              setActiveTab={setActive}
              data={data}
              searchParams={searchParams}
              id={id}
              editOutlet={editOutlet}
              editOutletReceipt={editOutletReceipt}
              mode={'edit'}
              navigate={navigate}
              reloadApi={reloadApi}
              allowedEdit={allowed([AllRoles.OUTLET.OUTLET], 'write')}
              allowedDelete={allowed([AllRoles.OUTLET.OUTLET], 'delete')}
            />
          </Col>
        </Row>
      </Spin>
      <Popup {...popup2} />
    </>
  )
}