import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Typography, Table, message, Empty } from 'antd';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getTaxChargesListing } from '../ducks/actions'
import { exportSalesReport } from '../ducks/services'
import Search from './components/Search';
import dayjs from 'dayjs';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { getListQueryItems } from 'Modules/Application/ducks/actions';
import { comma } from 'Features/utility';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;
const _ = require('lodash');

export default (props) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.reporting.taxChargesListing);
  const meta = useSelector((state) => state.reporting.taxChargesListingOption);
  const [searchVal, setSearchVal] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const i18n = useTranslate();
      const { t } = i18n;

  const btnList = [
    {
      text: t("General.export_report"),
      classes: 'green-btn',
      action: () => exportReport(),
    }
  ];

  useEffect(() => {
    dispatch(getTaxChargesListing(null, props.setLoading, true));
    dispatch(getListQueryItems('setupListQuery', null));
  }, []);

  useEffect(() => {
    if (meta) {
      if ("locations" in meta) {
        let temp = [];
        meta.locations?.map((x, ind) => {
          temp.push({
            label: `${x.code} ${x.desc}`,
            value: x.id
          })
        });
        setOutlets(temp);
      }
    }
  }, [meta]);

  const onSearch = (search) => {
    if (search && 'outlets' in search) {
      let searching = {};
      searching = {
        date_from: search?.start_date ? dayjs(search?.start_date).format("YYYY-MM-DD") : "",
        date_to: search?.end_date ? dayjs(search?.end_date).format("YYYY-MM-DD") : "",
      };
      if (search?.outlets?.length > 0) {
        let temp2 = [];
        search?.outlets?.map(x => {
          temp2.push(x.value)
        });
        searching["location_ids"] = JSON.stringify(temp2);
      }
      setSearchVal(searching);
      dispatch(getTaxChargesListing(searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getTaxChargesListing(null, props.setLoading));
    }
  };

  const exportReport = async () => {
    if (!orders?.total_count || orders?.total_count === 0) {
      return message.warning(t("General.no_data_export"));
    }
    props.setLoading(true);

    await exportSalesReport(searchVal, "tax_charges_listing").then((res) => {
      props.setLoading(false);
      if (res.data) {
        const aElement = document.createElement('a');
        let filename = res.headers["x-filename"];
        aElement.setAttribute('download', filename ?? "sample.pdf");
        const href = URL.createObjectURL(res.data);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  // test this
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {/* update roles to TAX_CHARGES_DETAILS, create new permission */}
          <HeadingChip title={t("General.tax_charges_list_report")} btnList={allowed([AllRoles.REPORTING.SALES_DETAILS], 'write') ? btnList : null} />
        </Col>
        <Col span={24} className="">
          <Card bordered={false}>
            <Search onSearch={onSearch} field1={outlets} />

            <div className="table-responsive" style={{ overflowX: 'auto', marginTop: "30px" }}>
              <table className='table table-borderless w-100' style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th className=''>{t("General.business_date")}</th>
                    <th className=''>{t("Outlet.outlet")}</th>
                    <th className='text-right'>{t("SalesReporting.trans_count")}</th>
                    <th className='text-right'>{t("Crm.taxable_amt")}</th>
                    <th className='text-right'>{t("Inventory.tax_amt")}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orders?.list?.length <= 0 &&
                    <tr>
                      <td colSpan="5">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  }
                  {
                    orders?.list?.length > 0 &&
                    orders?.list?.map((x, ind) => {
                      return (
                        <Fragment key={ind}>
                          <tr>
                            <td colSpan="2" className='fwbold'>{"Tax: "} {x.tax_code}</td>
                          </tr>
                          {
                            x.list?.map((y, yIndex) => {
                              return (
                                <tr key={yIndex}>
                                  <td style={{paddingLeft: "15px"}}>{y.date}</td>
                                  <td>{y.location_code}</td>
                                  <td align='right'>{comma(y.trans_count)}</td>
                                  <td align='right'>{comma(parseFloat(y.taxable_amount).toFixed(2))}</td>
                                  <td align='right'>{comma(parseFloat(y.tax_amount).toFixed(2))}</td>
                                </tr>
                              )
                            })
                          }
                          <tr className='bg-gray text-white fwbold'>
                            <td align='' colSpan="2">{t("Crm.subtotal")}</td>
                            {
                              x?.sub_totals && Object.entries(x?.sub_totals)?.map(([key, data], zIndex) => {
                                return (
                                  <td key={zIndex} align='right'>{comma(data)}</td>
                                )
                              })
                            }
                          </tr>
                        </Fragment>
                      )
                    })
                  }
                  <tr className='bg-gray fwbold text-white'>
                    {
                      orders?.list?.length <= 0
                        ? <td colSpan="5">{t("SalesReporting.grand_total")}</td>
                        : <td align='' colSpan="2">{t("SalesReporting.grand_total")}</td>
                    }
                    {
                      orders?.grand_totals && Object.entries(orders?.grand_totals)?.map(([key, data], index) => {
                        return (
                          <td key={index} align='right'>
                            {comma(data)}
                          </td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}