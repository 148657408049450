import React from 'react';
import { Row, Typography, Col, Button, Space } from "antd";
import { useTranslate } from 'Translate';
import { CloseCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
const { Title, Text } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const i18n = useTranslate();
  const { t } = i18n;

  return (
    <Row gutter={[30, 24]} justify={'center'}>
        <Col span={24}><Button type="link" onClick={() => navigate("/")}>{"<"} {t("General.back")}</Button></Col>
        <Col span={12} className='text-center'>
          <Space direction="vertical" size={15}>
            <CloseCircleFilled className='c-danger' style={{ fontSize: '46px' }} />
            <Title className='mb-0' level={1}>Access Denied</Title>
            <Text level={1}>You don't have access to this page</Text>
          </Space>
        </Col>
    </Row>
  )
}