import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { data, mode, meta, updateVoucher } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [isUserBorne, setIsUserBorne] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  const disabledDates = (current) => {
    return current && current < dayjs().startOf('day');
  }

  const formFields = [
    {
      name: 'voucher_type',
      label: t("Pos.voucher_type"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'code',
      label: t("Pos.voucher_code"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'apply_method',
      label: t("Pos.apply_method"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'apply_reference',
      label: t("Pos.apply_ref"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'from',
      label: t("Product.eff_date"),
      placeholder: t("General.please_select"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'to',
      label: t("Pos.exp_date"),
      placeholder: t("General.please_select"),
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      static: data?.status !== "Active",
      disabledDate: disabledDates
    },
    {
      name: 'discount_amount',
      label: t("Pos.voucher_val"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'discount_type',
      label: t("Pos.voucher_val_type"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'min_spend_amount',
      label: t("Pos.min_spend_amt"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true
    },
    {
      name: 'quantity',
      label: t("General.qty"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true,
      tips: t("Pos.enter_0_if_voucher_import")
    },
    {
      name: 'is_user_borne',
      label: t("Pos.user_borne_voucher"),
      placeholder: t("General.please_state"),
      type: 'switch',
      twocol: false,
      colWidth: '0 1 100%',
      static: true
    },
    {
      name: 'user_type',
      label: t("Pos.user_type"),
      hidden: !isUserBorne,
      placeholder: t("General.please_select"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true,
      class: 'SentanceCase'
    },
    {
      name: 'no_of_usage',
      label: t("Pos.no_usage"),
      hidden: !isUserBorne,
      placeholder: t("General.please_select"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      static: true,
      class: 'SentanceCase'
    },
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      // setValue('code', data.code);
      setValue('code', data.code);
      setValue('from', data.from);
      setValue('to', data.to ? dayjs(data.to) : null);
      setValue('status', data.status);
      setValue('voucher_type', `${data.voucher_type_code} (${data.voucher_type_desc})`);
      setValue('discount_amount', data.discount_amount);
      setValue('discount_type', data.discount_type);
      setValue('quantity', data.quantity);
      setValue('min_spend_amount', data.min_spend_amount);
      setValue('apply_method', data.apply_method);
      setValue('apply_reference', data.apply_reference);
      setValue('is_user_borne', data.is_user_borne === "1");
      setValue('no_of_usage', data?.no_of_uses ? meta?.no_of_usages?.find(x => x.value === data?.no_of_uses)?.label : '');
      setValue('user_type', data?.user_type ? meta?.user_types?.find(x => x.value === data?.user_type)?.label : '');
      setIsUserBorne(data.is_user_borne === "1");
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      voucher_id: data?.id,
      to: val?.to ? dayjs(val?.to).format("YYYY-MM-DD") : null
    }

    await updateVoucher(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.update_voucher"));
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        <Col span={24} className='text-right'>
          <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
        </Col>
      </Row>
    </Form>
  )
}