import React from 'react';
import ScreenListing from '../components/ScreenListing';
import { getAdList } from '../../../../../../../ducks/actions';

export default (props) => {
  const { data } = props;

  return (
    <>
      <ScreenListing
        {...props}
        isKiosk={"0"}
        getAdList={getAdList}
      />
    </>
  )
}