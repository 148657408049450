import React, { useEffect, Fragment } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import { useTranslate } from 'Translate';

const _ = require('lodash');

export default (props) => {
  const { id, data, updateCategory, reloadApi, mode, meta, allowedEdit } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const i18n = useTranslate();
  const { t } = i18n;

  const formFields = [
    {
      name: 'code',
      label: t("Product.category_code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      name: 'name',
      label: t("General.desc"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !allowedEdit
    },
    {
      type: 'select',
      label: t("Product.remark_types"),
      name: 'remark_types',
      twocol: false,
      req: false,
      multiple: true,
      options: meta?.remark_types?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      placeholder: t("General.please_select"),
      colWidth: '0 1 100%',
      static: !allowedEdit
    }
  ];

  useEffect(() => {
    if (data && 'id' in data && mode !== 'add') {
      setValue('code', data.code);
      setValue('name', data.name);
      let temp = [];
      data?.remark_types?.map(rt => {
        temp.push({
          label: `${rt.code} ${rt.desc}`,
          value: rt.id
        })
      })
      setValue('remark_types', temp);
    }
  }, [data]);

  const onFinish = async (val) => {
    props.setLoading(true);
    const payload = {
      ...val,
      remark_types: JSON.stringify(val?.remark_types?.map(x => ({ id: x.value }))),
      category_id: id
    }

    await updateCategory(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Product.update_cat_success"));
        reloadApi();
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message);
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          allowedEdit &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}