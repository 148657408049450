export const USER_LIST = 'USER_LIST';
export const USER_DETAIL = 'USER_DETAIL';
export const USER_OPTION = 'USER_OPTION';
export const USER_ROLES = 'USER_ROLES';
export const ROLE_OPTION = 'ROLE_OPTION';

export const POS_USER_LIST = 'POS_USER_LIST';
export const POS_USER_OPTION = 'POS_USER_OPTION';
export const POS_USER_DETAIL = 'POS_USER_DETAIL';
export const POS_USER_ROLES = 'POS_USER_ROLES';
export const POS_ROLE_OPTION = 'POS_ROLE_OPTION';

export const CURRENCY_LIST = 'CURRENCY_LIST';
export const CURRENCY_DETAIL = 'CURRENCY_DETAIL';

export const PAYMENT_TERM_LIST = 'PAYMENT_TERM_LIST';
export const PAYMENT_TERM_DETAIL = 'PAYMENT_TERM_DETAIL';

export const REGION_LIST = 'REGION_LIST';
export const REGION_DETAIL = 'REGION_DETAIL';

export const TAX_CONDITION_LIST = 'TAX_CONDITION_LIST';
export const TAX_CONDITION_DETAIL = 'TAX_CONDITION_DETAIL';
export const TAX_CONDITION_ITEM_LIST = 'TAX_CONDITION_ITEM_LIST';

export const REASON_LIST = 'REASON_LIST';
export const REASON_DETAIL = 'REASON_DETAIL';
export const REASON_OPTION = 'REASON_OPTION';

export const REMARK_LIST = 'REMARK_LIST';
export const REMARK_DETAIL = 'REMARK_DETAIL';

export const REMARK_TYPE_LIST = 'REMARK_TYPE_LIST';
export const REMARK_TYPE_DETAIL = 'REMARK_TYPE_DETAIL';
export const REMARK_TYPE_ITEM_LIST = 'REMARK_TYPE_ITEM_LIST';

export const TAXES_LIST = 'TAXES_LIST';

export const PAYMENT_METHOD_LIST = 'PAYMENT_METHOD_LIST';
export const PAYMENT_METHOD_DETAIL = 'PAYMENT_METHOD_DETAIL';
export const PAYMENT_METHOD_OPTION = 'PAYMENT_METHOD_OPTION';

export const PAYMENT_METHOD_TYPE_LIST = 'PAYMENT_METHOD_TYPE_LIST';
export const PAYMENT_METHOD_TYPE_DETAIL = 'PAYMENT_METHOD_TYPE_DETAIL';
export const PAYMENT_METHOD_TYPE_OPTION = 'PAYMENT_METHOD_TYPE_OPTION';

export const ENTITY_DETAIL = 'ENTITY_DETAIL';

export const AD_LIST = 'AD_LIST';

export const MERCHANT_LIST = 'MERCHANT_LIST';
export const MERCHANT_OPTION = 'MERCHANT_OPTION';
export const MERCHANT_DETAIL = 'MERCHANT_DETAIL';

export const ORDER_TYPE_GROUP_LIST = 'ORDER_TYPE_GROUP_LIST';
export const ORDER_TYPE_GROUP_DETAIL = 'ORDER_TYPE_GROUP_DETAIL';

export const ENTITY_USER_LIST = 'ENTITY_USER_LIST';

export const NETSUITE_SYNC_LOGS = 'NETSUITE_SYNC_LOGS';