import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Upload, Empty } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useDispatch, useSelector } from 'react-redux';
import { getBlockedOrderTypeList } from '../../../../ducks/actions'
import { addBlockedOrderType, removeBlockedOrderType, bulkRemoveBlockedOrderType } from '../../../../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { SelectField, CheckboxGroup } from 'Atoms/FormElement';
import { useForm } from 'react-hook-form';
import { dummyRequest, getFileName, uploadFileV2 } from 'Features/utility';
import { InputField } from '../../../../../../atoms/FormElement';
import { useTranslate } from 'Translate';

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, meta, activeTab, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const boms = useSelector((state) => state.product.boms);
  const blockedOrderTypeListData = useSelector((state) => state.outlet.blockedOrderTypeListData);
  const blockedOrderTypeListMeta = useSelector((state) => state.outlet.blockedOrderTypeListMeta);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rec, setRecord] = useState();
  const [fileList, setFileList] = useState([]);
  const [fileId, setFileId] = useState();
  const [load, setLoad] = useState(false);
  const [orderTypeList, setOrderTypeList] = useState([]);
  const i18n = useTranslate();
  const { t } = i18n;

  const colName = [
    {
      title: t("Outlet.order_type_code"),
      dataIndex: 'order_type_code',
      key: 'order_type_code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("Outlet.order_type_desc"),
      dataIndex: 'order_type_desc',
      key: 'order_type_desc',
      className: 'enable-click',
      sorter: true
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 200,
      render: (text, record) =>
        <ActionButton
          title="block_order_type"
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const addNew = () => { 
    setVisible(true);
    setRecord();
    setFileId();
    setFileList([]);
    resetOrderTypeList();
    setValue('order_type', '');
    setValue('order_types', '');
    setValue('price', '');
    setValue('promo_price', '');
  }

  const btnList = [
    {
      title: t("Outlet.confirm_remove_selected_order_types"),
      text: t("General.bulk_remove"),
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: `+ ${t("Outlet.new_order_type")}`,
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    if (activeTab === "5")
    dispatch(getBlockedOrderTypeList(1, limit, '', '', props.setLoading, id));
  }, [activeTab]);

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getBlockedOrderTypeList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, props.setLoading, id));
    } else {
      dispatch(getBlockedOrderTypeList(pagination.current, pagination.pageSize, '', '', props.setLoading, id));
    }
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (blockedOrderId) => {
    props.setLoading(true);

    const payload = {
      id: id,
      blocked_order_type_id: blockedOrderId,
      status_event: 'remove'
    }

    await removeBlockedOrderType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Outlet.blocked_order_type_remove_success"));
        setTimeout(() => dispatch(getBlockedOrderTypeList(1, limit, '', '', props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      id: id,
      blocked_order_types: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveBlockedOrderType(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success(t("Outlet.blocked_order_types_remove_success"));
        setTimeout(() => dispatch(getBlockedOrderTypeList(1, limit, '', '', props.setLoading, id)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      order_type_id: val?.order_type?.value,
      order_type_ids: JSON.stringify(val.order_types) || "[]",
      id: id,
    }

    await addBlockedOrderType(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(t("Outlet.blocked_order_type_added_success"));
        setVisible(false);
        setValue('order_type', '');
        setValue('order_types', '');
        setTimeout(() => dispatch(getBlockedOrderTypeList(1, limit, '', '', props.setLoading, id)), 500);
      }else {
        message.error(res.data.message);
      }
    }).catch(e =>{
      setLoad(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    });
  };


  useEffect(() => {
    if(meta && 'order_types' in meta) {
      resetOrderTypeList();
    }
  }, [meta, boms]);

  const resetOrderTypeList = () => {
    let temp = blockedOrderTypeListMeta?.order_types?.map(x => ({ label: x.desc, value: x.id }))
    temp = temp?.filter(x => blockedOrderTypeListData?.list?.filter(y => y.order_type_desc === x.label).length <= 0); // need check
    setOrderTypeList(temp);
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={20} className='w-100'>
          <Title level={4} className='m-0'>
            {rec ? t("General.edit") : t("General.add")} {t("General.order_type")}
          </Title>
          {
            (
              orderTypeList?.length === 0 ?
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              :
                // <Space size={10} className='w-100' style={ orderTypeList?.length > 2 ? {display: 'flex'} : {}}>
                  <CheckboxGroup
                    fieldname={'order_types'}
                    label={''}
                    class={`mb-1 fullWidth-checbox`}
                    control={control}
                    initValue={[]}
                    option={orderTypeList}
                    rules={{
                      required: 'Order Type is required',
                    }}
                    validate={errors.order_types && 'error'}
                    validMessage={errors.order_types && errors.order_types.message}
                  />
                // </Space>
            )
          }
          <Row gutter={10} justify={'center'}>
            <Col span={orderTypeList?.length === 0 && !rec ? 24 : 12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('order_type', '');
              setValue('order_types', '');
              setValue('price', '');
              setValue('promo_price', '');
              rec && setRecord();
              resetOrderTypeList();
            }}>{t("General.cancel")}</Button></Col>
            {
              (orderTypeList?.length > 0 || rec) && allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 536,
    onCancel: () => {
      setVisible(false);
      setValue('order_type', '');
      setValue('order_types', '');
      setValue('price', '');
      setValue('promo_price', '');
      setRecord();
      resetOrderTypeList();
    },
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit ? btnList : null} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24}>
          <ListCard
            listClass="nopad"
            ListData={blockedOrderTypeListData?.list?.map(x => ({ ...x, key: x.id }))}
            onChange={onTableChange}
            ListCol={colName}
            rowSelection={rowSelection}
            pagination={{
              total: blockedOrderTypeListData?.total_count,
              current: page,
              pageSize: limit
            }}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}