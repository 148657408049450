import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Typography, Form, Button, Popover, message } from 'antd';
import { useForm } from 'react-hook-form';
import { InputField, InputPassword } from 'Atoms/FormElement';
import { authentications } from '../../../services/services';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../features/userSlice';
import { useTranslate } from 'Translate';
import RoutingList from '../../../routing/config/RoutingList';

const { Title } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control, handleSubmit, formState: { errors } } = useForm();

  const i18n = useTranslate();
  const { t } = i18n;

  const onFinish = (values) => {
    props.setLoading(true);
    // localStorage.clear();
    authentications(values.username, values.password)
      .then((response) => {
        performLogin(response);
      })
      .catch(error => {
        props.setLoading(false);
        message.error(error?.data?.message || `${error}`);
      });
  };

  const performLogin = (response) => {
    let res = {
      access_token: response.data.meta.access_token, // frappe access token
      byebug_access_token: response.data.data.access_token, // byebug access token
      expires_in: response.data.data.expires_in,
      refresh_token: response.data.data.refresh_token,
    };

    if (res) {
      let data = response.data.data;
      let orgId = data.current_organization_id;
      localStorage.setItem('userid', JSON.stringify(data.id));
      if(data.name) localStorage.setItem('userName', data.name);
      if (orgId) localStorage.setItem('current_organization_id', orgId);
      if (data.role_list) localStorage.setItem('access', JSON.stringify(data.role_list));
      if (data.photo_url) localStorage.setItem('userImage', data.photo_url);
      if (data?.organization_name) localStorage.setItem('organization_name', data.organization_name);
      if (data?.is_superadmin) localStorage.setItem('is_superadmin', data.is_superadmin);
      localStorage.setItem('token', JSON.stringify(res));
      dispatch(
        login({
          username: data.email,
        }),
      );
      setTimeout(() => {
        props.setLoading(false);
        if (orgId) {
          // Redirect user that has dashboard access to dashboard page after login
          const userRoleObj = Object.keys(data.role_list[0])[0]
          const userAccessList = data.role_list[0][userRoleObj]

          if(userAccessList.dashboard.all === 1 || userAccessList.dashboard.delete === 1 || userAccessList.dashboard.read === 1 || userAccessList.dashboard.write === 1) {
            navigate('/dashboard')
          } else {
            let pageName = ''

            for(const key in userAccessList) {
              if(userAccessList[key].all === 1 || userAccessList[key].delete === 1 || userAccessList[key].write === 1 || userAccessList[key].read === 1) {
                pageName = key
                break
              }
            }

            const navPath = RoutingList.find(item => item?.permission?.includes(pageName))?.path

            navigate(navPath)

          }
        }else{
          navigate('/country');
        }
      }, 1000);
    }
  }

  return (
    <Row gutter={[30, 24]}>
      <Col span={24}>
        <Title level={3} className="text-center mb-0">
          {t('Login.title')}
        </Title>
      </Col>
      <Col span={24}>
        <Form name="normal_login" onFinish={handleSubmit(onFinish)}>
          <InputField
            fieldname="username"
            control={control}
            initValue=""
            iProps={{
              size: 'large',
              placeholder: t('Login.username_or_email'),
            }}
            rules={{
              required: 'Please input username or email',
              pattern: {
                value: /(?=^.{1,50}$)^\S*$/,
                message: 'Input username or email without space!',
              },
            }}
            validate={errors.username && 'error'}
            validMessage={errors.username && errors.username.message}
          />
          <InputPassword
            fieldname="password"
            control={control}
            initValue=""
            iProps={{
              size: 'large',
              placeholder: t('Login.password'),
            }}
            rules={{
              required: t("General.pls_enter_password"),
            }}
            validate={errors.password && 'error'}
            validMessage={errors.password && errors.password.message}
          />
          <Button type="primary" htmlType="submit" className="w-100" size="large">
            {t('Login.button')}
          </Button>
        </Form>
      </Col>
      <Col span={24} className="text-center">
        <Link to="/forgot-password" className="login-form-forgot">
          {t('Login.forget')}
        </Link>
      </Col>
    </Row>
  );
};
