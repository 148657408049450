import React, { useEffect, Fragment, useState } from 'react';
import { Button, Row, Col, Form, message } from 'antd';
import FormGroup from 'Molecules/FormGroup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslate } from 'Translate';

const _ = require('lodash');
const todayDate = new Date();

export default (props) => {
  const { id, data, meta, addStockConversion, editStockConversion, reloadApi, mode, allowedEdit, generateInventoryNo } = props;
  const { control, formState: { errors }, handleSubmit, setValue } = useForm();
  const [editable, setEditable] = useState(true);
  const [locationId, setLocationId] = useState(null);
  const orderTypeGroupsDropdown = meta?.locations?.find((x) => x.id === locationId)?.order_type_groups?.map(y => ({ label: `${y.code} ${y.desc}`, value: y.id }))
  const i18n = useTranslate();
    const { t } = i18n;

  const formFields = [
    {
      name: 'reference',
      label: t("Inventory.sc_no"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable || meta?.is_outlet_manager,
      addOnButton: t("General.generate"),
      onBtnClick: () => { 
        onGenerateInventoryNo()
      },
    },
    {
      name: 'date',
      label: t("Inventory.sc_date"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'date',
      format: 'YYYY-MM-DD',
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
      static: !editable
    },
    {
      type: 'select',
      label: t("Outlet.outlet"),
      name: 'location',
      twocol: false,
      options: meta?.locations?.map((x) => ({ label: `${x.code} ${x.desc}`, value: x.id })),
      req: true,
      placeholder: t("General.please_select"),
      reqmessage: 'Outlet Required',
      colWidth: '0 1 50%',
      static: !editable || meta?.is_outlet_manager,
      onChange: (e) => {
        setLocationId(e.value)
        setValue('order_type_group', '')
      }
    },
    {
      name: 'order_type_group',
      label: t("Inventory.recipe"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'select',
      class: 'default-select',
      options: orderTypeGroupsDropdown,
      twocol: false,
      colWidth: '0 1 50%',
      reqmessage: t("General.required"),
    },
    {
      name: 'remarks',
      label: t("System.remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable
    },
    {
      name: 'cancellation_remarks',
      label: t("Inventory.cancellation_remarks"),
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      static: !editable,
      hidden: data && data?.cancellation_remarks ? false : true
    },
  ];

  useEffect(() => {
    if(!data || !Object.keys(data).length) {
      setValue('date', dayjs(todayDate))
    }

    if (data && 'id' in data && mode !== 'add') {
      setValue('reference', data.reference);
      setValue('date', data?.date ? dayjs(data?.date) : '');
      setValue('location', data?.location_id ? { label: `${data?.location_code} ${data?.location_desc}`, value: data?.location_id } : '');
      setValue('order_type_group', data?.order_type_group_id ? { label: `${data?.order_type_group_code} ${data?.order_type_group_desc}`, value: data?.order_type_group_id } : '');
      setValue('remarks', data?.remarks);
      setLocationId(data?.location_id);
      setValue('cancellation_remarks', data?.cancellation_remarks);
      setEditable(data.status === "pending" && allowedEdit);
    }

    if (meta && mode === 'add') {
      if (meta?.is_outlet_manager) {
        let outlet = meta?.locations?.find(x => x.id === meta?.current_location_id);
        setValue('location', outlet?.id ? { label: `${outlet?.code} ${outlet?.desc}`, value: outlet?.id } : '');
        setValue('reference', meta?.reference);
        setLocationId(outlet.id);
      }
    }
  }, [data, meta]);

  const onGenerateInventoryNo = async () => {
    if(!locationId) {
      message.error(t("General.please_select_outlet"));
      return
    }
    props.setLoading(true);

    const payload = {
      stock_conversion_id: id,
      location_id: locationId
    }

    await generateInventoryNo(payload, 'stock-conversions').then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        setValue('reference', res.data.data)
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onFinishAdd = async (val) => {
    props.setLoading(true);

    const payload = {
      ...val,
      location_id: val?.location?.value,
      order_type_group_id: val?.order_type_group?.value,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : ''
    }

    await addStockConversion(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.create_stock_conversion"));
        reloadApi(res.data.data);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  const onFinish = async (val) => {
    if(mode === 'add') {
      return onFinishAdd(val);
    }
    props.setLoading(true);
    const payload = {
      ...val,
      location_id: val?.location?.value,
      order_type_group_id: val?.order_type_group?.value,
      date: val?.date ? dayjs(val?.date).format("YYYY-MM-DD") : '',
      stock_conversion_id: id,
    }

    await editStockConversion(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.update_stock_conversion"));
        setTimeout(() => reloadApi(res.data.data), 250);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[20, 30]}>
        {formFields.map((item, idx) => (
          <Fragment key={idx}>
            <FormGroup item={item} control={control} errors={errors} />
          </Fragment>
        ))}
        {
          editable &&
          <Col span={24} className='text-right'>
            <Button size="large" htmlType='submit' className='green-btn attendance-success'>{t("General.save")}</Button>
          </Col>
        }
      </Row>
    </Form>
  )
}