import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Row, Col, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AddForm from '../components/AddForm';
import { addDiscount } from "../../ducks/services"
import { getDiscountOption } from '../../ducks/actions';
import { useTranslate } from 'Translate';

const _ = require('lodash');
const { Title, Text } = Typography;

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const meta = useSelector((state) => state.pos.discountOption);
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    dispatch(getDiscountOption(props.setLoading));
  }, []);

  return (
    <Row gutter={[20, 30]}>
      <Col span={24}>
        <Row justify={'space-between'} align={'middle'}>
          <Col flex='auto'>
            <Title level={3} className='mb-0 mt-0'>{t("New.discount")}</Title>
          </Col>
          <Col flex='auto' className='text-right'>
            <Space size={10}>
              <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/pos/discount')}>{'<'} {t("General.back")}</Button>
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <AddForm
          loading={props.loading}
          setLoading={props.setLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          oneTab={true}
          mode={'add'}
          meta={meta}
          addDiscount={addDiscount}
          navigate={navigate}
          reloadApi={(propId) => navigate(`/pos/discount/${propId}`)}
          allowedEdit={true}
        />
      </Col>
    </Row>
  )
}