import React, { useEffect, useState, Fragment } from 'react';
import { Row, Col, message, Spin, Space, Button, Typography, Form, Switch } from 'antd';
import ListCard from 'Molecules/ListCard';
import HeadingChip from 'Molecules/HeadingChip';
import FormGroup from 'Molecules/FormGroup';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'Atoms/Popup';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import ActionButton from 'Molecules/ActionButton';
import { addAddress, editAddress, removeAddress, bulkRemoveAddresses } from "../../../../../ducks/services";
import { useTranslate } from 'Translate';

const _ = require('lodash');

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { id, reloadApi, data, allowedEdit, allowedDelete } = props;
  const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [load, setLoad] = useState(false);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const i18n = useTranslate();
  const { t } = i18n;

  const colName = [
    {
      title: t("Outlet.phone_number"),
      dataIndex: 'phone_no',
      key: 'phone_no',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("Outlet.address_1"),
      dataIndex: 'address',
      key: 'address',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("Outlet.address_2"),
      dataIndex: 'address_2',
      key: 'address_2',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("Outlet.postal_code"),
      dataIndex: 'postcode',
      key: 'postcode',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("Outlet.city"),
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("Outlet.state"),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      className: 'enable-click'
    },
    {
      title: t("Crm.default_shipping_address"),
      dataIndex: 'default_shipping_address',
      key: 'default_shipping_address',
      sorter: true,
      render: (text, record) => <Switch disabled={!allowedEdit} checked={text == '1' ? true : false} onClick={() => onStatus("default_shipping_address", record)} />
    },
    {
      title: t("Crm.default_billing_address"),
      dataIndex: 'default_billing_address',
      key: 'default_billing_address',
      sorter: true,
      render: (text, record) => <Switch disabled={!allowedEdit} checked={text == '1' ? true : false} onClick={() => onStatus("default_billing_address", record)} />
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (text, record) =>
        <ActionButton
          title="address"
          btnAction1={() => {
            setValue('id', record.id);
            setValue('phone_no', record.phone_no);
            setValue('address', record.address);
            setValue('address_2', record.address_2);
            setValue('postcode', record.postcode);
            setValue('city', record.city);
            setValue('state', record.state);
            setValue('default_shipping_address', record.default_shipping_address == "1");
            setValue('default_billing_address', record.default_billing_address == "1");
            setVisible(true);
          }}
          recordId={record.id}
          onRemove={allowedDelete && onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const formFields = [
    {
      name: 'id',
      label: '',
      type: 'input',
      hidden: true,
    },
    {
      name: 'phone_no',
      label: t("Outlet.phone_number"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
    },
    {
      name: 'address',
      label: t("Outlet.address_1"),
      req: true,
      placeholder: t("General.please_select"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
    },
    {
      name: 'address_2',
      label: t("Outlet.address_2"),
      req: false,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
    },
    {
      name: 'postcode',
      label: t("Outlet.postal_code"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
    },
    {
      name: 'city',
      label: t("Outlet.city"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
    },
    {
      name: 'state',
      label: t("Outlet.state"),
      req: true,
      placeholder: t("General.please_state"),
      type: 'input',
      twocol: false,
      colWidth: '0 1 100%',
      reqmessage: t("General.required"),
      static: !allowedEdit,
    },
    {
      type: 'switch',
      name: 'default_shipping_address',
      label: t("Crm.default_shipping"),
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      static: !allowedEdit,
      alignEnd: true
    },
    {
      type: 'switch',
      name: 'default_billing_address',
      label: t("Crm.default_billing"),
      req: false,
      twocol: true,
      colWidth: '1 0 50%',
      alignEnd: true,
      static: !allowedEdit,
    },
  ];

  const addNew = () => {
    setVisible(true);
    setValue('id', '');
    setValue('phone_no', '');
    setValue('address', '');
    setValue('address_2', '');
    setValue('postcode', '');
    setValue('city', '');
    setValue('state', '');
    setValue('default_shipping_address', false);
    setValue('default_billing_address', false);
  }

  const btnList = [
    {
      title: t("Confirm.remove_select_addresses"),
      text: t("General.bulk_remove"),
      classes: 'red-btn text-white attendance-success',
      permit: true,
      notooltip: true,
      action: () => onBulkRemove()
    },
    {
      text: `+ ${t("New.address")}`,
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRemove = async (prodId) => {
    props.setLoading(true);

    const payload = {
      user_id: id,
      address_id: prodId,
      status_event: 'remove'
    }

    await removeAddress(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.remove_address"));
        setTimeout(() => reloadApi(), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      user_id: id,
      addresses: JSON.stringify(selectedRowKeys)
    }

    await bulkRemoveAddresses(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success(t("Success.remove_addresses"));
        setSearchVal();
        setTimeout(() => reloadApi(), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onFinish = async (val) => {
    setLoad(true);

    const payload = {
      ...val,
      default_shipping_address: val?.default_shipping_address ? 1 : 0,
      default_billing_address: val?.default_billing_address ? 1 : 0,
      address_id: val?.id,
      user_id: id
    }

    await (val?.id ? editAddress : addAddress)(payload).then(res => {
      setLoad(false);
      if(res.data.code === 200) {
        message.success(t(`Success.address_${val?.id ? "edited" : "added"}`));
        setVisible(false);
        setTimeout(() => reloadApi(), 250);
      }else {
        message.error(res.data.message);
      }
    }).catch(e => {
      setLoad(false);
      message.error(e?.message)
    })
  }

  const onStatus = async (sType, record) => {
    props.setLoading(true);
    let payload = {
      ...record,
      address_id: record.id,
      user_id: id
    }
    payload[sType] = parseInt(record[sType]) === 1 ? 0 : 1

    await editAddress(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.updated_default"));
        setTimeout(() => reloadApi(), 250);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e?.message)
    })
  }

  const popup = {
    closable: false,
    visibility: visible,
    content: <Spin indicator={antIcon} size="large" spinning={load}>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Space direction='vertical' size={30} className='w-100'>
          <Title level={4} className='m-0'>
            {getValues('id') ? t("General.edit") : "Add New"} {t("Delete.address")}
          </Title>
          <Row gutter={[10, 10]}>
            {formFields.map((item, idx) => (
              <Fragment key={idx}>
                <FormGroup item={item} control={control} errors={errors} />
              </Fragment>
            ))}
          </Row>
          <Row gutter={10} justify={'center'}>
            <Col span={12}><Button size='large' danger type="primary" className='w-100' onClick={() => {
              setVisible(false);
              setValue('id', '');
              setValue('phone_no', '');
              setValue('address', '');
              setValue('address_2', '');
              setValue('postcode', '');
              setValue('city', '');
              setValue('state', '');
              setValue('default_shipping_address', false);
              setValue('default_billing_address', false);
            }}>{t("General.cancel")}</Button></Col>
            {
              allowedEdit &&
              <Col span={12}><Button size='large' type="primary" className='green-btn w-100' htmlType='submit'>{t("General.confirm")}</Button></Col>
            }
          </Row>
        </Space>
      </Form>
    </Spin>,
    width: 650,
    onCancel: () => {
      setVisible(false);
      setValue('id', '');
      setValue('phone_no', '');
      setValue('address', '');
      setValue('address_2', '');
      setValue('postcode', '');
      setValue('city', '');
      setValue('state', '');
      setValue('default_shipping_address', false);
      setValue('default_billing_address', false);
    },
  };

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click")) {
          setValue('id', record.id);
          setValue('phone_no', record.phone_no);
          setValue('address', record.address);
          setValue('address_2', record.address_2);
          setValue('postcode', record.postcode);
          setValue('city', record.city);
          setValue('state', record.state);
          setValue('default_shipping_address', record.default_shipping_address == "1");
          setValue('default_billing_address', record.default_billing_address == "1");
          setVisible(true);
        }
      },
    };
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <HeadingChip title={""} btnList={allowedEdit && btnList} btnHidden={selectedRowKeys.length <= 0} />
        </Col>
        <Col span={24} className="clickRow">
          <ListCard
            onRow={onClickRow}
            ListData={data?.addresses?.map(x => ({ ...x, key: x.id }))}
            ListCol={colName}
            rowSelection={allowedEdit && rowSelection}
            pagination={true}
          />
        </Col>
      </Row>
      <Popup {...popup} />
    </>
  )
}