export const PRODUCT_LIST = 'PRODUCT_LIST';
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL';
export const PRODUCT_OPTION = 'PRODUCT_OPTION';
export const BOM_LIST = 'BOM_LIST';
export const PRODUCT_GROUP_OPTION = 'PRODUCT_GROUP_OPTION';
export const PRODUCT_MENU_LIST = 'PRODUCT_MENU_LIST';
export const PRODUCT_MENU_DETAIL = 'PRODUCT_MENU_DETAIL';
export const ORDER_TYPE_LIST = 'ORDER_TYPE_LIST';
export const ORDER_TYPE_DETAIL = 'ORDER_TYPE_DETAIL';
export const ORDER_TYPE_OPTION = 'ORDER_TYPE_OPTION';
export const INGREDIENT_LIST = 'INGREDIENT_LIST';
export const INGREDIENT_DETAIL = 'INGREDIENT_DETAIL';
export const ALACARTE_LIST = 'ALACARTE_LIST';
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const CATEGORY_DETAIL = 'CATEGORY_DETAIL';
export const SUB_CATEGORY_LIST = 'SUB_CATEGORY_LIST';
export const FURTHER_SUBCATEGORY_LIST = 'FURTHER_SUBCATEGORY_LIST';
export const PRODUCT_MENU_TYPE_LIST = 'PRODUCT_MENU_TYPE_LIST';
export const MENU_CATEGORY_OPTION = 'MENU_CATEGORY_OPTION';
export const MENU_CATEGORY_DETAIL = 'MENU_CATEGORY_DETAIL';
export const MENU_ITEM_LIST = 'MENU_ITEM_LIST';
export const AVAILABLE_PRODUCT_LIST = 'AVAILABLE_PRODUCT_LIST';
export const UOM_LIST = 'UOM_LIST';
export const UOM_DETAIL = 'UOM_DETAIL';
export const PRODUCT_AVAILABILITY_LIST = 'PRODUCT_AVAILABILITY_LIST';
export const PRODUCT_AVAILABILITY_DETAIL = 'PRODUCT_AVAILABILITY_DETAIL';
export const PRODUCT_UOM_LIST = 'PRODUCT_UOM_LIST';
export const SELECTED_MENU_TYPE = 'SELECTED_MENU_TYPE';
export const KIOSK_VALUE = 'KIOSK_VALUE';
export const MENU_SYNC_LOGS = 'MENU_SYNC_LOGS';