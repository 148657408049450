import AllRoles from './AllRoles';

const titles = {
  InventoryReporting: 'InventoryReporting',
};

export const InventoryReporting = [
  {
    component: 'StockBalance',
    path: '/inventory-reporting/stock-balance',
    title: titles.InventoryReporting,
    key: 'stock-balance',
    menu: 'Inv. Reporting',
    submenu: 'Stock Balance',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.INVENTORY_REPORTING.STOCK_BALANCE],
  },
  {
    component: 'Inventory',
    path: '/inventory-reporting/inventory',
    title: titles.InventoryReporting,
    key: 'inventory',
    menu: 'Inv. Reporting',
    submenu: 'Inventory',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.INVENTORY_REPORTING.INVENTORY_REPORT],
  },
  {
    component: 'NetsuiteSync',
    path: '/inventory-reporting/inv-netsuite-sync',
    title: titles.InventoryReporting,
    key: 'inv-netsuite-sync',
    menu: 'Inv. Reporting',
    submenu: 'Netsuite Sync',
    icon: 'OverviewIcon',
    parent: true,
    parenticon: 'SetupIcon',
    permission: [AllRoles.INVENTORY_REPORTING.STOCK_BALANCE],
  },
]