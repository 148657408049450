import React from 'react';
import Icon from '@ant-design/icons';

const ListSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
    <g id="Group_25254" data-name="Group 25254" transform="translate(551.417 -316.556)">
      <g id="Group_25234" data-name="Group 25234">
        <path
          id="Path_28641"
          data-name="Path 28641"
          d="M-543.8,320.48a3.7,3.7,0,0,1,3.692,3.693,3.7,3.7,0,0,1-3.692,3.693,3.7,3.7,0,0,1-3.691-3.693,3.7,3.7,0,0,1,3.691-3.693m0-3.924a7.616,7.616,0,0,0-7.615,7.617,7.616,7.616,0,0,0,7.615,7.617,7.616,7.616,0,0,0,7.615-7.617,7.616,7.616,0,0,0-7.615-7.617Z"
          fill="#7c7c7c"
        />
      </g>
      <g id="Group_25235" data-name="Group 25235">
        <path
          id="Path_28642"
          data-name="Path 28642"
          d="M-543.8,338.863a3.7,3.7,0,0,1,3.692,3.693,3.7,3.7,0,0,1-3.692,3.692,3.7,3.7,0,0,1-3.691-3.692,3.7,3.7,0,0,1,3.691-3.693m0-3.925a7.617,7.617,0,0,0-7.615,7.618,7.616,7.616,0,0,0,7.615,7.617,7.616,7.616,0,0,0,7.615-7.617,7.616,7.616,0,0,0-7.615-7.618Z"
          fill="#7c7c7c"
        />
      </g>
      <g id="Group_25236" data-name="Group 25236">
        <path
          id="Path_28643"
          data-name="Path 28643"
          d="M-543.8,357.245a3.7,3.7,0,0,1,3.692,3.693,3.7,3.7,0,0,1-3.692,3.693,3.7,3.7,0,0,1-3.691-3.693,3.7,3.7,0,0,1,3.691-3.693m0-3.924a7.616,7.616,0,0,0-7.615,7.617,7.617,7.617,0,0,0,7.615,7.618,7.616,7.616,0,0,0,7.615-7.618,7.616,7.616,0,0,0-7.615-7.617Z"
          fill="#7c7c7c"
        />
      </g>
      <g id="Group_25237" data-name="Group 25237">
        <path
          id="Path_28644"
          data-name="Path 28644"
          d="M-501.378,326.135h-28.715a1.961,1.961,0,0,1-1.962-1.962,1.961,1.961,0,0,1,1.962-1.962h28.715a1.961,1.961,0,0,1,1.961,1.962A1.961,1.961,0,0,1-501.378,326.135Z"
          fill="#7c7c7c"
        />
      </g>
      <g id="Group_25238" data-name="Group 25238">
        <path
          id="Path_28645"
          data-name="Path 28645"
          d="M-501.378,344.518h-28.715a1.962,1.962,0,0,1-1.962-1.962,1.962,1.962,0,0,1,1.962-1.963h28.715a1.962,1.962,0,0,1,1.961,1.963A1.962,1.962,0,0,1-501.378,344.518Z"
          fill="#7c7c7c"
        />
      </g>
      <g id="Group_25239" data-name="Group 25239">
        <path
          id="Path_28646"
          data-name="Path 28646"
          d="M-501.378,362.9h-28.715a1.962,1.962,0,0,1-1.962-1.963,1.962,1.962,0,0,1,1.962-1.962h28.715a1.962,1.962,0,0,1,1.961,1.962A1.962,1.962,0,0,1-501.378,362.9Z"
          fill="#7c7c7c"
        />
      </g>
    </g>
  </svg>
);

const ListIcon = (props) => <Icon component={ListSVG} {...props} />;
export default ListIcon;
