import React, { useEffect, useState } from 'react';
import { Row, Col, message, Button } from 'antd';
import DndListCard from 'Molecules/DndListCard';
import HeadingChip from 'Molecules/HeadingChip';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentMethodList } from '../ducks/actions'
import { removePaymentMethod, bulkRemovePaymentMethods, updatePaymentMethodsOrder } from '../ducks/services'
import ActionButton from 'Molecules/ActionButton';
import Search from './components/Search'
import placeholderImg from "Assets/img/product-placeholder.png"

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.systems.paymentMethods);
  const meta = useSelector((state) => state.systems.paymentMethodOption);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const [list, setDataList] = useState([]);
  const [hasDirty, setHasDirty] = useState(false);

  const colName = [
    {
      title: t("General.image"),
      dataIndex: 'image_url',
      key: 'image_url',
      className: 'enable-click',
      sorter: true,
      render: (text) => <img src={text || placeholderImg} width="100px" />
    },
    {
      title: t("General.code"),
      dataIndex: 'code',
      key: 'code',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.desc"),
      dataIndex: 'name',
      key: 'name',
      className: 'enable-click',
      sorter: true
    },
    {
      title: t("General.status"),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record) =>
        <div className='flex align-items-center'>
          <span className={text === "Active" ? "c-success" : "c-danger"}>
            {t(`General.${text?.toLowerCase()}`)}
          </span>
          {/* {
            // allowed([Roles.USERS], 'write') &&
            <Button type="link" onClick={() => onAction(record.id, text)}>
              {text === "Active" ? `(${t("General.deactivate")})` : `(${t("General.activate")})`}
            </Button>
          } */}
        </div>
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) =>
        <ActionButton
          title="payment_method"
          btnAction1={() => navigate(`/payment-method/${record.id}`)}
          recordId={record.id}
          onRemove={onRemove}
          setLoading={props.setLoading}
          loading={props.loading}
        />
    }
  ];

  const addNew = () => navigate('/payment-method/add');

  const btnList = [
    {
      title: t("Product.confirm_reorder_list"),
      text: t("General.update_listing"),
      classes: 'b-success attendance-success',
      btnHidden: !hasDirty,
      action: () => onUpdateList()
    },
    {
      title: t("Confirm.remove_select_payment_methods"),
      text: t("General.bulk_remove"),
      classes: 'red-btn text-white attendance-success',
      permit: true,
      action: () => onBulkRemove(),
    },
    {
      text: `+ ${t("New.payment_method")}`,
      classes: 'attendance-success',
      action: () => addNew(),
    },
  ];

  useEffect(() => {
    dispatch(getPaymentMethodList(1, limit, '', '', null, props.setLoading));
  }, []);

  useEffect(() => {
    if(data) {
      setDataList(data.list);
    }
  }, [data])

  const onTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
    if (sorter.order) {
      dispatch(getPaymentMethodList(pagination.current, pagination.pageSize, sorter.order, sorter.columnKey, searchVal, props.setLoading));
    } else {
      dispatch(getPaymentMethodList(pagination.current, pagination.pageSize, '', '', searchVal, props.setLoading));
    }
  }

  const onSearch = (search) => {
    setPage(1);
    if (search) {
      let searching = {};
      searching = {
        payment_method_type: search.payment_method_type?.value
      };
      setSearchVal(searching);
      dispatch(getPaymentMethodList(1, limit, '', '', searching, props.setLoading));
    } else {
      setSearchVal(null);
      dispatch(getPaymentMethodList(1, limit, '', '', null, props.setLoading));
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAction = async (templateId, status) => {
    props.setLoading(true);

    const payload = {
      remark_type_id: templateId,
      status_event: status === "Active" ? "deactivate" : "activate"
    }

    await removePaymentMethod(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.update_payment_method_status"));
        setTimeout(() => dispatch(getPaymentMethodList(page, limit, '', '', searchVal, props.setLoading)), 500);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onRemove = async (id) => {
    props.setLoading(true);

    const payload = {
      payment_method_id: id,
      status_event: 'remove'
    }

    await removePaymentMethod(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.remove_payment_method"));
        setTimeout(() => dispatch(getPaymentMethodList(page, limit, '', '', searchVal, props.setLoading)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onBulkRemove = async () => {
    props.setLoading(true);

    const payload = {
      remark_types: JSON.stringify(selectedRowKeys)
    }

    await bulkRemovePaymentMethods(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        rowSelection.onChange([]);
        message.success(t("Success.remove_payment_methods"));
        setPage(1);
        setSearchVal(null);
        setTimeout(() => dispatch(getPaymentMethodList(1, limit, '', '', null, props.setLoading)), 500);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  const onClickRow = (record) => {
    return {
      onClick: (e) => {
        if (e.target.closest(".enable-click"))
          navigate(`/payment-method/${record.id}`)
      },
    };
  };

  const onUpdateList = async () => {
    props.setLoading(true);

    const payload = {
      payment_methods: JSON.stringify(list?.map((d, i) => ({ id: d.id, position: i + 1 })))
    }
    await updatePaymentMethodsOrder(payload).then(res => {
      props.setLoading(false);
      if (res.data.code === 200) {
        message.success(t("Success.order_update_payment_methods"));
        // setTimeout(() => callApi(), 500);
        setHasDirty(false);
      } else {
        message.error(res.data.message);
      }
    }).catch(e => {
      props.setLoading(false);
      message.error(e.message ?? t("General.something_went_wrong"));
    })
  }

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <HeadingChip title={t("General.payment_methods")} btnList={btnList} btnHidden={selectedRowKeys.length <= 0} />
      </Col>
      <Col span={24} className="clickRow">
        <DndListCard
          setHasDirty={setHasDirty}
          setDataList={setDataList}
          list={list}
          Search={Search}
          onSearch={onSearch}
          field1={meta?.payment_method_types}
          ListData={list?.map(x => ({ ...x, key: x.id }))}
          onChange={onTableChange}
          ListCol={colName}
          rowSelection={rowSelection}
          pagination={{
            total: data?.total_count,
            current: page,
            pageSize: limit
          }}
        />
      </Col>
    </Row>
  )
}