import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Space, Spin } from 'antd';
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from '../ducks/actions'
import { editProduct, uploadKdsImage, removeKdsImage } from '../ducks/services'
import AddForm from '../components/AddForm';
import { LoadingOutlined } from '@ant-design/icons';
import AllRoles from '../../../../routing/config/AllRoles';
import { allowed } from '../../../../routing/config/utils';
import { useTranslate } from 'Translate';

const { Title, Text } = Typography;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const data = useSelector((state) => state.product.product);
  const meta = useSelector((state) => state.product.option);
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const i18n = useTranslate();
  const { t } = i18n;

  useEffect(() => {
    callApi();
  }, []);

  const callApi = () => {
    dispatch(getProduct(id, setLoad))
  };

  useEffect(() => {
    if (searchParams.get('t')){
      setActiveTab(searchParams.get('t'));
    }
  }, [searchParams])

  const setActive = (tab) => {
    navigate(`${location}?t=${tab}`);
  }

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Row justify={'space-between'} align={'middle'}>
            <Col flex='auto'>
              <Title level={3} className='mb-0 mt-0'>{data?.code} - {data?.name}</Title>
            </Col>
            <Col flex='auto' className='text-right'>
              <Space size={10}>
                <Button size="large" htmlType='button' className='red-btn text-white' onClick={() => navigate('/product')}>{'<'} {t("General.back")}</Button>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {/* <Card bordered={false} className='transparent-card' style={{
            boxShadow: "0px 0px 32px -4px rgba(45, 64, 85, 0.15)"
          }}> */}
          <AddForm
            loading={props.loading}
            setLoading={setLoad}
            activeTab={activeTab}
            setActiveTab={setActive}
            data={data}
            meta={meta}
            id={id}
            updateProduct={editProduct}
            uploadKdsImage={uploadKdsImage}
            removeKdsImage={removeKdsImage}
            reloadApi={callApi}
            searchParams={searchParams}
            mode={'edit'}
            allowedEdit={allowed([AllRoles.PRODUCT.PRODUCT], 'write')}
            allowedDelete={allowed([AllRoles.PRODUCT.PRODUCT], 'delete')}
          />
          {/* </Card> */}
        </Col>
      </Row>
    </Spin>
  )
}